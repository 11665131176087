import { useDispatch } from "react-redux";
import ClientProvider from "../services/provider/ClientProvider";
import { getClientData } from "../feature/store/ReduxStore";

export default class ClientController {
  constructor() {
    this.clientProvider = new ClientProvider();
    this.dispatch = new useDispatch();
  }
  getClients = async () => {
    var res = await this.clientProvider.getClientList();
    return res.data.sendData;
  };
  deleteClient = async (data) => {
    var res = await this.clientProvider.deleteClient(data);
    return res;
  };
  addClient = async (data) => {
    var res = await this.clientProvider.addNewClient(data);
    return res;
  };
  updateClient = async (data) => {
    var res = await this.clientProvider.updateClient(data);
    return res;
  };
  getClientData = async () => {
    var res = await this.clientProvider.getClientList();
    this.dispatch(getClientData(res.data.sendData));
    // return res;
  };
}
