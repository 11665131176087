import styled from "styled-components";
import { CommonColor } from "../Utils/color";
import { Icon } from "@iconify/react";

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TabItem = styled.p`
  color: ${(props) =>
    props.activeTab ? CommonColor.secondary_color : CommonColor.primary_color};
`;
const AddProfileIcon = styled(Icon)`
  width: 25px;
  height: 25px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
  button {
    margin: 0 !important;
    padding: 5px 10px !important;
    line-height: 1.75 !important;
    margin-top: 0px !important;
    margin-left: 10px !important;
    font-size: 12px !important;
  }
`;
const TeamContainer = styled.div`
  border: 1px solid lightgrey;
  padding: 10px;
  border-radius: 5px;
  span {
    font-size: 11px;
    color: darkgrey;
  }
  hr {
    border: none;
    color: lightgray;
  }
`;
const ViewListButton = styled.div`
  font-size: 12px;
  text-align: right;
  color: grey;
  margin-top: 10px;
  margin-left: 15px;
  &:hover {
    cursor: pointer;
    color: ${CommonColor.primary_color};
    text-decoration: underline;
  }
`;
const ProfileImg = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
`;
const TeamFlexContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;
  p {
    font-size: 13px;
    line-height: 10px;
    margin-top: 4px;
  }
  span {
    color: lightgray;
  }
`;
export {
  TeamFlexContainer,
  ProfileImg,
  ViewListButton,
  TeamContainer,
  TabContainer,
  TabItem,
  AddProfileIcon,
  ButtonContainer,
};
