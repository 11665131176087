import React from "react";
import {
  FilterContainer,
  FilterIconInTable,
  FilterMenuItem,
  FilterSpan,
} from "../../style/common.style";
import { CommonIcon } from "../../Utils/CommonIcon";
import Menu from "@mui/material/Menu";
import JobCustomController from "../../controller/JobCustomController";

export default function JobTableFilter(props) {
  const { getFilterListByName, onClickOnFilter } = JobCustomController();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
  };
  return (
    <div>
      <FilterContainer onClick={handleClick}>
        <FilterSpan>{props.name}</FilterSpan>
        <FilterIconInTable
          is_active={getFilterListByName(props.name, "").is_active_filter}
          icon={CommonIcon.filter}
        />
      </FilterContainer>
      <Menu
        className="recruiterDropDown"
        style={{
          border: "none",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {getFilterListByName(props.name, "")?.list?.map((element, index) => (
          <FilterMenuItem
            key={index}
            isActive={getFilterListByName(props.name, element).is_active}
            onClick={() => {
              onClickOnFilter(props.name, element);
              handleClose();
            }}
          >
            {props.directName ? element : element.name}
          </FilterMenuItem>
        ))}
      </Menu>
    </div>
  );
}
