var likely_to_switch = ["Most Likely To Switch", "Possibly Willing To Switch"];
var languages = [
  "English",
  "Spanish",
  "French",
  "Arabic",
  "Chinese",
  "German",
  "Russian",
  "Portuguese",
  "Hindi",
  "Greek",
];
var social_platform = [
  "Github",
  "Stackoverflow",
  "X",
  "Facebook",
  "Linkedin",
  "Meetup",
];
var Education = [
  "Bachelors",
  "Masters",
  "Associates",
  "Doctorate",
  "High School",
  "Pre-Masters",
  "Military Service",
  "pre-Bachelors",
  "Pre-Doctorate",
];
var employment_type = [
  "Full-time",
  "Contract - W2",
  "Contract to Hire - W2",
  "Contract - Independent",
  "Contract to Hire - Independent",
  "Contract - Corp-to-Corp",
  "Part-time",
  "Contract to Hire - Corp-to-Corp",
  "Internship",
];
// var authorized_countries = ["USA", "Canada"];
var work_permits = [
  "US Citizen",
  "Employment Auth. Document",
  " Green Card Holder",
  "Have H1 Visa",
  "Need H1 Visa",
  "Canadian Citizen",
  "TN Permit Holder",
  "Unspecified",
];
export {
  work_permits,
  // authorized_countries,
  Education,
  social_platform,
  languages,
  likely_to_switch,
  employment_type,
};
