import styled from "styled-components";
import { CommonString } from "../../Utils/CommonString";
import { CommonColor } from "../../Utils/color";
export const TableHeading = styled.div`
  margin-bottom: 10px;
  font-size: 10.5px;
  font-weight: 700;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  padding: 16px 10px;
  color: #dbe2ff;
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
  background: ${CommonColor.primary_color};
`;
export const Content = styled.div`
  margin-bottom: 5px;
  font-size: 10.5px;
  padding: 0px 10px;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  text-align: left;
  border-bottom: 1px solid rgba(224, 224, 224, 1);

  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
`;
export const ContentWithoutSwitch = styled.div`
  margin-bottom: 5px;
  padding: 10px 10px;
  font-size: 10.5px;
  letter-spacing: 0.01071em;
  display: table-cell;
  vertical-align: inherit;
  text-align: left;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  -webkit-flex-direction: row-reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
`;
export const TableName = styled.div`
  margin: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.0075em;
  -webkit-flex: 1 1 100%;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  color: ${CommonColor.primary_color};
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media ${CommonString.mobile} {
    font-size: 15px;
  }
`;
export const TableRow = styled.div`
  cursor: pointer;
  color: inherit;
  display: table-row;
  vertical-align: middle;
  outline: 0;
`;
