import React from "react";
import Loader from "react-js-loader";
import { SolidBtn } from "../../style/common.style";
import { CommonColor } from "../../Utils/color";
import { CommonString } from "../../Utils/CommonString";
import { ROUND_LOADER_TYPE } from "../../Utils/constant";
export default function CustomButton(props) {
  return (
    <div style={{ display: "flex" }}>
      {props.isLoading ? (
        <div
          style={{
            background: CommonColor.primary_color,
            borderRadius: 5,
            padding: 0,
            height: 35,
            marginTop: 10,
          }}
        >
          <Loader
            type={ROUND_LOADER_TYPE}
            bgColor={CommonColor.white}
            size={20}
            color={CommonColor.red}
            timeout={3000}
          />
        </div>
      ) : (
        <SolidBtn variant={CommonString.contained} onClick={props.onClick}>
          {props.icon ? (
            <span style={{ marginRight: 10 }}>{props.icon}</span>
          ) : (
            ""
          )}
          {props.isLoading ? (
            <Loader
              type={ROUND_LOADER_TYPE}
              bgColor={CommonColor.white}
              size={20}
              color={CommonColor.red}
              timeout={3000}
            />
          ) : (
            props.name
          )}
        </SolidBtn>
      )}
    </div>
  );
}
