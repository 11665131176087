import React from "react";
import {
  FullScreenLoaderContainer,
  FullScreenLoaderContent,
  FullScreenLoaderMainContainer,
} from "../../../style/common.style";

export default function InitialLoader() {
  return (
    <FullScreenLoaderMainContainer>
      <FullScreenLoaderContainer>
        <FullScreenLoaderContent>
          Hold on we are baking...
        </FullScreenLoaderContent>
      </FullScreenLoaderContainer>
    </FullScreenLoaderMainContainer>
  );
}
