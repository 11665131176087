import React from "react";
import { TeamContainer, ViewListButton } from "../../style/recruiter.style";
import RecruiterInternalDomainTeamTile from "./RecruiterInternalDomainTeamTile";

import { SubTitle } from "../../style/dice.style";
import { useDispatch, useSelector } from "react-redux";
import { changeIsOpenModal } from "../../feature/store/ApplicantStore";
import { Grid } from "@mui/material";
import { onGetDomainSelectedTeam } from "../../feature/store/UserStore";

export default function RecruiterInternalDomainBlock(props) {
  const dispatch = useDispatch();
  const internal_team_list_by_domain = useSelector(
    (state) => state.UserStore.internal_team_list_by_domain
  );
  function getData() {
    var domain_all = internal_team_list_by_domain.filter(
      (data) => data.domain === props.domain
    );
    return domain_all[0]?.users;
  }
  function GetList() {
    dispatch(changeIsOpenModal(true));
    dispatch(
      onGetDomainSelectedTeam({ domain: props.domain, team: getData() })
    );
  }

  return (
    <Grid item md={6}>
      <TeamContainer>
        <SubTitle>
          <b>{props.domain}</b>
        </SubTitle>
        <span>Total Members {getData()?.length}</span>
        <hr />
        <Grid container>
          {getData()
            ?.slice(0, 10)
            .map((data) => (
              <Grid item md={6}>
                <RecruiterInternalDomainTeamTile data={data} />
              </Grid>
            ))}
        </Grid>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ViewListButton onClick={() => GetList()}>View All</ViewListButton>
          {/* <ViewListButton
            style={{ color: "blue" }}
            onClick={() => dispatch(changeIsOpenModal(true))}
          >
            Add Member
          </ViewListButton> */}
        </div>
      </TeamContainer>
    </Grid>
  );
}
