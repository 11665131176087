import React from "react";
import Faq from "react-faq-component";
import moment from "moment";
import { Icon } from "@iconify/react";
import { CallTrackerStatusColor } from "../../Utils/utilitiFunction";
import { CommonIcon } from "../../Utils/CommonIcon";
import { LONG_DATE_FORMATE } from "../../Utils/constant";
import { FaqContent, FaqNumber, FaqTitle } from "../../style/common.style";
import { CommonColor } from "../../Utils/color";

const config = {
  animate: true,
  arrowIcon: <Icon icon={CommonIcon.down_arrow} />,
};

const styles = {
  titleTextColor: CommonColor.primary_color,
  rowTitleColor: CommonColor.primary_color,
};

export default function CallTracker(props) {
  var data = {
    rows: [
      {
        title: (
          <h5>
            <FaqNumber>01</FaqNumber>
            <FaqTitle>
              {moment
                .tz(props.trackerList.call_1, "UTC")
                .format(LONG_DATE_FORMATE) + " - "}
              {/* {moment().format()} */}
              <span
                style={{
                  color:
                    CallTrackerStatusColor[
                      props.trackerList.call_1_status
                        ? props.trackerList.call_1_status
                        : props.selectedData.call_1_status
                    ].color,
                }}
              >
                {props.trackerList.call_1_status
                  ? props.trackerList.call_1_status
                  : props.selectedData.call_1_status}
              </span>
            </FaqTitle>
          </h5>
        ),
        content: (
          <div>
            <FaqContent>{props.trackerList.note_1}</FaqContent>
          </div>
        ),
        expand: true,
      },
      {
        title: (
          <h5>
            <FaqNumber>02</FaqNumber>
            <FaqTitle>
              {moment
                .tz(props.trackerList.call_2, "UTC")
                .format(LONG_DATE_FORMATE) + " - "}
              {/* {moment(props.trackerList.call_2).format(LONG_DATE_FORMATE)} -{" "} */}
              <span
                style={{
                  color:
                    CallTrackerStatusColor[
                      props.trackerList.call_2_status
                        ? props.trackerList.call_2_status
                        : props.selectedData.call_2_status
                    ].color,
                }}
              >
                {props.trackerList.call_2_status
                  ? props.trackerList.call_2_status
                  : props.selectedData.call_2_status}
              </span>
            </FaqTitle>
          </h5>
        ),
        content: <FaqContent>{props.trackerList.note_2}</FaqContent>,
      },
      {
        title: (
          <h5>
            <FaqNumber>03</FaqNumber>
            <FaqTitle>
              {moment
                .tz(props.trackerList.call_3, "UTC")
                .format(LONG_DATE_FORMATE) + " - "}
              {/* {moment(props.trackerList.call_3).format(LONG_DATE_FORMATE)} -{" "} */}
              <span
                style={{
                  color:
                    CallTrackerStatusColor[
                      props.trackerList.call_3_status
                        ? props.trackerList.call_3_status
                        : props.selectedData.call_3_status
                    ].color,
                }}
              >
                {props.trackerList.call_3_status
                  ? props.trackerList.call_3_status
                  : props.selectedData.call_3_status}
              </span>
            </FaqTitle>
          </h5>
        ),
        content: <FaqContent>{props.trackerList.note_3}</FaqContent>,
      },
    ],
    expand: true,
  };

  return <Faq open data={data} styles={styles} config={config} />;
}
