import { Icon } from "@iconify/react";
import React from "react";
import { CommonIcon } from "../../../Utils/CommonIcon";
import { Grid } from "@material-ui/core";
import {
  ApplicantProfileCardContainer,
  ExperienceWithSkillContainer,
  FirstContainerForApplicantProfile,
  FlexContainer,
  IconContainer,
  TextWithIconContainer,
} from "../../../style/dice.style";
import diceIcon from "../../../assets/images/diceLogo.jpg";
import { Redirect } from "react-router-dom";
import ExperienceWithSkill from "./ExperienceWithSkill";

export default function ApplicantProfileCard() {
  function TextWithIcon(props) {
    return (
      <Grid item md={3}>
        <TextWithIconContainer>
          <Icon icon={props.icon} />
          <p>{props.text}</p>
        </TextWithIconContainer>
      </Grid>
    );
  }

  return (
    // <Redirect to="/candidateProfile">
      <ApplicantProfileCardContainer>
        <FirstContainerForApplicantProfile>
          <span style={{ display: "flex", alignItems: "center" }}>
            <div>CC</div>
            <div>
              <h5>Carlos Carter</h5>
              <p>Sys Ops Administrator @ Kemper </p>
            </div>
          </span>
          <IconContainer>
            <img src={diceIcon} />
            <Icon icon={CommonIcon.linkedin} color="#006599" />
            <Icon icon={CommonIcon.call} />
            <Icon icon={CommonIcon.email} />
          </IconContainer>
        </FirstContainerForApplicantProfile>
        <hr />
        <FlexContainer>
          <TextWithIconContainer>
            <Icon icon={CommonIcon.bag} />
            <p>Preferred: DevOpsDevSecOpsAWSAzure</p>
          </TextWithIconContainer>
          <div>
            <span>
              Likely to Switch: <span className="switch"></span> Unknown
            </span>
          </div>
        </FlexContainer>
        <Grid container spacing={1} style={{ marginTop: 5, marginBottom: 15 }}>
          <TextWithIcon icon={CommonIcon.map} text={"Birnubgham,AL,US"} />
          <TextWithIcon icon={CommonIcon.calender} text={"18 years ago"} />
          <TextWithIcon icon={CommonIcon.glob} text={"US Citizen"} />
          <TextWithIcon
            icon={CommonIcon.road_map}
            text={"Not willing to relocated"}
          />
          <TextWithIcon icon={CommonIcon.cash} text={"135,000+ (USD) / yr"} />
          <TextWithIcon
            icon={CommonIcon.work_from_home}
            text={"No preference"}
          />
          <TextWithIcon
            icon={CommonIcon.clock}
            text={
              <>
                "Updated: "<b>{"6 moths ago"}</b>
              </>
            }
          />
        </Grid>
        <Grid container spacing={1}>
          {[1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12].map((data) => (
            <ExperienceWithSkill
              skill={"Cloude"}
              year={"2019"}
              experience={"10"}
            />
          ))}
          <Grid item md={3}>
            <p style={{ padding: "10px", color: "#006599" }}>30+ More</p>
          </Grid>
        </Grid>
      </ApplicantProfileCardContainer>
    // </Redirect>
  );
}
