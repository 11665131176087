import React, { useState, useEffect } from "react";
import CustomButton from "../CommonComponet/Button";
import CustomDatePicker from "../CommonComponet/DatePicker";
import FormInput from "../CommonComponet/FormInput";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import SelectTextFields from "../CommonComponet/SelectTextFields";
import { INTERVIEW_MODE, time_zone_list } from "../../Utils/constant";
import {
  CommonButtonName,
  CommonLabel,
  CommonString,
} from "../../Utils/CommonString";
import { SubmissionController } from "../../controller/SubmissionController";
import { changeInterviewDetails } from "../../feature/store/SubmissionReduxStore";
import {
  ERROR_UPDATE_INTERVIEW_DETAIL,
  UPDATE_INTERVIEW_DETAIL,
} from "../../Utils/alertMessage";
import { useAlert } from "react-alert";
import { getFieldNameByInterviewModeInInterview } from "../../Utils/utilitiFunction";
import CommonDatePicker from "../CommonComponet/CommonDatePicker";
import { Grid } from "@mui/material";

export default function AddInterviewDetail(props) {
  const submissionController = new SubmissionController();
  const dispatch = useDispatch();
  const alert = useAlert();
  const interview_details = useSelector(
    (state) => state.SubmissionReduxStore.interview_details
  );
  const is_update = useSelector(
    (state) => state.SubmissionReduxStore.is_update
  );
  const [interViewMode, setInterViewMode] = useState("");
  const [dateAndTime, setDateAndTime] = useState();
  const [time, setTime] = useState();
  const [webExLink, setWebExLink] = useState("");
  const [pointOfContact, setPointOfContact] = useState();
  const [note, setNote] = useState();
  const [ERROR_NAME, setERROR_NAME] = useState();
  const [selectedTimeZone, setSelectedTimeZone] = useState("");
  const [interviewTime, setInterviewTime] = useState("");

  useEffect(() => {
    onPrefilledData();
  }, [interview_details]);

  async function onPrefilledData() {
    if (interview_details === undefined) return;
    if (is_update) {
      setInterViewMode(interview_details.interview_mode ?? "");
      setSelectedTimeZone(interview_details?.time_zone);
      setDateAndTime(interview_details.date ?? "");
      setTime(interview_details.time ?? "");
      setWebExLink(interview_details.web_ex_link ?? "");
      setPointOfContact(interview_details.point_of_contact ?? "");
      setNote(interview_details.note ?? "");
      setInterViewMode(interview_details.interview_mode ?? "");
      setInterviewTime(interview_details?.interview_time);
      setSelectedTimeZone(interview_details?.time_zone);
    }
  }

  async function onAddInterViewDetails() {
    if (!validate()) {
      return;
    }
    var data = {
      interview_mode: interViewMode ?? "",
      date: dateAndTime === undefined ? moment()._d : dateAndTime,
      time: `${interviewTime} ${selectedTimeZone}` ?? "",
      web_ex_link: webExLink ?? "",
      point_of_contact: pointOfContact ?? "",
      note: note ?? "",
      comment: note ?? "",
      interview_time: interviewTime,
      time_zone: selectedTimeZone,
    };
    if (is_update) {
      var sendData = {
        _id: interview_details._id,
        ...data,
      };
      var res = await submissionController.UpdateInterviewDetail(sendData);
      if (res.data.status === 200) {
        dispatch(changeInterviewDetails(sendData));
        alert.show(UPDATE_INTERVIEW_DETAIL, {
          type: CommonString.success,
        });
        props.handleClose();
        return;
      }
      alert.show(ERROR_UPDATE_INTERVIEW_DETAIL, {
        type: CommonString.success,
      });
    }
    props.AddInterviewDetails(data);
  }

  function validate() {
    if (interViewMode === null || interViewMode === undefined) {
      setERROR_NAME("INTERVIEW_MODE");
      return false;
    }
    if (interviewTime === null || interviewTime === undefined) {
      setERROR_NAME("TIME");
      return false;
    }
    // if (pointOfContact === null || pointOfContact === undefined) {
    //   setERROR_NAME("POINT_OF_CONTACT");
    //   return false;
    // }
    // if (note === null || note === undefined) {
    //   setERROR_NAME("NOTE");
    //   return false;
    // }
    setERROR_NAME("");
    return true;
  }

  return (
    <>
      <SelectTextFields
        error={ERROR_NAME === "INTERVIEW_MODE" ? "fill" : "" > 0}
        placeholder="Inter View Mode"
        value={interViewMode}
        onChange={(value) => {
          setInterViewMode(value);
          setERROR_NAME("");
        }}
        options={INTERVIEW_MODE}
        getOptionLabel={(option) => (option === null ? "" : option)}
      />
      {/* <FormInput
        error={ERROR_NAME === "INTERVIEW_MODE" ? "fill" : "" > 0}
        value={interViewMode || ""}
        onChange={(e) => setInterViewMode(e.target.value)}
        placeholder="Inter View Mode"
      /> */}
      <CustomDatePicker
        name={CommonLabel.date_time}
        error={ERROR_NAME === "DATE_AND_TIME" ? "fill" : "" > 0}
        label={CommonLabel.date_time}
        value={dateAndTime}
        onChange={(date) => setDateAndTime(date)}
      />
      <Grid container>
        <Grid item md={6} style={{ paddingRight: 5 }}>
          <FormInput
            type="time"
            // error={ERROR_NAME === "TIME" ? "fill" : "" > 0}
            value={interviewTime || ""}
            onChange={(e) => setInterviewTime(e.target.value)}
            placeholder="Time"
          />
        </Grid>
        <Grid item md={6} style={{ paddingLeft: 5 }}>
          <SelectTextFields
            placeholder={"Time Zone"}
            value={selectedTimeZone}
            onChange={(value) => setSelectedTimeZone(value)}
            options={time_zone_list}
            getOptionLabel={(option) => (option === null ? "" : option)}
          />
        </Grid>
      </Grid>
      <FormInput
        error={ERROR_NAME === "POINT_OF_CONTACT" ? "fill" : "" > 0}
        value={pointOfContact || ""}
        onChange={(e) => setPointOfContact(e.target.value)}
        placeholder="Point of Contact"
      />
      <FormInput
        error={ERROR_NAME === "NOTE" ? "fill" : "" > 0}
        value={note || ""}
        onChange={(e) => setNote(e.target.value)}
        placeholder="Instruction / note"
      />
      {interViewMode === "" ? (
        <></>
      ) : (
        <FormInput
          value={webExLink || ""}
          onChange={(e) => setWebExLink(e.target.value)}
          placeholder={getFieldNameByInterviewModeInInterview(interViewMode)}
        />
      )}
      <CustomButton
        name={CommonButtonName.add}
        onClick={() => onAddInterViewDetails()}
      />
    </>
  );
}
