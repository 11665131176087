export function ValidateEmail(mail) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
    return true;
  }
  return false;
}
export function ValidateName(name) {
  if (/^[A-Za-z ]+$/.test(name)) {
    return true;
  }
  return false;
}
export function ValidatePhone(phone) {
  var pattern = new RegExp(/^[0-9\b]+$/);
  if (pattern.test(phone)) {
    return true;
  }
  return false;
}
export function ValidateNumber(number) {
  var pattern = new RegExp(/\D/);
  if (pattern.test(Number)) {
    return true;
  }
  return false;
}
export function ValidateZip(zip) {
  var pattern = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
  if (pattern.test(zip)) {
    return true;
  }
  return false;
}
export function formatPhoneNumber(value) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6
  )}-${phoneNumber.slice(6, 10)}`;
}
