import React from "react";
import Loader from "react-js-loader";
import { CommonColor } from "../../Utils/color";
import { ROUND_LOADER_TYPE } from "../../Utils/constant";
export default function RoundLoader() {
  return (
    <Loader
      type={ROUND_LOADER_TYPE}
      bgColor={CommonColor.primary_color}
      size={40}
      color={CommonColor.white}
      timeout={3000}
    />
  );
}
