import React from "react";
import { Box, Modal } from "@mui/material";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import "bootstrap/dist/css/bootstrap.min.css";
import CustomTooltip from "../CommonComponet/Tooltip";
import FullScreenLoader from "../CommonComponet/FullScreenLoader";
import { useSelector } from "react-redux";
import {
  FormTitleContainer,
  ModalDataContainer,
  ModalIconContainer,
  ModalTitle,
  ModalTitleSpan,
} from "../../style/common.style";
import { CommonString, CommonTitle } from "../../Utils/CommonString";
import { CommonIcon } from "../../Utils/CommonIcon";
import {
  TitleContainer,
  ViewJobFormTitleContainer,
  ViewJobTitle,
} from "../../style/job.style";
import moment from "moment";
import { CommonColor } from "../../Utils/color";
import { formatPhoneNumber, getJobLocation } from "../../Utils/utilitiFunction";
import {
  ApplicantViewFormTitle,
  SubmissionViewFormTitle,
} from "../../style/applicant.style";
import { ModalIcon } from "../../style/Icon.style";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #FFF,20px",
  backgroundColor: "#ffffff",
  boxShadow: 24,
  p: 3,
  transitionDelay: "0.3s !important",
  overflow: "auto",
  transition: "2s",
};
export default function BaseModal(props) {
  const isVisible = useSelector((state) => state.counter.is_visible);
  const is_attachment_open = useSelector(
    (state) => state.JobReduxStore.is_attachment_open
  );

  return (
    <Modal
      style={{
        zIndex: 90,
      }}
      open={props.open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 3000,
      }}
    >
      <Fade in={props.open}>
        <Box
          sx={style}
          style={{
            width: props.isBig
              ? "100%"
              : window.innerWidth > 500
              ? "50%"
              : "80%",
            height: props.isBig ? "100%" : "auto",
            borderRadius: props.isBig ? "0px" : "5px",
            overflowY:
              props.isLoading || is_attachment_open ? "hidden" : "auto",
            overflowX: is_attachment_open ? "hidden" : "hidden",
            ...props.mystyle,
            transitionDelay: "5000s",
          }}
        >
          {props.isLoading ? (
            <FullScreenLoader
              isUpdate={props.isUpdate}
              style={{
                width: props.isBig ? "100%" : 500,
                height: props.isBig ? "100%" : "auto",
                ...props.mystyle,
              }}
            />
          ) : (
            <div />
          )}
          <div>
            <FormTitleContainer>
              <TitleContainer>
                <ModalTitle margin={props.margin}>
                  {props.formTitle.substr(0, props.formTitle.indexOf(" ")) +
                    " "}
                  <ModalTitleSpan>
                    {props.formTitle.substr(props.formTitle.indexOf(" ") + 1)}
                  </ModalTitleSpan>
                </ModalTitle>
                <ModalIconContainer>
                  {isVisible === true ? (
                    <>
                      <CustomTooltip
                        style={{
                          display:
                            props.isView && props.isHide === true
                              ? "block"
                              : "none",
                        }}
                        placement={CommonString.top_placement}
                        title={CommonTitle.delete}
                        name={
                          <ModalIcon
                            icon={CommonIcon.modal_delete}
                            onClick={props.deleteClose}
                          />
                        }
                      />
                      <CustomTooltip
                        style={{
                          display:
                            (props.isView === true ||
                              props.isViewEdit === true ||
                              props.isFaq === true) &&
                            !props.isDashboard &&
                            !props.isRecruiter
                              ? "block"
                              : "none",
                        }}
                        placement={CommonString.top_placement}
                        title={CommonTitle.edit}
                        name={
                          <ModalIcon
                            icon={CommonIcon.modal_edit}
                            onClick={props.editClose}
                          />
                        }
                      />
                    </>
                  ) : (
                    <div />
                  )}
                  <CustomTooltip
                    style={{
                      display:
                        isVisible === false && props.isFaq === true
                          ? "block"
                          : "none",
                    }}
                    placement={CommonString.top_placement}
                    title={CommonTitle.edit}
                    name={
                      <ModalIcon
                        icon={CommonIcon.modal_edit}
                        onClick={props.editClose}
                      />
                    }
                  />
                  <CustomTooltip
                    placement={CommonString.top_placement}
                    title={CommonTitle.close}
                    name={
                      <ModalIcon
                        icon={CommonIcon.modal_close}
                        onClick={props.handleClose}
                      />
                    }
                  />
                </ModalIconContainer>
              </TitleContainer>
              <ApplicantViewFormTitle isApplicantView={props.isApplicantView}>
                <ViewJobTitle color={CommonColor.primary_color}>
                  {props.data
                    ? props.data.phone
                      ? props.data.primary_email +
                        " | " +
                        formatPhoneNumber(props.data.phone)
                      : props.data.primary_email
                    : ""}
                </ViewJobTitle>
              </ApplicantViewFormTitle>
              <ViewJobFormTitleContainer
                isVisible={
                  props.selectedData !== null ||
                  props.selectedData !== undefined
                }
              >
                <ViewJobTitle>
                  {props.selectedData
                    ? props.selectedData.clients.name +
                      "  |  " +
                      getJobLocation(props.selectedData.job.job_location) +
                      "  |  "
                    : ""}
                </ViewJobTitle>
                <ViewJobTitle color={CommonColor.secondary_color}>
                  {props.selectedData
                    ? moment(props.selectedData.job._created_at).fromNow()
                    : ""}
                </ViewJobTitle>
              </ViewJobFormTitleContainer>
              <SubmissionViewFormTitle
                isSubmissionView={props.isSubmissionView}
              >
                <ViewJobTitle color={CommonColor.primary_color}>
                  {props.data
                    ? props.data.phone
                      ? props.data.primary_email +
                        " | " +
                        formatPhoneNumber(props.data.phone)
                      : props.data.primary_email
                    : ""}
                </ViewJobTitle>
              </SubmissionViewFormTitle>
            </FormTitleContainer>
            <ModalDataContainer>{props.children}</ModalDataContainer>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
