import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { TableName } from "../DataTable/StyledDataTable";
import { useSelector, useDispatch } from "react-redux";
import RecruiterController from "../../controller/RecruiterController";
import { getRecruiterData } from "../../feature/store/ReduxStore";
import CustomButton from "../CommonComponet/Button";
import { CommonButtonName } from "../../Utils/CommonString";
import { TableMainContainer } from "../../style/table.styled";
function RecruiterGroupDataTable(props) {
  const recruiterController = new RecruiterController();
  const dispatch = useDispatch();
  const recruiterList = useSelector((state) => state.counter.recruiterData);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRecuiter, setselectedRecuiter] = useState([]);
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    if (recruiterList.length === 0) setIsLoading(true);
    var data = await recruiterController.getRecruiters();
    localStorage.setItem("RECRUITER", JSON.stringify(data));
    dispatch(getRecruiterData(data));
    setIsLoading(false);
  }

  return (
    <TableMainContainer>
      <Grid container spacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <div className="recruiterGroupContainer">
            <Grid
              container
              spacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid
                item
                xs={5}
                style={{ display: "flex", alignItems: "center" }}
              >
                <h1>Olan</h1>
              </Grid>
              <Grid item xs={6} className="recruiterGroupList">
                {props.userinfo.response.map((recruiter,index) => (
                  <p key={index}>{recruiter}</p>
                ))}
              </Grid>
              <Grid item xs={1} style={{ position: "relative" }}>
                <span
                  style={{ position: "absolute", right: "10px", bottom: "0" }}
                >
                  More...
                </span>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={6}>
          <TableName>Recruiters</TableName>
          <div style={{ height: "400px", overflow: "auto" }}>
            <table style={{ width: "100%" }}>
              <tr>
                <th>No</th>
                <th>Name</th>
                <th>Email</th>
              </tr>
              {recruiterList.map((recruiter, index) => (
                <tr key={index}>
                  <td>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="languages"
                      value={recruiter.name}
                      id="flexCheckDefault"
                      onChange={(e) => {
                        props.handleChange(e);
                        setselectedRecuiter(e);
                      }}
                    />
                    {/* <Checkbox
                      defaultChecked={selectedRecuiter === index}
                      checked={selectedRecuiter === index}
                      onChange={(e) => setselectedRecuiter(e)}
                    /> */}
                  </td>
                  <td>{recruiter.name}</td>
                  <td>{recruiter.email}</td>
                </tr>
              ))}
            </table>
          </div>
          <div className="buttonRight">
            <CustomButton name={CommonButtonName.create_group} />
          </div>
        </Grid>
      </Grid>
    </TableMainContainer>
  );
}
export default RecruiterGroupDataTable;
