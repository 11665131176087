import React, { useState } from "react";

export default function CollapsibleInput(props) {
  const [activeIndex, setActiveIndex] = useState([]);
  const toggleFAQ = (index) => {
    if (activeIndex.includes(index)) {
      var filterData = activeIndex.filter((data) => data !== index);
      setActiveIndex(filterData);
      return;
    }
    setActiveIndex([...activeIndex, index]);
  };
  // console.log(props.value === undefined ? "" : props.value !== "");
  return (
    <li key={props.index} className="faq-item">
      <div className="faq-question" onClick={() => toggleFAQ(props.index)}>
        <div>
          {activeIndex.includes(props.index) ? "-" : "+"} {props.title}
        </div>
        <p>{!props.is_active ? 1 : 0}</p>
      </div>
      {activeIndex.includes(props.index) && (
        <div className="faq-answer">{props.content}</div>
      )}
    </li>
  );
}
