import styled from "styled-components";
import { CommonString } from "../Utils/CommonString";
import { CommonColor } from "../Utils/color";

const DashboardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
`;
const DashboardTopBar = styled.div`
  width: 100%;
  background: white;
  height: 10vh;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  padding-left: 10px;
  color: ${CommonColor.primary_color};
  @media ${CommonString.mobile} {
    height: 50px;
  }
`;
const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10.2vh;
  border-bottom: 1px solid ${CommonColor.tablerow_even_color};
  @media ${CommonString.mobile} {
    height: 50px;
  }
`;
const CardTitle = styled.p`
  color: ${(props) => props.background};
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
  white-space: normal;
`;

const CardTotalCount = styled.h1`
  color: ${(props) => props.background};
  font-size: 24px;
  font-weight: bold;
  margin-top: 10px !important;
`;
const ChartContainer = styled.div`
  padding: 10px 50px;
  .chart-wrapper {
    width: 350px;
  }
`;
export {
  DrawerHeader,
  DashboardContainer,
  DashboardTopBar,
  CardTitle,
  CardTotalCount,
  ChartContainer,
};
