import React, { useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function HtmlTextEditor(props) {
  const [value, setValue] = useState(props.value);
  return (
    <div>
      <CKEditor
        style={{ height: 193, overflow: "auto" }}
        height={193}
        value={value}
        editor={ClassicEditor}
        data={value}
        onReady={(editor) => {
          setValue(props.value);
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          props.onChange(data);
        }}
      />
    </div>
  );
}
