import React, { useState } from "react";
import CustomTooltip from "./Tooltip";
import { CommonString } from "../../Utils/CommonString";
import { CopyIcon } from "../../style/Icon.style";
import { CommonIcon } from "../../Utils/CommonIcon";

export default function CopyTextComponent(props) {
  const [display, setDisplay] = useState(false);

  function afterCopyDisplayText() {
    setDisplay(true);
    setTimeout(() => {
      setDisplay(false);
    }, 2000);
  }

  const handleChildClick = function (e) {
    e.stopPropagation();
  };

  const copyToClipboard = (elementId) => {
    const temp = document.createElement("div");
    temp.setAttribute("contentEditable", true);
    temp.innerHTML = props.value;
    temp.setAttribute(
      "onfocus",
      "document.execCommand('selectAll',false,null)"
    );
    document.body.appendChild(temp);
    temp.focus();
    document.execCommand("copy");
    document.body.removeChild(temp);
  };

  return (
    <div onMouseLeave={() => setDisplay(false)} className="textWithCopyIcon">
      <CustomTooltip
        onClick={handleChildClick}
        placement={CommonString.top_placement}
        title={display ? CommonString.copied : CommonString.copy}
        name={
          <p>
            {/* {props.title} */}
            <CopyIcon
              style={{ display: props.value === "" ? "none" : "initial" }}
              onClick={() => {
                copyToClipboard(props.id);
                afterCopyDisplayText();
              }}
              icon={CommonIcon.copy}
            />
          </p>
        }
      />
    </div>
  );
}
