import React from "react";
import {
  TextInTextIcon,
  TextWithIconContainer,
} from "../../style/common.style";
import { IconInTextIcon } from "../../style/Icon.style";

export default function TextWithIcon(props) {
  // console.log(props.text);
  return (
    <TextWithIconContainer data={props.text} {...props}>
      <IconInTextIcon icon={props.icon} />
      <TextInTextIcon>{props.text} </TextInTextIcon>
    </TextWithIconContainer>
  );
}
