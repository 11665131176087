import React, { useState, useEffect } from "react";
import CustomButton from "../../../component/CommonComponet/Button";
import { Icon } from "@iconify/react";
import { useHistory } from "react-router-dom";
import { withRouter } from "react-router";
import FormInput from "../../../component/CommonComponet/FormInput";
import PrexelLogo from "../../../assets/images/PrexelLogo.png";
import AuthProvider from "../../../services/provider/AuthProvider";
import { ValidateEmail } from "../../../services/Validate";
import Loader from "react-js-loader";
// import Checkbox from "@mui/material/Checkbox";
import { useDispatch } from "react-redux";
import {
  changeUserDetail,
  changeUserName,
  getVisibility,
} from "../../../feature/store/ReduxStore";
import login_successful from "../../../assets/images/login_successful.gif";
import { CommonColor } from "../../../Utils/color";
import {
  CommonButtonName,
  CommonString,
  CommonStringForLocalStorage,
} from "../../../Utils/CommonString";
import { CustomForm, Div, HR, ShowPassText } from "../../../style/common.style";
import { CommonIcon } from "../../../Utils/CommonIcon";
import { ROUND_LOADER_TYPE } from "../../../Utils/constant";
import {
  ContainerForLogin,
  ErrorText,
  LightText,
  LoaderForLoginContainer,
  LoaderForLoginImg,
  LoginForm,
  LoginIcon,
  LoginImg,
  LoginImgContainer,
  LoginTitle,
  LoginTitleContainer,
  PrimaryColorText,
  SecondaryColorText,
} from "../../../style/login.style";
import { FormPlaceholder } from "../../../Utils/FormPlaceholder";
import { onCheckLevelAccessibility } from "../../../Utils/utilitiFunction";
import CheckBox from "../../../component/basic/CheckBox";

function LoginScreen(props) {
  let history = useHistory();
  const dispatch = useDispatch();
  const authProvider = new AuthProvider();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSuccess, setIsLoadingSuccess] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  useEffect(() => {
    getData();
  }, []);

  function getData() {
    var tk = localStorage.getItem(CommonStringForLocalStorage.token);
    if (tk != null) {
      history.push("/");
    }
  }

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  async function login() {
    try {
      if (username === null || !ValidateEmail(username)) {
        setEmailError("d");
        return;
      }
      setEmailError(null);
      if (password === null || password.length < 6) {
        setPasswordError("d");
        return;
      }
      setPasswordError(null);
      setIsLoading(true);
      var data = {
        email: username,
        password: password,
      };
      var res = await authProvider.login(data);
      if (res.data.status === 200) {
        setIsValidate(false);
        setIsLoadingSuccess(true);
        setTimeout(() => {
          setIsLoadingSuccess(false);
          history.push("/dashboard");
        }, 2000);
        setIsLoading(false);
        localStorage.setItem(CommonStringForLocalStorage.token, res.data.token);
        var isVisible = onCheckLevelAccessibility(JSON.stringify(res.data));
        dispatch(getVisibility(isVisible));
        dispatch(changeUserDetail(JSON.stringify(res.data)));
        localStorage.setItem(
          CommonStringForLocalStorage.user,
          JSON.stringify(res.data)
        );
        dispatch(changeUserName(res.data));
      } else {
        setIsValidate(true);
        setIsLoading(false);
      }
    } catch (error) {}
  }

  const handleEnter = (event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 2].focus();
      event.preventDefault();
      if (form.elements[4]) {
        form.elements[index + 1].focus();
        login();
      }
    }
  };

  return (
    <>
      {isLoadingSuccess ? (
        <LoaderForLoginContainer>
          <LoaderForLoginImg src={login_successful} />
        </LoaderForLoginContainer>
      ) : (
        <ContainerForLogin>
          <Div>
            <LoginImgContainer>
              <LoginImg src={PrexelLogo} alt={CommonString.logo} />
              {/* <LoginImgContainer>
                <HR />
              </LoginImgContainer> */}
            </LoginImgContainer>
            <CustomForm>
              {isValidate ? (
                <ErrorText>{CommonString.login_error_message}</ErrorText>
              ) : (
                <Div />
              )}
              <LightText>{CommonString.welcome}</LightText>
              <LoginTitleContainer>
                <LoginTitle>
                  <SecondaryColorText>{CommonString.ats}</SecondaryColorText>
                  <PrimaryColorText> {CommonString.sign_in}</PrimaryColorText>
                </LoginTitle>
                {isLoading ? (
                  <Loader
                    type={ROUND_LOADER_TYPE}
                    bgColor={CommonColor.primary_color}
                    size={40}
                    color={CommonColor.white}
                    timeout={3000}
                  />
                ) : (
                  <Div />
                )}
              </LoginTitleContainer>
              <LoginForm onSubmit={login}>
                <FormInput
                  onKeyDown={handleEnter}
                  autoComplete
                  icon={<LoginIcon icon={CommonIcon.user} />}
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  error={emailError}
                  disabled={false}
                  placeholder={FormPlaceholder.email}
                />
                <FormInput
                  onKeyDown={handleEnter}
                  type={checked ? CommonString.email : CommonString.password}
                  icon={<LoginIcon icon={CommonIcon.lock_line} />}
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                  disabled={false}
                  error={passwordError}
                  placeholder={FormPlaceholder.password}
                />
                <ShowPassText>
                  <CheckBox
                    style={{ color: `${CommonColor.primary_color}` }}
                    checked={checked}
                    onClick={handleChange}
                    label={CommonString.show_password}
                  />
                </ShowPassText>
              </LoginForm>
              <CustomButton
                onClick={login}
                name={CommonButtonName.sign_in}
              ></CustomButton>
            </CustomForm>
          </Div>
        </ContainerForLogin>
      )}
    </>
  );
}
export default withRouter(LoginScreen);
