import * as axios from "axios";
import { BASE_URL } from "../../Utils/constant";

export default class ApiUtils {
  constructor() {
    this.api_token = localStorage.getItem("token");
    this.refresh_token = "refresh_token";
    this.client = null;
    this.api_url = BASE_URL;
  }
  init = () => {
    let headers = {
      Accept: "application/json",
    };
    if (this.api_token) {
      headers.Authorization = `Bearer ${this.api_token}`;
    }
    this.client = axios.create({
      baseURL: this.api_url,
      timeout: 31000,
      headers: headers,
    });
    this.client.interceptors.response.use(
      (response) => response, // Pass successful responses directly
      async (error) => {
        // If the response status is 401 (Unauthorized), try refreshing the token
        if (error.response && error.response.status === 401) {
          // Attempt to refresh the token
          const success = await this.refreshAccessToken();
          if (success) {
            // Update the Authorization header with the new token
            error.config.headers["Authorization"] = `Bearer ${this.api_token}`;
            // Retry the failed request with the new token
            return this.client(error.config);
          }
        }
        return Promise.reject(error);
      }
    );

    return this.client;
  };

  // Method to refresh the access token using the refresh token
  refreshAccessToken = async () => {
    try {
      // Make a request to the refresh token endpoint
      const response = await axios.post(`${this.api_url}/refresh-token`, {
        refresh_token: this.refresh_token,
      });

      // Update api_token with the new access token from response
      this.api_token = response.data.access_token;

      // Update client instance's Authorization header with new token
      this.client.defaults.headers[
        "Authorization"
      ] = `Bearer ${this.api_token}`;

      return true; // Indicate success
    } catch (error) {
      console.error("Failed to refresh access token:", error);
      return false; // Indicate failure
    }
  };
}
