import React from "react";
import { useSelector } from "react-redux";
import { Table, TableRow, TableTd, TableTh } from "../../style/table.styled";
import { VIEW_SUBMISSION } from "../../Utils/enum";
import { getClientName } from "../../Utils/utilitiFunction";
import { CopyTextMainContainer } from "../../style/common.style";
import CustomizedTooltip from "../CommonComponet/CustomizedTooltip";
import CopyTextComponent from "../CommonComponet/CopyTextComponent";

export default function SubmissionDetails(props) {
  const selected_data = useSelector(
    (state) => state.ReportStoreRedux.select_data
  );
  var submission_data = selected_data?.submissionData;

  return (
    <>
      <Table>
        <TableRow>
          <TableTh>No.</TableTh>
          <TableTh>Applicant</TableTh>
          <TableTh>Position Title</TableTh>
          <TableTh>Job ID</TableTh>
          <TableTh>Partner</TableTh>
          <TableTh>Client</TableTh>
          <TableTh>Tax Term</TableTh>
          <TableTh align="center">Pay Rate</TableTh>
        </TableRow>
        {submission_data.map((data, index) => (
          <TableRow
            onDoubleClick={() =>
              props.onViewModal(VIEW_SUBMISSION, data?.submissionV2)
            }
          >
            <TableTd>{index + 1}</TableTd>
            <TableTd>{data?.submissionV2?.full_name}</TableTd>
            <TableTd style={{ maxWidth: 300 }}>
              {data?.submissionV2?.parentJob?.position_title}
            </TableTd>
            <TableTd>
              <CopyTextMainContainer>
                {data?.submissionV2?.parentJob?.client_position_id}
                <CopyTextComponent
                  value={data?.submissionV2?.parentJob?.client_position_id}
                  id={"job_ID"}
                />
              </CopyTextMainContainer>
            </TableTd>
            <TableTd>
              {getClientName(data?.submissionV2?.partner?.name)}
            </TableTd>
            <TableTd>{getClientName(data?.submissionV2?.client?.name)}</TableTd>
            <TableTd>{data?.submissionV2?.tax_term}</TableTd>
            <TableTd>{data?.submissionV2?.payrate}</TableTd>
          </TableRow>
        ))}
      </Table>
    </>
  );
}
