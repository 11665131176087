import React from "react";
import { TableRow, TableTd } from "../../style/table.styled";
import { CommonColor } from "../../Utils/color";

export default function GetViewForDetailsTile(props) {
  return props?.value === "" ? (
    <></>
  ) : (
    <TableRow>
      <TableTd>
        <b> {props.name}</b>
      </TableTd>
      <TableTd
        style={{
          color: props.color ? props.color + "!important" : CommonColor.black,
        }}
      >
        {props.value}
      </TableTd>
    </TableRow>
  );
}
