import React from "react";
import { useSelector } from "react-redux";
import PayRateSectionTileForJob from "./PayRateSectionTileForJob";

export default function PayRateSectionForJob(props) {
  const selected_partner_list = useSelector(
    (state) => state.ClientReduxStore.selected_partner_list
  );

  const selected_partner_tax_list = useSelector(
    (state) => state.ClientReduxStore.selected_partner_tax_list
  );

  return selected_partner_list.map((item) => {
    const filtered = selected_partner_tax_list.filter((itemX) => {
      return itemX.partner_id === item.id;
    });
    var data = filtered.length === 0 ? null : filtered[0];
    return (
      <PayRateSectionTileForJob
        selectedData={props.data}
        isUpdate={props.isUpdate}
        data={item}
        taxData={data}
      />
    );
  });
}
