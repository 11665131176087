import React, { useState, useEffect } from "react";
import TopMenu from "../Menu/TopMenu";
import { Container, MainScreen, TopFixedMenu } from "./StyledView";
import { useSelector, useDispatch } from "react-redux";
import { ADD_INTERVIEW_DETAIL, INTERVIEW_DETAILS } from "../../Utils/enum";
import AppLoader from "../CommonComponet/Loader";
import {
  getClickableAccess,
  getFormTitleForSubmission,
  getStatusName,
  onCheckLevelAccessibility,
} from "../../Utils/utilitiFunction";
import JobController from "../../controller/JobController";
import {
  getRecruiterData,
  getjobData,
  removeJObData,
  changeInitialLoader,
  onChangeFilterStatusObject,
} from "../../feature/store/ReduxStore";
import RecruiterController from "../../controller/RecruiterController";
import SubmissionTableForDashboard from "../DataTable/Table/SubmissionTableForDashboard";
import {
  SUBMISSION_STATUS_FOR_MANAGER,
  SUBMISSION_STATUS_FOR_RECRUITER,
} from "../../Utils/constant";
import {
  changeFilterData,
  changeSelectedSubmission,
  changeStatusList,
  onChangeSubmissionDataForDashboard,
} from "../../feature/store/SubmissionReduxStore";
import { TableMainContainer } from "../../style/table.styled";
import JobList from "../Dashboard/JobList";
import TrackerTable from "../DataTable/Table/TrackerTable";
import { SubmissionController } from "../../controller/SubmissionController";
import CountDataDetails from "../Dashboard/CountDataDetails";
import BaseModal from "../Modal/BaseModal";
import SubmissionViewDetails from "../ViewDetails/SubmissionViewDetails";
import InterviewStatus from "../CommonComponet/IntwerviewDetail/interviewStatus";
import InterviewTableForDashboard from "../DataTable/Table/InterviewTableForDashboard";
import DashboardCard from "../Dashboard/DashboardCard";
import LoaderForTable from "../CommonComponet/LoaderForTable";
import { changeInitialTableList } from "../../feature/store/JobReduxStore";

export default function DashBoardView(props) {
  const isVisible = useSelector((state) => state.counter.is_visible);
  const dispatch = useDispatch();
  const jobController = new JobController();
  const submissionController = new SubmissionController();
  const recruiterController = new RecruiterController();
  const jobPagenumber = useSelector((state) => state.counter.jobPagenumber);
  const jobDataList = useSelector((state) => state.counter.jobData);
  const [dummuyState, setDummuyState] = useState(0);
  const [isOpenCountDetail, setIsOpenCountDetail] = useState(false);
  const [selectedStatusName, setSelectedStatusName] = useState();
  const [statusDetailData, setStatusDetailData] = useState();
  const [isopenModal, setisOpenModal] = useState(false);
  const [isLoadingStatusData, setIsLoadingStatusData] = useState(false);
  const [modalStatus, setModalStatus] = useState();
  const selected_submission = useSelector(
    (state) => state.SubmissionReduxStore.selected_submission
  );
  const access_data_by_user_id = useSelector(
    (state) => state.counter.access_data_by_user_id
  );
  const job_status_name = useSelector(
    (state) => state.JobReduxStore.job_status_name
  );
  const initial_loader = useSelector((state) => state.counter.initial_loader);

  useEffect(() => {
    getData();
    getStatusList();
    dispatch(removeJObData([]));
    dispatch(
      changeInitialTableList([
        "status",
        "due_date",
        "job_title",
        "job_id",
        "client",
        "max_sub",
        "sub_made",
        "partner",
        "recruiter",
      ])
    );
  }, []);
  useEffect(() => {
    getDataForSubmission();
  }, []);

  async function getData() {
    dispatch(changeInitialLoader(true));
    if (jobDataList === null) {
      var statusData = {
        status: job_status_name,
        order: "",
        recruiter_id: "123",
        partner_id: "123",
      };
      var jobData = await jobController.getJobsByStatus(
        statusData,
        jobPagenumber
      );
      dispatch(getjobData(jobData));
    }
    dispatch(changeInitialLoader(false));
  }
  async function onClick(data) {
    if (!getClickableAccess(data)) {
      return;
    }
    dispatch(
      changeFilterData({
        status: "ALL",
        recruiter_id: "123",
        client_id: "123",
        partner_id: "123",
      })
    );
    setIsLoadingStatusData(true);
    setSelectedStatusName(data);
    setIsOpenCountDetail(true);
    var res = await submissionController.getSubmissionDataForDashboard({
      status: await getStatusName(data),
    });
    setStatusDetailData(res?.data);
    setIsLoadingStatusData(false);
  }
  function getStatusList() {
    const tempStatusList = [];
    var submissionList = isVisible
      ? SUBMISSION_STATUS_FOR_MANAGER
      : SUBMISSION_STATUS_FOR_RECRUITER;
    tempStatusList.push("All", ...submissionList);
    dispatch(changeStatusList(tempStatusList));
  }
  async function getDataForSubmission() {
    var user = localStorage.getItem("USER");
    var sendData = {
      recruiter_id: JSON.parse(user).user._id,
    };
    var res =
      await submissionController.onGetSubmissionTrackerDataByRecruiterId(
        sendData
      );
    dispatch(onChangeSubmissionDataForDashboard(res?.tracker_data));
  }
  function onViewSubmission(modalSt, data) {
    setisOpenModal(true);
    setModalStatus(modalSt);
    dispatch(changeSelectedSubmission(data));
  }
  return (
    <Container>
      <BaseModal
        isBig={modalStatus === INTERVIEW_DETAILS ? false : true}
        open={isopenModal}
        formTitle={
          modalStatus === INTERVIEW_DETAILS
            ? INTERVIEW_DETAILS
            : getFormTitleForSubmission(selected_submission)
        }
        data={selected_submission}
        isSubmissionView={true}
        submissionList={props.submissionList}
        handleClose={() => setisOpenModal(!isopenModal)}
      >
        {modalStatus === INTERVIEW_DETAILS ? (
          <InterviewStatus
            handleClose={() => setisOpenModal(!isopenModal)}
            modalStatus={modalStatus}
            selectedData={selected_submission}
          />
        ) : (
          <SubmissionViewDetails
            handleClose={() => setisOpenModal(!isopenModal)}
            onClickEdit={(e) => onViewSubmission(ADD_INTERVIEW_DETAIL, e)}
            onRefresh={() => props.onRefresh()}
            data={selected_submission}
          />
        )}
      </BaseModal>
      <CountDataDetails
        isLoadingStatusData={isLoadingStatusData}
        onViewSubmission={onViewSubmission}
        data={statusDetailData}
        selectedStatusName={selectedStatusName}
        onClose={() => setIsOpenCountDetail(false)}
        open={isOpenCountDetail}
      />
      {initial_loader ? <LoaderForTable /> : <></>}
      <TopFixedMenu>
        <TopMenu noDisplay={false} status={props.status} />
      </TopFixedMenu>
      <MainScreen style={{ height: "90vh", overflow: "auto" }}>
        {/* {countData === null ? <AppLoader /> : <div />} */}
        <DashboardCard onClick={onClick} />
        <div container>
          <TableMainContainer>
            <TrackerTable />
            {access_data_by_user_id.includes("view_review") ? (
              <SubmissionTableForDashboard
                submissionStatus={"REVIEW"}
                height={"auto"}
                dummuyState={dummuyState}
                onRefresh={() => setDummuyState(dummuyState + 1)}
              />
            ) : (
              <></>
            )}
            {onCheckLevelAccessibility() ? (
              <>
                {/* <SubmissionTableForDashboard
                  submissionStatus={"REVIEW"}
                  height={"auto"}
                  dummuyState={dummuyState}
                  onRefresh={() => setDummuyState(dummuyState + 1)}
                /> */}
                <InterviewTableForDashboard
                  submissionStatus={"INTERVIEW"}
                  height={"auto"}
                  dummuyState={0}
                  onRefresh={() => setDummuyState(dummuyState + 1)}
                />
                <SubmissionTableForDashboard
                  submissionStatus={"PLEASE SUBMIT"}
                  height={"auto"}
                  dummuyState={dummuyState}
                  onRefresh={() => setDummuyState(dummuyState + 1)}
                />
              </>
            ) : (
              <>
                <InterviewTableForDashboard
                  submissionStatus={"INTERVIEW"}
                  height={"auto"}
                  dummuyState={dummuyState}
                  onRefresh={() => setDummuyState(dummuyState + 1)}
                />
                <SubmissionTableForDashboard
                  submissionStatus={"PLEASE SUBMIT"}
                  height={"auto"}
                  dummuyState={dummuyState}
                  onRefresh={() => setDummuyState(dummuyState + 1)}
                />
                <JobList />
              </>
            )}
          </TableMainContainer>
        </div>
      </MainScreen>
    </Container>
  );
}
