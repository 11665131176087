import { Grid } from "@mui/material";
import React from "react";
import { ExperienceWithSkillContainer } from "../../../style/dice.style";

export default function ExperienceWithSkill(props) {
  return (
    <Grid item md={3}>
      <ExperienceWithSkillContainer>
        <p>{props.skill}</p>
        <div style={{ display: "flex", gap: "10px" }}>
          <p>{props.experience} yrs</p>
          <p>{props.year}</p>
        </div>
      </ExperienceWithSkillContainer>
    </Grid>
  );
}
