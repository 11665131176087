import React from "react";
import { Box, Modal } from "@mui/material";
import { changeIsOpenForTable } from "../../feature/store/ReportStore";
import { useDispatch, useSelector } from "react-redux";
import { TitleContainer } from "../../style/job.style";
import CustomTooltip from "../CommonComponet/Tooltip";
import { CommonString, CommonTitle } from "../../Utils/CommonString";
import { Icon } from "@iconify/react";
import { CommonIcon } from "../../Utils/CommonIcon";
import { ModalTitle } from "../../style/common.style";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  bgcolor: "background.paper",
  p: 4,
};
export default function ModalForDailyReportDetail(props) {
  const dispatch = useDispatch();
  const is_open = useSelector((state) => state.ReportStoreRedux.is_open);
  const modal_status = useSelector(
    (state) => state.ReportStoreRedux.modal_status
  );
  return (
    <Modal
      style={{ zIndex: 1 }}
      open={is_open}
      //   onClose={() => dispatch(changeIsOpen(!is_open))}
    >
      <Box sx={style}>
        <TitleContainer style={{ padding: 0, marginBottom: 20 }}>
          <ModalTitle style={{ margin: 0 }}>{modal_status}</ModalTitle>
          <CustomTooltip
            placement={CommonString.top_placement}
            title={CommonTitle.close}
            name={
              <Icon
                width={24}
                height={24}
                icon={CommonIcon.modal_close}
                onClick={() => dispatch(changeIsOpenForTable(!is_open))}
              />
            }
          />
        </TitleContainer>
        {props.view}
      </Box>
    </Modal>
  );
}
