import { PlaceholderName } from "./CommonString";

export const workAuthoritys = ["sazia", "vishal", "abrar"];
export const skillList = [
  {
    name: "react",
  },
  {
    name: "node",
  },
  {
    name: "java",
  },
];
export const options = [
  "Resume",
  "RTR",
  "References",
  "Rate Confirmation",
  "Skill Matrix",
  "Cover Letter",
];
export const prefred_skill = [
  "darrt",
  "node js",
  "graphic designer",
  "java script",
  "react js",
  "Resume",
  "RTR",
  "References",
  "Rate Confirmation",
  "Skill Matrix",
  "Cover Letter",
  "DONE",
  "PENDING",
  "WORKING",
];
export const status = ["DONE", "PENDING", "WORKING"];
export const months = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];
export const years = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];
export const Job_duration_in = ["Month", "Year", "Week", "Hours"];
export const require_education = [
  "High school",
  "Associate degree",
  "Bachelor degree",
  "Master degree",
  "Doctorate degree",
  "Others",
];
export const internal_domain = ["All", "Public", "Private", "Healthcare"];
export const domain_list = [
  "State Government",
  "District Government",
  "Federal Government ",
  "Commercial",
  "HealthCare",
  "Banking and Financial Services",
  "Insurance",
  "Defense",
  "Education",
  "Energy (Utilities/Gas/Electronics)",
  "Manufacturing",
  "Automotive",
  "Airline",
  "Agriculture",
  "Others",
];
export const departmentList = [
  "Account",
  "IT",
  "Recruitment",
  "Bussiness development",
];
export const MonthForReport = [
  "Jan",
  "Feb",
  "March",
  "April",
  "May",
  "Jun",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const YearForReport = ["2021", "2022", "2023"];
export const InterviewModeForJob = [
  "In-Person",
  "Phone",
  "WebEx",
  "MS Team",
  "Zoom",
  "Video",
  "Google Meets",
  "Others",
];
export const SDLC_Process = [
  "Project Planning",
  "Gathering Requirements & Analysis",
  "Design",
  "Coding or Implementation",
  "Testing",
  "Deployment",
  "Maintenance",
];
export const Job_type = ["Contract", "Full-time(PERM)", "Contract to hire"];
export const Working_hours = ["40", "20", "Others"];
export const RecruiterList = [
  {
    name: "All",
    id: "1",
    text: "All",
  },
  {
    name: "Abrar Shaik",
    id: "2",
    text: "Abrar Shaik",
  },
  {
    name: "Deepak Kumar",
    id: "3",
    text: "Deepak Kumar",
  },
  {
    name: "Vishal Mange",
    id: "4",
    text: "Vishal Mange",
  },
  {
    name: "Sazia Husain",
    id: "5",
    text: "Sazia Husain",
  },
  {
    name: "Jolly matchar",
    id: "6",
    text: "Jolly matchar",
  },
  {
    name: "soman Prajapati",
    id: "7",
    text: "soman Prajapati",
  },
];
export const submission_table_in_job = [
  "Applicant Name",
  "Tax Term",
  "Rate",
  "Recruiter name",
  "Date Of Contact",
  "Status",
];
export const submission_table_in_applicant = [
  "Position Title",
  "Tax Term",
  "Rate",
  "Recruiter name",
  "Sub. Date",
  "Status",
];
export const form_name = {
  client_name: "client_name",
  department: "department",
  position_title: "position_title",
  client_position_id: "client_position_id",
  job_location: "job_location",
  postal_code: "postal_code",
  contact_manager: "contact_manager",
  job_duration: "job_duration",
  total_opening: "total_opening",
  max_submission: "max_submission",
  interview_mode: "interview_mode",
  job_mode: "job_mode",
  SDLC_Process: "SDLC_Process",
  working_hour: "working_hour",
  domain: "domain",
  job_type: "job_type",
  bill_rate: "bill_rate",
  experience: "experience",
  required_education: "required_education",
  Job_duration_type: "Job_duration_type",
  comment: "comment",
  status: "Status",
  due_date: "Due Date",
  partner: "Partner",
  recruiter: "Recruiter",
};
export const placeholder_name_without_id = [
  PlaceholderName.job_duration_type,
  PlaceholderName.job_mode,
  PlaceholderName.working_hours,
  PlaceholderName.required_education,
  PlaceholderName.years_of_experience,
  PlaceholderName.job_type,
  PlaceholderName.interview_mode,
  PlaceholderName.experience,
  PlaceholderName.work_authorization,
  PlaceholderName.tax_term,
  PlaceholderName.sourced_from,
  PlaceholderName.domain,
  PlaceholderName.duration,
];
