import { Icon } from "@iconify/react";
import React, { useState } from "react";
import styled from "styled-components";
import { CommonIcon } from "../../../Utils/CommonIcon";

const Title = styled.h1`
  font-size: 12px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 5px 10px;
  width: fit-content;
`;

const CloseButton = styled.button`
  background: lightgray;
  border-radius: 30px;
  border: none;
  font-size: 12px;
  /* padding: 3px 5px; */
  margin-left: 2px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  svg {
    align-self: center;
    /* margin-top: -2px; */
    /* margin-left: 1px; */
  }
`;

const SuggestionsTitle = styled.h2`
  font-size: 15px;
  margin-bottom: 10px;
`;

const AddButton = styled.button`
  border: 1px solid #007bff;
  background-color: transparent;
  color: #007bff;
  padding: 0px 5px;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 10px;
`;

const Item = styled.div`
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const Suggestions = (props) => {
  const [suggestionList, setSuggestionList] = useState(props.list);

  function onAdd(e) {
    if (props.value.includes(e) || e === undefined) {
      return;
    }
    props.onClickOnAdd([...props.value, e]);
    var is_exist = suggestionList.filter((data) => data !== e);
    // console.log("is_exist", is_exist);
    setSuggestionList(is_exist);
  }
  function onRemove(e) {
    var is_exist = props.value.filter((data) => data !== e);
    setSuggestionList([...suggestionList, e]);
    props.onClickOnAdd(is_exist);
  }

  return (
    <div>
      {props.value.map((data) => (
        <Title onClick={() => onRemove(data)}>
          <p>{data}</p>
          <CloseButton>
            <Icon icon={CommonIcon.close} />
          </CloseButton>
        </Title>
      ))}
      <div>
        <SuggestionsTitle>Suggestions</SuggestionsTitle>
        {suggestionList
          .filter((item) => !props.value.includes(item))
          .map((data) => (
            <Item onClick={() => onAdd(data)}>
              <AddButton>+</AddButton>
              {data}
            </Item>
          ))}
      </div>
    </div>
  );
};

export default Suggestions;
