const CommonColor = {
  primary_color: "#142130",
  secondary_color: "#ff6600",
  screen_background_color: "#f5f5f5",
  tablerow_odd_color: "#e6e6e7",
  tablerow_even_color: "#f5f5f5",
  table_heading_color: "#142130",
  th_text_color: "#ffff",
  td_text_color: "#2d4047",
  table_border: "#e2e2e2",
  bg_red: "#F7D7DA",
  red: "#7C2D36",
  bg_green: "#D5EDDA",
  green: "#276235",
  bg_blue: "#CCE4FE",
  blue: "#084384",
  primary_color_opacity: "#14213020",
  primary_bg: "#14213010",
  secondary_bg: "#ff660010",
  tooltip_color: "#454545",
  border: "lightgrey",
  light_grey: "lightgrey",
  grey: "grey",
  white: "#FFFFFF",
  primary_hover_color: "#2f4758",
  recruiter_green: "#40a357",
  recruiter_red: "#da3b4d",
  light_text: "#ced4da",
  error_text_color: "#fa6502",
  bg_yellow: "#f5f581",
  black: "black",
  transparent: "transparent",
  black_opacity_color: "#6e6e6e",
  button_hover: "#142130cc",
  grey_text: "#727272",
  bg_yellow_text_color: "#8a8a0d",
  background_black_opacity_color: "rgba(3, 3, 3, 0.09)",
};

export { CommonColor };
