import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  onChangeIsModalOpen,
  onChangeModalStatus,
  onChangeSearchResult,
  onChangeSearchText,
  onChangeSelectData,
} from "../feature/store/LevelReduxStore";
import { changeInitialLoader, getLevelData } from "../feature/store/ReduxStore";
import LevelProvider from "../services/provider/LevelProvider";
import {
  ADD_LEVEL_ERROR,
  ADD_LEVEL_SUCCESS,
  DELETE_LEVEL_ERROR,
  DELETE_LEVEL_SUCCESS,
  UPDATE_LEVEL_ERROR,
  UPDATE_LEVEL_SUCCESS,
} from "../Utils/alertMessage";
import { CommonString } from "../Utils/CommonString";
import { UPDATE_LEVEL } from "../Utils/enum";

export default class LevelController {
  constructor() {
    this.dispatch = new useDispatch();
    this.levelProvider = new LevelProvider();
    this.alert = new useAlert();
    this.levelList = new useSelector((state) => state.counter.levelData);
    this.select_data = new useSelector(
      (state) => state.LevelStoreRedux.select_data
    );
  }
  getLevels = async () => {
    this.dispatch(changeInitialLoader(true));
    var res = await this.levelProvider.getLevelList();
    this.dispatch(getLevelData(res.data.data));
    this.dispatch(changeInitialLoader(false));
  };
  deleteLevels = async (data) => {
    this.dispatch(changeInitialLoader(true));
    var sendData = {
      _id: data._id,
    };
    var res = await this.levelProvider.deleteLevel(sendData);
    if (res.data.status === 200) {
      this.alert.show(DELETE_LEVEL_SUCCESS, { type: CommonString.success });
      this.getLevels();
      this.dispatch(changeInitialLoader(false));
    } else {
      this.alert.show(DELETE_LEVEL_ERROR, { type: CommonString.error });
      this.dispatch(changeInitialLoader(false));
    }
  };
  onSearch = async (search_text) => {
    this.dispatch(onChangeSearchText(search_text));
    if (search_text.length < 2) {
      if (search_text.length === 0 || this.levelList.length === 0) {
        this.dispatch(onChangeSearchResult(this.levelList));
      }
      return;
    }
    const result = this.levelList.filter(
      (level) =>
        level.name.toLowerCase().trim().includes(search_text.toLowerCase()) ||
        level.designation
          .toLowerCase()
          .trim()
          .includes(search_text.toLowerCase())
    );
    this.dispatch(onChangeSearchResult(result));
  };
  onViewModal = async (modalSt, data) => {
    this.dispatch(onChangeIsModalOpen(true));
    this.dispatch(onChangeModalStatus(modalSt));
    this.dispatch(onChangeSelectData(data));
  };
  onSubmitData = async (modal_status, data) => {
    this.dispatch(changeInitialLoader(true));
    // this.dispatch(onChangeIsLoading(true));
    if (modal_status === UPDATE_LEVEL) {
      var sendData = {
        _id: this.select_data._id,
        ...data,
      };
      var res = await this.levelProvider.updateLevel(sendData);
      if (res.data.status === 200) {
        this.dispatch(changeInitialLoader(false));
        this.getLevels();
        this.dispatch(onChangeIsModalOpen(false));
        this.alert.show(UPDATE_LEVEL_SUCCESS, { type: CommonString.success });
      } else {
        this.alert.show(UPDATE_LEVEL_ERROR, { type: CommonString.error });
        this.dispatch(changeInitialLoader(false));
      }
      return;
    }
    var add_res = await this.levelProvider.addNewLevel(data);
    if (add_res.data.status === 200) {
      localStorage.removeItem(CommonString.level_local_data);
      this.dispatch(changeInitialLoader(false));
      this.getLevels();
      this.dispatch(onChangeIsModalOpen(false));
      this.alert.show(ADD_LEVEL_SUCCESS, { type: CommonString.success });
    } else {
      this.alert.show(ADD_LEVEL_ERROR, { type: CommonString.error });
      this.dispatch(changeInitialLoader(false));
    }
    return;
  };
}
