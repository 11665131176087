import React from "react";
import Lottie from "react-lottie";
import loader from "../../assets/images/loader3.json";
import { TableLoaderContainer } from "../../style/common.style";

export default function LoaderForTable() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <TableLoaderContainer>
      <Lottie options={defaultOptions} height={40} width={40} />
      {/* <Lottie animationData={loader} loop={true} autoplay={true} /> */}
    </TableLoaderContainer>
  );
}
