import React from "react";
import { DiceMainContainer } from "../../style/dice.style";
import { Grid } from "@mui/material";
import SearchComponent from "./Component/SearchComponent";
import Filters from "./Component/Filters";
import ApplicantProfileCard from "./Component/ApplicantProfileCard";
import DiceProfileFilters from "./Component/DiceProfileFilters";

export default function DiceScreen() {
  return (
    <DiceMainContainer>
      <SearchComponent />
      <Grid container>
        <Grid item md={3}>
          <Filters />
          <DiceProfileFilters />
        </Grid>
        <Grid item md={9}>
          {[1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12].map((data) => (
            <ApplicantProfileCard />
          ))}
        </Grid>
      </Grid>
    </DiceMainContainer>
  );
}
