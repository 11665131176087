import ApiUtils from "../APICallServices/APIUtils";

export default class NotificationProvider {
  constructor() {
    this.Apiprovider = new ApiUtils();
  }
  getNotification = () => {
    return this.Apiprovider.init().get("notification/get-notification");
  };
}
