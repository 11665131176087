import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { formatPhoneNumber } from "../../services/Validate";
import FormInput from "../CommonComponet/FormInput";
import { Icon } from "@iconify/react";
import { onUpdatePOCdetail } from "../../feature/store/ClientReduxStore";
import { PocDetailLastcol } from "../../style/client.style";
import { CommonIcon } from "../../Utils/CommonIcon";

export default function ClientPOCDetail(props) {
  const dispatch = useDispatch();
  const [poc_name, setpoc_name] = useState();
  const [poc_phone, setpoc_phone] = useState();
  const [poc_email, setpoc_email] = useState();
  const [poc_designation, setpoc_designation] = useState();

  useEffect(() => {
    onPrefilledData();
  }, []);

  function datadasda(data) {
    var senddata = {
      ...data,
      active: false,
    };
    dispatch(onUpdatePOCdetail(senddata));
  }

  function onPrefilledData() {
    if (props.isUpdate && props.data != null) {
      setpoc_name(props.data.poc_name);
      setpoc_email(props.data.poc_email);
      setpoc_designation(props.data.poc_designation);
      setpoc_phone(props.data.poc_phone);
    }
  }

  function onAddPOC() {
    var data = {
      active: props.data.active,
      count: props.data.count,
      poc_name: poc_name ?? "",
      poc_email: poc_email ?? "",
      poc_phone: poc_phone ?? "",
      poc_designation: poc_designation ?? "",
    };
    dispatch(onUpdatePOCdetail(data));
  }

  return (
    <>
      {props.data.active === false ? (
        <div />
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <FormInput
              onBlur={() => onAddPOC()}
              value={poc_name || ""}
              onChange={(e) => {
                setpoc_name(e.target.value);
              }}
              placeholder="POC Name"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormInput
              onBlur={() => onAddPOC()}
              value={poc_phone || ""}
              onChange={(e) => {
                setpoc_phone(formatPhoneNumber(e.target.value));
              }}
              placeholder="POC Phone Number"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormInput
              onBlur={() => onAddPOC()}
              value={poc_email || ""}
              onChange={(e) => {
                setpoc_email(e.target.value);
              }}
              placeholder="POC Email"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <PocDetailLastcol>
              <div>
                <FormInput
                  onBlur={() => onAddPOC()}
                  value={poc_designation || ""}
                  onChange={(e) => {
                    setpoc_designation(e.target.value);
                  }}
                  placeholder="POC Designation"
                />
              </div>
              <Icon
                icon={CommonIcon.close}
                onClick={() => datadasda(props.data)}
              />
            </PocDetailLastcol>
          </Grid>
        </Grid>
      )}
    </>
  );
}
