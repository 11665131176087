import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommonIcon } from "../../Utils/CommonIcon";
import {
  CommonString,
  CommonStringForLocalStorage,
} from "../../Utils/CommonString";
import {
  APPLICANTS,
  CLIENT,
  DAILY_REPORT,
  DASHBOARD,
  JOBS,
  LEVEL,
  PARTNER,
  RECRUITERS,
  REPORT,
  STATUS,
  SUBMISSION,
} from "../../Utils/enum";
import {
  changeMenuStatus,
  onChangePageNumber,
  removeJObData,
} from "../../feature/store/ReduxStore";
import { CustomIcon } from "../../style/Icon.style";
import { MenuListContainer } from "../../style/menu.style";
import MenuItem from "./MenuItem";

export default function MenuList(props) {
  const dispatch = useDispatch();
  const isVisible = useSelector((state) => state.counter.is_visible);
  const menuStatus = useSelector((state) => state.counter.menuStatus);

  useEffect(() => {
    dispatch(
      changeMenuStatus(
        localStorage.getItem(CommonStringForLocalStorage.view_status) === null
          ? CommonString.dashboard
          : localStorage.getItem(CommonStringForLocalStorage.view_status)
      )
    );
  }, []);

  function onClick(data) {
    dispatch(removeJObData([]));
    dispatch(onChangePageNumber(1));
    dispatch(changeMenuStatus(data));
    props.onClick(data);
  }

  return (
    <MenuListContainer>
      <MenuItem
        open={props.open}
        isVisible={true}
        icon={<CustomIcon icon={CommonIcon.dashboard} />}
        name={DASHBOARD}
        isActive={DASHBOARD === menuStatus}
        onClick={() => onClick(DASHBOARD)}
      />
      <MenuItem
        open={props.open}
        isVisible={true}
        icon={<CustomIcon icon={CommonIcon.bag} />}
        name={JOBS}
        isActive={JOBS === menuStatus}
        onClick={() => onClick(JOBS)}
      />
      <MenuItem
        open={props.open}
        icon={<CustomIcon icon={CommonIcon.file_text} />}
        isVisible={true}
        onClick={() => {
          onClick(APPLICANTS);
        }}
        isActive={APPLICANTS === menuStatus}
        name={APPLICANTS}
      />
      <MenuItem
        open={props.open}
        icon={<CustomIcon icon={CommonIcon.people_checkmark} />}
        isVisible={true}
        name={STATUS}
        isActive={SUBMISSION === menuStatus}
        onClick={() => onClick(SUBMISSION)}
      />
      <MenuItem
        open={props.open}
        isVisible={isVisible}
        icon={<CustomIcon icon={CommonIcon.handshake} />}
        isActive={PARTNER === menuStatus}
        name={PARTNER}
        onClick={() => onClick(PARTNER)}
      />
      {/* <MenuItem
        open={props.open}
        isVisible={isVisible}
        icon={<CustomIcon icon={CommonIcon.people_group} />}
        name={DOMAIN}
        isActive={DOMAIN === menuStatus}
        onClick={() => onClick(DOMAIN)}
      /> */}
      <MenuItem
        open={props.open}
        isVisible={isVisible}
        icon={<CustomIcon icon={CommonIcon.people_group} />}
        name={CLIENT}
        isActive={CLIENT === menuStatus}
        onClick={() => onClick(CLIENT)}
      />
      <MenuItem
        open={props.open}
        isVisible={isVisible}
        icon={<CustomIcon icon={CommonIcon.search_people} />}
        name={RECRUITERS}
        isActive={RECRUITERS === menuStatus}
        onClick={() => onClick(RECRUITERS)}
      />
      <MenuItem
        open={props.open}
        isVisible={isVisible}
        icon={<CustomIcon icon={CommonIcon.hierarchy} />}
        name={LEVEL}
        isActive={LEVEL === menuStatus}
        onClick={() => onClick(LEVEL)}
      />
      <MenuItem
        isVisible={true}
        icon={<CustomIcon icon="hugeicons:clipboard" />}
        name={DAILY_REPORT}
        isActive={DAILY_REPORT === menuStatus}
        onClick={() => onClick(DAILY_REPORT)}
      />
      <MenuItem
        isVisible={true}
        icon={<CustomIcon icon="carbon:report" />}
        name={REPORT}
        isActive={REPORT === menuStatus}
        onClick={() => onClick(REPORT)}
      />
      {/* <MenuItem
        isVisible={isVisible}
        icon={<CustomIcon icon="clarity:group-outline-alerted"  />}
        name={GROUP}
        isActive={GROUP === menuStatus}
        onClick={() => onClick(GROUP)}
      /> */}
    </MenuListContainer>
  );
}
