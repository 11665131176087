import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import Uploader from "../../services/provider/uploader";
import { log } from "../../services/Logger/Logger";
import { RESUME_BASEURL } from "../../Utils/constant";
import PdfOrDocViewwer from "../CommonComponet/ApplicantComponent/PdfOrDocViewer";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import AddApplicantForm from "./AddApplicantForm";
import { useDispatch, useSelector } from "react-redux";
import { ButtonName } from "../../Utils/CommonString";
import {
  changeEmailList,
  changePhone,
  changeResumeUrl,
} from "../../feature/store/ApplicantStore";
import {
  findEmailFromString,
  findPhoneFromString,
} from "../../Utils/validation";
// const WordExtractor = require("word-extractor");
const pdfjsLib = require("pdfjs-dist");

pdfjsLib.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

export default function AddApplicant(props) {
  const uploader = new Uploader();
  const dispatch = useDispatch();
  const [selectedFileName, setselectedFileName] = useState();
  const [resumeLink, setResumeLink] = useState();
  const [raw_resume, setRaw_resume] = useState();
  const [uploadResumeResponse, setUploadResumeResponse] = useState(null);
  const applicant = useSelector(
    (state) => state.ApplicantStore.selectedApplicant
  );
  const button_name = useSelector((state) => state.ApplicantStore.button_name);
  useEffect(() => {
    onPrefilledData();
  }, []);

  function onPrefilledData() {
    if (button_name === ButtonName.edit_applicant && applicant !== undefined) {
      setResumeLink(applicant.resume_link);
      dispatch(changeResumeUrl(applicant.resume_link));
    }
  }
  async function onFetchData(text) {
    if (text === undefined || text === null) return;
    var emails = await findEmailFromString(text);
    if (!emails) return;
    dispatch(changeEmailList(emails[0]));
    var phone = await findPhoneFromString(text);
    if (!phone) return;
    dispatch(changePhone(phone[0]));
  }
  async function onselect(e) {
    if (e.length == 0) return;
    let selectedFile = e;
    setselectedFileName(e);
    var values = e.name.split(".").reverse()[0];
    if (e.type === "application/pdf") {
      if (selectedFile) {
        let reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onloadend = (e) => {
          onrenderPDf(e);
        };
      } else {
        log("select your file");
      }
    } else if (values === "docx") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const content = e.target.result;
        var doc = new Docxtemplater(new PizZip(content), {
          delimiters: {
            start: "12op1j2po1j2poj1po",
            end: "op21j4po21jp4oj1op24j",
          },
        });
        var text = doc.getFullText();
        var RegExpression = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
        var pattern = text
          .replace(RegExpression, "")
          .replace(/[`~�!#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
          .replace(/[^a-zA-Z0-9 ]/g, "");
        setRaw_resume(pattern);
        onFetchData(text);
      };
      reader.readAsBinaryString(e);
    }
    const formData = new FormData();
    formData.append("image", e);
    const res = await uploader.uploadResume(formData);
    setUploadResumeResponse(res);
    if (res.data.status === 200) {
      dispatch(changeResumeUrl(res.data.data.url));
      setResumeLink(res.data.data.url);
      if (values === "doc") {
        fetch(RESUME_BASEURL + res.data.data.url)
          .then((response) => {
            return response.text();
          })
          .then((text) => {
            var RegExpression = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
            var pattern = text
              .replace(RegExpression, "")
              .replace(/[`~�!#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, "")
              .replace(/[^a-zA-Z0-9 ]/g, "");
            setRaw_resume(pattern.toString());
            onFetchData(text);
          });
      }
      return res.data.data.url;
    }
  }

  async function onrenderPDf(e) {
    let doc = await pdfjsLib.getDocument(e.target.result).promise;
    var commonText = "";
    for (let index = 1; index <= doc._pdfInfo.numPages; index++) {
      let page = await doc.getPage(index);
      let tr = await page.getTextContent();
      tr.items.map((item) => (commonText = commonText + item.str));
    }
    var RegExpression = /^(?![\s.]+$)[a-zA-Z\s.]*$/;
    var pattern = commonText.replace(RegExpression, "");
    setRaw_resume(pattern.toString());
    setRaw_resume(pattern);
    onFetchData(commonText);
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <PdfOrDocViewwer onselect={(e) => onselect(e)} url={resumeLink} />
      </Grid>
      <Grid item xs={12} md={6}>
        <AddApplicantForm
          uploadResumeResponse={uploadResumeResponse}
          onselect={(e) => onselect(e)}
          resumeLink={resumeLink}
          raw_resume={raw_resume}
          data={applicant}
        />
      </Grid>
    </Grid>
  );
}
