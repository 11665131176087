import React, { useState, useRef } from "react";
import UserDropDown from "./UserDropDown";
import { RightMenuIcon, RightTopMenu } from "../../style/menu.style";
import Search from "./Search";
import { CommonString } from "../../Utils/CommonString";
import { DashboardTopBar } from "../../style/dashboard.style";
import { AddButtonForJob } from "../../style/job.style";
import SocketBox from "../Dashboard/SocketBox";
import { DAILY_REPORT, REPORT } from "../../Utils/enum";

export default function TopMenu(props) {
  const [textValue, setTextValue] = useState("");
  const childRef = useRef(null);

  return (
    <RightTopMenu>
      {props.status === CommonString.dashboard ||
      props.status === DAILY_REPORT ||
      props.status === REPORT ? (
        <DashboardTopBar>{props.status}</DashboardTopBar>
      ) : (
        <>
          <Search
            internalSearch={props.internalSearch}
            searchText={props.searchText}
            isApplicant={props.isApplicant}
            placeholder={props.placeholder}
            setStatus={(e) => {}}
            ref={childRef}
            textValue={textValue}
            // settextValue={(e) => settextValue(e)}
            onChange={(e) => {
              props.onChange(e);
              setTextValue(e.target.value);
            }}
            onClickSearch={props.onClickSearch}
          />
        </>
      )}
      <RightMenuIcon setX={props.setX}>
        {props.noDisplay === false || textValue.length === 0 ? (
          <>
            <UserDropDown />
            <div
              style={{
                marginRight: "5px",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SocketBox />
              {/* <NotificationDropDown /> */}
            </div>
          </>
        ) : (
          <div></div>
        )}
        {props.viewButton && textValue.length === 0 ? (
          <AddButtonForJob variant="outlined" onClick={props.onClickAddButton}>
            + {props.buttonName}
          </AddButtonForJob>
        ) : (
          <></>
        )}
      </RightMenuIcon>
    </RightTopMenu>
  );
}
