import React, { useState } from "react";
import diceLogo from "../../../assets/images/diceLogo.jpg";
import styled from "styled-components";
import { FilterListContainer } from "../../../style/dice.style";
import InputWithLabel from "./InputWithLabel";
import Suggestions from "./Suggestions";
import { employment_type, work_permits } from "./Constant";
import CheckBox from "../../../component/basic/CheckBox";
import CollapsibleInput from "./CollapsibleInput";
import { useDispatch } from "react-redux";
import { onChangeDiceProfileFilterData } from "../../../feature/store/DiceStore";
const Title = styled.h5`
  font-size: 16px;
  color: grey;
  font-weight: bold;
  img {
    width: 20px;
    margin-right: 10px;
  }
`;

export default function DiceProfileFilters() {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    onlyWithSecurityClearance: false,
    workPermit: "",
    employmentType: "",
    dateResumeLastUpdated: 0,
  });

  function handleInputChange(event) {
    if (event.target !== undefined) {
      const { name, checked } = event.target;
      setFormData({ ...formData, [name]: checked });
      dispatch(onChangeDiceProfileFilterData({ ...formData, [name]: checked }));
      return;
    }
    const { name, value } = event;
    setFormData({ ...formData, [name]: value });
    dispatch(onChangeDiceProfileFilterData({ ...formData, [name]: value }));
  }

  function onGetValue(e) {
    if (e === "") {
      return [];
    }
    return JSON.parse(e);
  }
  function onChangeSuggestion(name, value) {
    handleInputChange({
      name: name,
      value: JSON.stringify(value),
    });
  }

  return (
    <div>
      <Title>
        <img src={diceLogo} /> Dice Profile Filters:
      </Title>
      <span>These filters will only find technologists with Dice accounts</span>
      <FilterListContainer>
        <CollapsibleInput
          is_active={
            formData.dateResumeLastUpdated === 0 ||
            formData.dateResumeLastUpdated === ""
          }
          index={1}
          title={"Resume Last Updated"}
          content={
            <>
              <span>
                Maximum number of days since Resume last updated on Dice
              </span>
              <InputWithLabel
                onChange={(e) =>
                  handleInputChange({
                    name: "dateResumeLastUpdated",
                    value: e.target.value,
                  })
                }
                value={formData.dateResumeLastUpdated}
                placeholder="# of days"
                unitLabel="days"
              />
            </>
          }
        />
        <CollapsibleInput
          is_active={
            formData.employmentType === "" ||
            formData.employmentType.length === 0
          }
          index={2}
          title={"Employment Type"}
          content={
            <>
              <Suggestions
                list={employment_type}
                value={onGetValue(formData.employmentType)}
                onClickOnAdd={(e) => onChangeSuggestion("employmentType", e)}
              />
            </>
          }
        />
        <CollapsibleInput
          is_active={formData.workPermit === ""}
          index={4}
          title={"Work Permits"}
          content={
            <>
              <Suggestions
                list={work_permits}
                value={onGetValue(formData.workPermit)}
                onClickOnAdd={(e) => onChangeSuggestion("workPermit", e)}
              />
            </>
          }
        />
        <CollapsibleInput
          is_active={formData.onlyWithSecurityClearance === false}
          index={5}
          title={"Has Security Clearance"}
          content={
            <>
              <CheckBox
                onClick={handleInputChange}
                value={formData.onlyWithSecurityClearance}
                label={"Has Security Clearance"}
                name={"onlyWithSecurityClearance"}
              />
            </>
          }
        />
      </FilterListContainer>
    </div>
  );
}
