import React, { useState } from "react";
import { FlexContainer } from "../../style/job.style";
import CustomCard from "../CommonComponet/Card";
import dash_1 from "../../assets/images/dash_1.png";
import dash_2 from "../../assets/images/dash_2.png";
import dash_3 from "../../assets/images/dash_3.png";
import dash_4 from "../../assets/images/dash_4.png";
import dash_5 from "../../assets/images/dash_5.png";
import dash_6 from "../../assets/images/dash_6.png";
import { CommonString } from "../../Utils/CommonString";
import CustomizedTableField from "./CustomizedTableField";
import { useDispatch, useSelector } from "react-redux";
import BaseModal from "../Modal/BaseModal";
import SubmissionViewDetails from "../ViewDetails/SubmissionViewDetails";
import { ADD_INTERVIEW_DETAIL, VIEW_SUBMISSION } from "../../Utils/enum";
import ReportController from "../../controller/ReportController";
import CountDataDetails from "../Dashboard/CountDataDetails";
import { changeSelectedSubmission } from "../../feature/store/SubmissionReduxStore";

export default function TotalCountCards(props) {
  const dispatch = useDispatch();
  const reportController = new ReportController();
  const [isopenModal, setisOpenModal] = useState(false);
  const [selectedData, setselectedData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenCountDetail, setIsOpenCountDetail] = useState(false);
  const selected_submission = useSelector(
    (state) => state.SubmissionReduxStore.selected_submission
  );
  const table_list = useSelector((state) => state.ReportStoreRedux.table_list);
  var total_count = props.data;
  var job_data = props.jobCountData;
  var totalJobCount =
    job_data[0]?.count +
    job_data[1]?.count +
    job_data[2]?.count +
    job_data[3]?.count +
    job_data[4]?.count +
    job_data[5]?.count +
    job_data[6]?.count +
    job_data[7]?.count +
    job_data[8]?.count +
    job_data[9]?.count +
    job_data[10]?.count +
    job_data[11]?.count;
  var data = [
    {
      count: getStatusName("IN TOUCH")?.count,
      title: getStatusName("IN TOUCH")?.status,
      img: dash_3,
      visible: table_list.includes("in_touch"),
    },
    {
      count: getStatusName("LOCKED")?.count,
      title: getStatusName("LOCKED")?.status,
      img: dash_4,
      visible: table_list.includes("locked"),
    },
    {
      count: getStatusName("REVIEW")?.count,
      title: getStatusName("REVIEW")?.status,
      img: dash_5,
      visible: table_list.includes("review"),
    },
    {
      count: getStatusName("PLEASE SUBMIT")?.count,
      title: getStatusName("PLEASE SUBMIT")?.status,
      img: dash_6,
      visible: table_list.includes("please_submit"),
    },
    {
      count: getStatusName("SUBMISSION")?.count,
      title: getStatusName("SUBMISSION")?.status,
      img: dash_1,
      visible: table_list.includes("submission"),
    },
    {
      count: getStatusName("HOLD INTERNALLY")?.count,
      title: getStatusName("HOLD INTERNALLY")?.status,
      img: dash_2,
      visible: table_list.includes("hold_internally"),
    },
    {
      count: getStatusName("REJECTED INTERNALLY")?.count,
      title: getStatusName("REJECTED INTERNALLY")?.status,
      img: dash_3,
      visible: table_list.includes("rejected_internally"),
    },
    {
      count: getStatusName("REJECTED BY CLIENT")?.count,
      title: getStatusName("REJECTED BY CLIENT")?.status,
      img: dash_4,
      visible: table_list.includes("rejected_by_client"),
    },

    {
      count: getStatusName("INTERVIEW")?.count,
      title: getStatusName("INTERVIEW")?.status,
      img: dash_5,
      visible: table_list.includes("interview"),
    },
    {
      count: getStatusName("INTERVIEW NO SHOW")?.count,
      title: getStatusName("INTERVIEW NO SHOW")?.status,
      img: dash_6,
      visible: table_list.includes("iv_no_show"),
    },
    {
      count: getStatusName("OFFER")?.count,
      title: getStatusName("OFFER")?.status,
      img: dash_1,
      visible: table_list.includes("offer"),
    },

    {
      count: getStatusName("PLACEMENT")?.count,
      title: getStatusName("PLACEMENT")?.status,
      img: dash_2,
      visible: table_list.includes("placement"),
    },
    {
      count: getStatusName("DECLINED/BD BY CLIENT")?.count,
      title: getStatusName("DECLINED/BD BY CLIENT")?.status,
      img: dash_3,
      visible: table_list.includes("bd_by_client"),
    },
    {
      count: getStatusName("DECLINED/BD BY CONSULTANT")?.count,
      title: getStatusName("DECLINED/BD BY CONSULTANT")?.status,
      img: dash_4,
      visible: table_list.includes("bd_by_consultant"),
    },
    // {
    //   count: getStatusName("RESIGNED")?.count,
    //   title: getStatusName("RESIGNED")?.status,
    //   img: dash_5,
    //   visible: table_list.includes(report_card_list_without_value[15]),
    // },
    // {
    //   count: getStatusName("TERMINATED")?.count,
    //   title: getStatusName("TERMINATED")?.status,
    //   img: dash_3,
    //   visible: table_list.includes("IN TOUCH"),
    // },
    {
      count: getStatusName("PROJECT END")?.count,
      title: getStatusName("PROJECT END")?.status,
      img: dash_6,
      visible: table_list.includes("project_end"),
    },
  ];
  function getStatusName(name) {
    return total_count?.filter((data) => data?.status === name)[0];
  }
  async function onViewModal(data) {
    setIsLoading(true);
    setIsOpenCountDetail(true);
    // setisOpenModal(!isopenModal);
    setSelectedStatus(data);
    var sendData = {
      recruiter_id: props.selectedData?.current?.recruiter_id,
      from_date: props.selectedData?.current?.from_date,
      to_date: props.selectedData?.current?.to_date,
      partner_id: props.selectedData?.current?.partner_id,
      client_id: props.selectedData?.current?.client_id,
      status: data,
    };
    var res = await reportController.getSubmissionReportDataByStatus(sendData);
    setselectedData(res?.data);
    setIsLoading(false);
  }
  function onViewSubmission(modalSt, data) {
    setisOpenModal(true);
    // setModalStatus(modalSt);
    dispatch(changeSelectedSubmission(data));
  }
  return (
    <>
      <CountDataDetails
        isLoadingStatusData={isLoading}
        onViewSubmission={onViewSubmission}
        data={selectedData}
        selectedStatusName={selectedStatus}
        onClose={() => setIsOpenCountDetail(false)}
        open={isOpenCountDetail}
      />
      <BaseModal
        isBig={true}
        open={isopenModal}
        formTitle={VIEW_SUBMISSION}
        handleClose={() => setisOpenModal(!isopenModal)}
      >
        <SubmissionViewDetails
          handleClose={() => setisOpenModal(!isopenModal)}
          onClickEdit={(e) => onViewSubmission(ADD_INTERVIEW_DETAIL, e)}
          onRefresh={() => props.onRefresh()}
          data={selected_submission}
        />
      </BaseModal>
      <CustomizedTableField />
      <FlexContainer style={{ flexWrap: "wrap", marginTop: -10 }}>
        <CustomCard
          style={{
            display: table_list.includes("job") ? "inherit" : "none",
          }}
          onClick={() => console.log("")}
          title={CommonString.positions}
          total={isNaN(totalJobCount) ? 0 : totalJobCount}
          image={dash_1}
        />
        <CustomCard
          style={{
            display: table_list.includes("applicant") ? "inherit" : "none",
          }}
          onClick={() => console.log("")}
          title={CommonString.applicants}
          total={props?.addedApplicant}
          image={dash_2}
        />
        {data.map((data) => (
          <CustomCard
            style={{
              display: data.visible ? "inherit" : "none",
            }}
            onClick={() => onViewModal(data.title)}
            title={data.title}
            total={data?.count === undefined ? 0 : data?.count}
            image={data.img}
          />
        ))}
      </FlexContainer>
    </>
  );
}
