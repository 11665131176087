import React from "react";
import { SolidBtnForGroup } from "../style/common.style";
import {CommonString} from "../Utils/CommonString";

export default function SolidBtnForGroupContainer(props) {
  return (
    <SolidBtnForGroup
      width={props.width}
      onClick={props.onClick}
      variant={CommonString.contained}
    >
      {props.name}
    </SolidBtnForGroup>
  );
}
