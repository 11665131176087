import React from "react";
import { Dropdown } from "semantic-ui-react";

export default function CustomDropdown(props) {
  const formattedOptions = props.options.map((option) => ({
    key: option._id === undefined ? option : option?._id,
    value: option,
    text: props.getOptionLabel(option),
  }));
  function onChangeData(e) {
    var filterData = props.options.filter(
      (data) => props.getOptionLabel(data) === e.target.outerText
    );
    props.onChange(filterData[0]);
    return filterData[0];
  }
  return (
    <div className="field">
      {props.disableLabel ? <></> : <label>{props.placeholder}</label>}
      <Dropdown
        {...props}
        placeholder={props.placeholder}
        search
        selection
        onChange={onChangeData}
        options={formattedOptions}
        style={{ fontSize: "0.925em" }}
        icon={""}
      />
    </div>
  );
}
