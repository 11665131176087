import React from "react";
import { useState, useEffect } from "react";
import TopMenu from "../Menu/TopMenu";
import SubmissionDataTable from "../DataTable/SubmissionDataTable";
import { Container, MainScreen, TopFixedMenu } from "./StyledView";
import { SubmissionController } from "../../controller/SubmissionController";
import {
  changeSearchText,
  getVisibility,
  getsubmissionData,
  onChangeFilterStatusObject,
  onChangeSubmissionPageNumber,
  onRemoveSubmissionData,
} from "../../feature/store/ReduxStore";
import { useSelector, useDispatch } from "react-redux";
import { SEARCH_SUBMISSION } from "../../Utils/enum";
import {
  changeFilterData,
  changeIsSearchLoading,
  changeSearchSubmission,
  changeSubmissionTotalCount,
  changeTempClientData,
  changeTempPartnerData,
} from "../../feature/store/SubmissionReduxStore";
import { changeIsSearching } from "../../feature/store/ApplicantStore";
import InitialLoader from "../CommonComponet/Loader/InitialLoader";
import { onCheckLevelAccessibility } from "../../Utils/utilitiFunction";
import {
  CommonString,
  CommonStringForLocalStorage,
} from "../../Utils/CommonString";
import CustomizeTableFiledForStatus from "../Status/CustomizeTableFiledForStatus";
import { ClearFilterText } from "../../style/common.style";
import { FlexContainer } from "../../style/chat.style";

export default function SubmiSSionView() {
  const submissionController = new SubmissionController();
  const dispatch = useDispatch();
  const submissionData = useSelector((state) => state.counter.submissionData);
  const filterStatusObj = useSelector(
    (state) => state.SubmissionReduxStore.filter_data
  );
  const submission_count = useSelector(
    (state) => state.SubmissionReduxStore.submission_count
  );
  const submissionPagenumber = useSelector(
    (state) => state.counter.submissionPagenumber
  );
  const [isLoading, setIsLoading] = useState(false);
  const searchText = useSelector((state) => state.counter.searchText);
  const initial_loader = useSelector((state) => state.counter.initial_loader);
  const access_data_by_user_id = useSelector(
    (state) => state.counter.access_data_by_user_id
  );
  var user = JSON.parse(localStorage.getItem(CommonStringForLocalStorage.user))
    .user._id;

  useEffect(() => {
    var isVisible = onCheckLevelAccessibility();
    dispatch(getVisibility(isVisible));
    dispatch(changeSearchText(""));
    // var sendData = {
    //   status: "ALL",
    //   recruiter_id: "123",
    //   client_id: "123",
    //   partner_id: "123",
    // };
    // dispatch(changeFilterData(sendData));
    // dispatch(onChangeFilterStatusObject(sendData));
  }, []);
  useEffect(() => {
    getData();
  }, [filterStatusObj, submissionPagenumber]);

  async function getData() {
    try {
      setIsLoading(true);
      var sendData = {
        status: filterStatusObj.status.toUpperCase(),
        recruiter_id:
          !onCheckLevelAccessibility() &&
          !access_data_by_user_id.includes("please_submit_update")
            ? user
            : filterStatusObj.recruiter_id,
        partner_id: filterStatusObj.partner_id,
        client_id: filterStatusObj.client_id,
      };
      var res = await submissionController.onFilterStatusData(
        sendData,
        submissionPagenumber
      );
      dispatch(changeSubmissionTotalCount(res?.count));
      dispatch(getsubmissionData(res?.data));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }
  async function onSearch() {
    if (searchText.length < 1 || searchText.length === undefined) return;
    dispatch(changeIsSearchLoading(true));
    dispatch(changeIsSearching(true));
    dispatch(changeSearchText(searchText));
    var sendData = {
      search_query: searchText?.trim(),
    };
    var res = await submissionController.searchSubmission(sendData, 1);
    dispatch(changeSearchSubmission(res));
    dispatch(changeIsSearchLoading(false));
  }
  function onChangeSearchText(e) {
    // console.log(e);
    if (e.target === undefined) {
      return;
    }
    if (e.target.value === "") {
      dispatch(changeIsSearching(false));
    }
    dispatch(changeSearchText(e.target.value));
    if (searchText.length === 0) {
      dispatch(changeSearchSubmission(submissionData));
    }
  }
  function onClickClearFilter() {
    // console.log(filterStatusObj);
    if (
      filterStatusObj.status === "ALL" &&
      filterStatusObj.recruiter_id === "123" &&
      filterStatusObj.client_id === "123" &&
      filterStatusObj.partner_id === "123"
    ) {
      return;
    }
    var sendData = {
      status: "ALL",
      recruiter_id: "123",
      client_id: "123",
      partner_id: "123",
    };
    dispatch(onRemoveSubmissionData([]));
    dispatch(changeFilterData(sendData));
    dispatch(onChangeSubmissionPageNumber(1));
  }
  return (
    <Container>
      {initial_loader ? <InitialLoader /> : <></>}
      <TopFixedMenu>
        <TopMenu
          noDisplay={false}
          // internalSearch={true}
          placeholder={SEARCH_SUBMISSION}
          onChange={(e) => onChangeSearchText(e)}
          onClickSearch={onSearch}
        />
      </TopFixedMenu>
      <MainScreen>
        <FlexContainer>
          <p style={{ marginLeft: 10 }}>
            {CommonString.total_submission_count} : {submission_count}
          </p>
          <FlexContainer>
            <ClearFilterText
              onBlur={() => onClickClearFilter()}
              onClick={() => onClickClearFilter()}
            >
              {filterStatusObj.status === "ALL" &&
              filterStatusObj.recruiter_id === "123" &&
              filterStatusObj.client_id === "123" &&
              filterStatusObj.partner_id === "123"
                ? ""
                : CommonString.clear_filter}
            </ClearFilterText>
            <CustomizeTableFiledForStatus />
          </FlexContainer>
        </FlexContainer>
        <SubmissionDataTable
          isLoading={isLoading}
          page={submissionPagenumber}
          onRefresh={() => getData()}
          onSearch={() => onSearch()}
        />
      </MainScreen>
    </Container>
  );
}
