import React from "react";
import { ConfirmationAlertContainer } from "../../../style/login.style";
import { ConfirmationFormTitle, DeleteConfirmationFormTitle, ModalIconContainer, ModalTitle, ModalTitleSpan } from "../../../style/common.style";
import CustomTooltip from "../Tooltip";
import { ModalIcon } from "../../../style/Icon.style";
import ButtonGroup from "../ButtonGroup";
import { CommonIcon } from "../../../Utils/CommonIcon";
import { CommonString, CommonTitle } from "../../../Utils/CommonString";
import FormInput from "../FormInput";

export default function AlertConfirmation({title,subtitle,subtitleKey,onClose,onApprove,content,setContent,enableInput}) {
  return (
    <ConfirmationAlertContainer>
      <DeleteConfirmationFormTitle>
        <ModalTitle margin={false}>
           {title} <ModalTitleSpan>Confirmation</ModalTitleSpan>
        </ModalTitle>
        <ModalIconContainer>
          <CustomTooltip
            placement={CommonString.top_placement}
            title={CommonTitle.close}
            name={
              <ModalIcon
                icon={CommonIcon.modal_close}
                onClick={onClose}
              />
            }
          />
        </ModalIconContainer>
      </DeleteConfirmationFormTitle>
      <ConfirmationFormTitle>
        <span >{`${subtitleKey} ` }</span>
        {subtitle}
      </ConfirmationFormTitle>
      <FormInput></FormInput>
      <ButtonGroup
        solidBtnClick={() => onApprove()}
        outlinedBtnClick={onClose}
        solidBtnName={CommonString.yes}
        outlinedBtnName={CommonString.cancle}
      />
    </ConfirmationAlertContainer>
  );
}
