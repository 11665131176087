import React from "react";
import { TopFixedMenu } from "../View/StyledView";
import TopMenu from "../Menu/TopMenu";
import { JOB, SEARCH_JOB } from "../../Utils/enum";
import JobCustomController from "../../controller/JobCustomController";

export default function JobSearch() {
  const {
    onSearchJob,
    onChangeSearchText,
    onClickAddButton,
    access_data_by_user_id,
  } = JobCustomController();

  return (
    <TopFixedMenu>
      <TopMenu
        noDisplay={true}
        placeholder={SEARCH_JOB}
        onChange={(e) => onChangeSearchText(e)}
        onClickSearch={() => onSearchJob()}
        buttonName={JOB}
        viewButton={access_data_by_user_id.includes("job_create")}
        onClickAddButton={onClickAddButton}
      />
    </TopFixedMenu>
  );
}
