import { Icon } from "@iconify/react";
import { Grid } from "@mui/material";
import DatePicker from "react-multi-date-picker";
import styled from "styled-components";
import { CommonColor } from "../Utils/color";
import { GraphContainer } from "./common.style";

const Contain = styled.div`
  padding: 10px;
`;
const CardContainer = styled.div`
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  user-select: none;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 27px 80px rgba(0, 0, 0, 0.07),
    0px 5.4px 13px rgba(0, 0, 0, 0.035);
  white-space: nowrap;
  height: 100px;
`;
const BarChartBody = styled.div``;
const BarRoundedChartTitle = styled.div`
  color: ${CommonColor.grey};
`;
const BarRoundedChartCount = styled.h2`
  color: ${CommonColor.primary_color};
  span {
    font-size: 14px;
    color: ${CommonColor.grey};
  }
`;
const PieChartTitleContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${CommonColor.grey};
`;
const PieChartLabelContainer = styled(Grid)`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const PieChartLabelCard = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 80px;
  span {
    color: ${CommonColor.grey};
  }
  h5 {
    margin-left: 15px;
  }
`;
const PieChartLabelIcon = styled(Icon)`
  color: ${(props) => props.color};
  width: 15px;
  height: 15px;
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  select {
    margin-left: 5px;
    border: 1px solid ${CommonColor.grey} !important;
    &:hover {
      border: 1px solid ${CommonColor.primary_color}!important;
    }
  }
`;
const ChartContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ChartTabContainer = styled.div`
  display: flex;
`;
const TabIcon = styled(Icon)`
  width: 20px;
  height: 20px;
  color: ${CommonColor.grey};
  &:hover {
    color: ${CommonColor.primary_color};
    cursor: pointer;
  }
`;
const DatePickerCalendar = styled(DatePicker)`
  font-weight: 600;
  font-size: 0.875em;
  padding-bottom: 1%;
  color: ${CommonColor.primary_color};

  & .rmdp-day,
  .rmdp-week-day {
    color: ${CommonColor.primary_color};
  }
  & .rmdp-header-values {
    color: ${CommonColor.primary_color};
  }
  & .rmdp-day.rmdp-deactive,
  .rmdp-day.rmdp-disabled {
    color: #8798ad;
  }

  & .rmdp-range {
    background-color: ${CommonColor.primary_color};
    border-radius: 2px;
    color: ${CommonColor.white};
  }
  & .rmdp-today:not(.rmdp-day:hover) span {
    background-color: ${CommonColor.transparent};
    color: unset;
    border-radius: 3px;
  }
  &
    .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden):not(.rmdp-selected)
    span:hover {
    background-color: ${CommonColor.transparent};
    border-radius: 2px;
    color: ${CommonColor.primary_color};
    border: 1px solid ${CommonColor.primary_color};
    border-color: ${(props) => (props.iserror ? CommonColor.red : "")};
    border-radius: 5px;
  }
  & .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: transparent;
  }
  && {
    & .rmdp-arrow {
      border-color: ${CommonColor.primary_color};
      background-color: ${CommonColor.transparent};
    }
  }
  & .rmdp-arrow-container:hover {
    background-color: ${CommonColor.transparent};
    box-shadow: none;
  }
  && .rmdp-selected span {
    background-color: ${CommonColor.primary_color};
    color: ${CommonColor.white};
    border-radius: 5px;
    /* & span.sd {
      background-color: ${CommonColor.transparent};
      box-shadow: none;
    } */
  }
  &&& .rmdp-selected span:hover {
    background-color: ${CommonColor.primary_color};
    color: ${CommonColor.white};
    border-radius: 5px;
  }
  & .rmdp-week-day {
    color: ${CommonColor.primary_color};
  }
  & .rmdp-ep-arrow[direction="top"] {
    margin-top: 0%;
  }
  &.rmdp-container input {
    background: red;
  }
`;
const OutlinedButtonContainer = styled.div`
  /* display: flex; */
  /* align-items: flex-end; */
  && button {
    background-color: ${CommonColor.transparent} !important;
    color: ${CommonColor.primary_color} !important;
    border: 1px solid ${CommonColor.primary_color};
    box-shadow: none !important;
  }
`;
const PieChartContainer = styled(GraphContainer)`
  height: 400px;
`;
const InnerCard = styled.div`
  position: relative;
  transition: transform 0.8s;
  perspective: 1000px;
  transform-style: preserve-3d;
  transform: ${(props) =>
    props.$isRotate ? "rotateY(180deg)" : "rotateY(0deg)"};
`;
const FrontCard = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
`;
const BackCard = styled(FrontCard)`
  transform: rotateY(180deg);
`;
const PieChartSideTitle = styled.div`
  font-size: 11px;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
`;
const TitleContainerPieChart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: max-content;
`;
const ReportTableContainer = styled.div`
  margin-top: -15px;
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
`;
export {
  CardContainer,
  BackCard,
  BarChartBody,
  BarRoundedChartCount,
  BarRoundedChartTitle,
  ChartContainer,
  ChartTabContainer,
  Contain,
  DatePickerCalendar,
  FrontCard,
  InnerCard,
  OutlinedButtonContainer,
  PieChartContainer,
  PieChartLabelCard,
  PieChartLabelContainer,
  PieChartLabelIcon,
  PieChartSideTitle,
  PieChartTitleContainer,
  TabIcon,
  TitleContainer,
  TitleContainerPieChart,
  ReportTableContainer,
};
