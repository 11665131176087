import React, { useRef } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CheckBoxContainer } from "../../style/common.style";
import { FormLabel } from "react-bootstrap";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export default function SelectMultiple(props) {
  const inputClick = useRef();
  return (
    <div className="">
      <label>Select Recruiter</label>
      <br />
      <FormControl>
        <Select
          style={{ border: "1px solid lightgrey", width: 200, padding: 0 }}
          multiple
          displayEmpty
          value={props.selectedRecruiterList}
          input={<OutlinedInput />}
          renderValue={(selected) => {
            return (
              <span
                style={{
                  marginLeft: 6,
                  width: 210,
                  overflowX: "auto",
                  padding: 0,
                }}
              >
                {selected.join(", ")}
              </span>
            );
          }}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          {props.list.map((data) => (
            <MenuItem key={data} value={data?.name}>
              <CheckBoxContainer>
                <input
                  ref={inputClick}
                  id="default-checkbox"
                  type="checkbox"
                  name={data?.name}
                  onChange={(event) => props.handleChange(event, data)}
                  checked={props.selectedRecruiterList.includes(data?.name)}
                  value=""
                  placeholder="Select Recruiter"
                />
                <FormLabel>{data?.name}</FormLabel>
              </CheckBoxContainer>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
