import React, { useEffect, useRef, useState } from "react";
import {
  Body,
  SearchContainer,
  ListContainer,
  DocContainer,
  Input,
  SearchButton,
  SearchInputForm,
  ApplicantButtonContainer,
  SubmitButton,
  SearchMainContainer,
  MainContainer,
} from "../../style/applicant.style";
import { Divider } from "@material-ui/core";
import { ButtonName, CommonString } from "../../Utils/CommonString";
import ApplicantContoller from "../../controller/ApplicantContoller";
import { useDispatch, useSelector } from "react-redux";
import {
  changeButtonName,
  changeIsOpenModal,
  setApplicantData,
  setSearchData,
  setSelectedApplicant,
} from "../../feature/store/ApplicantStore";
import ApplicantList from "../Applicant/ApplicantList";
import { checkString } from "../../Utils/validation";
import ApplicantDocument from "../Applicant/ApplicantDocument";
import SearchLoader from "../CommonComponet/SearchLoader";
import AddApplicant from "../Modal/AddApplicant";
import CommonBaseModal from "../Modal/CommonBaseModal";
import LockApplicant from "../ViewDetails/LockApplicant";
import ApplicantDataDetails from "../ViewDetails/ApplicantDataDetails";
import { onCheckLevelAccessibility } from "../../Utils/utilitiFunction";
import {
  changeInitialLoader,
  getVisibility,
} from "../../feature/store/ReduxStore";
import NoData from "../../assets/images/nodata.png";
import LoaderForTable from "../CommonComponet/LoaderForTable";
import { TableNoDataContainer } from "../../style/common.style";
import {
  ButtonForChangePage,
  ButtonForChangePageContainer,
} from "../../style/button.style";
export default function ApplicantsView() {
  const listInnerRef = useRef();
  const dispatch = useDispatch();

  ///Controller
  const applicantController = new ApplicantContoller();

  //State
  const [keyStringSearchText, setkeyStringSearchText] = useState("");
  const [nameSearchText, setnameSearchText] = useState("");
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [isDataNull, setIsDataNull] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //redux state variable
  const applicantList = useSelector((state) => state.ApplicantStore.applicants);
  const searchResult = useSelector(
    (state) => state.ApplicantStore.searchResult
  );
  const pageNumber = useSelector((state) => state.ApplicantStore.pageNumber);
  const searchPageNumber = useSelector(
    (state) => state.ApplicantStore.searchPageNumber
  );
  const button_name = useSelector((state) => state.ApplicantStore.button_name);
  const initial_loader = useSelector((state) => state.counter.initial_loader);
  const access_data_by_user_id = useSelector(
    (state) => state.counter.access_data_by_user_id
  );

  useEffect(() => {
    setIsSearchLoading(true);
    var isVisible = onCheckLevelAccessibility();
    dispatch(getVisibility(isVisible));
    intializationData(pageNumber);
  }, []);

  async function intializationData(page) {
    if (page === 1) {
      dispatch(changeInitialLoader(true));
    } else {
      setIsLoading(true);
    }
    try {
      if (page > 1) setIsSearchLoading(true);
      var data = await applicantController.getApplicants(page);
      dispatch(setApplicantData({ data: [...applicantList, ...data], page }));
      if (data.length > 0 && page == 1) dispatch(setSelectedApplicant(data[0]));
      setIsLoading(false);
      setTimeout(() => {
        setIsSearchLoading(false);
      }, 800);
    } catch (error) {
      setIsLoading(false);
      setIsSearchLoading(false);
    }
    dispatch(changeInitialLoader(false));
  }

  const handleScroll = (e) => {
    if (getList().length % 20 !== 0) return;
    if (keyStringSearchText.length > 0 || nameSearchText.length > 0) {
      onSearch(listInnerRef, searchPageNumber + 1);
    } else {
      intializationData(pageNumber + 1);
    }
  };

  async function onSearch(e, page) {
    try {
      setIsSearchLoading(true);
      if (page == 1) e.preventDefault();
      var sendData = {
        search_query: checkString(keyStringSearchText),
        search_word: checkString(nameSearchText),
      };
      var data = await applicantController.searchApplicant(sendData, page);
      setIsSearchLoading(false);
      if (data.length === 0) {
        setIsDataNull(true);
        return;
      }
      setIsDataNull(false);
      if (page == 1) {
        dispatch(setSearchData({ data: [...data], page: page }));
        if (data.length > 0) dispatch(setSelectedApplicant(data[0]));
      } else {
        dispatch(
          setSearchData({ data: [...searchResult, ...data], page: page })
        );
      }
    } catch (error) {
      console.log(error);
      setIsSearchLoading(false);
    }
  }

  function getList() {
    if (keyStringSearchText.length > 0 || nameSearchText.length > 0) {
      return searchResult;
    } else {
      if (searchResult.length > 0)
        dispatch(setSearchData({ data: [], page: 1 }));
      return applicantList;
    }
  }
  function getFormView(button_name) {
    if (button_name === ButtonName.add_applicant) {
      return <AddApplicant />;
    } else if (button_name === ButtonName.edit_applicant) {
      return <AddApplicant />;
    } else if (button_name === ButtonName.close_applicant) {
      return <LockApplicant />;
    } else if (button_name === ButtonName.view_applicant) {
      return <ApplicantDataDetails />;
    }
  }
  function getLoadingScreen() {
    if (
      (keyStringSearchText.length > 0 || nameSearchText.length > 0) &&
      isDataNull
    ) {
      return true;
    }
    return false;
  }
  return (
    <MainContainer>
      <CommonBaseModal children={getFormView(button_name)} />
      <SearchContainer>
        <SearchInputForm onSubmit={(e) => onSearch(e, 1)}>
          <Input
            type="text"
            placeholder={CommonString.keyString}
            value={keyStringSearchText}
            onChange={(e) => setkeyStringSearchText(e.target.value)}
          />
        </SearchInputForm>
        <Divider />
        <SearchInputForm onSubmit={(e) => onSearch(e, 1)}>
          <Input
            type="text"
            placeholder={CommonString.search_name}
            value={nameSearchText}
            onChange={(e) => setnameSearchText(e.target.value)}
          />
          <SearchMainContainer>
            <SearchButton outline={true} type="submit">
              search
            </SearchButton>
            <ApplicantButtonContainer
              isVisible={access_data_by_user_id.includes("applicant_create")}
            >
              <SubmitButton
                onClick={() => {
                  dispatch(changeIsOpenModal(true));
                  dispatch(changeButtonName(ButtonName.add_applicant));
                }}
              >
                {ButtonName.add_applicant}
              </SubmitButton>
            </ApplicantButtonContainer>
          </SearchMainContainer>
        </SearchInputForm>
      </SearchContainer>
      <Body>
        {getLoadingScreen() || initial_loader ? (
          <></>
        ) : (
          <>
            <ListContainer ref={listInnerRef}>
              <ApplicantList data={getList()}></ApplicantList>
              {getList().length % 20 !== 0 ||
              getList().length === 0 ||
              getLoadingScreen() ||
              initial_loader ? (
                <></>
              ) : (
                <ButtonForChangePageContainer style={{ marginTop: 5 }}>
                  <ButtonForChangePage onClick={() => handleScroll()}>
                    {ButtonName.load_more}
                  </ButtonForChangePage>
                </ButtonForChangePageContainer>
              )}
              <div
                style={{
                  position: "absolute",
                  bottom: 100,
                  left: "3vw",
                  zIndex: 10000,
                  visibility: isSearchLoading ? "visible" : "hidden",
                }}
              >
                <SearchLoader is_applicant={true} />
              </div>
            </ListContainer>
            <DocContainer isVisible={true}>
              <div style={{ width: "100%" }}>
                <ApplicantDocument is_visible_icon={false} />
              </div>
            </DocContainer>
          </>
        )}
        {initial_loader ? <LoaderForTable /> : <></>}
        {getLoadingScreen() ? (
          <TableNoDataContainer>
            <img src={NoData} alt="" />
          </TableNoDataContainer>
        ) : (
          <></>
        )}
        {/* <Container isVisible={getLoadingScreen()} /> */}
      </Body>
    </MainContainer>
  );
}
