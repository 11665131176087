import React from "react";
import { TableRow, TableTd } from "../../style/table.styled";
import { Checkbox, Icon } from "@mui/material";
import { CommonColor } from "../../Utils/color";
import { CommonIcon } from "../../Utils/CommonIcon";
import PlaceholderLoader from "../CommonComponet/PlaceholderLoader";

export default function JobDataShimmer() {
  return (
    <TableRow style={{width:"90vw"}}>
      <TableTd>
        <Checkbox
          defaultChecked={false}
          checked={false}
          onClick={(e) => e.stopPropagation()}
          style={{ color: CommonColor.primary_color }}
        />
      </TableTd>
      <TableTd><p style={{width:74,height:12,backgroundColor:"#efefef"}}></p></TableTd>
      <TableTd> <p style={{width:74,height:12,backgroundColor:"#efefef"}}></p></TableTd>
      <TableTd> <p style={{width:74,height:12,backgroundColor:"#efefef"}}></p></TableTd>
      <TableTd><p style={{width:74,height:12,backgroundColor:"#efefef"}}></p></TableTd>
      <TableTd><p style={{width:74,height:12,backgroundColor:"#efefef"}}></p></TableTd>
      <TableTd isCenter={true}> <p style={{width:74,height:12,backgroundColor:"#efefef"}}></p></TableTd>
      <TableTd isCenter={true}> <p style={{width:74,height:12,backgroundColor:"#efefef"}}></p></TableTd>
      <TableTd><p style={{width:74,height:12,backgroundColor:"#efefef"}}></p></TableTd>
      <TableTd><p style={{width:74,height:12,backgroundColor:"#efefef"}}></p></TableTd>
      <TableTd>
        <Icon icon={CommonIcon.dash_icon} />
      </TableTd>
    </TableRow>
  );
}
