import React, { useState } from "react";
import TableToolbar from "./TableToolbar";
import AddPartner from "../Modal/AddPartner";
import BaseModal from "../Modal/BaseModal";
import {
  PARTNER,
  ADD_PARTNER,
  UPDATE_PARTNER,
  VIEW_PARTNER,
  PARTNERS,
} from "../../Utils/enum";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DeleteConfirmation from "../Modal/DeleteConfirmation";
import { useAlert } from "react-alert";
import PartnerViewDetails from "../ViewDetails/PartnerViewDetails";
import {
  ADD_PARTNER_SUCCESS,
  ADD_PARTNER_ERROR,
  DELETE_PARTNER_ERROR,
  DELETE_PARTNER_SUCCESS,
  UPDATE_PARTNER_ERROR,
  UPDATE_PARTNER_SUCCESS,
} from "../../Utils/alertMessage";
import PartnerController from "../../controller/PartnerController";
import PartnerTable from "./Table/PartnerTable";
import { partnerModalStatus } from "../../Utils/utilitiFunction";
import { CommonString } from "../../Utils/CommonString";
import { TableMainContainer } from "../../style/table.styled";
import { onChangeIsLoading } from "../../feature/store/LevelReduxStore";
import { useDispatch } from "react-redux";

export default function PartnerDataTable(props) {
  const alert = useAlert();
  var partnerController = new PartnerController();
  const dispatch = useDispatch();
  const [isopenModal, setisOpenModal] = useState(false);
  const [modalStatus, setModalStatus] = useState(ADD_PARTNER);
  const [selectedData, setselectedData] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  function onViewModal(modalSt, data) {
    setisOpenModal(true);
    setModalStatus(modalSt);
    setselectedData(data);
  }
  function onDelete(data) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <DeleteConfirmation
            isLoading={isLoading}
            name={data.name}
            onClose={onClose}
            onDelete={() => {
              OndeleteData(data);
              onClose();
              setisOpenModal(false);
            }}
          />
        );
      },
    });
  }
  async function OndeleteData(data) {
    dispatch(onChangeIsLoading(true));
    var sendData = {
      _id: data._id,
    };
    var res = await partnerController.deletePartner(sendData);
    if (res.data.status === 200) {
      alert.show(DELETE_PARTNER_SUCCESS, { type: CommonString.success });
      props.onRefresh();
      dispatch(onChangeIsLoading(false));
    } else {
      alert.show(DELETE_PARTNER_ERROR, { type: CommonString.error });
      dispatch(onChangeIsLoading(false));
    }
  }
  async function onSubmitData(data) {
    dispatch(onChangeIsLoading(true));
    if (modalStatus === UPDATE_PARTNER) {
      var sendData = {
        _id: selectedData._id,
        ...data,
      };
      var res = await partnerController.updatePartner(sendData);
      if (res.data.status === 200) {
        props.onRefresh();
        alert.show(UPDATE_PARTNER_SUCCESS, { type: CommonString.success });
        setisOpenModal(!isopenModal);
        dispatch(onChangeIsLoading(false));
      } else {
        alert.show(UPDATE_PARTNER_ERROR, { type: CommonString.error });
        dispatch(onChangeIsLoading(false));
      }
      return;
    }
    var add_res = await partnerController.addPartner(data);
    if (add_res.data.status === 200) {
      localStorage.removeItem(CommonString.partner_local_data);
      props.onRefresh();
      alert.show(ADD_PARTNER_SUCCESS, { type: CommonString.success });
      setisOpenModal(!isopenModal);
      dispatch(onChangeIsLoading(false));
    } else {
      alert.show(ADD_PARTNER_ERROR, { type: CommonString.error });
      dispatch(onChangeIsLoading(false));
    }
  }

  return (
    <>
      <TableToolbar
        title={PARTNERS}
        buttonName={PARTNER}
        isVisible={true}
        onClick={() => onViewModal(ADD_PARTNER, null)}
      />
      <BaseModal
        mystyle={{ width: window.innerWidth > 500 ? "40%" : "90%" }}
        isHide={CommonString.true}
        isUpdate={modalStatus === UPDATE_PARTNER}
        isLoading={isLoading}
        isView={modalStatus === VIEW_PARTNER ? true : false}
        editClose={() => onViewModal(UPDATE_PARTNER, selectedData)}
        deleteClose={() => onDelete(selectedData)}
        open={isopenModal}
        formTitle={partnerModalStatus(modalStatus, selectedData)}
        handleClose={() => setisOpenModal(!isopenModal)}
      >
        {modalStatus === VIEW_PARTNER ? (
          <PartnerViewDetails data={selectedData} />
        ) : (
          <AddPartner
            isUpdate={modalStatus === UPDATE_PARTNER}
            data={selectedData}
            onSubmitData={onSubmitData}
          />
        )}
      </BaseModal>
      <TableMainContainer>
        <PartnerTable
          isLoading={props.isLoading}
          onDelete={onDelete}
          onViewModal={onViewModal}
        />
      </TableMainContainer>
    </>
  );
}
