import React, { useEffect } from "react";
import { RESUME_BASEURL } from "../Utils/constant";
import { PdfViewwerContainer } from "./View/StyledView";
import { CommonIcon } from "../Utils/CommonIcon";
import { Div, IframeContainer } from "../style/common.style";
import { CommonString, CommonTitle } from "../Utils/CommonString";
import { PdfCloseIcon } from "../style/Icon.style";
import FileViewer from "react-file-viewer";
import { CustomErrorComponent } from "custom-error";
export default function CustomPDFViewer(props) {
  useEffect(() => {}, [props.children]);
  return (
    <PdfViewwerContainer>
      {props.is_applicant ? (
        <div />
      ) : (
        <PdfCloseIcon icon={CommonIcon.close} onClick={props.handleClose} />
      )}

      {props.resumeLink.split(".")[1] === CommonTitle.pdf ? (
        <IframeContainer
          onLoadedData={props.onLoad}
          title={CommonTitle.pdf}
          src={
            RESUME_BASEURL + props.resumeLink + CommonString.pdf_post_extention
          }
        />
      ) : props.resumeLink.split(".")[1] === CommonTitle.docx &&
        !props.isLockView ? (
        <Div key={Math.random()}>
          <FileViewer
            fileType={props.resumeLink.split(".")[1]}
            filePath={RESUME_BASEURL + props.resumeLink}
            errorComponent={CustomErrorComponent}
          />
        </Div>
      ) : (
        <IframeContainer
          loading={CommonString.lazy}
          onLoadedData={props.onLoad}
          title={CommonTitle.doc}
          src={
            CommonString.doc_pre_extention +
            RESUME_BASEURL +
            props.resumeLink.replace(/\s/g, "%20") +
            CommonString.doc_post_extention
          }
          frameborder="0"
        />
      )}
      {/* {console.log(
        CommonString.doc_pre_extention +
          RESUME_BASEURL +
          props.resumeLink.replace(/\s/g, "%20") +
          CommonString.doc_post_extention
      )} */}
    </PdfViewwerContainer>
  );
}
