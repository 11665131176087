import { TableRow } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { TableTd, TableTh, Table } from "../../style/table.styled";
import { VIEW_JOB_DETAIL } from "../../Utils/enum";
import moment from "moment";
import { DATE_FORMATE } from "../../Utils/constant";
import { getCapitalizeText, getClientName } from "../../Utils/utilitiFunction";
import { CopyTextMainContainer } from "../../style/common.style";
import CustomizedTooltip from "../CommonComponet/CustomizedTooltip";
import CopyTextComponent from "../CommonComponet/CopyTextComponent";

export default function JobDetailForDailyReport(props) {
  const selected_data = useSelector(
    (state) => state.ReportStoreRedux.select_data
  );
  var job_data = selected_data?.job_data;

  return (
    <div>
      <Table>
        <TableRow>
          <TableTh>No.</TableTh>
          <TableTh>status</TableTh>
          <TableTh>Due Date</TableTh>
          <TableTh>Position Title</TableTh>
          <TableTh>Job ID</TableTh>
          <TableTh>Partner</TableTh>
          <TableTh>Client</TableTh>
          <TableTh>Max Sub</TableTh>
          <TableTh align="center">Sub Made</TableTh>
        </TableRow>
        {job_data.map((data, index) => (
          <TableRow
            onDoubleClick={() => props.onViewModal(VIEW_JOB_DETAIL, data)}
          >
            <TableTd>{index + 1}</TableTd>
            <TableTd>{getCapitalizeText(data?.status)}</TableTd>
            <TableTd>{moment(data?.due_date).format(DATE_FORMATE)}</TableTd>
            <TableTd style={{ maxWidth: 300 }}>
              <CopyTextMainContainer>
                <CustomizedTooltip
                  name={data?.job_detail?.position_title}
                  title={data?.job_detail?.position_title}
                />
                {/* <CopyTextComponent
                  value={data?.job_detail?.position_title}
                  id={"job_title"}
                /> */}
              </CopyTextMainContainer>
            </TableTd>
            <TableTd>
              <CopyTextMainContainer>
                {data?.job_detail?.client_position_id}
                <CopyTextComponent
                  value={data?.job_detail?.client_position_id}
                  id={"job_ID"}
                />
              </CopyTextMainContainer>
            </TableTd>
            <TableTd>{getClientName(data?.partner?.name)}</TableTd>
            <TableTd>{getClientName(data?.job_detail?.client?.name)}</TableTd>
            <TableTd>{data?.max_submission}</TableTd>
            <TableTd>{data?.no_of_submission}</TableTd>
          </TableRow>
        ))}
      </Table>
    </div>
  );
}
