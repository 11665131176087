import React from "react";
import CustomCard from "../CommonComponet/Card";
import dash_1 from "../../assets/images/dash_1.png";
import dash_2 from "../../assets/images/dash_2.png";
import dash_3 from "../../assets/images/dash_3.png";
import dash_4 from "../../assets/images/dash_4.png";
import dash_5 from "../../assets/images/dash_5.png";
import dash_6 from "../../assets/images/dash_6.png";
import { CommonString } from "../../Utils/CommonString";
import { useSelector } from "react-redux";
import { onCheckLevelAccessibility } from "../../Utils/utilitiFunction";
import { FlexContainer } from "../../style/job.style";

export default function DashboardCard(props) {
  const total_count = useSelector((state) => state.counter.dashBoardData);
  const is_recruiter = !onCheckLevelAccessibility();

  return (
    <FlexContainer style={{ paddingLeft: 5 }}>
      <CustomCard
        style={{ display: is_recruiter ? "none" : "block" }}
        onClick={(e) => props.onClick(e)}
        title={CommonString.positions}
        total={total_count === undefined ? 0 : total_count.positions}
        image={dash_1}
      />
      <CustomCard
        onClick={(e) => props.onClick(e)}
        title={CommonString.applicants}
        total={is_recruiter ? total_count?.APPLICANT : total_count?.applicants}
        image={dash_2}
      />
      <CustomCard
        onClick={(e) => props.onClick(e)}
        title={CommonString.submission}
        total={is_recruiter ? total_count?.SUBMISSION : total_count?.submission}
        image={dash_3}
      />
      <CustomCard
        onClick={(e) => props.onClick(e)}
        title={CommonString.interview}
        total={is_recruiter ? total_count?.INTERVIEW : total_count?.interview}
        image={dash_5}
      />
      <CustomCard
        onClick={(e) => props.onClick(e)}
        title={CommonString.offer}
        total={is_recruiter ? total_count?.OFFER : total_count?.offer}
        image={dash_4}
      />
      <CustomCard
        onClick={(e) => props.onClick(e)}
        title={CommonString.running_placement}
        total={is_recruiter ? total_count?.PLACEMENT : total_count?.placement}
        image={dash_6}
      />
    </FlexContainer>
  );
}
