import React from "react";
import Tooltip from "@material-ui/core/Tooltip";

export default function CustomizedTooltip(props) {
  return (
    <Tooltip title={props.title}>
      <span>{props.name}</span>
    </Tooltip>
  );
}
