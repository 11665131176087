import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onClearAllLocation } from "../../../feature/store/DiceStore";
import {
  ButtonContainer,
  InputContainer,
  SearchContainer,
  SearchInfoMessage,
  SearchTitle,
} from "../../../style/dice.style";
import { CommonIcon } from "../../../Utils/CommonIcon";
import { Icon } from "@iconify/react";
import AddLocationComponent from "./AddLocationComponent";
import axios from "axios";

export default function SearchComponent() {
  const dispatch = useDispatch();

  const [jobDescription, setJobDescription] = useState("");
  const [keyword, setKeyword] = useState("");

  const searchedLocation = useSelector(
    (state) => state.DiceStore.searchedLocation
  );
  const filter_data = useSelector((state) => state.DiceStore.filter_data);
  const dice_profile_filter_data = useSelector(
    (state) => state.DiceStore.dice_profile_filter_data
  );

  function onClearAll() {
    setJobDescription("");
    setKeyword("");
    dispatch(onClearAllLocation([]));
  }
  async function onSearch() {
    var sendData = {
      q: "",
      input: "",
      facets: "",
      skills: "",
      sortBy: "relevancy",
      company: "",
      language: "",
      hasPatent: false,
      hourlyRate: "",
      lastActive: "",
      searchType: "Integrated",
      annualSalary: "",
      currencyCode: "",
      // likelyToMove:"",
      skillsKeyword: "",
      companyCurrent: false,
      companyKeyword: "",
      // likelyToSwitch:false,
      socialProfiles: "",
      excludeFounders: true,
      // jobTitleKeyword:"",
      sortByDirection: "asc",
      travelPreference: null,
      excludeThirdParty: false,
      // willingToRelocate:false,
      workPermitLocation: "",
      dateResumeLastUpdated: "",
      skillYearsOfExperience: "",
      onlyWithSecurityClearance: "",
      willingToRelocateIncludeLocals: "",
      willingToRelocatePreferredLocationsOnly: "",
      jobDescription: jobDescription,
      jobTitle: keyword,
      locations: searchedLocation,
    };
    const response = await axios.post(
      "https://talent-api.dice.com/v2/profiles/search",
      { ...sendData, ...filter_data, ...dice_profile_filter_data },
      {
        headers: {
          "Content-Type": "application/json",
          // "Authorization": `Bearer ${API_KEY}`, // Modify if the API uses a different authentication scheme
        },
      }
    );
    console.log(response);
  }
  return (
    <SearchContainer>
      <SearchTitle>Candidate Search</SearchTitle>
      <InputContainer>
        <div>
          <Icon icon={CommonIcon.search} />
          <textarea
            rows={jobDescription.length === 0 ? "2" : "3"}
            multiple={true}
            value={jobDescription}
            onChange={(e) => setJobDescription(e.target.value)}
            placeholder="IntelliSearch copy and paste job description or resume (optional)"
          />
        </div>
      </InputContainer>
      <SearchInfoMessage>
        Minimum of 150 characters (max 20,000), For searches with less 150, use
        the "keyword or Boolean Search" filed below.
      </SearchInfoMessage>
      <InputContainer>
        <div>
          <Icon icon={CommonIcon.search} />
          <input
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            placeholder="Keyword or Boolean Search"
          />
        </div>
      </InputContainer>
      <AddLocationComponent />
      <ButtonContainer>
        <p onClick={() => onClearAll()}>Clear All</p>
        <p>New Search</p>
        <button onClick={() => onSearch()}>Search</button>
      </ButtonContainer>
    </SearchContainer>
  );
}
