import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DateTableInJob } from "../../style/job.style";
// import CustomDatePicker from "../CommonComponet/DatePicker";
import { CommonLabel } from "../../Utils/CommonString";
// import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { changeClientDueDate } from "../../feature/store/JobReduxStore";
// import CommonDatePicker from "../CommonComponet/CommonDatePicker";
import CustomDatePicker from "../CommonComponet/DatePicker";

export default function PartnerDateComponent(props) {
  const dispatch = useDispatch();
  const [open_date, setOpen_date] = useState(null);
  const [due_date, setDue_date] = useState(null);
  const client_due_date = useSelector(
    (state) => state.JobReduxStore.client_due_date
  );
  useEffect(() => {
    updateDate();
  }, [open_date, due_date, props.data.name]);

  useEffect(() => {
    onPrefill();
  }, []);
  function updateDate() {
    var dateObj = {
      open_date: open_date,
      due_date: due_date,
      partner_name: props.data.name,
      partner_id: props.data.partner_id,
    };
    dispatch(changeClientDueDate(dateObj));
  }

  function onPrefill() {
    if (props.isUpdate) {
      setDue_date(props.childJob.due_date);
      setOpen_date(props.childJob.open_date);
      // setDue_date(
      //   props.due_date ?? props.due_date !== ""
      //     ? props.due_date
      //     : props.jobData.due_date
      // );
      // setOpen_date(props.open_date ?? props.open_date);
    }
  }
  return (
    <DateTableInJob container>
      <Grid item xs={12} md={2}>
        <span>{props.data.name.split("(").reverse()[0].replace(")", "")}</span>
      </Grid>
      <Grid item xs={6} md={5} style={{ paddingRight: 5, marginTop: -8 }}>
        <CustomDatePicker
          name={CommonLabel.open_date}
          label={CommonLabel.open_date}
          value={open_date}
          onChange={(date) => setOpen_date(date)}
        />
      </Grid>
      <Grid item xs={6} md={5} style={{ marginTop: -8 }}>
        {props.isUpdate ? (
          <CustomDatePicker
            label={CommonLabel.due_date}
            name={CommonLabel.due_date}
            value={due_date}
            onChange={(date) => setDue_date(date)}
          />
        ) : (
          <CustomDatePicker
            name={CommonLabel.due_date}
            label={CommonLabel.due_date}
            value={due_date}
            onChange={(date) => setDue_date(date)}
          />
        )}
      </Grid>
    </DateTableInJob>
  );
}
