import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ADD_JOBS, UPDATE_JOBS, VIEW_JOBS } from "../Utils/enum";
import AddJob from "../component/Modal/AddJob";
import {
  changeInitialLoader,
  changeSearchText,
  deletejob,
  onChangePageNumber,
  removeJObData,
} from "../feature/store/ReduxStore";
import {
  changeFilterData,
  changeIsJobStatusName,
  changeIsLoading,
  changeSearchjob,
  changeSelectedData,
  deleteMultipleJob,
  deleteSearchJob,
  onChangeAttachmentList,
  onChangeIsLoadingMultipleRecruiterJobList,
  onChangeSubmissionDataForJob,
  onLoadMoreSearchJob,
  removeSelectedFileName,
  removeTempSelectedFileName,
} from "../feature/store/JobReduxStore";
import JobController from "./JobController";
import { changeIsSearchLoading } from "../feature/store/SubmissionReduxStore";
import {
  changeButtonName,
  changeIsOpenModal,
  changeIsSearching,
} from "../feature/store/ApplicantStore";
import moment from "moment";
import {
  DATE_FORMATE,
  dueDateShortBy,
  JobStatusArray,
} from "../Utils/constant";
import { confirmAlert } from "react-confirm-alert";
import DeleteConfirmation from "../component/Modal/DeleteConfirmation";
import { onChangeIsLoading } from "../feature/store/LevelReduxStore";
import { DELETE_JOB_ERROR, DELETE_JOB_SUCCESS } from "../Utils/alertMessage";
import { CommonString } from "../Utils/CommonString";
import JobViewDetails from "../component/ViewDetails/JobViewDetails";
import { SubmissionController } from "./SubmissionController";
import { CommonIcon } from "../Utils/CommonIcon";
import ConfirmationAlert from "../component/Modal/ConfirmationAlert";

function JobCustomController() {
  const dispatch = useDispatch();
  const jobController = new JobController();
  const submissionController = new SubmissionController();

  const jobPageNumber = useSelector((state) => state.counter.jobPagenumber);
  const filter_data = useSelector((state) => state.JobReduxStore.filter_data);
  const button_name = useSelector((state) => state.ApplicantStore.button_name);
  const searchText = useSelector((state) => state.counter.searchText);
  const table_list = useSelector((state) => state.JobReduxStore.table_list);
  const tab_name = useSelector((state) => state.JobReduxStore.tab_name);
  const is_loading_multiple_recruiter_job_list = useSelector(
    (state) => state.JobReduxStore.is_loading_multiple_recruiter_job_list
  );
  const multiple_recruiter_job_list = useSelector(
    (state) => state.JobReduxStore.multiple_recruiter_job_list
  );
  const searchJobPageNumber = useSelector(
    (state) => state.counter.searchJobPagenumber
  );
  const access_data_by_user_id = useSelector(
    (state) => state.counter.access_data_by_user_id
  );
  const partner_list = useSelector(
    (state) => state.SubmissionReduxStore.temp_partner_data
  );
  const temp_recruiter_list = useSelector(
    (state) => state.JobReduxStore.temp_recruiter_list
  );
  const search_result = useSelector(
    (state) => state.JobReduxStore.search_result
  );
  const jobList = useSelector((state) => state.counter.jobData);
  const is_searching = useSelector(
    (state) => state.ApplicantStore.is_searching
  );
  const is_loading = useSelector((state) => state.JobReduxStore.is_loading);
  const multiple_recruiter_job_count = useSelector(
    (state) => state.JobReduxStore.multiple_recruiter_job_count
  );
  const initial_loader = useSelector((state) => state.counter.initial_loader);
  const job_count = useSelector((state) => state.counter.job_count);
  const selected_data = useSelector(
    (state) => state.JobReduxStore.selected_data
  );
  const partner_in_add_job = useSelector(
    (state) => state.JobReduxStore.partner_in_add_job
  );
  const submission_data_for_job = useSelector(
    (state) => state.JobReduxStore.submission_data_for_job
  );
  const temp_client_data = useSelector(
    (state) => state.SubmissionReduxStore.temp_client_data
  );
  const attachment_list = useSelector(
    (state) => state.JobReduxStore.attachment_list
  );
  const temp_partner_data = useSelector(
    (state) => state.ClientReduxStore.temp_partner_data
  );

  function getFormView() {
    if (button_name === UPDATE_JOBS) {
      return <AddJob />;
    } else if (button_name === VIEW_JOBS) {
      return <JobViewDetails />;
    }
    if (button_name === "Add Partner" && !partner_in_add_job) {
      return <JobViewDetails />;
    }
    if (button_name === "Add Partner") {
      return <AddJob />;
    }
    if (button_name === "Clone Job") {
      return <AddJob />;
    }
    return <AddJob data={null} />;
  }
  function handleCloseJob() {
    // if (button_name === VIEW_JOBS || button_name === UPDATE_JOBS) {
    //   dispatch(changeIsOpenModal(false));
    // } else {
    //   confirmAlert({
    //     customUI: ({ onClose }) => {
    //       return (
    //         <ConfirmationAlertForAddPartner
    //           onClose={onClose}
    //           onDelete={() => {
    //             onViewModal();
    //             onClose();
    //             dispatch(changeIsPartnerInAddJob(true));
    //           }}
    //         />
    //       );
    //     },
    //   });
    // }
  }
  function onCloseJob() {
    if (button_name === VIEW_JOBS || button_name === UPDATE_JOBS) {
      dispatch(changeIsOpenModal(false));
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <ConfirmationAlert
              onClose={onClose}
              onDelete={() => {
                dispatch(removeSelectedFileName([]));
                dispatch(removeTempSelectedFileName([]));
                dispatch(changeIsOpenModal(true));
                onClose();
                dispatch(changeIsOpenModal(false));
              }}
            />
          );
        },
      });
    }
  }
  async function getData(page) {
    // if (searchText.length >= 1 && search_result.length !== 0) {
    //   await dispatch(onChangeJobPageNumber(searchJobPagenumber + 1));
    //   onSearch();
    //   return;
    // }
    if (page === 1) {
      dispatch(changeInitialLoader(true));
    } else {
      dispatch(changeIsLoading(true));
    }
    dispatch(onChangePageNumber(page));
    var sendData = {
      status: filter_data.status,
      order: filter_data.order,
      recruiter_id: filter_data.recruiter_id,
      partner_id: filter_data.partner_id,
      client_id: filter_data?.client_id,
    };
    await jobController.getJobsByStatus(sendData, page);
    dispatch(changeIsJobStatusName(false));
    if (page === 1) {
      dispatch(changeInitialLoader(false));
    } else {
      dispatch(changeIsLoading(false));
    }
  }
  async function onSearchJob() {
    dispatch(changeInitialLoader(true));
    dispatch(changeIsSearching(true));
    dispatch(changeSearchText(searchText));
    var sendData = {
      search_query: searchText.trim(),
    };
    var res = await jobController.searchJob(sendData, searchJobPageNumber);
    if (searchJobPageNumber === 1) {
      dispatch(changeSearchjob(res.data));
      dispatch(changeInitialLoader(false));
    } else {
      dispatch(onLoadMoreSearchJob(res.data));
      dispatch(changeIsSearchLoading(false));
    }
  }
  async function onChangeSearchText(e) {
    if (e.target === undefined) {
      return;
    }
    if (e.target.value === "") {
      dispatch(changeIsSearching(false));
    }
    dispatch(changeSearchText(e.target.value));
  }
  function onClickAddButton() {
    dispatch(changeIsOpenModal(true));
    dispatch(changeButtonName(ADD_JOBS));
  }
  async function getMultipleRecruiterData(data) {
    dispatch(onChangeIsLoadingMultipleRecruiterJobList(true));
    if (data === undefined) {
      await jobController.getMultipleRecruiterJob(filter_data);
      dispatch(onChangeIsLoadingMultipleRecruiterJobList(false));
      return;
    }
    var sendData = {
      status: data?.status,
      order: data?.order,
      recruiter_id: data?.recruiter_id,
      partner_id: data?.partner_id,
      client_id: "123",
    };
    await jobController.getMultipleRecruiterJob(sendData);
    dispatch(onChangeIsLoadingMultipleRecruiterJobList(false));
  }
  function getDate(data) {
    if (data.due_date === null || data.due_date === undefined) {
      if (
        data?.job_detail?.due_date === "" ||
        data?.job_detail?.due_date === null
      ) {
        return "open";
      }
      return moment(data?.job_detail?.due_date).format(DATE_FORMATE);
    }
    return moment(data.due_date).format(DATE_FORMATE);
  }
  function getFilterListByName(name, element) {
    if (name === "Client") {
      let data = {
        list: temp_client_data,
        is_active: element?._id === filter_data.client_id,
        is_active_filter: filter_data.client_id !== "123",
      };
      return data;
    }
    if (name === "Partner") {
      let data = {
        list: partner_list,
        is_active: element?._id === filter_data.partner_id,
        is_active_filter: filter_data.partner_id !== "123",
      };
      return data;
    }
    if (name === "Recruiter") {
      let data = {
        list: temp_recruiter_list,
        is_active: element?._id === filter_data.recruiter_id,
        is_active_filter: filter_data.recruiter_id !== "123",
      };
      return data;
    }
    if (name === "Status") {
      let data = {
        list: JobStatusArray,
        is_active: element === filter_data.status,
        is_active_filter: filter_data.status !== "ALL",
      };
      return data;
    }
    if (name === "Due Date") {
      let data = {
        list: dueDateShortBy,
        is_active: element === filter_data.order,
        is_active_filter: true,
      };
      return data;
    }
  }
  async function onClickOnFilter(name, data) {
    dispatch(changeIsLoading(true));
    if (name === "Status") {
      let filtered_data = { ...filter_data, status: data };
      dispatch(changeFilterData(filtered_data));
      if (tab_name === "Multiple") {
        await getMultipleRecruiterData(filtered_data);
      } else {
        dispatch(removeJObData([]));
        await jobController.getJobsByStatus(filtered_data, 1);
      }
    }
    if (name === "Due Date") {
      let filtered_data = { ...filter_data, order: data };
      dispatch(changeFilterData(filtered_data));
      if (tab_name === "Multiple") {
        await getMultipleRecruiterData(filtered_data);
      } else {
        dispatch(removeJObData([]));
        await jobController.getJobsByStatus(filtered_data, 1);
      }
    }
    if (name === "Recruiter") {
      let filtered_data = {
        ...filter_data,
        recruiter_id: data._id,
      };
      dispatch(changeFilterData(filtered_data));
      if (tab_name === "Multiple") {
        await getMultipleRecruiterData(filtered_data);
      } else {
        dispatch(removeJObData([]));
        await jobController.getJobsByStatus(filtered_data, 1);
      }
    }
    if (name === "Partner") {
      let filtered_data = { ...filter_data, partner_id: data?._id };
      dispatch(changeFilterData(filtered_data));
      if (tab_name === "Multiple") {
        await getMultipleRecruiterData(filtered_data);
      } else {
        dispatch(removeJObData([]));
        await jobController.getJobsByStatus(filtered_data, 1);
      }
    }
    if (name === "Client") {
      let filtered_data = { ...filter_data, client_id: data._id };
      dispatch(changeFilterData(filtered_data));
      if (tab_name === "Multiple") {
        await getMultipleRecruiterData(filtered_data);
      } else {
        dispatch(removeJObData([]));
        await jobController.getJobsByStatus(filtered_data, 1);
      }
    }
    dispatch(changeIsLoading(false));
  }
  function onClickOnData(singleData) {
    jobController.onViewDetail(singleData, VIEW_JOBS);
  }
  function isVisible(name) {
    return !table_list.includes(
      name?.props?.name === undefined ? name : name?.props?.name
    );
  }
  function onViewModal(data, name) {
    // dispatch(changeButtonName("Add Partner"));
    dispatch(changeIsOpenModal(true));
    dispatch(changeButtonName(name));
    dispatch(changeSelectedData(data));
  }
  function getVerticalMenuVisibility() {
    if (
      access_data_by_user_id.includes("job_update") ||
      access_data_by_user_id.includes("job_delete")
    )
      return true;
    else {
      return false;
    }
  }
  function checkBlankArray() {
    return (
      !is_loading_multiple_recruiter_job_list &&
      multiple_recruiter_job_list.length === 0
    );
  }
  function checkBlankArrayForSingleJob() {
    return search_result.length === 0 && !initial_loader;
  }
  function getIndividualJobList() {
    return is_searching ? search_result : jobList;
  }
  function onCheckVisibilityOfLoadMoreButton() {
    return (
      getIndividualJobList().length % 20 !== 0 ||
      getIndividualJobList().length === 0 ||
      is_loading ||
      initial_loader
    );
  }
  function onDelete(data) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <DeleteConfirmation
            name={data.job_detail.client_position_id}
            onClose={onClose}
            onDelete={() => {
              onDeleteData(data);
              onClose();
            }}
          />
        );
      },
    });
  }
  async function onDeleteData(data) {
    try {
      dispatch(onChangeIsLoading(true));
      var sendData = {
        _id: data._id,
        _is_multiple: data?._is_multiple,
      };
      var res = await jobController.deleteJob(sendData);
      if (res.data.status === 200) {
        if (sendData._is_multiple) {
          dispatch(deleteMultipleJob(sendData._id));
        } else {
          dispatch(deleteSearchJob(data._id));
          dispatch(deletejob(data._id));
        }
        alert.show(DELETE_JOB_SUCCESS, { type: CommonString.success });
        dispatch(onChangeIsLoading(false));
      } else {
        alert.show(DELETE_JOB_ERROR, { type: CommonString.error });
        dispatch(onChangeIsLoading(false));
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function onClickClearFilter() {
    if (
      filter_data.status === "ALL" &&
      filter_data.order === "ASC" &&
      filter_data.partner_id === "123" &&
      filter_data.recruiter_id === "123" &&
      filter_data.client_id === "123"
    ) {
      return;
    }
    var sendData = {
      status: "ALL",
      recruiter_id: "123",
      order: "ASC",
      partner_id: "123",
      client_id: "123",
    };
    dispatch(changeIsLoading(true));
    dispatch(removeJObData([]));
    dispatch(changeFilterData(sendData));
    dispatch(onChangePageNumber(1));
    await jobController.getJobsByStatus(sendData, 1);
    dispatch(changeIsLoading(false));
  }
  function getClearFilterName() {
    if (
      filter_data.status === "ALL" &&
      filter_data.order === "ASC" &&
      filter_data.partner_id === "123" &&
      filter_data.recruiter_id === "123" &&
      filter_data.client_id === "123"
    ) {
      return "";
    } else {
      return CommonString.clear_filter;
    }
  }
  async function getJobAttachmentByID() {
    let job_id =
      selected_data?.child_job !== undefined
        ? selected_data.child_job?.job_detail._id
        : selected_data.job_detail._id;
    var sendData = {
      job_id: job_id,
    };
    var res = await jobController.getAttachmentByID(sendData);
    dispatch(onChangeAttachmentList(res.data.data));
    // setAttachmentData(res.data.data);
  }
  async function getSubmissionData() {
    var sendData = {
      child_job_id: selected_data._id,
    };
    var res = await submissionController.getSubmissionByChildJob(sendData);
    dispatch(onChangeSubmissionDataForJob(res.data.data));
  }
  function getIcon(e) {
    if (e.substr(e.indexOf(".")) === ".pdf") {
      return CommonIcon.pdf;
    }
    return CommonIcon.word;
  }

  return {
    temp_partner_data,
    attachment_list,
    temp_client_data,
    jobPageNumber,
    submission_data_for_job,
    getIcon,
    handleCloseJob,
    getJobAttachmentByID,
    getSubmissionData,
    selected_data,
    multiple_recruiter_job_count,
    job_count,
    getClearFilterName,
    onClickClearFilter,
    onDelete,
    is_loading,
    onCheckVisibilityOfLoadMoreButton,
    getIndividualJobList,
    checkBlankArrayForSingleJob,
    checkBlankArray,
    getVerticalMenuVisibility,
    onViewModal,
    isVisible,
    onClickOnData,
    onClickOnFilter,
    is_loading_multiple_recruiter_job_list,
    getFilterListByName,
    getMultipleRecruiterData,
    getDate,
    onClickAddButton,
    onChangeSearchText,
    onSearchJob,
    getData,
    onCloseJob,
    getFormView,
    access_data_by_user_id,
    multiple_recruiter_job_list,
  };
}

export default JobCustomController;
