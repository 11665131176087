import React from "react";
import { UPDATE_PARTNER, VIEW_PARTNER } from "../../../Utils/enum";
import VerticalMenu from "../../CommonComponet/VerticalMenu";
import {
  CustomTableContainer,
  TableRow,
  TableTbody,
  TableTd,
  TableTh,
  Table,
  JobFirstColumn,
} from "../../../style/table.styled";
import { PARTNER_TH } from "../../../Utils/constant";
import Container from "../../CommonComponet/Container";
import { formatPhoneNumber } from "../../../services/Validate";
import { CommonString } from "../../../Utils/CommonString";
import { useSelector } from "react-redux";
import {
  getVerticalMenu,
  jobStatusColor,
} from "../../../Utils/utilitiFunction";
import { Icon } from "@iconify/react";
import CustomizedTooltip from "../../CommonComponet/CustomizedTooltip";

export default function PartnerTable(props) {
  const partnerData = useSelector((state) => state.counter.partnerData);

  return (
    <CustomTableContainer>
      <Table>
        <TableTbody>
          <TableRow>
            {PARTNER_TH.map((singleTH, index) => (
              <TableTh align={singleTH.align} key={index}>
                {singleTH.name}
              </TableTh>
            ))}
          </TableRow>
          {partnerData.length === 0 ? (
            <></>
          ) : (
            partnerData.map((data, index) => {
              return (
                <TableRow
                  key={index}
                  onDoubleClick={() => props.onViewModal(VIEW_PARTNER, data)}
                  onClick={() =>
                    window.innerWidth < 500
                      ? props.onViewModal(VIEW_PARTNER, data)
                      : null
                  }
                >
                  <TableTd style={{ paddingLeft: 10 }}>{index + 1}</TableTd>
                  <TableTd>
                    <CustomizedTooltip name={data.name} title={data.name} />
                  </TableTd>
                  <TableTd>
                    {data.poc_name === undefined || data.poc_name === ""
                      ? CommonString.na
                      : data.poc_name}
                  </TableTd>
                  <TableTd isemail={CommonString.true}>
                    <CustomizedTooltip name={data.email} title={data.email} />
                  </TableTd>
                  <TableTd>{formatPhoneNumber(data.phone)}</TableTd>
                  <TableTd isCenter={true}>
                    <JobFirstColumn
                      style={{
                        backgroundColor:
                          jobStatusColor[data.is_active].backgroundColor,
                        color: jobStatusColor[data.is_active].color,
                      }}
                    >
                      <Icon icon="octicon:dot-fill-16" />{" "}
                      {data.is_active === 1 ? "Active" : "In Active"}
                    </JobFirstColumn>
                  </TableTd>
                  <TableTd>
                    <VerticalMenu
                      data={getVerticalMenu()}
                      onView={() => props.onViewModal(VIEW_PARTNER, data)}
                      onEdit={() => props.onViewModal(UPDATE_PARTNER, data)}
                      onDelete={() => props.onDelete(data)}
                    />
                  </TableTd>
                </TableRow>
              );
            })
          )}
        </TableTbody>
      </Table>
      <Container
        isLoading={props.isLoading === true}
        isVisible={props.isLoading === false && partnerData.length === 0}
      />
    </CustomTableContainer>
  );
}
