import React, { useState } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const LocationSearchInput = (props) => {
  const [address, setAddress] = useState("");

  const handleChange = (address) => {
    props.getLocation(address);
    setAddress(address);
  };
  const handleSelect = (address) => {
    props.getLocation(address);
    setAddress(address);
    geocodeByAddress(address).then((results) => getLatLng(results[0]));
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={{ componentRestrictions: { country: "us" } }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: "Location (city, state, zip, time zone)",
              className: "location-search-input",
            })}
          />
          <div
            style={{
              position: "absolute",
              background: "white",
              width: "100%",
              top: "50px",
              borderRadius: 4,
              left: 0,
              zIndex: 1000,
              boxShadow:
                "0px 27px 80px rgba(0, 0, 0, 0.07), 0px 5.4px 13px rgba(0, 0, 0, 0.035)",
            }}
            className="autocomplete-dropdown-container"
          >
            {suggestions.map((suggestion) => {
              const className = suggestion.active
                ? "suggestion-item--active"
                : "suggestion-item";
              return (
                <div
                  style={{ padding: 10, cursor: "pointer" }}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                  })}
                  onClick={() => handleSelect(suggestion.description)}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
