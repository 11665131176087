import React from "react";
import { TabPane, Tab } from "semantic-ui-react";
import MultipleJobTable from "../CommonComponet/jobComponent/MultipleJobTable";
import JobTable from "../DataTable/Table/JobTable";
import { FlexContainer } from "../../style/dice.style";
import { ClearFilterText } from "../../style/common.style";
import JobCustomController from "../../controller/JobCustomController";
import CustomizeTableFiled from "./CustomizeTableFiled";
import { TabMenuContainer, TabSecondContainer } from "../../style/job.style";
import { CommonString } from "../../Utils/CommonString";
import { onChangeTabName } from "../../feature/store/JobReduxStore";
import { useDispatch } from "react-redux";

const JobHome = () => {
  const dispatch = useDispatch();
  const {
    onClickClearFilter,
    getClearFilterName,
    job_count,
    multiple_recruiter_job_count,
  } = JobCustomController();
  const panes = [
    {
      menuItem: `Individual Requisition (${job_count})`,
      render: () => (
        <TabPane
          onClick={(e) => dispatch(onChangeTabName("Individual"))}
          style={tabPanelStyle}
        >
          <JobTable />
        </TabPane>
      ),
    },
    {
      menuItem: `Multiple Requisition (${multiple_recruiter_job_count})`,
      render: () => (
        <TabPane
          onClick={(e) => dispatch(onChangeTabName("Multiple"))}
          style={tabPanelStyle}
        >
          <MultipleJobTable />
        </TabPane>
      ),
    },
  ];

  return (
    <TabMenuContainer>
      <Tab
        defaultActiveIndex={0}
        menu={{ secondary: true, pointing: true }}
        panes={panes}
      />
      <TabSecondContainer>
        <FlexContainer>
          {/* <span>
            {CommonString.total_job_count} : {job_count}
          </span> */}
          <ClearFilterText onClick={() => onClickClearFilter()}>
            {getClearFilterName()}
          </ClearFilterText>
          {/* <CustomizeTableFiled /> */}
        </FlexContainer>
      </TabSecondContainer>
    </TabMenuContainer>
  );
};

const tabPanelStyle = {
  border: "none",
  overflow: "hidden",
  padding: 0,
};
export default JobHome;
