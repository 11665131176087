import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { AccessKey } from "../../Utils/CommonString";
// import { onChangeAccessData } from "../../feature/store/ReduxStore";
import {
  getAccessVisibility,
  onCheckAdminAccessibility,
} from "../../Utils/utilitiFunction";
import CheckBox from "../basic/CheckBox";
import { OutlinedBtn } from "../../style/common.style";
import { Grid } from "@mui/material";
import { ButtonContainer } from "../../style/recruiter.style";
import CustomButton from "../CommonComponet/Button";
import RecruiterController from "../../controller/RecruiterController";
import { onChangeAccessDataById } from "../../feature/store/ReduxStore";
import { useDispatch, useSelector } from "react-redux";
import SearchLoader from "../CommonComponet/SearchLoader";

export default function AccessDetail(props) {
  const dispatch = useDispatch();
  var recruiterController = new RecruiterController();
  const selected_user = useSelector((state) => state.UserStore.selected_user);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    job_create: false,
    job_update: false,
    job_delete: false,
    applicant_create: false,
    applicant_update: false,
    applicant_delete: false,
    client_create: false,
    client_update: false,
    client_delete: false,
    review_create: false,
    review_update: false,
    review_delete: false,
    submission_create: false,
    submission_update: false,
    submission_delete: false,
    offer_create: false,
    offer_update: false,
    offer_delete: false,
    placement_create: false,
    placement_update: false,
    placement_delete: false,
    job_status_update: false,
    add_partner_in_job: false,
    close_applicant: false,
    view_review: false,
    please_submit_update: false,
    reject_internally_update: false,
    hold_internally_update: false,
  });
  useEffect(() => {
    onPrefilledData();
  }, [selected_user]);

  async function onPrefilledData() {
    try {
      if (selected_user?._id === undefined) return;
      var access_res = await recruiterController.getAccessById({
        user_id: selected_user?._id,
      });
      if (access_res === null) return;
      let access = getAccessVisibility(access_res);
      setFormData(access_res);
      dispatch(onChangeAccessDataById(access));
    } catch (error) {
      console.log(error);
    }
  }
  function handleInputChange(event) {
    const { name, checked } = event.target;
    setFormData({ ...formData, [name]: checked });
  }
  async function onAddAccess(isClose) {
    try {
      setIsLoading(true);
      ///api call for Update or add Data
      let res = await recruiterController.updateAccessById({
        user_id: selected_user?._id,
        ...formData,
      });
      ////ends here
      /// for the tab chnage to next tab
      setTimeout(() => {
        if (isClose) {
          props.onChange();
        } else {
          props.handleClose();
        }
        setIsLoading(false);
      }, 2000);
      ///ends here
    } catch (error) {
      console.log(error);
    }
  }

  return onCheckAdminAccessibility() ? (
    <>
      {isLoading ? <SearchLoader /> : <></>}
      <Grid container className="mt-2">
        <CheckBox
          onClick={handleInputChange}
          value={formData.please_submit_update}
          label={AccessKey.please_submit_update}
          name={"please_submit_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.reject_internally_update}
          label={AccessKey.reject_internally_update}
          name={"reject_internally_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.hold_internally_update}
          label={AccessKey.hold_internally_update}
          name={"hold_internally_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.job_status_update}
          label={AccessKey.job_status_update}
          name={"job_status_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.job_create}
          label={AccessKey.job_create}
          name="job_create"
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.job_update}
          label={AccessKey.job_update}
          name={"job_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.job_delete}
          label={AccessKey.job_delete}
          name={"job_delete"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.job_status_update}
          label={AccessKey.job_status_update}
          name={"job_status_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.add_partner_in_job}
          label={AccessKey.add_partner_in_job}
          name={"add_partner_in_job"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.applicant_create}
          label={AccessKey.applicant_create}
          name={"applicant_create"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.applicant_update}
          label={AccessKey.applicant_update}
          name={"applicant_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.close_applicant}
          label={AccessKey.close_applicant}
          name={"close_applicant"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.applicant_delete}
          label={AccessKey.applicant_delete}
          name={"applicant_delete"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.client_create}
          label={AccessKey.client_create}
          name={"client_create"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.client_update}
          label={AccessKey.client_update}
          name={"client_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.client_delete}
          label={AccessKey.client_delete}
          name={"client_delete"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.view_review}
          label={AccessKey.view_review}
          name={"view_review"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.review_create}
          label={AccessKey.review_create}
          name={"review_create"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.review_update}
          label={AccessKey.review_update}
          name={"review_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.review_delete}
          label={AccessKey.review_delete}
          name={"review_delete"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.submission_create}
          label={AccessKey.submission_create}
          name={"submission_create"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.submission_update}
          label={AccessKey.submission_update}
          name={"submission_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.submission_delete}
          label={AccessKey.submission_delete}
          name={"submission_delete"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.offer_create}
          label={AccessKey.offer_create}
          name={"offer_create"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.offer_update}
          label={AccessKey.offer_update}
          name={"offer_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.offer_delete}
          label={AccessKey.offer_delete}
          name={"offer_delete"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.placement_create}
          label={AccessKey.placement_create}
          name={"placement_create"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.placement_update}
          label={AccessKey.placement_update}
          name={"placement_update"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.placement_delete}
          label={AccessKey.placement_delete}
          name={"placement_delete"}
        />
      </Grid>
      <ButtonContainer>
        <OutlinedBtn onClick={() => onAddAccess(false)}>
          Save & Exit
        </OutlinedBtn>
        <CustomButton onClick={() => onAddAccess(true)} name={"Save & Next"} />
      </ButtonContainer>
    </>
  ) : (
    <></>
  );
}
