import React from "react";
import { Icon } from "@iconify/react";
import FullScreenLoader from "../CommonComponet/FullScreenLoader";
import ButtonGroup from "../CommonComponet/ButtonGroup";
import { CommonString, CommonTitle } from "../../Utils/CommonString";
import {
  FormTitleContainerForConfirmation,
  ModalIconContainer,
  ModalTitle,
  ModalTitleSpan,
} from "../../style/common.style";
import CustomTooltip from "../CommonComponet/Tooltip";
import { CommonIcon } from "../../Utils/CommonIcon";
import { CommonColor } from "../../Utils/color";
import { ConfirmationAlertContainer } from "../../style/login.style";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

export default function UpdateStatusConfirmation(props) {
  return (
    <Modal
      style={{ zIndex: 100 }}
      open={props.isOpenAlertBox}
      onClose={props.onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <ConfirmationAlertContainer>
          {props.isLoading ? (
            <FullScreenLoader isDelete={true} height="150px" width="100%" />
          ) : (
            <div />
          )}
          <FormTitleContainerForConfirmation>
            <ModalTitle>
              Update <ModalTitleSpan>{props.status}</ModalTitleSpan>
            </ModalTitle>
            <ModalIconContainer>
              <CustomTooltip
                placement={CommonString.top_placement}
                title={CommonTitle.close}
                name={
                  <Icon
                    width={24}
                    height={24}
                    icon={CommonIcon.modal_close}
                    onClick={props.onClose}
                  />
                }
              />
            </ModalIconContainer>
          </FormTitleContainerForConfirmation>
          <p>
            Are you sure you want to change status to{" "}
            <span
              style={{
                textTransform: "capitalize",
                color: CommonColor.secondary_color,
              }}
            >
              {props.status + " "}
            </span>
            for
            <span
              style={{
                textTransform: "capitalize",
                color: CommonColor.secondary_color,
              }}
            >
              {" " + props.possition_id}
            </span>
            .
          </p>
          {!props.enableInput ? (
            <></>
          ) : (
            <textarea
              style={{ width: "100%", marginTop: 10, padding: 5 }}
              multiple
              onChange={(e) => props.setContent(e.target.value)}
              value={props.content}
              placeholder="Submit Your Comment"
            />
          )}
          <div style={{ marginTop: 20 }}>
            <ButtonGroup
              style={{ marginTop: "20px" }}
              solidBtnClick={() => props.onDelete()}
              outlinedBtnClick={props.onClose}
              solidBtnName={CommonString.update}
              outlinedBtnName={CommonString.cancle}
            />
          </div>
        </ConfirmationAlertContainer>
      </Box>
    </Modal>
  );
}
