// src/components/InputWithLabel.js
import React from "react";
import styled from "styled-components";

// Styled component for the input wrapper and inner elements
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  margin-top: 10px;
`;
const StyledInput = styled.input`
  border: 1px solid #ccc;
  outline: none;
  font-size: 14px;
  padding: 8px 10px;
  border-radius: 0;
`;

const UnitLabel = styled.div`
  color: #666;
  font-size: 14px;
  padding: 8px 10px;
  border: 1px solid #ccc;
  select {
    width: auto;
    padding: 0px;
    border-radius: 0;
    border: none;
  }
`;

const InputWithLabel = (props) => {
  return (
    <InputWrapper>
      <StyledInput {...props} placeholder={props.placeholder} />
      {props.unitLabel === undefined ? (
        <></>
      ) : (
        <UnitLabel>{props.unitLabel}</UnitLabel>
      )}
    </InputWrapper>
  );
};

export default InputWithLabel;
