import React, { useEffect, useState } from "react";
import FormInput from "../CommonComponet/FormInput";
import CustomDatePicker from "../CommonComponet/DatePicker";
import { CommonLabel } from "../../Utils/CommonString";
import { Grid } from "@material-ui/core";
import { DateContainer, FormField } from "../../style/common.style";
import CustomButton from "../CommonComponet/Button";
import { FormButton } from "../../style/button.style";
import { SubmissionController } from "../../controller/SubmissionController";
import { useDispatch } from "react-redux";
import { onUpdateOfferDetilsBoolean } from "../../feature/store/JobReduxStore";
import { SUBMISSION_STATUS } from "../../Utils/constant";
import CommonDatePicker from "../CommonComponet/CommonDatePicker";

export default function AddOFferDetails(props) {
  const dispatch = useDispatch();
  const submissionController = new SubmissionController();
  const [submission_id, setSubmission_id] = useState();
  const [offerDate, setOfferDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [invoicedTill, setInvoicedTill] = useState(null);
  const [bdDate, setBdDate] = useState(null);
  const [payRate, setPayRate] = useState();
  const [billRate, setBillRate] = useState();
  const [conversion_1099, setconversion_1099] = useState();
  const [mspVendorFee, setMspVendorFee] = useState();
  const [payrollManagementFee, setPayrollManagementFee] = useState();
  const [gp, setGp] = useState();
  const [prexelShare, setPrexelShare] = useState();
  const [rw, setRw] = useState();
  const [bae, setBae] = useState();
  const [it, setIt] = useState();
  const [comment, setComment] = useState();
  const [offer_detail, setOffer_detail] = useState([]);

  useEffect(() => {
    onPreFieldData();
  }, [props.submission_id]);

  async function onPreFieldData() {
    if (props.modalStatus === SUBMISSION_STATUS.PLACEMENT) {
      if (props.submission_id === undefined) return;
      var data = {
        submission_id: props?.submission_id,
      };
      var res = await submissionController.getSubmissionOfferDetail(data);
      setOffer_detail(res?.data?.data[0]);
      var res_data = res?.data?.data[0];
      setOfferDate(res_data?.offer_date);
      setStartDate(res_data?.start_date);
      setInvoicedTill(res_data?.invoice_till);
      setBdDate(res_data?.bd_date);
      setPayRate(res_data?.payrate);
      setBillRate(res_data?.billrate);
      setconversion_1099(res_data?.conversion_1099);
      setMspVendorFee(res_data?.msp_vendor_fee);
      setPayrollManagementFee(res_data?.payroll_management_fee);
      setGp(res_data?.gp);
      setPrexelShare(res_data?.prexel_share);
      setRw(res_data?.rw);
      setBae(res_data?.bae);
      setIt(res_data?.it);
      setComment(res_data?.comment);
    }
  }
  async function onSave() {
    const pay_rate = payRate === undefined ? "0" : String(payRate).trim();
    const bill_rate = billRate === undefined ? "0" : String(billRate).trim();
    const conversion1099 =
      conversion_1099 === undefined ? "0" : String(conversion_1099).trim();
    const msp_vendor_fee =
      mspVendorFee === undefined ? "0" : String(mspVendorFee).trim();
    const payroll_management_fee =
      payrollManagementFee === undefined
        ? "0"
        : String(payrollManagementFee).trim();
    const GP = gp === undefined ? "0" : String(gp).trim();
    const prexel_share =
      prexelShare === undefined ? "0" : String(prexelShare).trim();
    const RW = rw === undefined ? "0" : String(rw).trim();
    const BAE = bae === undefined ? "0" : String(bae).trim();
    const IT = it === undefined ? "0" : String(it).trim();
    var sendData = {
      submission_id: props.submission_id,
      offer_date: offerDate ?? "",
      start_date: startDate ?? "",
      end_date: endDate ?? "",
      invoice_till: invoicedTill ?? "",
      bd_date: bdDate ?? "",
      payrate: pay_rate,
      billrate: bill_rate,
      conversion_1099: conversion1099,
      msp_vendor_fee: msp_vendor_fee,
      payroll_management_fee: payroll_management_fee,
      gp: GP,
      prexel_share: prexel_share,
      rw: RW,
      bae: BAE,
      it: IT,
      comment: comment ?? "",
    };
    if (props.modalStatus === SUBMISSION_STATUS.PLACEMENT) {
      let offer_res = await submissionController.onEditOfferDetail({
        ...sendData,
        _id: offer_detail?._id,
      });
      props.onAddOfferDetail(sendData, offer_res);
      props.handleClose();
      return;
    }
    let offer_res = await submissionController.addSubmissionOfferDetail(
      sendData
    );
    props.onAddOfferDetail(sendData, offer_res);
    if (offer_res.status === 200) {
      props.handleClose();
      dispatch(onUpdateOfferDetilsBoolean(true));
    }
    props.handleClose();
  }
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6}>
          {/* <FormInput
          value={submission_id || ""}
          onChange={(e) => setSubmission_id(e.target.value)}
          placeholder="Time"
        /> */}
          <CustomDatePicker
            margin={50}
            label={CommonLabel.offer_date}
            name={CommonLabel.offer_date}
            value={offerDate}
            onChange={(date) => setOfferDate(date)}
          />
          <DateContainer
            noDisplay={props.modalStatus === SUBMISSION_STATUS.OFFER}
          >
            <CustomDatePicker
              margin={10}
              label={CommonLabel.start_date}
              name={CommonLabel.start_date}
              value={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </DateContainer>
          {/* <DateContainer>
          <CustomDatePicker
            margin={10}
            label={CommonLabel.end_date}
            value={endDate}
            onChange={(date) => setEndDate(date)}
          />
        </DateContainer> */}
          {/* <DateContainer
            noDisplay={props.modalStatus === SUBMISSION_STATUS.OFFER}
          >
            <CustomDatePicker
              margin={10}
              label={CommonLabel.invoiced_till}
              value={invoicedTill}
              onChange={(date) => setInvoicedTill(date)}
            />
          </DateContainer>
          <DateContainer
            noDisplay={props.modalStatus === SUBMISSION_STATUS.OFFER}
          >
            <CustomDatePicker
              margin={10}
              label={CommonLabel.bd_date}
              value={bdDate}
              onChange={(date) => setBdDate(date)}
            />
          </DateContainer> */}
          <FormInput
            value={payRate || ""}
            onChange={(e) => setPayRate(e.target.value)}
            placeholder={CommonLabel.pay_rate}
          />
          <FormInput
            value={billRate || ""}
            onChange={(e) => setBillRate(e.target.value)}
            placeholder={CommonLabel.bill_rate}
          />
          <FormInput
            value={conversion_1099 || ""}
            onChange={(e) => setconversion_1099(e.target.value)}
            placeholder={CommonLabel.conversion_1099}
          />
          <FormInput
            value={mspVendorFee || ""}
            onChange={(e) => setMspVendorFee(e.target.value)}
            placeholder={CommonLabel.msp_vendor_fee}
          />
          <FormInput
            value={payrollManagementFee || ""}
            onChange={(e) => setPayrollManagementFee(e.target.value)}
            placeholder={CommonLabel.payroll_management_fees}
          />
        </Grid>
        <Grid item md={6}>
          <FormInput
            value={gp || ""}
            onChange={(e) => setGp(e.target.value)}
            placeholder={CommonLabel.gp}
          />
          <FormInput
            value={prexelShare || ""}
            onChange={(e) => setPrexelShare(e.target.value)}
            placeholder={CommonLabel.prexel_share}
          />
          <FormInput
            value={rw || ""}
            onChange={(e) => setRw(e.target.value)}
            placeholder={CommonLabel.rw}
          />
          <FormInput
            value={bae || ""}
            onChange={(e) => setBae(e.target.value)}
            placeholder={CommonLabel.bae}
          />
          <FormInput
            value={it || ""}
            onChange={(e) => setIt(e.target.value)}
            placeholder={CommonLabel.it}
          />
        </Grid>
      </Grid>
      <FormInput
        value={comment || ""}
        onChange={(e) => setComment(e.target.value)}
        placeholder={CommonLabel.comment}
      />
      <FormButton>
        <CustomButton onClick={() => onSave()} name={"Save"} />
      </FormButton>
    </>
  );
}
