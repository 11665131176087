import React, { Component } from "react";
import Lottie from "react-lottie";

export default class LottieAnimation extends Component {
  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: require("../assets/images/cracker.json"),
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div style={{ position: "absolute", left: 0, right: 0, top: 100 }}>
        {this.props.isVisible === true ? (
          <Lottie
            options={defaultOptions}
            height={"100vh"}
            width={"80vw"}
          />
        ) : (
          <></>
        )}
      </div>
    );
  }
}
