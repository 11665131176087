import React from 'react'
import PatnerSelectionComponent from '../../CommonComponet/jobComponent/PatnerSelectionComponent'
import PayRateSection from '../../jobs/PayRateSection'

export default function TaxTermComponent() {
  return (
    <>
        <PatnerSelectionComponent></PatnerSelectionComponent>
        <PayRateSection/>
    </>
  )
}
