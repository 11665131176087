import React from "react";
import { Content } from "../../style/applicant.style";

export default function EmptyBlock(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <img src={props.image} alt="" height={100} />
      <div style={{ height: 10 }}></div>
      <Content> {props.title} </Content>
      <div style={{ height: 80 }}></div>

    </div>
  );
}
