import React, { useState } from "react";
import { TabPane, Tab } from "semantic-ui-react";
import BasicInfoForm from "./BasicInfoForm";
import AccessDetail from "./AccessDetail";
import InternalDomainAccess from "../Access/InternalDomainAccess";

const CustomTabPanel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const panes = [
    {
      menuItem: "Basic Information",
      render: () => (
        <TabPane>
          <BasicInfoForm
            handleClose={() => {
              props.handleClose();
              props.onRefresh();
            }}
            onChange={() => onChange(1)}
          />
        </TabPane>
      ),
    },
    {
      menuItem: "Account Access",
      render: () => (
        <TabPane>
          <AccessDetail
            handleClose={() => {
              props.handleClose();
              props.onRefresh();
            }}
            onChange={() => onChange(2)}
          />
        </TabPane>
      ),
    },
    {
      menuItem: "Internal Domain Assign",
      render: () => (
        <TabPane>
          <InternalDomainAccess
            handleClose={() => {
              props.handleClose();
              props.onRefresh();
            }}
          />
        </TabPane>
      ),
    },
  ];

  function onChange(index) {
    setActiveIndex(index);
  }
  return (
    <Tab
      menu={{ secondary: true, pointing: true }}
      panes={panes}
      activeIndex={activeIndex}
      onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
    />
  );
};

export default CustomTabPanel;
