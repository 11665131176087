import React from "react";
import { Div, NoDataContainer } from "../../style/common.style";
import { ImgNodata } from "../../style/table.styled";
// import Lottie from "react-lottie";
import NoData from "../../assets/images/nodata.png";
export default function Container(props) {
  // const defaultOptions = {
  //   loop: false,
  //   autoplay: true,
  //   animationData: require("../../assets/images/noDataFound.json"),
  //   rendererSettings: {
  //     preserveAspectRatio: "xMidYMid slice",
  //   },
  // };
  return props.isLoading === true ? (
    <Div />
  ) : props.isVisible ? (
    <NoDataContainer>
      {props.isJob ? (
        props.children
      ) : (
        <ImgNodata>
          <img src={NoData} alt="" />
          {/* <Lottie options={defaultOptions} /> */}
        </ImgNodata>
      )}
    </NoDataContainer>
  ) : (
    <></>
  );
}
