import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import CommonBaseModal from "../Modal/CommonBaseModal";
import { changeIsOpenModal } from "../../feature/store/ApplicantStore";
import RecruiterInternalDomainBlock from "./RecruiterInternalDomainBlock";
import MemberListInInternalDomainTeam from "./MemberListInInternalDomainTeam";
import RecruiterController from "../../controller/RecruiterController";
import { onGetInternalTeamListByDomain } from "../../feature/store/UserStore";

export default function InternalDomainTeams() {
  const dispatch = useDispatch();
  const recruiterController = new RecruiterController();

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    var res = await recruiterController.getInternalDomainUserList();
    dispatch(onGetInternalTeamListByDomain(res));
  }
  return (
    <>
      <CommonBaseModal
        size="medium"
        handleClose={() => {
          dispatch(changeIsOpenModal(false));
        }}
        zIndex={5}
        children={<MemberListInInternalDomainTeam />}
      />
      <Grid container spacing={2}>
        <RecruiterInternalDomainBlock domain="ALL" />
        <RecruiterInternalDomainBlock domain="Public" />
        <RecruiterInternalDomainBlock domain="Private" />
        <RecruiterInternalDomainBlock domain="Healthcare" />
      </Grid>
    </>
  );
}
