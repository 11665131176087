import React, { useState } from "react";
import { TabPane, Tab } from "semantic-ui-react";
import RecruitersView from "../View/RecruitersView";
import InternalDomainTeams from "./InternalDomainTeams";

const RecruiterHome = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const panes = [
    {
      menuItem: "All Recruiter",
      render: () => (
        <TabPane style={{ border: "none"}}>
          <RecruitersView />
        </TabPane>
      ),
    },
    {
      menuItem: "Internal Domain Team",
      render: () => (
        <TabPane
          style={{ border: "none", overflow: "auto", maxHeight: "90vh" }}
        >
          <InternalDomainTeams />
        </TabPane>
      ),
    },
  ];

  return (
    <Tab
      menu={{ secondary: true, pointing: true }}
      panes={panes}
      activeIndex={activeIndex}
      onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
    />
  );
};

export default RecruiterHome;
