import React from "react";
import { Icon } from "@iconify/react";
import FullScreenLoader from "../CommonComponet/FullScreenLoader";
import ButtonGroup from "../CommonComponet/ButtonGroup";
import { CommonString, CommonTitle } from "../../Utils/CommonString";
import {
  ConfirmationFormTitle,
  DeleteConfirmationFormTitle,
  ModalIconContainer,
  ModalTitle,
  ModalTitleSpan,
} from "../../style/common.style";
import CustomTooltip from "../CommonComponet/Tooltip";
import { CommonIcon } from "../../Utils/CommonIcon";
import { ConfirmationAlertContainer } from "../../style/login.style";
import { ModalIcon } from "../../style/Icon.style";

export default function DeleteConfirmation(props) {
  return (
    <ConfirmationAlertContainer>
      {props.isLoading ? (
        <FullScreenLoader isDelete={true} height="150px" width="100%" />
      ) : (
        <div />
      )}
      <DeleteConfirmationFormTitle>
        <ModalTitle margin={false}>
          Delete <ModalTitleSpan>Confirmation</ModalTitleSpan>
        </ModalTitle>
        <ModalIconContainer>
          <CustomTooltip
            placement={CommonString.top_placement}
            title={CommonTitle.close}
            name={
              <ModalIcon
                icon={CommonIcon.modal_close}
                onClick={props.onClose}
              />
            }
          />
        </ModalIconContainer>
      </DeleteConfirmationFormTitle>
      <ConfirmationFormTitle>
        <span>{props.name + " "}</span>
        {props.message !== undefined
          ? props.message
          : " will be deleted parmenetly from your records. Are you sure you want to proceed ?"}
      </ConfirmationFormTitle>
      <ButtonGroup
        solidBtnClick={() => props.onDelete()}
        outlinedBtnClick={props.onClose}
        solidBtnName={CommonString.delete}
        outlinedBtnName={CommonString.cancle}
      />
    </ConfirmationAlertContainer>
  );
}
