import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { CommonColor } from "../../Utils/color";
import { CommonString } from "../../Utils/CommonString";

export default function CustomTooltip(props) {
  function getPlacement() {
    if (props.placement === CommonString.right_end_placement) {
      return CommonString.right_end_placement;
    }
    if (props.placement === CommonString.top_placement) {
      return CommonString.top_placement;
    }
  }

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip
      placement={getPlacement()}
      {...props}
      arrow
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: CommonColor.tooltip_color,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: CommonColor.tooltip_color,
    },
  }));
  
  return (
    <BootstrapTooltip {...props} title={props.title}>
      {props.name}
    </BootstrapTooltip>
  );
}
