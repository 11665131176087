import { createSlice } from "@reduxjs/toolkit";
import { ADD_LEVEL } from "../../Utils/enum";
// import { onChangeIsLoading } from "./LevelReduxStore";

export const ReportStoreRedux = createSlice({
  name: "ReportStoreRedux",
  initialState: {
    search_text: "",
    selected_job_list: [],
    open_position_list: [],
    call_report_list: [],
    is_open: false,
    modal_status: "",
    select_data: [],
    is_loading: false,
    table_list: [
      "all",
      "submission",
      "interview",
      "offer",
      "placement",
      "project_end",
    ],
  },
  reducers: {
    // onChangeShift: (state, actions) => {
    //   state.shift = actions.payload;
    // },\
    changeTableList: (state, action) => {
      state.table_list.push(action.payload);
      var newData = state.table_list.filter(
        (item, index) => state.table_list.indexOf(item) === index
      );
      state.table_list = newData;
    },
    changeInitialTableList: (state, action) => {
      state.table_list = action.payload;
    },
    removeFromTableList: (state, action) => {
      var index = state.table_list.indexOf(action.payload);
      if (index !== -1) {
        state.table_list.splice(index, 1);
      }
    },
    onChangeIsLoading: (state, actions) => {
      state.is_loading = actions.payload;
    },
    onChangeModalStatusForTable: (state, actions) => {
      state.modal_status = actions.payload;
    },
    onChangeSelectDataForTable: (state, actions) => {
      state.select_data = actions.payload;
    },
    changeIsOpenForTable: (state, action) => {
      state.is_open = action.payload;
    },
    getCallReportList: (state, action) => {
      state.call_report_list = action.payload;
    },
    onChangeOpenPositionList: (state, action) => {
      state.open_position_list = action.payload;
    },
    onAddDataInCallReport: (state, action) => {
      state.call_report_list.push(action.payload);
    },
    onEditDataInCallReport: (state, action) => {
      var data = state.call_report_list.map(function (item) {
        return item._id === action.payload._id ? action.payload : item;
      });
      state.call_report_list = data;
    },
    onDeleteDataInCallReport: (state, action) => {
      const newList = state.call_report_list.filter(
        (item) => item._id !== action.payload
      );
      state.call_report_list = newList;
    },
    changeSelectedJobList: (state, action) => {
      state.selected_job_list.push(action.payload);
      var newData = state.selected_job_list.filter(
        (item, index) => state.selected_job_list.indexOf(item) === index
      );
      state.selected_job_list = newData;
    },
    removeFromSelectedJobList: (state, action) => {
      var index = state.selected_job_list.indexOf(action.payload);
      if (index !== -1) {
        state.selected_job_list.splice(index, 1);
      }
    },
  },
});

export const {
  changeTableList,
  changeInitialTableList,
  removeFromTableList,
  onChangeIsLoading,
  onDeleteDataInCallReport,
  onEditDataInCallReport,
  onAddDataInCallReport,
  onChangeModalStatusForTable,
  onChangeSelectDataForTable,
  changeIsOpenForTable,
  getCallReportList,
  // onChangeShift,
  onChangeOpenPositionList,
  changeSelectedJobList,
  removeFromSelectedJobList,
} = ReportStoreRedux.actions;

export default ReportStoreRedux.reducer;
