import ApiUtils from "../APICallServices/APIUtils";

export default class ClientProvider {
  constructor() {
    this.Apiprovider = new ApiUtils();
  }
  getClientList = () => {
    return this.Apiprovider.init().get("client/get-client");
  };
  addNewClient = (data) => {
    return this.Apiprovider.init().post("client/add-client", data);
  };
  updateClient = (data) => {
    return this.Apiprovider.init().post("client/update-client", data);
  };
  deleteClient = (data) => {
    return this.Apiprovider.init().post("client/delete-client", data);
  };
}
