import ApiUtils from "../APICallServices/APIUtils";

export default class LevelProvider {
  constructor() {
    this.Apiprovider = new ApiUtils();
  }
  getLevelList = (page = 1) => {
    return this.Apiprovider.init().get(`level/get-level`);
  };
  addNewLevel = (data) => {
    return this.Apiprovider.init().post("level/add-level", data);
  };
  updateLevel = (data) => {
    return this.Apiprovider.init().post("level/update-level", data);
  };
  deleteLevel = (data) => {
    return this.Apiprovider.init().post("level/delete-level", data);
  };
}
