import React from "react";
import { ConfirmationAlertContainer } from "../../../style/login.style";
import FullScreenLoader from "../FullScreenLoader";
import {
  ConfirmationFormTitle,
  DeleteConfirmationFormTitle,
  ModalIconContainer,
  ModalTitle,
  ModalTitleSpan,
} from "../../../style/common.style";
import CustomTooltip from "../Tooltip";
import { CommonString, CommonTitle } from "../../../Utils/CommonString";
import { CommonIcon } from "../../../Utils/CommonIcon";
import { ModalIcon } from "../../../style/Icon.style";
import ButtonGroup from "../ButtonGroup";

export default function ConfirmationBox(props) {
  return (
    <ConfirmationAlertContainer>
      {props.isLoading ? (
        <FullScreenLoader isDelete={true} height="150px" width="100%" />
      ) : (
        <div />
      )}
      <DeleteConfirmationFormTitle>
        <ModalTitle margin={false}>
          Status <ModalTitleSpan>Confirmation</ModalTitleSpan>
        </ModalTitle>
        <ModalIconContainer>
          <CustomTooltip
            placement={CommonString.top_placement}
            title={CommonTitle.close}
            name={
              <ModalIcon
                icon={CommonIcon.modal_close}
                onClick={props.onClose}
              />
            }
          />
        </ModalIconContainer>
      </DeleteConfirmationFormTitle>
      <ConfirmationFormTitle>
        Do you want to make <span>{props.name}</span>{" "}
        {props.active ? "active" : "inactive"}? Please Confirm.
      </ConfirmationFormTitle>
      <ButtonGroup
        solidBtnClick={() => props.onUpdate()}
        outlinedBtnClick={props.onClose}
        solidBtnName={CommonString.confirm}
        outlinedBtnName={CommonString.cancle}
      />
    </ConfirmationAlertContainer>
  );
}
