import moment from "moment";
import { useDispatch } from "react-redux";
import JobController from "../controller/JobController";
import { updateSelectedFileName } from "../feature/store/JobReduxStore";
import { AccessKey, CommonString } from "./CommonString";
import {
  DATE_FORMATE,
  INTERVIEW_MODE,
  SUBMISSION_STATUS,
  VERTICALMENU_OPTION,
  VERTICALMENU_OPTION_WITHOUT_DELETE,
  VERTICAL_MENU_OPTION_WITHOUT_UPDATE,
} from "./constant";
import {
  ADD_APPLICANTS,
  ADD_CLIENT,
  ADD_JOBS,
  ADD_LEVEL,
  ADD_PARTNER,
  ADD_RECRUITERS,
  LOCK_APPLICANTS,
  SELECTE_PARTNER,
  UPDATE_APPLICANTS,
  UPDATE_CLIENT,
  UPDATE_JOBS,
  UPDATE_LEVEL,
  UPDATE_PARTNER,
  UPDATE_RECRUITERS,
  UPDATE_SUBMISSION,
  VIEW_APPLICANTS,
  VIEW_CLIENT,
  VIEW_JOBS,
  VIEW_LEVEL,
  VIEW_PARTNER,
  VIEW_RECRUITERS,
} from "./enum";
import { formatName } from "./validation";
import { CommonColor } from "./color";
import { FlexContainer } from "../style/job.style";
export function onCheckAccessibilityForDashboard(data) {
  if (data == null) {
    return false;
  }
  var level = data.level.value;
  if (level <= 3 || level === "100") {
    return true;
  }
  return false;
}
export function onCheckForSeniorRecruiter() {
  var user = localStorage.getItem("USER");
  if (user == null) {
    return true;
  }
  var level = JSON.parse(user).level.value;
  if (level === 4) {
    return true;
  }
  return false;
}
export function onCheckLevelAccessibility() {
  var user = localStorage.getItem("USER");
  if (user == null) {
    return false;
  }
  var level = JSON.parse(user).level.value;
  if (level <= 3 || level === 100) {
    return true;
  }
  return false;
}
export function onCheckLevelAccessibilityForAdmin() {
  var user = localStorage.getItem("USER");
  if (user == null) {
    return false;
  }
  var level = JSON.parse(user).level.value;
  if (level === 100) {
    return true;
  }
  return false;
}
export function onCheckLevelAccessibilityForDashboard() {
  var user = localStorage.getItem("USER");
  // if (user == null) {
  //   return true;
  // }
  var level = JSON.parse(user).level.value;
  if (level === 1 || level === 100) {
    return false;
  }
  return true;
}
export const jobStatusColor = {
  OPEN: {
    border: "0.5px solid #5BAA66",
    backgroundColor: "rgb(204 238 204)",
    color: "#0f9521",
    filterColor: "#5BAA66",
  },
  CLOSED: {
    backgroundColor: "rgb(255 220 221)",
    border: "0.5px solid #EB7679",
    color: "rgb(169 0 4)",
    filterColor: "#EB7679",
  },
  "ON HOLD": {
    backgroundColor: "rgb(241 228 136)",
    border: "0.5px solid #E0CF58",
    color: "rgb(144 134 65)",
    filterColor: "rgb(144 134 65)",
  },
  IGNORED: {
    backgroundColor: "rgb(205 242 255)",
    border: "0.5px solid rgb(80 168 199)",
    color: "rgb(80 168 199)",
    filterColor: "rgb(80 168 199)",
  },
  COVERED: {
    backgroundColor: "rgb(255 219 195)",
    border: "0.5px solid rgb(255, 102, 0)",
    color: "rgb(255, 102, 0)",
    filterColor: "rgb(255, 102, 0)",
  },
  ALL: {
    backgroundColor: "#14213020",
    filterColor: CommonColor.primary_color,
    color: CommonColor.primary_color,
  },
  ACTIVE: {
    backgroundColor: "#14213020",
    filterColor: CommonColor.primary_color,
    color: CommonColor.primary_color,
  },
  1: {
    border: "0.5px solid #5BAA66",
    backgroundColor: "rgb(204 238 204)",
    color: "#0f9521",
    filterColor: "#5BAA66",
  },
  0: {
    backgroundColor: "rgb(255 219 195)",
    border: "0.5px solid rgb(255, 102, 0)",
    color: "rgb(255, 102, 0)",
    filterColor: "rgb(255, 102, 0)",
  },
};

export const jobModalStatus = {
  "View Job": VIEW_JOBS,
  "Add Job": ADD_JOBS,
  "Update Job": UPDATE_JOBS,
  "Update Submission": UPDATE_SUBMISSION,
};
export function levelModalStatus(modalStatus, selectedData) {
  if (modalStatus === VIEW_LEVEL || modalStatus === UPDATE_LEVEL) {
    return selectedData.name;
  }
  return ADD_LEVEL;
}
export const RecruiterGroupModalStatus = {
  "View Recruiter Group": "View Level",
  "Add Recruiter Group": "Add Recruiter Group",
  "Update Recruiter Group": "Update Recruiter Group",
};

export function recruiterModalStatus(modalStatus, selectedData) {
  if (modalStatus === VIEW_RECRUITERS || modalStatus === UPDATE_RECRUITERS) {
    return selectedData.name;
  }
  return ADD_RECRUITERS;
}

export function partnerModalStatus(modalStatus, selectedData) {
  if (modalStatus === VIEW_PARTNER || modalStatus === UPDATE_PARTNER) {
    return selectedData.name;
  }
  return ADD_PARTNER;
}
export function clientModalStatus(modalStatus, selectedData) {
  if (modalStatus === VIEW_CLIENT || modalStatus === UPDATE_CLIENT) {
    return selectedData.name;
  }
  return ADD_CLIENT;
}

export const applicantModalStatus = {
  "View Applicant": VIEW_APPLICANTS,
  "Add Applicant": ADD_APPLICANTS,
  "Update Applicant": UPDATE_APPLICANTS,
  "Lock Applicant": LOCK_APPLICANTS,
};

export const applicantAddPartnerModalStatus = {
  "View Job": VIEW_JOBS,
  "Select Partner": SELECTE_PARTNER,
};

export function copyText(event, text) {
  event.stopPropagation();
  navigator.clipboard.writeText(text);
}
export const CallTrackerStatusColor = {
  COMPLETED: {
    color: "#0f9521",
  },
  MISSED: {
    color: "rgb(239 83 87)",
  },
  PENDING: {
    color: "rgb(255, 156, 0)",
  },
  IGNORED: {
    color: "rgb(80 168 199)",
  },
  undefined: {
    color: CommonColor.primary_color,
  },
};
export const CallTrackerStatus = {
  PENDING: "carbon:pending",
  COMPLETED: "lucide:check-circle",
  MISSED: "gg:close-o",
};
export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

export function checkMinusValue(value) {
  if (value < 0 || isNaN(value) || value == null) {
    return 0;
  }
  return value.toFixed(2);
}
export function getJobLocation(e) {
  if (e === "") {
    return "NA";
  } else if (e.split(",") !== undefined) {
    var FullLocation = e.split(",");
    var FullLocationReverse = FullLocation.reverse();
    var SortLocation = FullLocationReverse[1] + "," + FullLocationReverse[0];
    return SortLocation;
  }
  return "NA";
}
export function getDepartment(e) {
  if (e === "") {
    return "NA";
  }
  var str = e;
  if (str.length >= 1 && e.search(/\(([^)]+)\)/) === -1) {
    var matches = str.match(/\b(\w)/g);
    if (matches.length > 1) {
      var acronym = matches.join("");
      return acronym.toUpperCase();
    }
    return str;
  } else if (e.split("(") !== undefined) {
    var FullStr = e.split("(");
    var SortStr = FullStr.reverse()[0];
    var FinalStr = SortStr.replace(")", "");
    return FinalStr;
  }
}

export async function getJobAttachmentByID(e) {
  const jobController = new JobController();
  const dispatch = new useDispatch();
  var data = {
    job_id: e,
  };
  var res = await jobController.getAttachmentByID(data);
  dispatch(updateSelectedFileName(res.data.data));
}

export function getDateFormat(date) {
  if (!date) return CommonString.open;
  return moment(date).format(DATE_FORMATE);
}
export function getTimeFormat(date) {
  if (!date) return CommonString.open;
  return moment(date).format("hh:mm");
}
export function getFormTitle(modalStatus, selectedData, isAddApplicant) {
  if (
    (modalStatus === VIEW_APPLICANTS ||
      modalStatus === UPDATE_APPLICANTS ||
      modalStatus === LOCK_APPLICANTS) &&
    !isAddApplicant
  ) {
    return `${
      selectedData.first_name +
      " " +
      selectedData.middle_name +
      " " +
      selectedData.last_name
    }${selectedData.designation ? "-" : ""}${selectedData.designation}`;
  }
  return applicantModalStatus[isAddApplicant ? ADD_APPLICANTS : modalStatus];
}
export function getFormTitleForSubmission(selectedData) {
  if (selectedData === null) return "";
  return `${
    formatName(selectedData?.full_name) +
    " || " +
    selectedData?.parentJob?.position_title +
    " - " +
    selectedData?.parentJob?.client_position_id
  }`;
}
export function getClientName(e) {
  if (e === undefined) return "NA";
  var FullStr = e.split("(");
  var SortStr = FullStr.reverse()[0];
  var FinalStr = SortStr.replace(")", "");
  return FinalStr;
}
export function getRecruiterName(e) {
  if (e.name === undefined) return;
  return e.name.substring(0, e.name.indexOf(" "));
}
export function getCapitalizeText(text) {
  //   'String capitalizeFirstLetterr(String text) {
  //     var capitalized;
  //     if (text.length <= 1) return text.toUpperCase();
  //     var words = text.split(' ');

  // capitalized = words.map((word) {
  //   var first = word.substring(0, 1).toUpperCase();
  //   var rest = word.substring(1);
  //   return '$first$rest';
  // });

  // return capitalized.join(' ');
  //   }'
  if (text === undefined) return;
  const words = text.trim().toLowerCase();
  if (words[0] === undefined || words === undefined) return;
  // for (let i = 0; i < words.length; i++) {
  //   // console.log(words[i][0]);
  //   if (words[i][0] === undefined) return;
  //   words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  // }

  return words[0].toUpperCase() + words.substring(1);
}
export function IsOpenAlertBox(status) {
  if (
    status === SUBMISSION_STATUS.LOCKED ||
    status === SUBMISSION_STATUS.OFFER ||
    status === SUBMISSION_STATUS.PLACEMENT ||
    status === SUBMISSION_STATUS.INTERVIEW ||
    status === SUBMISSION_STATUS.PROJECT_COMPLETED ||
    status === SUBMISSION_STATUS.DECLINED_BY_CLIENT ||
    status === SUBMISSION_STATUS.DECLINED_BY_CONSULTANT ||
    status === SUBMISSION_STATUS.TERMINATED ||
    status === SUBMISSION_STATUS.RESIGNED
  ) {
    return false;
  }
  return true;
}
export function getStatusName(data) {
  if (data === CommonString.running_placement) {
    return "PLACEMENT";
  }
  if (data === CommonString.interview) {
    return "INTERVIEW";
  }
  if (data === CommonString.offer) {
    return "OFFER";
  }
}
export function getClickableAccess(data) {
  if (data === CommonString.running_placement) {
    return true;
  }
  if (data === CommonString.interview) {
    return true;
  }
  if (data === CommonString.offer) {
    return true;
  }
  return false;
}
export function getFieldNameByInterviewModeInInterview(name) {
  if (name === INTERVIEW_MODE[0]) {
    return "Web Link";
  }
  if (name === INTERVIEW_MODE[1] || name === "Phone") {
    return "Phone No.";
  }
  if (name === INTERVIEW_MODE[2]) {
    return "Address";
  }
  return "Web Link";
}
export async function onCheckAdminAccessibility() {
  var user = localStorage.getItem("USER") || "{}";
  var level = JSON.parse(user)?.level?.value;
  if (level === 100) {
    return true;
  }
  return false;
}
export function getAccessVisibility(data) {
  var user = localStorage.getItem("USER") || "{}";
  var user_id = JSON.parse(user)?.user?._id;
  if (user_id === undefined) return;
  const findKeys = (obj, fn) =>
    Object?.keys(obj)?.filter((key) => fn(obj[key], key, obj));
  const found_access = findKeys(data, (x) => x === true);
  return found_access;
}
export function getAccess(array, value) {
  if (array.find((e) => e === value)) {
    return true;
  }
  return false;
}
export function getAccessForJob(array) {
  if (array === undefined) return;
  var arr = [];
  // if (array.includes("job_create")) {
  //   arr.push("Add");
  // }
  if (array.includes("job_update")) {
    arr.push("Edit");
  }
  if (array.includes("job_update")) {
    arr.push("Clone");
  }
  if (array.includes("job_delete")) {
    arr.push("Delete");
  }
  return arr;
}
export function getAccessForClient(array) {
  if (array === undefined) return;
  var arr = [];
  // if (array.includes("client_create")) {
  //   arr.push("Add");
  // }
  if (array.includes("client_update")) {
    arr.push("Edit");
  }
  if (array.includes("client_delete")) {
    arr.push("Delete");
  }
  return arr;
}
export function getVerticalMenu() {
  var user = localStorage.getItem("USER") || "{}";
  var level = JSON.parse(user)?.level?.value;
  if (level === 100) {
    return VERTICALMENU_OPTION;
  }
  return VERTICALMENU_OPTION_WITHOUT_DELETE;
}
export function getAccessForRecruiter() {
  var user = localStorage.getItem("USER") || "{}";
  var level = JSON.parse(user)?.level?.value;
  if (level === 100) {
    return VERTICALMENU_OPTION;
  }
  return VERTICAL_MENU_OPTION_WITHOUT_UPDATE;
}
export function getJobTableList(data) {
  const findKeys = (obj, fn) =>
    Object?.keys(obj)?.filter((key) => fn(obj[key], key, obj));
  const found_access = findKeys(data, (x) => x === true);
  return found_access;
}
export function getRecruiters(data) {
  if (data === undefined) return "";
  if (data.recruiter === null) {
    if (data?.Recruiter_list?.length === 0) {
      return "Open";
    }
    return (
      <FlexContainer>
        {data?.Recruiter_list?.map((singleData, index) => (
          <div>
            {singleData?.recruiter?.name}{" "}
            {index + 1 === data?.Recruiter_list?.length ? "" : ","}
          </div>
        ))}
      </FlexContainer>
    );
  }
  return data?.recruiter?.name ?? "NA";
}
export function convertTo12HourFormat(time24) {
  if (time24 === undefined || time24 === null) return;
  // Split the time into hours and minutes
  let [hours, minutes] = time24.split(":").map(Number);

  // Determine AM or PM
  const amOrPm = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12 || 12; // Converts '0' hours to '12' (for midnight)

  // Return the formatted time
  return `${hours}:${minutes < 10 ? "0" : ""}${minutes} ${amOrPm}`;
}
export   function getValueForAccessRights(data) {
  if (data === "please_submit_update") {
    return AccessKey.please_submit_update;
  }
  if (data === "reject_internally_update") {
    return AccessKey.reject_internally_update;
  }
  if (data === "hold_internally_update") {
    return AccessKey.hold_internally_update;
  }
  if (data === "job_status_update") {
    return AccessKey.job_status_update;
  }
  if (data === "job_create") {
    return AccessKey.job_create;
  }
  if (data === "job_update") {
    return AccessKey.job_update;
  }
  if (data === "job_delete") {
    return AccessKey.job_delete;
  }
  if (data === "job_status_update") {
    return AccessKey.job_status_update;
  }
  if (data === "add_partner_in_job") {
    return AccessKey.add_partner_in_job;
  }
  if (data === "applicant_create") {
    return AccessKey.applicant_create;
  }
  if (data === "applicant_update") {
    return AccessKey.applicant_update;
  }
  if (data === "close_applicant") {
    return AccessKey.close_applicant;
  }
  if (data === "applicant_delete") {
    return AccessKey.applicant_delete;
  }
  if (data === "client_create") {
    return AccessKey.client_create;
  }
  if (data === "client_update") {
    return AccessKey.client_update;
  }
  if (data === "client_delete") {
    return AccessKey.client_delete;
  }
  if (data === "view_review") {
    return AccessKey.view_review;
  }
  if (data === "review_create") {
    return AccessKey.review_create;
  }
  if (data === "review_update") {
    return AccessKey.review_update;
  }
  if (data === "review_delete") {
    return AccessKey.review_delete;
  }
  if (data === "submission_create") {
    return AccessKey.submission_create;
  }
  if (data === "submission_update") {
    return AccessKey.submission_update;
  }
  if (data === "submission_delete") {
    return AccessKey.submission_delete;
  }
  if (data === "offer_create") {
    return AccessKey.offer_create;
  }
  if (data === "offer_update") {
    return AccessKey.offer_update;
  }
  if (data === "offer_delete") {
    return AccessKey.offer_delete;
  }
  if (data === "placement_create") {
    return AccessKey.placement_create;
  }
  if (data === "placement_update") {
    return AccessKey.placement_update;
  }
  if (data === "placement_delete") {
    return AccessKey.placement_delete;
  }
  return data;
}