import styled from "styled-components";
import { Icon } from "@iconify/react";
import { CommonColor } from "../Utils/color";
import { CommonString } from "../Utils/CommonString";

const LogoutButtonIcon = styled(Icon)`
  margin-right: 10px;
`;

const DeleteIcon = styled(Icon)`
  color: ${CommonColor.light_grey};
  display: ${(props) =>
    !props.isDahsboard && props.isVisible ? "block" : "none"};
  width: 16px;
  height: 16px;
  &:hover {
    color: ${CommonColor.grey};
  }
`;

const FilterIcon = styled(Icon)`
  font-size: 15px;
  color: ${CommonColor.th_text_color};
  // margin-top: 4px;
  // margin-left: 2px;
  @media ${CommonString.mobile} {
    font-size: 5px;
  }
`;

const PdfCloseIcon = styled(Icon)`
  width: 36px;
  height: 36px;
  padding: 6px;
  margin: 20px 15px;
  cursor: pointer;
  position: absolute !important;
  right: 25px !important;
  background-color: #253f50;
  color: white;
  border-radius: 50%;
  z-index: 99999;
  &:hover {
    border-radius: 50%;
    background-color: #14213030;
  }
`;

const SubmissionIcon = styled(Icon)`
  height: 15px;
  width: 15px;
  margin: 0px 2px;
  color: ${(props) => props.color};
`;

const IconInTextIcon = styled(Icon)`
  padding: 0px;
  width: 0.9vmax;
  height: 0.9vmax;
`;

const CustomIcon = styled(Icon)`
  height: 20px;
  width: 20px;
  // margin-right: 15px;
`;

const AddButtonIcon = styled(Icon)`
  // width: 20px;
`;
const ModalIcon = styled(Icon)`
  width: 36px;
  height: 36px;
  padding: 6px;
  margin: 0 4px;
  cursor: pointer !important;
  &:hover {
    border-radius: 50%;
    background-color: #14213030;
  }
`;
const CopyIcon = styled(Icon)`
  margin-left: 5px;
  cursor: pointer;
`;
const IconTab = styled(Icon)`
  cursor: pointer;
  margin: 0 10px;
  border-radius: 50%;
  padding: 4px;
  width: 18px;
  height: 18px;
`;
const TableIcon = styled(Icon)`
  cursor: pointer;
  margin-right: 7px;
  width: 15px;
  height: 15px;
`;
export {
  TableIcon,
  IconTab,
  CopyIcon,
  ModalIcon,
  AddButtonIcon,
  FilterIcon,
  LogoutButtonIcon,
  DeleteIcon,
  PdfCloseIcon,
  SubmissionIcon,
  IconInTextIcon,
  CustomIcon,
};
