import React, { useEffect } from "react";
import {
  CustomTableContainer,
  JobFirstColumn,
  Table,
  TableRow,
  TableTbody,
  TableTd,
  TableTh,
} from "../../../style/table.styled";
import CustomizedTooltip from "../CustomizedTooltip";
import {
  getAccessForJob,
  getClientName,
  jobStatusColor,
} from "../../../Utils/utilitiFunction";
import CopyTextComponent from "../CopyTextComponent";
import {
  CopyTextMainContainer,
  TableNoDataContainer,
} from "../../../style/common.style";
import { Job_th_list } from "../../../Utils/constant";
import { UPDATE_JOBS } from "../../../Utils/enum";
import NoData from "../../../assets/images/nodata.png";
import VerticalMenu from "../VerticalMenu";
import JobCustomController from "../../../controller/JobCustomController";

export default function MultipleJobTable(props) {
  const {
    getDate,
    getMultipleRecruiterData,
    filter_data,
    onClickOnData,
    isVisible,
    onViewModal,
    getVerticalMenuVisibility,
    multiple_recruiter_job_list,
    checkBlankArray,
    access_data_by_user_id,
    onDelete,
  } = JobCustomController();

  useEffect(() => {
    getMultipleRecruiterData(filter_data);
  }, []);

  return (
    <>
      <CustomTableContainer height={"80vh"}>
        <Table>
          <TableTbody>
            <TableRow>
              {Job_th_list.map((data) => (
                <TableTh isVisible={!isVisible(data)}>{data}</TableTh>
              ))}
            </TableRow>
            {multiple_recruiter_job_list?.map((singleData, index) => {
              var data = singleData?.child_job;
              return (
                <TableRow
                  key={index + 1}
                  onDoubleClick={() => onClickOnData(singleData)}
                  onClick={() =>
                    window.innerWidth < 500 ? onClickOnData(singleData) : null
                  }
                >
                  <TableTd isVisible={isVisible("status")}>
                    <JobFirstColumn
                      style={{
                        color: jobStatusColor[data.status]?.color,
                        background:
                          jobStatusColor[data.status]?.backgroundColor,
                      }}
                    >
                      {data.status}
                    </JobFirstColumn>
                  </TableTd>
                  <TableTd isVisible={isVisible("due_date")}>
                    {getDate(data)}
                  </TableTd>
                  <TableTd maxHeight={true} isVisible={isVisible("job_title")}>
                    <CopyTextMainContainer>
                      <CustomizedTooltip
                        name={data.job_detail.position_title}
                        title={data.job_detail.position_title}
                      />
                      <CopyTextComponent
                        value={data.job_detail.position_title}
                        id={"job_title"}
                      />
                    </CopyTextMainContainer>
                  </TableTd>
                  <TableTd isVisible={isVisible("job_id")}>
                    {data.job_detail.client_position_id === "" ? (
                      "NA"
                    ) : (
                      <CopyTextMainContainer>
                        <CustomizedTooltip
                          name={data.job_detail.client_position_id}
                          title={data.job_detail.client_position_id}
                        />
                        <CopyTextComponent
                          value={data.job_detail.client_position_id}
                          id={"job_id"}
                        />
                      </CopyTextMainContainer>
                    )}
                  </TableTd>
                  <TableTd isVisible={isVisible("client")}>
                    <CustomizedTooltip
                      name={getClientName(data.clients.name)}
                      title={getClientName(data.clients.name)}
                    />
                  </TableTd>
                  <TableTd isVisible={isVisible("max_sub")} isCenter={true}>
                    {data.max_submission === "" ? "NA" : data.max_submission}
                  </TableTd>
                  <TableTd isVisible={isVisible("sub_made")} isCenter={true}>
                    {data.no_of_submission}
                  </TableTd>
                  <TableTd isVisible={isVisible("partner")}>
                    <CustomizedTooltip
                      name={getClientName(data.partner.name)}
                      title={getClientName(data.partner.name)}
                    />
                  </TableTd>
                  <TableTd isVisible={isVisible("recruiter")}>
                    {singleData?.recruiter?.name.split(" ")[0] ?? "Open"}
                  </TableTd>
                  <TableTd onClick={(event) => event.stopPropagation()}>
                    <VerticalMenu
                      data={getAccessForJob(access_data_by_user_id)}
                      onClone={() => onViewModal(data, "Clone Job")}
                      onEdit={() => onViewModal(data, UPDATE_JOBS)}
                      onDelete={() => onDelete(data)}
                    />
                  </TableTd>
                </TableRow>
              );
            })}
          </TableTbody>
        </Table>
        <TableNoDataContainer isVisible={checkBlankArray()}>
          <img src={NoData} alt="" />
        </TableNoDataContainer>
      </CustomTableContainer>
    </>
  );
}
