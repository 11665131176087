import { Grid } from "@material-ui/core";
import React from "react";
import { formatPhoneNumber } from "../../services/Validate";
import { POCTitle, ViewDetailContainer } from "../../style/common.style";
import {
  JobtableForbillrate,
  JobtableForbillratetd,
  JobtableForbillrateTh,
  Table,
  TableTbody,
  TableTd,
  TableTh,
} from "../../style/table.styled";
import { CommonString, CommonStringForClient } from "../../Utils/CommonString";
import { TAXTERM_TH } from "../../Utils/constant";
import { TableRow } from "../DataTable/StyledDataTable";
import RowText from "./view_component/RowText";

export default function ClientViewDetails(props) {
  return (
    <ViewDetailContainer>
      <Grid container>
        <Grid item xs={12} md={6}>
          <ViewDetailContainer>
            <RowText title={CommonString.name} value={props.data.name} />
            <RowText
              title={CommonString.phone}
              value={formatPhoneNumber(props.data.phone)}
            />
            <RowText
              title={CommonStringForClient.domain}
              value={props.data.domain}
            />
            <RowText
              nodivider={true}
              title={CommonStringForClient.location}
              value={props.data.location}
            />
            <POCTitle isDisplayNone={JSON.parse(props.data.poc).length === 0}>
              POC Detail
            </POCTitle>
            <Table isDisplayNone={JSON.parse(props.data.poc).length === 0}>
              <TableTbody>
                <TableRow>
                  <TableTh textAlignLeft={true}>Name</TableTh>
                  <TableTh>Email</TableTh>
                  <TableTh>Phone</TableTh>
                  <TableTh textAlignLeft={true}>Designation</TableTh>
                </TableRow>
                {props.data.poc === undefined
                  ? ""
                  : JSON.parse(props.data.poc).map((data, index) => (
                      <>
                        <TableRow key={index}>
                          <TableTd textAlignLeft={true}>
                            {data.poc_name}
                          </TableTd>
                          <TableTd>{data.poc_email}</TableTd>
                          <TableTd>{data.poc_phone}</TableTd>
                          <TableTd>{data.poc_designation}</TableTd>
                        </TableRow>
                      </>
                    ))}
              </TableTbody>
            </Table>
          </ViewDetailContainer>
        </Grid>
        <Grid item xs={12} md={6}>
          <div>
            <JobtableForbillrate>
              <tbody>
                <tr>
                  {TAXTERM_TH.map((data) => (
                    <JobtableForbillrateTh key={data}>
                      {data}
                    </JobtableForbillrateTh>
                  ))}
                </tr>
                {JSON.parse(props.data.partner_id).map((item) => (
                  <tr key={item.name}>
                    <JobtableForbillratetd>{item.name}</JobtableForbillratetd>
                    <JobtableForbillratetd>
                      {item.margin}
                      {item.is_in_percentage === true
                        ? CommonString.percentage
                        : CommonString.dollar}
                    </JobtableForbillratetd>
                    <JobtableForbillratetd>{item._1099}</JobtableForbillratetd>
                    <JobtableForbillratetd>
                      {item.w2_with_benifit}
                    </JobtableForbillratetd>
                    <JobtableForbillratetd>
                      {item.w2_without_benifit}
                    </JobtableForbillratetd>
                    <JobtableForbillratetd>
                      {item.w2_salarized}
                    </JobtableForbillratetd>
                  </tr>
                ))}
              </tbody>
            </JobtableForbillrate>
          </div>
        </Grid>
      </Grid>
    </ViewDetailContainer>
  );
}
