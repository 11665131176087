import styled from "styled-components";
import { CommonColor } from "../Utils/color";

const PocDetailLastcol = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 30px;
    height: 21px;
    margin-left: 5px;
    margin-top: 10px;
    padding: 5px;
    cursor: pointer;
    &:hover {
      background: ${CommonColor.light_grey};
      border-radius: 50%;
    }
  }
`;

export { PocDetailLastcol };
