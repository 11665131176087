import React, { useState } from "react";
import RecruiterGroupDataTable from "../DataTable/RecruiterGroupDataTable";
import TopMenu from "../Menu/TopMenu";
import { Container, MainScreen, TopFixedMenu } from "./StyledView";
import {SEARCH_RECUITER_GROUP} from "../../Utils/enum"

export default function RecruiterGroup() {
  const [userinfo, setUserInfo] = useState({
    languages: [],
    response: [],
  });

  const handleChange = (e) => {
    const { value, checked } = e.target;
    const { languages } = userinfo;
    if (checked) {
      setUserInfo({
        languages: [...languages, value],
        response: [...languages, value],
      });
    }
    else {
      setUserInfo({
        languages: languages.filter((e) => e !== value),
        response: languages.filter((e) => e !== value),
      });
    }
  };
  return (
      <Container>
        <TopFixedMenu>
          <TopMenu
            placeholder={SEARCH_RECUITER_GROUP}
          />
        </TopFixedMenu>
        <MainScreen>
          <RecruiterGroupDataTable userinfo={userinfo} handleChange={(e) => handleChange(e)} />
        </MainScreen>
      </Container>
  );
}
