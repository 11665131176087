import { createSlice } from "@reduxjs/toolkit";
import { ADD_LEVEL } from "../../Utils/enum";

export const LevelStoreRedux = createSlice({
  name: "LevelStoreRedux",
  initialState: {
    search_text: "",
    search_result: [],
    isLoading: false,
    modal_status: ADD_LEVEL,
    select_data: "",
    is_modal_open: false,
    shift: [],
  },
  reducers: {
    onChangeShift: (state, actions) => {
      state.shift = actions.payload;
    },
    onChangeSearchText: (state, actions) => {
      state.search_text = actions.payload;
    },
    onChangeSearchResult: (state, actions) => {
      state.search_result = actions.payload;
    },
    onChangeIsLoading: (state, actions) => {
      state.isLoading = actions.payload;
    },
    onChangeModalStatus: (state, actions) => {
      state.modal_status = actions.payload;
    },
    onChangeSelectData: (state, actions) => {
      state.select_data = actions.payload;
    },
    onChangeIsModalOpen: (state, actions) => {
      state.is_modal_open = actions.payload;
    },
  },
});

export const {
  onChangeShift,
  onChangeSearchText,
  onChangeSearchResult,
  onChangeIsLoading,
  onChangeModalStatus,
  onChangeSelectData,
  onChangeIsModalOpen,
} = LevelStoreRedux.actions;

export default LevelStoreRedux.reducer;
