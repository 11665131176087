import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";
import { vapidKey } from "../Utils/constant";

const firebaseConfig = {
  apiKey: "AIzaSyA3o978joJXkV64hFO6RFAB9yHex9bERQk",
  authDomain: "prexel-attendance.firebaseapp.com",
  projectId: "prexel-attendance",
  storageBucket: "prexel-attendance.appspot.com",
  messagingSenderId: "383041096350",
  appId: "1:383041096350:web:240ac1865166447edd498f",
  measurementId: "G-7PWSGHXQMJ",
};

let firebaseApp;

try {
  firebaseApp = initializeApp(firebaseConfig);
} catch (e) {
  console.error(e);
}

const messaging = getMessaging(firebaseApp);

export { firebaseApp, messaging, getTokenFromDevice };

function getTokenFromDevice() {
  // console.log("messaging", messaging);
  return getToken(messaging, {
    vapidKey: vapidKey,
  })
    .then((currentToken) => {
      // console.log("token====>", currentToken);
      if (currentToken) {
        return currentToken;
      } else {
        console.error(
          "No registration token available. Request permission to generate one."
        );
        return false;
      }
    })
    .catch((err) => {
      console.error("An error occurred while retrieving token. ", err);
      return false;
    });
}
