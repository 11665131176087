import React, { useEffect } from "react";
import InternalDomainTeamSearch from "./InternalDomainTeamSearch";
import RecruiterInternalDomainTeamTile from "./RecruiterInternalDomainTeamTile";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { Checkbox } from "semantic-ui-react";
import { FlexContainer, SubTitle } from "../../style/dice.style";
import { onGetInternalTeamSearchResult } from "../../feature/store/UserStore";

export default function MemberListInInternalDomainTeam() {
  // const dispatch = useDispatch();

  const domain_selected_team = useSelector(
    (state) => state.UserStore.domain_selected_team
  );

  useEffect(() => {
    // dispatch(onGetInternalTeamSearchResult(recruiter_list));
  }, []);
  // function getIsChecked(data, checked) {
  //   console.log(checked, data);
  //   var filteredData=internal_team_search_result.includes()
  // }
  return (
    <div>
      {/* <InternalDomainTeamSearch /> */}
      <SubTitle>
        <b>{domain_selected_team.domain}</b>
      </SubTitle>
      <span>Total Members {domain_selected_team.team?.length}</span>
      <hr />
      <Grid container className="mt-4" spacing={2}>
        {domain_selected_team.team.map((data) => (
          <Grid item md={6}>
            <FlexContainer style={{ alignItems: "center" }}>
              <RecruiterInternalDomainTeamTile data={data} />
              {/* <Checkbox
                onChange={(e, { checked }) => getIsChecked(data, checked)}
              /> */}
            </FlexContainer>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
