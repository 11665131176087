import React, { useEffect } from "react";
import TableToolbar from "./TableToolbar";
import AddLevel from "../Modal/AddLevel";
import BaseModal from "../Modal/BaseModal";
import {
  LEVEL,
  ADD_LEVEL,
  UPDATE_LEVEL,
  VIEW_LEVEL,
  LEVELS,
} from "../../Utils/enum";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DeleteConfirmation from "../Modal/DeleteConfirmation";
import LevelViewDetails from "../ViewDetails/LevelViewDetails";
import LevelController from "../../controller/LevelController";
import LevelTable from "./Table/LevelTable";
import { levelModalStatus } from "../../Utils/utilitiFunction";
import { useDispatch, useSelector } from "react-redux";
import { onChangeIsModalOpen } from "../../feature/store/LevelReduxStore";
import { TableMainContainer } from "../../style/table.styled";

function LevelDataTable(props) {
  const dispatch = useDispatch();
  var levelController = new LevelController();
  const tlevelList = useSelector((state) => state.counter.levelData);
  const modal_status = useSelector(
    (state) => state.LevelStoreRedux.modal_status
  );
  const select_data = useSelector((state) => state.LevelStoreRedux.select_data);
  const is_modal_open = useSelector(
    (state) => state.LevelStoreRedux.is_modal_open
  );
  const isLoading = useSelector((state) => state.LevelStoreRedux.isLoading);

  useEffect(() => {
    getData();
  });

  function getData() {
    if (props.searchText != null && props.searchText.length > 0) {
      return props.searchResult;
    }
    return tlevelList;
  }

  function onDelete(data) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <DeleteConfirmation
            isLoading={isLoading}
            name={data.name}
            onClose={onClose}
            onDelete={() => {
              levelController.deleteLevels(data);
              dispatch(onChangeIsModalOpen(true));
              onClose();
              dispatch(onChangeIsModalOpen(false));
            }}
          />
        );
      },
    });
  }

  return (
    <>
      <TableToolbar
        isloading={props.isLoading}
        title={LEVELS}
        buttonName={LEVEL}
        isVisible={true}
        onClick={() => levelController.onViewModal(ADD_LEVEL, null)}
      />
      <BaseModal
        mystyle={{ width: window.innerWidth > 500 ? "40%" : "90%" }}
        isUpdate={modal_status === UPDATE_LEVEL}
        isLoading={isLoading}
        isView={modal_status === VIEW_LEVEL ? true : false}
        editClose={() => levelController.onViewModal(UPDATE_LEVEL, select_data)}
        deleteClose={() => onDelete(select_data)}
        open={is_modal_open}
        formTitle={levelModalStatus(modal_status, select_data)}
        handleClose={() => dispatch(onChangeIsModalOpen(!is_modal_open))}
      >
        {modal_status === VIEW_LEVEL ? (
          <LevelViewDetails data={select_data} />
        ) : (
          <AddLevel
            isUpdate={modal_status === UPDATE_LEVEL}
            data={select_data}
            onSubmitData={levelController.onSubmitData}
          />
        )}
      </BaseModal>
      <TableMainContainer>
        <LevelTable
          isLoading={props.isLoading}
          onDelete={onDelete}
          levelList={getData()}
        />
      </TableMainContainer>
    </>
  );
}
export default LevelDataTable;
