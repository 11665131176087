import React from "react";
import { FlexContainer } from "../../style/dice.style";
import { ProfileImg, TeamFlexContainer } from "../../style/recruiter.style";
import { PROFILE_BASEURL } from "../../Utils/constant";

export default function RecruiterInternalDomainTeamTile({ data }) {
  return (
    <FlexContainer>
      <TeamFlexContainer key={data}>
        <ProfileImg
          src={
            data.image_url === undefined
              ? PROFILE_BASEURL + data.profile_image_url
              : PROFILE_BASEURL + data.image_url
          }
        />
        <div>
          <p>{data.name}</p>
          <span>{data.employee_id}</span>
        </div>
      </TeamFlexContainer>
    </FlexContainer>
  );
}
