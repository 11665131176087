import styled from "styled-components";
import { CommonColor } from "../Utils/color";
import { Grid, TableContainer } from "@mui/material";
import { Icon } from "@iconify/react";
import { CommonString } from "../Utils/CommonString";

const CustomTableContainer = styled(TableContainer)`
  width: 100%;
  // border: 1px solid ${CommonColor.table_heading_color} !important;
  // border-radius: 5px;
  height: ${(props) => (props.height !== undefined ? props.height : "84vh")};
  background-color: ${CommonColor.white};
  @media ${CommonString.mobile} {
    // width: 48.8vh !important;
    overflow: auto;
  }
`;

const Table = styled.table`
  display: ${(props) => (props.isDisplayNone ? "none" : "table")};
  border-collapse: collapse;
  width: 100%;
  border-radius: 10;
  border-bottom: none;
  // border-top: 1px solid ${CommonColor.tablerow_odd_color} !important;
  border: 1px solid black !important;
  position: relative;
  tr:first-child {
    position: relative;
    top: -0.2px;
    // z-index: 2;
  }
`;

const TableRow = styled.tr`
  text-align: left;
  border: none;
  th {
    font-size: 10px !important;
  }
  td {
    /* font-size: 0.74rem !important; */
  }
  &:first-of-type {
    // min-width: 45px;
  }
  &:hover {
    background-color: ${(props) =>
      props.isActiveDate
        ? CommonColor.bg_yellow + "!important"
        : CommonColor.tablerow_even_color + "!important"};
  }
`;

const TableTh = styled.th`
  width: ${(props) => props.width ?? "auto"};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  display: ${(props) => (props.isVisible ? "none" : "table-cell")};
  border: 0.5px solid ${CommonColor.table_border} !important;
  vertical-align: inherit;
  font-weight: bold;
  padding: 5px 10px;
  color: ${CommonColor.th_text_color} !important;
  background: ${CommonColor.table_heading_color};
  text-align: ${(props) => props.align};
  white-space: nowrap;
  position: sticky;
  top: -0.4px !important;
  z-index: 1;
  @media ${CommonString.mobile} {
    display: none;
    font-size: 7px;
    padding: 5px !important;
    &:first-child {
      display: table-cell;
    }
    &:nth-child(2) {
      display: table-cell;
    }
    &:nth-child(3) {
      display: table-cell;
    }
    &:last-child {
      display: table-cell;
    }
  }
`;

const TableTd = styled.td`
  /* max-width: 100px; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
  /* white-space: nowrap; */
  display: ${(props) => (props.isVisible ? "none" : "table-cell")};
  text-transform: capitalize;
  border: 0.5px solid ${CommonColor.tablerow_odd_color};
  vertical-align: inherit;
  // font-weight: 600;
  padding: 5px 10px !important;
  text-align: ${(props) => (props.isCenter ? "center" : "left")};
  color: ${CommonColor.td_text_color};
  &:first-child {
    // min-width: 45px;
    text-align: ${(props) => (props.textAlignLeft ? "left" : "center")};
  }
  &:last-child {
    text-align: ${(props) => (props.textAlignLeft ? "left" : "center")};
  }
  &:hover {
    text-decoration: ${(props) => (props.onHover ? "underline" : "none")};
    color: ${(props) =>
      props.onHover ? CommonColor.secondary_color : "inherit"};
    cursor: ${(props) => (props.onHover ? "pointer" : "inherit")};
  }
  @media ${CommonString.mobile} {
    display: none;
    font-size: 6px;
    padding: 5px !important;
    &:first-child {
      display: table-cell;
    }
    &:nth-child(2) {
      display: table-cell;
    }
    &:nth-child(3) {
      display: table-cell;
    }
    &:last-child {
      display: table-cell;
    }
  }
`;

const JobtableForbillrate = styled.table`
  width: 100% !important;
  margin-top: 10px;
  input {
    width: 60px;
    border-radius: 5px;
  }
  @media ${CommonString.mobile} {
    td {
      display: table-cell;
      vertical-align: baseline;
      &:first-child span {
        padding-bottom: 0px;
        margin-top: 5px;
      }
      span {
        padding-bottom: 12px;
      }
    }
    th {
      display: table-cell;
    }
  }
`;
const JobtableForbillrateTh = styled.th`
  font-weight: normal;
  border: 0.4px solid #c4c4c4 !important;
  background-color: white !important;
  color: #7a7a7a !important;
  font-size: 11px;
  text-align: center;
`;

const JobtableForbillratetd = styled.td`
  border: 1px solid ${CommonColor.border}!important;
  padding: 4px;
  /* width: 8% !important; */
  div:first-child {
    display: ${(props) => (props.selectedRecruter > 1 ? "block" : "flex")};
    align-items: center;
    justify-content: space-between;
  }
`;
const JobtableForbillrateContainer = styled.div`
  display: flex;
  margin: 0 5px;
  align-items: center;
  position: relative;
  span {
    /* margin-left: -30px; */
    position: absolute;
    right: 8px;
    color: ${CommonColor.grey};
    /* background-color: red; */
    /* margin: -4px; */
  }
  @media ${CommonString.mobile} {
    justify-content: space-between;
    flex-direction: column-reverse;
    .firstRowInTable inpute {
      margin: 0px !important;
    }
  }
`;

const JobtableForbillrateinput = styled.input`
  /* padding: 4px 4px !important; */
  /* padding-right: 20px !important; */
  margin-right: 2px;
  border: 1px solid lightgrey !important;
  width: 100% !important;
  /* text-align: center; */
`;

const JobViewDetailGridContainer = styled(Grid)`
  border-right: 0.5px solid lightgrey;
`;

const TableTbody = styled.tbody`
  border: 1px solid ${CommonColor.primary_color} !important;
`;

const ImgNodata = styled.div`
  // width: 60%;
`;
const NoDataImg = styled.img`
  width: auto;
`;
const IsRecruiterContainer = styled(Icon)`
  font-size: 25px;
  color: ${(props) =>
    props.recruiter === 1
      ? CommonColor.recruiter_green
      : CommonColor.primary_color};
`;
const AttachmentIcon = styled(Icon)`
  font-size: 40px;
  color: ${CommonColor.primary_color};
  margin: 20px;
`;
const JobViewContainer = styled.div`
  overflow: ${(props) => (props.isOpen ? "hidden" : "auto")};
`;
const JobFirstColumn = styled.span`
  padding: 3px 10px;
  border-radius: 15px;
  margin: 3px;
  white-space: nowrap;
  margin-block-start: 0px;
  margin-block-end: 0px;
  font-size: 9px;
  margin-top: -2px;
  // box-shadow: 0 4px 8px 0 rgb(83 82 82 / 10%), 0 6px 20px 0 rgba(0, 0, 0, 0);
  // font-weight: 400;
  // display: flex !important;
  // align-items: center;
  width: fit-content;
  // line-height: 0px;
  // display: flex;
  // align-items: center;
  svg {
    margin-right: 2px;
    margin-top: -2px;
  }
`;
const DomainTag = styled.p`
  font-size: 11px;
  svg {
    margin-left: 10px;
  }
`;
const TableNameContainer = styled.div`
  display: flex;
  align-items: center !important;
  padding: 0 10px;
  height: 5vh;
`;
const TableMainContainer = styled.div`
  position: relative;
  // border-top: 1px solid ${CommonColor.tablerow_even_color};
  table {
    margin-bottom: 20px;
  }
`;
const FlexContainerForRecruiterName = styled.div`
  display: flex;
  align-items: center;
`;
const RecruiterNameImgContainer = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 10px;
  background: ${CommonColor.light_grey};
  border-radius: 50%;
`;
const RecruiterNameImg = styled.img`
  width: 30px;
  margin-right: 10px;
  border-radius: 50%;
`;
const ActiveContainer = styled.div`
  // font-size: 25px;
  border-radius: 10px;
`;
const TableName = styled.h6`
  // text-align: center;
  color: ${CommonColor.secondary_color};
  margin: 10px 0px;
`;
const TitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-left: 10px;
    width: 30px;
    height: 30px;
    padding: 7px;
    &:hover {
      background: ${CommonColor.primary_bg};
      border-radius: 50%;
      cursor: pointer;
    }
  }
`;
const FlexContainerForPartnerList = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (!props.isVisible ? "10px" : "0")};
  justify-content: space-between;
  width: 100%;
`;
const DashboardTableContainer = styled.div`
  padding: 0 10px;
  margin: 10px;
  margin-top: 0px;
  border: 1px solid ${CommonColor.light_grey};
  background: ${CommonColor.white};
  border-radius: 5px;
`;
const TableContainerForCountData = styled.div`
  border: 1px solid ${CommonColor.primary_bg};
  height: 85vh;
  overflow: auto;
  margin-top: 10px;
`;
export {
  TableContainerForCountData,
  DashboardTableContainer,
  FlexContainerForPartnerList,
  TitleWithIcon,
  TableName,
  ActiveContainer,
  RecruiterNameImgContainer,
  RecruiterNameImg,
  FlexContainerForRecruiterName,
  TableMainContainer,
  TableNameContainer,
  DomainTag,
  JobFirstColumn,
  JobtableForbillrateContainer,
  JobViewContainer,
  AttachmentIcon,
  IsRecruiterContainer,
  NoDataImg,
  ImgNodata,
  JobViewDetailGridContainer,
  JobtableForbillrateinput,
  JobtableForbillratetd,
  JobtableForbillrateTh,
  JobtableForbillrate,
  TableTd,
  TableTh,
  TableRow,
  Table,
  TableTbody,
  CustomTableContainer,
};
