import React from "react";
import { ViewDetailContainer } from "../../style/common.style";
import { CommonStringForLevel } from "../../Utils/CommonString";
import RowText from "./view_component/RowText";

export default function LevelViewDetails(props) {
  return (
    <ViewDetailContainer>
      <RowText title={CommonStringForLevel.level} value={props.data.name} />
      <RowText
        nodivider={true}
        title={CommonStringForLevel.designation}
        value={props.data.designation}
      />
    </ViewDetailContainer>
  );
}
