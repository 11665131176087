import React, { useEffect, useState } from "react";
import { CommonColor } from "../../Utils/color";
import { CommonString } from "../../Utils/CommonString";
import {
  CustomButtonForJob,
  FlexContainerForStatus,
  OutlineButtonForJob,
} from "../../style/job.style";
import { JOB_STATUS } from "../../Utils/constant";
import { useSelector } from "react-redux";
import CustomButton from "../CommonComponet/Button";
import Menu from "@mui/material/Menu";
import { OutlinedBtn } from "../../style/common.style";
import { Icon } from "@iconify/react";
import { CommonIcon } from "../../Utils/CommonIcon";

export default function ChangeStatus(props) {
  const [isActive, setIsActive] = useState(props.status);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const access_data_by_user_id = useSelector(
    (state) => state.counter.access_data_by_user_id
  );
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setIsActive(props.status);
  }, []);

  function getColor(status) {
    if (status === CommonString.open.toUpperCase()) {
      if (isActive === CommonString.open) {
        let data = {
          color: CommonColor.green,
          background: CommonColor.bg_green,
        };
        return data;
      }
      let data = {
        color: CommonColor.green,
        background: CommonColor.bg_green,
      };
      return data;
    }
    if (status === CommonString.closed.toUpperCase()) {
      let data = {
        color: CommonColor.red,
        background: CommonColor.bg_red,
      };
      return data;
    }
    if (status === CommonString.on_hold.toUpperCase()) {
      let data = {
        color: CommonColor.yellow,
        background: CommonColor.bg_yellow,
      };
      return data;
    }
    if (status === CommonString.ignored.toUpperCase()) {
      let data = {
        color: CommonColor.blue,
        background: CommonColor.bg_blue,
      };
      return data;
    }
    if (status === CommonString.covered.toUpperCase()) {
      let data = {
        color: CommonColor.secondary_color,
        background: CommonColor.secondary_bg,
      };
      return data;
    }
    let data = {
      color: CommonColor.green,
      background: CommonColor.bg_green,
    };
    return data;
  }
  return (
    <>
      <div style={{ marginLeft: 5 }}>
        {!access_data_by_user_id.includes("job_status_update") ? (
          <CustomButtonForJob
            className="flex items-center"
            color={getColor(props.status ?? CommonString.open).color}
            background={getColor(props.status).background}
          >
            {props.status}{" "}
          </CustomButtonForJob>
        ) : (
          <CustomButtonForJob
            className="flex items-center"
            onClick={handleClick}
            color={getColor(props.status).color}
            background={getColor(props.status).background}
          >
            {props.status}{" "}
            {!open ? (
              <Icon icon={CommonIcon.down_arrow} />
            ) : (
              <Icon icon={CommonIcon.up_arrow} />
            )}
          </CustomButtonForJob>
        )}
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          <div className="p-3">
            <FlexContainerForStatus>
              {JOB_STATUS.map((status) => {
                if (status === "All") return;
                return (
                  <OutlineButtonForJob
                    onClick={() => setIsActive(status.toUpperCase())}
                    isActive={isActive === status.toUpperCase()}
                    color={getColor(status.toUpperCase()).color}
                    border={getColor(status.toUpperCase()).background}
                  >
                    {status}
                  </OutlineButtonForJob>
                );
              })}
            </FlexContainerForStatus>
            <hr />
            <FlexContainerForStatus>
              <CustomButton
                noMarginTop={true}
                onClick={() => props.onSubmit(isActive)}
                name={CommonString.change}
              />
              <OutlinedBtn
                customizeButton={true}
                onClick={() => handleClick(false)}
              >
                {CommonString.cancel}
              </OutlinedBtn>
            </FlexContainerForStatus>
          </div>
        </Menu>
      </div>
    </>
  );
}
