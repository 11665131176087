import { createSlice } from "@reduxjs/toolkit";

export const FirebaseStoreRedux = createSlice({
  name: "FirebaseStoreRedux",
  initialState: {
    userList: [],
  },
  reducers: {
    onAddFIrebaseUserList: async (state, actions) => {
      // console.log("actions.payload", actions.payload);
      state.userList.push(...actions.payload);
    },
  },
});

export const { onAddFIrebaseUserList } = FirebaseStoreRedux.actions;

export default FirebaseStoreRedux.reducer;
