import React, { useEffect, useMemo, useState } from "react";
import FormInput from "../CommonComponet/FormInput";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import {
  changeSelectedJobList,
  removeFromSelectedJobList,
} from "../../feature/store/ReportStore";
import {
  CheckBoxContainer,
  FormLabel,
  SolidBtn,
} from "../../style/common.style";
import { CommonString } from "../../Utils/CommonString";
import moment from "moment";
import { onChangeIsModalOpen } from "../../feature/store/LevelReduxStore";
import { EDIT_CALL_REPORT } from "../../Utils/enum";
import JobController from "../../controller/JobController";
import { Icon } from "@iconify/react";
import { CommonIcon } from "../../Utils/CommonIcon";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function AddDailyReport(props) {
  const dispatch = useDispatch();
  const jobController = new JobController();
  const [selectedReportList, setSelectedReportList] = React.useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const open_position_list = useSelector(
    (state) => state.ReportStoreRedux.open_position_list
  );
  const selected_job_list = useSelector(
    (state) => state.ReportStoreRedux.selected_job_list
  );
  const user_data = useSelector((state) => state.counter.user_data);
  const selected_data = useSelector(
    (state) => state.ReportStoreRedux.select_data
  );
  const modal_status_for_Table = useSelector(
    (state) => state.ReportStoreRedux.modal_status
  );

  const [formData, setFormData] = useState({
    no_of_calls: "",
    call_duration: "",
    no_of_received_call: "",
  });

  useEffect(() => {
    props.getOpenJobList();
    onPrefilledData();
  }, []);

  function onPrefilledData() {
    if (modal_status_for_Table === EDIT_CALL_REPORT) {
      var data = {
        no_of_calls: selected_data.total_calls,
        call_duration: selected_data.call_duration,
        no_of_received_call: selected_data.total_received_calls,
      };
      setFormData(data);
      var filtere = selected_data?.job_data.map((data) =>
        selectedReportList.push(
          `${data?.job_detail?.position_title} (${data?.job_detail?.client_position_id}) - ${data?.partner?.name}`
        )
      );
      setSelectedReportList(selectedReportList);
    }
  }
  function handleInputChange(event) {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    // dispatch(onChangeAccessData(formData));
  }
  const handleChange = (data) => {
    var value = `${data?.job_detail?.position_title} (${data?.job_detail?.client_position_id}) - ${data?.partner?.name}`;
    var checked = selectedReportList.includes(
      `${data?.job_detail?.position_title} (${data?.job_detail?.client_position_id}) - ${data?.partner?.name}`
    );
    if (!checked) {
      selectedReportList.push(value);
      var newData = selectedReportList.filter(
        (item, index) => selectedReportList.indexOf(item) === index
      );
      setSelectedReportList(newData);
      dispatch(changeSelectedJobList(data?._id));
      return;
    }
    var index = selectedReportList.indexOf(value);
    if (index !== -1) {
      selectedReportList.splice(index, 1);
    }
    dispatch(removeFromSelectedJobList(data?._id));
  };
  function onSubmitData() {
    var sendData = {
      _id: selected_data?._id,
      user_id: user_data._id,
      total_calls: formData.no_of_calls,
      call_duration: formData.call_duration,
      total_received_calls: formData.no_of_received_call,
      date: moment().format("YYYY-MM-DD"),
      job_id: selected_job_list,
    };
    dispatch(onChangeIsModalOpen(false));
    props.onAddData(sendData);
  }
  async function onSearch() {
    if (searchText.length !== 0) {
      setIsSearched(true);
      var sendData = {
        search_query: searchText.trim(),
      };
      var res = await jobController.searchJob(sendData, 1);
      setSearchResult(res?.data);
      // const result = open_position_list.filter(
      //   (data) =>
      //     // data?.job_detail?.position_title
      //     //   .toLowerCase()
      //     //   .trim()
      //     //   .includes(e.toLowerCase()) ||
      //     data?.job_detail?.client_position_id
      //       .toLowerCase()
      //       .trim()
      //       .includes(e.toLowerCase())
      //   //   ||
      //   // data?.partner?.name.toLowerCase().trim().includes(e.toLowerCase())
      // );
    }
  }

  return (
    <div>
      <FormInput
        value={formData.no_of_calls}
        onChange={handleInputChange}
        name="no_of_calls"
        placeholder="No Of Calls"
      />
      <FormInput
        value={formData.call_duration}
        onChange={handleInputChange}
        placeholder="Call Duration"
        name="call_duration"
      />
      <FormInput
        value={formData.no_of_received_call}
        onChange={handleInputChange}
        placeholder="No Of Received Calls"
        name="no_of_received_call"
      />
      <FormControl sx={{ width: "100%" }} className="selectMultiple">
        <label>Select Partner</label>
        <Select
          size="small"
          style={{ border: "1px solid lightgrey", padding: 0 }}
          multiple
          displayEmpty
          value={selectedReportList}
          input={<OutlinedInput />}
          renderValue={(selected) => (
            <p style={{ marginTop: -5 }}>{selected.join(", ")}</p>
          )}
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSearch(e);
              }}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                position: "relative",
              }}
            >
              <input
                value={searchText}
                style={{ width: "100%" }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
                placeholder="Search By Job ID"
                type="text"
              />
              <Icon
                className="searchForSelect"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  onSearch(e);
                }}
                icon={CommonIcon.search_icon}
              />
            </form>
          </MenuItem>
          {(searchText.length !== 0 && isSearched
            ? searchResult
            : open_position_list
          ).map((name, index) => (
            <MenuItem
              style={{ cursor: "pointer" }}
              key={name}
              value={`${name?.job_detail?.position_title} (${name?.job_detail?.client_position_id}) - ${name?.partner?.name}`}
            >
              <CheckBoxContainer onClick={() => handleChange(name)}>
                <input
                  id="default-checkbox"
                  type="checkbox"
                  checked={selectedReportList.includes(
                    `${name?.job_detail?.position_title} (${name?.job_detail?.client_position_id}) - ${name?.partner?.name}`
                  )}
                />
                <FormLabel>{`${name?.job_detail?.position_title} (${name?.job_detail?.client_position_id}) - ${name?.partner?.name}`}</FormLabel>
              </CheckBoxContainer>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <SolidBtn onClick={onSubmitData}>{CommonString.add}</SolidBtn>
    </div>
  );
}
