import styled, { keyframes } from "styled-components";
import { CommonColor } from "../Utils/color";
import { Icon } from "@iconify/react";
const scalingAniamtion = keyframes`
0%{
    transform: scale(1.1);
    opacity: 1;

}
50%{
    transform: scale(1.5);
    opacity: 0;
}
51%{
  transform: scale(1.1);
    opacity: 0;
}
100%{
    opacity: 1;
    transform: scale(1.1);
}
`;

const Line = styled.div`
  width: 90px;
  height: 0.5px;
  border-radius: 10px;
  margin: 0 10px;
  background-color: ${(props) =>
    props.isCompleted
      ? CommonColor.bg_green
      : props.isActive
      ? CommonColor.green
      : CommonColor.grey};
  display: ${(props) => (props.visible ? "" : "none")};
`;

const Circle = styled.div`
  height: ${(props) => (props.isActive ? "5px" : "16px")};
  width: ${(props) => (props.isActive ? "5px" : "16px")};
  border-radius: 50%;
  cursor: pointer;
  background-color: ${(props) =>
    props.isActive
      ? CommonColor.green
      : props.isCompleted
      ? CommonColor.bg_green
      : props.isSkip
      ? CommonColor.bg_red
      : CommonColor.transparent};
  overflow: visible;
  border-width: 0.5px;
  border-style: solid;
  border-color: ${(props) =>
    props.isActive
      ? CommonColor.green
      : props.isCompleted
      ? CommonColor.bg_green
      : props.isSkip
      ? CommonColor.bg_red
      : CommonColor.grey};
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: "";
    background-color: ${(props) =>
      props.isActive ? CommonColor.bg_green : CommonColor.transparent};
    display: inline-block;
    position: absolute; /* same as the div */
    top: -6.3px;
    left: -6.3px;

    width: 17px;
    height: 17px;
    /* transform: translate(-4%, -4.5%); remove this property */
    border-radius: 50%;
    animation-name: ${scalingAniamtion};
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-delay: 1s;
    z-index: -1; /* lower than the div */
  }
  & svg {
    display: ${(props) => (props.isCompleted ? "" : "none")};
  }
`;

const TimelineBlockContainer = styled.div`
  display: flex;
  align-items: center;
`;
const FlexContainer = styled.div`
  display: flex;
  justify-content: ${(props) => props.jc ?? "center"};
  align-items: ${(props) => props.ai ?? "center"};
  flex-direction: ${(props) => props.flexDirection ?? "row"};
  width: ${(props) => props.width ?? "100%"};
  margin: ${(props) => props.margin};
  flex-wrap: ${(props) => props.flexWrap};
  gap: ${(props) => props.gap};
`;

const FlexContainerVerical = styled.div`
  justify-content: ${(props) => props.jc ?? "center"};
  align-items: ${(props) => props.ai ?? "center"};
  width: ${(props) => props.width ?? "100%"};
  /* background-color: red; */
  margin: 2px;
`;
const StatusTitle = styled.div`
  font-size: 10px;
  width: min-content;
  position: absolute;
  transform: translateX(-30%);
  margin-top: 8%;
  text-align: center;
  text-transform: capitalize;
  color: ${(props) =>
    props.isActive
      ? CommonColor.green
      : props.isCompleted
      ? CommonColor.green
      : props.isSkip
      ? CommonColor.bg_red
      : CommonColor.grey};
  font-weight: ${(props) =>
    props.isActive ? "700" : props.isCompleted ? "600" : "400"};
`;
const ButtonContainer = styled.div`
  display: ${(props) => (props.visible ? "flex" : "none")};
  margin-top: 50px;
  width: auto;
  align-items: center;
`;

export {
  Line,
  Circle,
  ButtonContainer,
  TimelineBlockContainer,
  FlexContainer,
  StatusTitle,
  FlexContainerVerical,
};
