import React, { useState } from "react";
import Container from "../../CommonComponet/Container";
import {
  CustomTableContainer,
  DomainTag,
  TableTbody,
  TableTd,
  TableTh,
} from "../../../style/table.styled";
import { Table } from "react-bootstrap";
import { TableRow } from "@mui/material";
import { DOMAIN_TH } from "../../../Utils/constant";
import { Div, OutlinedBtn } from "../../../style/common.style";
import FormInput from "../../CommonComponet/FormInput";
import { Icon } from "@iconify/react";
import { CommonIcon } from "../../../Utils/CommonIcon";
import { DomainInputContainer } from "../../../style/domain.style";

export default function DomainTable(props) {
  const [domain, setdomain] = useState();
  const [domainCategory, setdomainCategory] = useState();
  const [domainSubCategory, setdomainSubCategory] = useState();

  const [domainList, setDomainList] = useState(["IT", "Health care"]);
  const [domainCategoriesList, setDomainCategoriesList] = useState([
    "IT1",
    "IT2",
  ]);
  const [domainSubCategoriesList, setDomainSubCategoriesList] = useState([
    "IT1.1",
    "IT1.2",
  ]);

  const [selectedDomain, setselectedDomain] = useState(domainList[0]);
  const [selectedDomainCategory, setselectedDomainCategory] = useState(
    domainCategoriesList[0]
  );

  function deleteDomainSubCategory() {}
  function deleteDomain() {}
  function deleteDomainCategory() {}
  function editDomainSubCategory() {}
  function editDomain() {}
  function editDomainCategory() {}
  return (
    <CustomTableContainer>
      <Table>
        <TableTbody>
          <TableRow>
            {DOMAIN_TH.map((singleTH, index) => (
              <TableTh key={index}>{singleTH}</TableTh>
            ))}
          </TableRow>
          <TableRow>
            <TableTd>
              <DomainInputContainer>
                <FormInput
                  value={domain || ""}
                  onChange={(e) => setdomain(e.target.value)}
                  placeholder="Add Industry"
                />
                <OutlinedBtn
                  onClick={() => setDomainList([...domainList, domain])}
                >
                  <Icon icon={CommonIcon.check} />
                </OutlinedBtn>
              </DomainInputContainer>
              {domainList.map((data) => (
                <div style={{ display: "flex" }}>
                  <DomainTag onClick={() => setselectedDomain(data)}>
                    {data}
                    <Icon
                      onClick={(e) => deleteDomain(e)}
                      icon={CommonIcon.delete_outline}
                    />
                    <Icon
                      onClick={(e) => editDomain(e)}
                      icon={CommonIcon.edit}
                    />
                  </DomainTag>
                </div>
              ))}
            </TableTd>
            <TableTd>
              <DomainInputContainer>
                <FormInput
                  value={domainCategory || ""}
                  onChange={(e) => setdomainCategory(e.target.value)}
                  placeholder={"Add Role Category in " + selectedDomain}
                />
                <OutlinedBtn
                  onClick={() =>
                    setDomainCategoriesList([
                      ...domainCategoriesList,
                      domainCategory,
                    ])
                  }
                >
                  Add
                </OutlinedBtn>
              </DomainInputContainer>
              {domainCategoriesList.map((data) => (
                <DomainTag onClick={() => setselectedDomainCategory(data)}>
                  {data}
                  <Icon
                    onClick={(e) => deleteDomainCategory(e)}
                    icon={CommonIcon.delete_outline}
                  />
                  <Icon
                    onClick={(e) => editDomainCategory(e)}
                    icon={CommonIcon.edit}
                  />
                </DomainTag>
              ))}
            </TableTd>
            <TableTd>
              <DomainInputContainer>
                <FormInput
                  value={domainSubCategory || ""}
                  onChange={(e) => setdomainSubCategory(e.target.value)}
                  placeholder={
                    "Add Role Sub Category in" + selectedDomainCategory
                  }
                />
                <OutlinedBtn
                  onClick={() =>
                    setDomainSubCategoriesList([
                      ...domainSubCategoriesList,
                      domainSubCategory,
                    ])
                  }
                >
                  <Icon icon={CommonIcon.check} />
                </OutlinedBtn>
              </DomainInputContainer>
              {domainSubCategoriesList.map((data) => (
                <DomainTag>
                  {data}
                  <Icon
                    onClick={(e) => deleteDomainSubCategory(e)}
                    icon={CommonIcon.delete_outline}
                  />
                  <Icon
                    onClick={(e) => editDomainSubCategory(e)}
                    icon={CommonIcon.edit}
                  />
                </DomainTag>
              ))}
            </TableTd>
          </TableRow>
        </TableTbody>
      </Table>
      <Container
        isLoading={props.isLoading === true}
        isVisible={props.isLoading === false && props.levelList.length === 0}
      />
    </CustomTableContainer>
  );
}
