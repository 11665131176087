import React from "react";
import {
  CustomTableContainer,
  Table,
  TableRow,
  TableTbody,
  TableTd,
  TableTh,
} from "../../style/table.styled";
import {
  EDIT_CALL_REPORT,
  VIEW_INTERVIEW_DETAIL,
  VIEW_JOBS,
  VIEW_SUBMISSION_DETAIL,
} from "../../Utils/enum";
import { useSelector } from "react-redux";
import { FlexContainer, TableNoDataContainer } from "../../style/common.style";
import { CommonIcon } from "../../Utils/CommonIcon";
import { TableIcon } from "../../style/Icon.style";
import NoData from "../../assets/images/nodata.png";
import LoaderForTable from "../CommonComponet/LoaderForTable";

export default function DailyReportTable(props) {
  const call_report_list = useSelector(
    (state) => state.ReportStoreRedux.call_report_list
  );
  const is_loading = useSelector((state) => state.ReportStoreRedux.is_loading);

  return (
    <>
      <CustomTableContainer height={"80vh"}>
        <Table>
          <TableTbody>
            <TableRow>
              <TableTh>No.</TableTh>
              <TableTh>Date</TableTh>
              <TableTh>Name</TableTh>
              <TableTh textAlignLeft="left">Calls</TableTh>
              <TableTh>Call Duration</TableTh>
              <TableTh>Received Calls</TableTh>
              <TableTh>Submission</TableTh>
              <TableTh>Interviews</TableTh>
              <TableTh>Requisition worked on</TableTh>
              <TableTh></TableTh>
            </TableRow>
            {!is_loading && call_report_list.length === 0 ? (
              <></>
            ) : (
              call_report_list.map((singleData, index) => {
                return (
                  <TableRow id={index}>
                    <TableTd>{index + 1}</TableTd>
                    <TableTd>{singleData?.date}</TableTd>
                    <TableTd>
                      {singleData?.recruiter?.name?.split(" ")[0]}
                    </TableTd>
                    <TableTd>{singleData?.total_calls}</TableTd>
                    <TableTd>{singleData?.call_duration}</TableTd>
                    <TableTd>{singleData?.total_received_calls}</TableTd>
                    <TableTd
                      onHover={singleData?.submissionData.length >= 1}
                      onClick={() =>
                        singleData?.submissionData.length >= 1
                          ? props.onClick(VIEW_SUBMISSION_DETAIL, singleData)
                          : ""
                      }
                    >
                      {singleData?.submission_count}
                    </TableTd>
                    <TableTd
                      onHover={singleData?.InterviewData.length >= 1}
                      onClick={() =>
                        singleData?.InterviewData.length >= 1
                          ? props.onClick(VIEW_INTERVIEW_DETAIL, singleData)
                          : ""
                      }
                    >
                      {singleData?.interview_count}
                    </TableTd>
                    <TableTd
                      style={{ maxWidth: 300 }}
                      onHover={singleData?.job_data.length >= 1}
                      onClick={() => props.onClick(VIEW_JOBS, singleData)}
                      textAlignLeft="left"
                    >
                      {singleData?.job_data.map((data) => (
                        <li>{`${data?.job_detail?.position_title} (${data?.job_detail?.client_position_id})`}</li>
                      ))}
                    </TableTd>
                    <TableTd>
                      <FlexContainer>
                        <TableIcon
                          onClick={() => props.onDelete(singleData)}
                          icon={CommonIcon.modal_delete}
                        />
                        <TableIcon
                          onClick={() =>
                            props.onClick(EDIT_CALL_REPORT, singleData)
                          }
                          icon={CommonIcon.edit}
                        />
                      </FlexContainer>
                    </TableTd>
                  </TableRow>
                );
              })
            )}
          </TableTbody>
        </Table>
        {!is_loading && call_report_list.length === 0 ? (
          <TableNoDataContainer>
            <img src={NoData} alt="" />
          </TableNoDataContainer>
        ) : (
          <></>
        )}
        {is_loading ? <LoaderForTable /> : <></>}
      </CustomTableContainer>
    </>
  );
}
