import { useDispatch, useSelector } from "react-redux";
import { getNotificationData } from "../feature/store/ReduxStore";
import SubmissionProvider from "../services/provider/SubmissionProvider";
import NotificationController from "./NotificationController";
import {
  SUBMISSION_STATUS,
  SUBMISSION_STATUS_FOR_RECRUITER,
} from "../Utils/constant";
import {
  INTERVIEW_DETAILS,
  OFFER_DETAILS,
  PROJECT_DETAILS,
  SELECTE_PARTNER,
} from "../Utils/enum";
import { changeProjectDetails } from "../feature/store/SubmissionReduxStore";

export class SubmissionController {
  constructor() {
    this.submissionProvider = new SubmissionProvider();
    this.notificationController = new NotificationController();
    this.dispatch = new useDispatch();
    this.notificationData = new useSelector(
      (state) => state.counter.notificationData
    );
  }
  SearchDataInChatBox = async (data) => {
    var res = await this.submissionProvider.SearchDataInChatBox(data);
    return res.data.data;
  };
  getSubmissions = async (page) => {
    var res = await this.submissionProvider.getSubmission(page);
    return res.data.data;
  };

  getChildrenByParentJob = async (page) => {
    var res = await this.submissionProvider.getChildjobsByParent(page);
    return res.data.data;
  };
  onUpdatePartnerInSubmission = async (page) => {
    var res = await this.submissionProvider.onAddParnerInSubmission(page);
    return res;
  };

  getSubmissionWithStatus = async (page) => {
    var res = await this.submissionProvider.getSubmissionWithStatus(page);
    return res.data.data;
  };
  getSubmissionByRecruiterID = async (page, data) => {
    var res = await this.submissionProvider.getSubmissionByRecruiterID(
      page,
      data
    );
    return res.data.data;
  };
  searchSubmission = async (data, page) => {
    var res = await this.submissionProvider.searchSubmission(data, page);
    return res.data.data;
  };
  changeStatusSubmission = async (data) => {
    var res = await this.submissionProvider.changeSubmissionStatus(data);
    return res;
  };
  addSubmission = async (data) => {
    var res = await this.submissionProvider.addSubmission(data);
    return res;
  };
  getDataById = async (data) => {
    var res = await this.submissionProvider.getTrackerDataById(data);
    return res;
  };
  updateSubmissionTracker = async (data) => {
    var res = await this.submissionProvider.updateSubmissionTracker(data);
    return res;
  };
  getInterviewDetailById = async (data) => {
    var res = await this.submissionProvider.getInterviewDetailById(data);
    return res;
  };
  deleteSubmission = async (data) => {
    var res = await this.submissionProvider.deleteSubmission(data);
    return res;
  };
  getSubmissionByChildJob = async (data) => {
    var res = await this.submissionProvider.getSubmissionByChildJob(data);
    return res;
  };
  getSubmissionByApplicant = async (data) => {
    var res = await this.submissionProvider.getSubmissionByApplicant(data);
    return res;
  };
  getSubmissionsByStatus = async (data) => {
    var res = await this.submissionProvider.getSubmissionByStatusV2(data);
    return res.data.data;
  };
  addSubmissionOfferDetail = async (data) => {
    var res = await this.submissionProvider.addSubmissionOfferDetail(data);
    return res;
  };
  getSubmissionOfferDetail = async (data) => {
    var res = await this.submissionProvider.getSubmissionOfferDetail(data);
    return res;
  };
  UpdateInterviewDetail = async (data) => {
    var res = await this.submissionProvider.UpdateInterviewDetail(data);
    return res;
  };
  addProjectCompletedDetail = async (data) => {
    var res = await this.submissionProvider.addProjectCompletedDetail(data);
    return res;
  };
  updateProjectCompletedDetail = async (data) => {
    var res = await this.submissionProvider.updateProjectCompletedDetail(data);
    return res;
  };
  onGetSubmissionTrackerDataByRecruiterId = async (data) => {
    var res = await this.submissionProvider.onGetSubmissionTrackerData(data);
    return res.data;
  };
  getProjectCompletedDetail = async (data) => {
    var obj = {
      submission_id: data?._id,
    };
    var res = await this.submissionProvider.getProjectCompletedDetail(obj);
    this.dispatch(changeProjectDetails(res.data.data));
    return res;
  };
  onEditOfferDetail = async (data) => {
    var res = await this.submissionProvider.onEditOfferDetail(data);
    return res.data;
  };
  onFilterStatusData = async (data, page) => {
    var res = await this.submissionProvider.onFilterData(data, page);
    return res.data;
  };
  getSubmissionDataForDashboard = async (data) => {
    var res = await this.submissionProvider.getStatusDataForDashboard(data);
    return res.data;
  };
  getStatusDataById = async (data) => {
    var res = await this.submissionProvider.getStatusDataByIdAction(data);
    return res.data;
  };
}
//function
export function getFormTitle(modalStatus) {
  if (modalStatus === SUBMISSION_STATUS.LOCKED) {
    return SELECTE_PARTNER;
  } else if (modalStatus === SUBMISSION_STATUS.INTERVIEW) {
    return INTERVIEW_DETAILS;
  } else if (modalStatus === SUBMISSION_STATUS.OFFER) {
    return OFFER_DETAILS;
  } else if (modalStatus === SUBMISSION_STATUS.PLACEMENT) {
    return "Project Details";
  } else if (modalStatus === SUBMISSION_STATUS.DECLINED_BY_CLIENT) {
    return "Declined By Client Details";
  } else if (modalStatus === SUBMISSION_STATUS.DECLINED_BY_CONSULTANT) {
    return "Declined By Consultant Details";
  } else if (modalStatus === SUBMISSION_STATUS.TERMINATED) {
    return "Terminated Details";
  } else if (modalStatus === SUBMISSION_STATUS.RESIGNED) {
    return "Resigned Details";
  } else {
    return PROJECT_DETAILS;
  }
}
export function getDisabledValue(selectedData, submission_status_list) {
  const data = submission_status_list.findIndex((e) => e === selectedData);
  return data;
}
export function checkVisibilityForStatus(e) {
  var filteredData = SUBMISSION_STATUS_FOR_RECRUITER.filter(
    (data) => data === e
  );
  return filteredData.length === 0;
}
