import NotificationProvider from "../services/provider/notificationProvider";

export default class NotificationController {
  constructor() {
    this.notificationProvider = new NotificationProvider();
  }

  getNotification = async () => {
    var res = await this.notificationProvider.getNotification();
    return res.data.data;
  };

}
