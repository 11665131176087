import React, { useState } from "react";
import { FilterListContainer } from "../../../style/dice.style";
import CheckBox from "../../../component/basic/CheckBox";
import InputWithLabel from "./InputWithLabel";
import { Icon } from "@iconify/react";
import { CommonIcon } from "../../../Utils/CommonIcon";
import { Education } from "./Constant";
import Suggestions from "./Suggestions";
import InputButtonWithAddButton from "./InputButtonWithAddButton";
import CollapsibleInput from "./CollapsibleInput";
import { useDispatch } from "react-redux";
import { onChangeFilterData } from "../../../feature/store/DiceStore";

export default function Filters() {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    educationDegree: "",
    hasEmail: true,
    hasPhoneNumber: true,
    lastActive: 0,
    yearsExperience: { min: 0, max: 0 },
    jobTitle: "",
  });

  function handleInputChange(event) {
    if (event.target !== undefined) {
      const { name, checked } = event.target;
      setFormData({ ...formData, [name]: checked });
      dispatch(onChangeFilterData({ ...formData, [name]: checked }));
      return;
    }
    const { name, value } = event;
    setFormData({ ...formData, [name]: value });
    dispatch(onChangeFilterData({ ...formData, [name]: value }));
  }
  function onGetValue(e) {
    if (e === "") {
      return [];
    }
    return JSON.parse(e);
  }
  function onChangeSuggestion(name, value) {
    handleInputChange({
      name: name,
      value: JSON.stringify(value),
    });
  }

  return (
    <div>
      <h5
        style={{
          color: "grey",
          display: "flex",
          alignItems: "center",
          fontWeight: "bold",
          marginTop: 20,
        }}
      >
        <Icon style={{ marginRight: 10 }} icon={CommonIcon.filter} />
        Filters
      </h5>
      <FilterListContainer>
        <CollapsibleInput
          is_active={formData.jobTitle === ""}
          index={1}
          title={"Job Title"}
          content={
            <>
              <InputButtonWithAddButton
                // value={formData.jobTitle}
                placeholder="Job Title or Boolean"
                icon={CommonIcon.plus}
                onAdd={(e) => onChangeSuggestion("jobTitle", e)}
              />
            </>
          }
        />
        <CollapsibleInput
          is_active={formData.lastActive === "" || formData.lastActive === 0}
          index={2}
          title={"Last Active on Dice"}
          content={
            <div>
              <span>
                Maximum number of days since the last activity on Dice (Max 2500
                days)
              </span>
              <InputWithLabel
                onChange={(e) =>
                  handleInputChange({
                    name: "lastActive",
                    value: e.target.value,
                  })
                }
                value={formData.lastActive}
                placeholder="# of days"
                unitLabel="days"
              />
            </div>
          }
        />
        <CollapsibleInput
          is_active={formData.hasEmail || formData.hasPhoneNumber}
          index={3}
          title={"Contact Method"}
          content={
            <div>
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Boolean equivalent
                {/* <CustomSwitch /> */}
              </p>
              <br />
              <CheckBox
                onClick={handleInputChange}
                value={formData.hasEmail}
                label={"Email"}
                name={"hasEmail"}
              />
              <CheckBox
                onClick={handleInputChange}
                value={formData.hasPhoneNumber}
                label={"phone"}
                name={"hasPhoneNumber"}
              />
            </div>
          }
        />
        <CollapsibleInput
          is_active={
            formData.yearsExperience.max !== 0 ||
            formData.yearsExperience.min !== 0
          }
          index={4}
          title={"Total Years Experience"}
          content={
            <p>
              <span>Min / Max0+ years</span>
              <div style={{ display: "flex" }}>
                <InputWithLabel
                  onChange={(e) =>
                    handleInputChange({
                      name: "yearsExperience",
                      value: {
                        min: e.target.value,
                        max: formData.yearsExperience.max,
                      },
                    })
                  }
                  placeholder="0"
                  unitLabel={"to"}
                />
                <InputWithLabel
                  onChange={(e) =>
                    handleInputChange({
                      name: "yearsExperience",
                      value: {
                        min: formData.yearsExperience.min,
                        max: e.target.value,
                      },
                    })
                  }
                  placeholder="any"
                  unitLabel={"years"}
                />
              </div>
            </p>
          }
        />
        <CollapsibleInput
          index={5}
          title={"Education"}
          content={
            <>
              <Suggestions
                list={Education}
                value={onGetValue(formData.educationDegree)}
                onClickOnAdd={(e) => onChangeSuggestion("educationDegree", e)}
              />
            </>
          }
        />
      </FilterListContainer>
    </div>
  );
}
