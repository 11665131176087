import React from "react";
import ApplicantCard from "./ApplicantCard";

export default function ApplicantList(props) {
  return (
    <div>
      {props.data.map((e, index) => (
        <ApplicantCard data={e} key={index} />
      ))}
    </div>
  );
}
