import React from "react";
import { ErrorText } from "../../style/common.style";
import { Input } from "semantic-ui-react";
export default function FormInput(props) {
  return (
    <>
      <div class="field">
        <label>{props.placeholder}</label>
        <div>
          {props.multiline ? (
            <textarea {...props} />
          ) : (
            <Input {...props} size="small" required={props.error} />
          )}

          {props.error === undefined ? (
            ""
          ) : (
            <ErrorText>{props.error}</ErrorText>
          )}
        </div>
      </div>
    </>
  );
}
{
  /* <FormField>
      <TextField
        fullWidth
        onKeyDown={props.onKeyDown}
        disabled={props.disabled}
        onChange={props.onChange}
        value={props.value}
        placeholder={props.placeholder}
        label={props.placeholder}
        id="outlined-size-small"
        size="small"
        InputProps={{ style: { fontSize: 12 } }}
        InputLabelProps={{ style: { fontSize: 12 } }}
        {...props}
        onKeyUp={props.onKeyUp}
        autoComplete={props.autoComplete ?? "off"}
        {...props}
        onBlur={props.onBlur}
      />
    </FormField> */
}
