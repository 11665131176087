import React from "react";
import moment from "moment";
import { CallTrackerStatusColor } from "../../Utils/utilitiFunction";
import Switch from "@mui/material/Switch";
import { LONG_DATE_FORMATE } from "../../Utils/constant";
import {
  CallTrackerFormUiContainer,
  CallTrackerTextArea,
  FaqNumber,
  FaqTitle,
  TitleHR,
} from "../../style/common.style";
import { CommonString } from "../../Utils/CommonString";
import { FormPlaceholder } from "../../Utils/FormPlaceholder";
export default function CallTrackerFormUi(props) {
  return (
    <>
      <CallTrackerFormUiContainer>
        <h5>
          <FaqNumber>{props.index} </FaqNumber>
          <FaqTitle>
            {moment.tz(props.call, "UTC").format(LONG_DATE_FORMATE) + " - "}
            {/* {moment().format(LONG_DATE_FORMATE)} - */}
            <span style={{ color: CallTrackerStatusColor[props.status].color }}>
              {props.status}
            </span>
          </FaqTitle>
        </h5>
        <Switch
          size={CommonString.small}
          checked={props.status === CommonString.pending ? false : true}
          onChange={props.onChangeStatus}
        />
      </CallTrackerFormUiContainer>
      <CallTrackerTextArea
        value={props.value}
        placeholder={FormPlaceholder.enter_note}
        onChange={props.onChange}
      />
      <TitleHR />
    </>
  );
}
