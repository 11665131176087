import React, { useState, useEffect } from "react";
import CallTrackerFormUi from "./CallTrackerFormUi";
import { SubmissionController } from "../../controller/SubmissionController";
import { CommonString } from "../../Utils/CommonString";
import ButtonGroup from "../CommonComponet/ButtonGroup";
import { useDispatch } from "react-redux";
import { updateSubmissionData } from "../../feature/store/ReduxStore";
import {
  CALL_TRACKER_UPDATE_ERROR,
  UPDATE_SUBMISSION_TRACKER,
  UPDATE_SUBMISSION_TRACKER_ERROR,
} from "../../Utils/alertMessage";
import { useAlert } from "react-alert";
import { updateSubmissionDataForDashboard } from "../../feature/store/SubmissionReduxStore";

export default function EditCallTracker(props) {
  const dispatch = useDispatch();
  const alert = useAlert();
  const submissionController = new SubmissionController();
  const [CallContent_1, setCallContent_1] = useState();
  const [CallContent_2, setCallContent_2] = useState();
  const [CallContent_3, setCallContent_3] = useState();
  const [call_1_status, setcall_1_status] = useState();
  const [call_2_status, setcall_2_status] = useState();
  const [call_3_status, setcall_3_status] = useState();

  useEffect(() => {
    onPrefilledData();
  }, []);

  function onPrefilledData() {
    if (props.trackerList != null) {
      setCallContent_1(props.trackerList.note_1);
      setCallContent_2(props.trackerList.note_2);
      setCallContent_3(props.trackerList.note_3);
      setcall_1_status(
        props.trackerList.call_1_status
          ? props.trackerList.call_1_status
          : props.selectedData.call_1_status
      );
      setcall_2_status(
        props.trackerList.call_2_status
          ? props.trackerList.call_2_status
          : props.selectedData.call_2_status
      );
      setcall_3_status(
        props.trackerList.call_3_status
          ? props.trackerList.call_3_status
          : props.selectedData.call_3_status
      );
    }
  }

  async function onChangeContent() {
    // props.isLoading(true);
    var data = {
      _id: props.trackerList._id,
      note_1: CallContent_1 ?? "",
      note_2: CallContent_2 ?? "",
      note_3: CallContent_3 ?? "",
      call_1_status: call_1_status,
      call_2_status: call_2_status,
      call_3_status: call_3_status,
      // applicant_last_name: props.selectedData.applicant_last_name,
      // applicant_first_name: props.selectedData.applicant_first_name,
      // position_title: props.selectedData.position_title,
      // department: props.selectedData.department,
      // partner_name: props.selectedData.partner_name,
      // recruiter_name: props.selectedData.recruiter_name,
      // status: props.selectedData.status,
    };
    // console.log("props.submission_data", );
    // return;
    var res = await submissionController.updateSubmissionTracker(data);
    if (res.data.status === 202) {
      alert.show(CALL_TRACKER_UPDATE_ERROR, { type: CommonString.error });
      props.isLoading(false);
      return;
    }
    if (res.data.status === 200) {
      var xdata = {
        submitions_id: props.submission_data._id,
        submissions_id: props.submission_data._id,
        _id: props.trackerList._id,
        note_1: CallContent_1 ?? "",
        note_2: CallContent_2 ?? "",
        note_3: CallContent_3 ?? "",
        call_1_status: call_1_status,
        call_2_status: call_2_status,
        call_3_status: call_3_status,
      };
      alert.show(UPDATE_SUBMISSION_TRACKER, { type: CommonString.success });
      if (props.submission_data._id === undefined) {
        dispatch(updateSubmissionDataForDashboard(xdata));
      } else {
        dispatch(updateSubmissionData(xdata));
      }
      props.handleClose();
      props.isLoading(false);
      return;
    }
    alert.show(UPDATE_SUBMISSION_TRACKER_ERROR, { type: CommonString.error });
    props.isLoading(false);
    props.handleClose();
  }
  return (
    <div>
      <CallTrackerFormUi
        onChangeStatus={(e) => {
          setcall_1_status(
            e.target.checked === true
              ? CommonString.completed
              : CommonString.pending
          );
        }}
        value={CallContent_1}
        onChange={(e) => setCallContent_1(e.target.value)}
        index="01"
        call={props.trackerList.call_1}
        status={call_1_status}
      />
      <CallTrackerFormUi
        onChangeStatus={(e) => {
          setcall_2_status(
            e.target.checked === true
              ? CommonString.completed
              : CommonString.pending
          );
        }}
        value={CallContent_2}
        onChange={(e) => setCallContent_2(e.target.value)}
        index="02"
        call={props.trackerList.call_2}
        status={call_2_status}
      />
      <CallTrackerFormUi
        onChangeStatus={(e) => {
          setcall_3_status(
            e.target.checked === true
              ? CommonString.completed
              : CommonString.pending
          );
        }}
        value={CallContent_3}
        onChange={(e) => setCallContent_3(e.target.value)}
        index="03"
        call={props.trackerList.call_3}
        status={call_3_status}
      />
      <ButtonGroup
        width="auto"
        solidBtnClick={() => onChangeContent()}
        outlinedBtnClick={props.handleClose}
        solidBtnName={CommonString.save}
        outlinedBtnName={CommonString.cancle}
      />
    </div>
  );
}
