export const EDIT = "edit";
export const VIEW = "view";
export const DELETE = "delete";

export const VIEW_STATUS = "ViewStatus";
//MENU NAME
export const DASHBOARD = "Dashboard";
// TABLE NAME
export const JOBS = "Jobs";
export const JOB = "Job";
export const SUBMISSION = "Submission";
export const STATUS = "Status";
export const CONSULTANT_STATUS = "Consultant Status";
export const SUBMISSIONS = "Submissions";
export const RECRUITERS = "Recruiters";
export const RECRUITER = "Recruiter";
export const APPLICANTS = "Applicants";
export const APPLICANT = "Applicant";
export const PARTNER = "Partner";
export const PARTNERS = "Partners";
export const CLIENT = "Client";
export const DOMAIN = "Domain";
export const CLIENTS = "Clients";
export const LEVEL = "Level";
export const LEVELS = "Levels";
export const REPORT = "Report";
export const DAILY_REPORT = "Daily Report";
export const GROUP = "Recruiter Groups";
export const SKILL = "Skill";
//ADD FORM NAME
export const ADD_JOBS = "Add Job";
export const ADD_SUBMISSION = "Add Submission";
export const ADD_RECRUITERS = "Add Recruiter";
export const ADD_APPLICANTS = "Add Applicant";
export const ADD_PARTNER = "Add Partner";
export const ADD_CLIENT = "Add Client";
export const ADD_LEVEL = "Add Level";
export const ADD_SKILL = "Add Skill";
export const ADD_DAILY_REPORT = "Add Daily Report";
export const VIEW_SUBMISSION_DETAIL = "View Submission Details";
export const VIEW_INTERVIEW_DETAIL = "View Interview Details";
export const EDIT_CALL_REPORT = "Edit Call Report";
export const VIEW_JOB_DETAIL = "View Job Details";
//UPDATE FORM NAME
export const UPDATE_JOBS = "Update Job";
export const UPDATE_SUBMISSION = "Update Submission";
export const UPDATE_RECRUITERS = "Update Recruiter";
export const UPDATE_APPLICANTS = "Update Applicant";
export const UPDATE_PARTNER = "Update Partner";
export const UPDATE_CLIENT = "Update Client";
export const UPDATE_LEVEL = "Update Level";
export const UPDATE_SKILL = "Update Skill";
//VIEW FORM NAME
export const VIEW_JOBS = "View Job";
export const VIEW_SUBMISSION = "View Submission";
export const VIEW_RECRUITERS = "View Prexelite";
export const VIEW_APPLICANTS = "View Applicant";
export const LOCK_APPLICANTS = "Lock Applicant";
export const VIEW_PARTNER = "View Partner";
export const VIEW_CLIENT = "View Client";
export const VIEW_LEVEL = "View Level";
//CARD TITLE
export const TOTAL_JOBS = "Total Job";
export const TOTAL_RECRUITERS = "Total Recruiter";
export const TOTAL_PARTNER = "Total Partner";
export const TOTAL_CLIENT = "Total Client";

//amount in tax tearm
export const W2_WITH_BENIFIT_TAX_TEARM = "25";
export const W2_WITHOUT_BENIFIT_TAX_TEARM = "15";
export const W2_SALARIZED_TAX_TEARM = "30";
export const OTHER_TAX_TEARM = "35";

export const BILLRATE_ERROR = "Please select client to calculate magin";
export const SELECTE_PARTNER = "Payrate and taxterm";

//search text
export const SEARCH_SUBMISSION = "Search Submission";
export const SEARCH_JOB = "Search Job";
export const SEARCH_APPLICANT = "Search Applicant";
export const SEARCH_PARTNER = "Search Partner";
export const SEARCH_CLIENT = "Search Client";
export const SEARCH_DOMAIN = "Search Domain";
export const SEARCH_RECRUITER = "Search Recruiter";
export const SEARCH_LEVEL = "Search Level";
export const SEARCH_TRACKER = "Search Tracker";
export const SEARCH_RECUITER_GROUP = "Search Recruiter group";
export const INTERVIEW_DETAILS = "Interview Details";
export const OFFER_DETAILS = "Offer Details";
export const PROJECT_DETAILS = "Project Completed Details";
export const CALL_TRACKER = "Call Tracker";
export const VIEW_CALL_TRACKER = "ViewCallTracker";
export const ADD_INTERVIEW_DETAIL = "AddInterviewDetail";

//button name
