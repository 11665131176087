import React from "react";
import {
  AnimatedBackground,
  PlaceholderAnimation,
} from "../../style/common.style";

export default function PlaceholderLoader() {
  return (
    <PlaceholderAnimation >
      <AnimatedBackground></AnimatedBackground>
    </PlaceholderAnimation>
  );
}
