import React, { useState } from "react";
import {
  AddLocationButton,
  InfoButton,
  LocationChip,
  LocationChipContainer,
  SearchLocationContainer,
  SearchWithLabelContainer,
  SubTitle,
} from "../../../style/dice.style";
import { Icon } from "@iconify/react";
import { CommonIcon } from "../../../Utils/CommonIcon";
import Menu from "@mui/material/Menu";
import { SwitchWithTitle } from "../../../style/common.style";
import CustomSwitch from "../../../component/CommonComponet/CustomSwitch";
import CheckBox from "../../../component/basic/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import {
  changeSearchedLocation,
  onClearAllLocation,
} from "../../../feature/store/DiceStore";
import { useAlert } from "react-alert";
import { CommonString } from "../../../Utils/CommonString";
import LocationAutoComplete from "./LocationAutoComplete";

export default function AddLocationComponent() {
  const dispatch = useDispatch();
  const alert = useAlert();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [location, setLocation] = useState("");
  const [distance, setDistance] = useState(50);
  const [uom, setUom] = useState("mi");
  const searchedLocation = useSelector(
    (state) => state.DiceStore.searchedLocation
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function onAddLocation() {
    if (location === "") {
      alert.show("Please add location name and then try again", {
        type: CommonString.info,
      });
      return;
    }
    const exists = searchedLocation.some((e) => e.location_name === location);
    if (exists) {
      alert.show("This Location already exist", { type: CommonString.info });
      return;
    }
    var sendData = {
      location_name: location,
      distance: distance,
      uom: uom,
    };
    dispatch(changeSearchedLocation(sendData));
    setLocation("");
    setDistance("");
    setUom("mi");
  }
  function onClickOnCancel(data) {
    const updatedArray = searchedLocation.filter(
      (item) => item.location_name !== data.location_name
    );
    dispatch(onClearAllLocation(updatedArray));
  }

  return (
    <>
      <div style={{ display: "flex", width: "100%" }}>
        <SearchLocationContainer>
          <Icon icon={CommonIcon.map} />
          <LocationAutoComplete getLocation={(e) => setLocation(e)} />
          {/* <input
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            placeholder="Location (city, state, zip, time zone)"
          /> */}
        </SearchLocationContainer>
        <SearchWithLabelContainer>
          <div>
            <label>Distance</label>
            <input
              value={distance}
              onChange={(e) => setDistance(e.target.value)}
              type="number"
              placeholder="0"
            />
          </div>
          <div class="styled-select">
            <label for="uom">UOM</label>
            <select
              value={uom}
              onChange={(e) => setUom(e.target.value)}
              id="uom"
            >
              <option value="mi">mi</option>
              <option value="km">km</option>
            </select>
          </div>
        </SearchWithLabelContainer>
        <AddLocationButton onClick={() => onAddLocation()}>+</AddLocationButton>
        <InfoButton>
          <Icon icon="icon-park-outline:info" />
        </InfoButton>
        <SearchWithLabelContainer>
          <div class="styled-select">
            <label>Willing To Relocate</label>
            <p
              onClick={handleClick}
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
                cursor: "pointer",
                justifyContent: "end",
              }}
            >
              -Select- <Icon icon="mdi:arrow-down-drop" />
            </p>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <p className="menuItem">
                <CheckBox
                  // onClick={handleInputChange}
                  // value={formData.job_create}
                  label={"Willing To Relocate Anywhere"}
                  name="Willing To Relocate Anywhere"
                />
              </p>
              <p className="menuItem">
                <CheckBox
                  // onClick={handleInputChange}
                  // value={formData.job_create}
                  label={"Willing To Relocate To Included Location(s)"}
                  name="Willing To Relocate To Included Location(s)"
                />
              </p>
              <p className="menuItem">
                <SwitchWithTitle style={{ marginTop: 0 }}>
                  <CustomSwitch
                    checked={false}
                    // onChange={(e) =>
                    //   updateValue("_is_multiple", e.target.checked)
                    // }
                  />
                  {"Include Candidates Living in Location(s) Searched"}
                </SwitchWithTitle>{" "}
              </p>
            </Menu>
          </div>
        </SearchWithLabelContainer>
      </div>
      {searchedLocation.length === 0 ? (
        <></>
      ) : (
        <SubTitle>
          <b>Search Criteria</b>
        </SubTitle>
      )}
      <LocationChipContainer>
        {searchedLocation.map((singleData) => (
          <LocationChip>
            <span>{`${singleData.location_name} (${singleData.distance} ${singleData.uom})`}</span>
            <Icon
              onClick={() => onClickOnCancel(singleData)}
              icon={CommonIcon.close}
            />
          </LocationChip>
        ))}
      </LocationChipContainer>
    </>
  );
}
