import ApiUtils from "../APICallServices/APIUtils";

export default class JobProvider {
  constructor() {
    this.Apiprovider = new ApiUtils();
  }
  getJobList = (page) => {
    return this.Apiprovider.init().get(`job/get-all-jobs?page=${page}`);
  };
  getOpenJobList = () => {
    return this.Apiprovider.init().get(`v2/partner-job-relation/get-open-job`);
  };
  // getJobListByStatus = (data, page) => {
  //   return this.Apiprovider.init().post(
  //     `job/get-all-jobs-by-status?page=${page}`,
  //     data
  //   );
  // };
  // getJobListByStatus = (data, page) => {
  //   return this.Apiprovider.init().post(`v2/jobs/get-all-filter/${page}`, data);
  // };
  getJobListByStatus = (data, page) => {
    return this.Apiprovider.init().post(
      `v2/jobs/get-all-filter-by-condition/${page}`,
      data
    );
  };
  // addNewJob = (data) => {
  //   return this.Apiprovider.init().post("job/add-job", data);
  // };
  // addNewJob = (data) => {
  //   return this.Apiprovider.init().post("v2/jobs/add-job", data);
  // };
  addNewJob = (data) => {
    return this.Apiprovider.init().post("v2/jobs/add-new-job", data);
  };
  updateJob = (data) => {
    return this.Apiprovider.init().post("v2/jobs/update-job", data);
  };
  deleteJob = (data) => {
    return this.Apiprovider.init().post("job/delete-job", data);
  };
  // getSinglejob = (data) => {
  //   return this.Apiprovider.init().post("job/get-single-job", data);
  // };
  getSinglejob = (data) => {
    return this.Apiprovider.init().post("v2/jobs/get-single-job", data);
  };
  // getAllCountList = () => {
  //   return this.Apiprovider.init().get("job/get-all-count");
  // };
  getAllCountList = () => {
    return this.Apiprovider.init().get("v2/submissionV2/count-all");
  };
  // updateJobStatus = (data) => {
  //   return this.Apiprovider.init().post("job/change-status", data);
  // };
  updateJobStatus = (data) => {
    return this.Apiprovider.init().post("v2/jobs/change-status", data);
  };
  checkJobExist = (data) => {
    return this.Apiprovider.init().post("Job/check-job-exist", data);
  };
  // searchJob = (data, page) => {
  //   return this.Apiprovider.init().post(`job/search-job?page=${page}`, data);
  // };
  searchJob = (data, page) => {
    return this.Apiprovider.init().post(`v2/jobs/search-job/${page}`, data);
  };
  attachFile = (data) => {
    return this.Apiprovider.init().post(`job/add-job-attachment`, data);
  };
  uploadAttachFile = (data) => {
    return this.Apiprovider.init().post(`upload-attachment`, data);
  };
  getAttachmentByID = (data) => {
    return this.Apiprovider.init().post(`job/get-job-attachment`, data);
  };
  deleteAttachmentByID = (data) => {
    return this.Apiprovider.init().post(`job/delete-job-attachment`, data);
  };
  // searchByClientID = (data) => {
  //   return this.Apiprovider.init().post(
  //     `job/search-job-by-client-position-id`,
  //     data
  //   );
  // };
  searchByClientID = (data) => {
    return this.Apiprovider.init().post(
      `v2/jobs/search-job-by-client-position-id`,
      data
    );
  };
  // getAllJobByStatus = (data) => {
  //   return this.Apiprovider.init().post("job/search-job-by-user", data);
  // };
  // getAllJobByStatus = (data) => {
  //   return this.Apiprovider.init().post(
  //     "v2/jobs/search-job-by-recruiter-id",
  //     data
  //   );
  // };
  getAllJobByStatus = (data) => {
    return this.Apiprovider.init().post(
      "v2/jobs/get-job-by-recruiter-id",
      data
    );
  };
  onAddPartnerInJob = (data) => {
    return this.Apiprovider.init().post("v2/jobs/add-new-partner", data);
  };
  getPartnerByJob = (data) => {
    return this.Apiprovider.init().post("job/get-partners-by-job", data);
  };
  getMultipleRecruiterJob = (data) => {
    return this.Apiprovider.init().post(
      "v2/partner-job-relation/get_job_recruiter_filter",
      data
    );
  };
}
