import React, { useState } from "react";
import CustomButton from "../CommonComponet/Button";
import FormInput from "../CommonComponet/FormInput";
import SelectTextFields from "../CommonComponet/SelectTextFields";
import { TEXT_TERM } from "../../Utils/constant";
import { CommonButtonName, CommonString } from "../../Utils/CommonString";
import { useAlert } from "react-alert";
import { CommonColor } from "../../Utils/color";
import { TextArea } from "../../style/common.style";

export default function SelectPartner(props) {
  const alert = useAlert();
  const [payRate, setpayRate] = useState();
  const [taxTerm, SetTaxTerm] = useState();
  const [ERROR_NAME, setERROR_NAME] = useState();
  const [comment, setComment] = useState("");

  async function onClickSubmit() {
    if (!validate()) {
      alert.show("Please fill all detail", {
        type: CommonString.info,
      });
      return;
    }
    var data = {
      payRate: payRate,
      tax_term: taxTerm,
      comment: comment,
    };
    props.onSubmit(data);
  }

  function validate() {
    if ((taxTerm === null) | (taxTerm === undefined)) {
      setERROR_NAME("tax_term");
      return false;
    }
    if ((payRate === null) | (payRate === undefined)) {
      setERROR_NAME("pay_rate");
      return false;
    }
    setERROR_NAME("");
    return true;
  }

  return (
    <>
      <SelectTextFields
        error={ERROR_NAME === "tax_term" ? "fill" : "" > 0}
        placeholder="Tax Term"
        value={taxTerm || ""}
        onChange={(value) => {
          SetTaxTerm(value);
          setERROR_NAME("");
        }}
        options={TEXT_TERM}
        getOptionLabel={(option) => (option === null ? "" : option)}
      />
      <FormInput
        error={ERROR_NAME === "pay_rate" ? "fill" : "" > 0}
        onChange={(e) => {
          setpayRate(e.target.value);
          setERROR_NAME("");
        }}
        value={payRate || ""}
        placeholder="Pay rate"
      />
      <label>Comment</label>
      <TextArea
        multiple
        onChange={(e) => setComment(e.target.value)}
        value={comment}
        placeholder="Comment"
      />
      <CustomButton
        onClick={() => onClickSubmit()}
        name={CommonButtonName.submit_profile}
      />
    </>
  );
}
