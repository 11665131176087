import styled from "styled-components";
import { CommonColor } from "../Utils/color";
import fire from "../assets/images/fire.gif";

const ResumeContaienr = styled.div`
  padding: 10px 0px;
  text-align: left;
  cursor: pointer;
`;

const ResumeText = styled.div`
  text-decoration: underline;
  color: ${CommonColor.secondary_color};
`;
const FireGif = styled.div`
  position: absolute;
  background-image: url(${fire});
  background-repeat: repeat;
  width: 99vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: ${(props) => (props.isVisible ? "block" : "none")};
`;
const AboutRecruiter = styled.div`
  margin-top: 7px;
`;
const SubmissionTitle = styled.h5`
  color: ${CommonColor.secondary_color};
  margin-left: 10px;
  margin-bottom: 15px !important;
  text-align: center;
`;
export {
  ResumeContaienr,
  ResumeText,
  FireGif,
  AboutRecruiter,
  SubmissionTitle,
};
