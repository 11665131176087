import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Container, MainScreen } from "./StyledView";
import CommonBaseModal from "../Modal/CommonBaseModal";
import LoaderForTable from "../CommonComponet/LoaderForTable";
import JobHome from "../jobs/JobHome";
import JobSearch from "../jobs/JobSearch";
import JobCustomController from "../../controller/JobCustomController";

export default function JobsView() {
  const { getFormView, onCloseJob } = JobCustomController();

  const initial_loader = useSelector((state) => state.counter.initial_loader);

  return (
    <Container>
      {initial_loader ? <LoaderForTable /> : <></>}
      <CommonBaseModal
        handleClose={() => onCloseJob()}
        zIndex={5}
        children={getFormView()}
      />
      <JobSearch />
      <MainScreen>
        <JobHome />
      </MainScreen>
    </Container>
  );
}
