import { createSlice } from "@reduxjs/toolkit";

export const DiceStore = createSlice({
  name: "DiceStore",
  initialState: {
    searchedLocation: [],
    filter_data: {
      educationDegree: "",
      hasEmail: true,
      hasPhoneNumber: true,
      lastActive: 0,
      yearsExperience: { min: 0, max: 0 },
      jobTitle: "",
    },
    dice_profile_filter_data: {
      onlyWithSecurityClearance: false,
      workPermit: "",
      employmentType: "",
      dateResumeLastUpdated: 0,
    },
  },
  reducers: {
    changeSearchedLocation: (state, action) => {
      state.searchedLocation.push(action.payload);
    },
    onClearAllLocation: (state, action) => {
      state.searchedLocation = action.payload;
    },
    onChangeFilterData: (state, action) => {
      state.filter_data = action.payload;
    },
    onChangeDiceProfileFilterData: (state, action) => {
      state.dice_profile_filter_data = action.payload;
    },
  },
});

export const {
  changeSearchedLocation,
  onClearAllLocation,
  onChangeFilterData,
  onChangeDiceProfileFilterData,
} = DiceStore.actions;

export default DiceStore.reducer;
