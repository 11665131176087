import React from "react";
import ExperienceWithSkill from "./Component/ExperienceWithSkill";
import { Grid } from "@mui/material";
import { Redirect } from "react-router-dom";

export default function CandidateProfile() {
  return (
    <div>
      <Redirect to={"/dice"}>
        <p>back</p>
      </Redirect>
      {/* <Redirect to="/dice">
        <p>back</p>
      </Redirect> */}
      <h5>Summary</h5>
      <h5>Skills</h5>
      <Grid container spacing={1}>
        {[1, 2, 3, 4, 5, 7, 8, 9, 10, 11, 12].map((data) => (
          <ExperienceWithSkill
            skill={"Cloude"}
            year={"2019"}
            experience={"10"}
          />
        ))}
      </Grid>
      <h5>Work History</h5>
    </div>
  );
}
