// src/App.js
import React, { useEffect } from "react";
import Routes from "./Routes";
import { Provider } from "react-redux";
import store from "./store/store";
import usePreventZoom from "./Utils/PreventZoom";
import { getTokenFromDevice } from "./services/firebase"; // Adjust the path based on your project structure
import { CommonStringForLocalStorage } from "./Utils/CommonString";
import RecruiterProvider from "./services/provider/RecruiterProvider";
import 'semantic-ui-css/semantic.min.css';

export default function App({ hideLoader }) {
  let recruiterProvider = new RecruiterProvider();

  useEffect(() => {
    try {
      getFbToken();
    } catch (e) {
      console.log("error in getting firebase token : =>", e);
    }
  }, []);

  async function getFbToken() {
    // let fbToken = await getTokenFromDevice();
    // addFirebaseToken(fbToken);
  }

  async function addFirebaseToken(token) {
    // let user = localStorage.getItem(CommonStringForLocalStorage.user);
    // var userDetails = JSON.parse(user);
    // if (!userDetails) return;
    // console.log(token);
    // let data = {
    //   token: token,
    //   user_id: userDetails.user._id,
    //   device_type: "WEB-ATS",
    //   level_value: userDetails.level.value,
    // };
    // console.log(data);
    // return;
    // var res = await recruiterProvider.addToken(data);
    // localStorage.setItem(
    //   CommonStringForLocalStorage.token_for_notification,
    //   JSON.stringify(res)
    // );
    // console.log(res);
  }

  return (
    <Provider store={store}>
      {usePreventZoom()}
      <Routes />
    </Provider>
  );
}
