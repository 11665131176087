import React from "react";
import CustomTooltip from "../CommonComponet/Tooltip";
import { ListItem, ListItemIcon, ListItemText } from "../../style/menu.style";
import { CommonString } from "../../Utils/CommonString";
import { Div } from "../../style/common.style";

export default function MenuItem(props) {
  return props.isVisible === true ? (
    <ListItem onClick={props.onClick} isActive={props.isActive}>
      <ListItemIcon isActive={props.isActive}>
        {props.open ? (
          props.icon
        ) : (
          <CustomTooltip
            placement={CommonString.right_end_placement}
            title={props.name}
            name={props.icon}
          />
        )}
      </ListItemIcon>
      <ListItemText isActive={props.isActive}>{props.name}</ListItemText>
    </ListItem>
  ) : (
    <Div />
  );
}
