import React from 'react'
import { GroupButtonContainer } from '../../style/common.style';
import OutlinedButton from './OutlinedButton';
import SolidBtnForGroupContainer from '../SolidBtnForGroup';

export default function ButtonGroup(props) {
    return (
        <GroupButtonContainer>
            <SolidBtnForGroupContainer width={props.width} onClick={props.solidBtnClick} name={props.solidBtnName} />
            <OutlinedButton width={props.width} onClick={props.outlinedBtnClick} name={props.outlinedBtnName} />
        </GroupButtonContainer>
    )
}
