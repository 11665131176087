import React from "react";
import { getUrl } from "../../Utils/validation";
import { useSelector } from "react-redux";

export default function ApplicantDocument(props) {
  const applicant = useSelector(
    (state) => state.ApplicantStore.selectedApplicant
  );
  return applicant == null ? (
    <div></div>
  ) : (
    <iframe
      src={getUrl(applicant.resume_link)}
      all
      style={{
        height: props.height ? props.height : "88vh",
        width: "100%",
        paddingTop: 10,
      }}
      onLoad={(e) => {}}
    />
  );
}
