import React, { useState, useEffect } from "react";
import TopMenu from "../Menu/TopMenu";
import ClientDataTable from "../DataTable/ClientDataTable";
import { Container, MainScreen, TopFixedMenu } from "./StyledView";
import { useSelector, useDispatch } from "react-redux";
import ClientController from "../../controller/ClientController";
import { getClientData } from "../../feature/store/ReduxStore";
import { SEARCH_CLIENT } from "../../Utils/enum";
import { onChangeIsLoading } from "../../feature/store/LevelReduxStore";
import InitialLoader from "../CommonComponet/Loader/InitialLoader";
import LoaderForTable from "../CommonComponet/LoaderForTable";

export default function ClientView() {
  const clientController = new ClientController();
  const dispatch = useDispatch();
  const clientlList = useSelector((state) => state.counter.clientData);
  const initial_loader = useSelector((state) => state.counter.initial_loader);
  const [isLoading, setisLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchText, setsearchText] = useState();

  useEffect(() => {
    setSearchResult(clientlList);
    getData();
  }, []);

  async function getData() {
    dispatch(onChangeIsLoading(true));
    if (clientlList.length === 0) setisLoading(true);
    var data = await clientController.getClients();
    setSearchResult(data);
    dispatch(getClientData(data));
    setisLoading(false);
    dispatch(onChangeIsLoading(false));
  }

  function onSearch(search_text) {
    setsearchText(search_text);
    setisLoading(true);
    const result = clientlList.filter(
      (client) =>
        client.name.toLowerCase().trim().includes(search_text.toLowerCase()) ||
        client.department
          .toLowerCase()
          .trim()
          .includes(search_text.toLowerCase()) ||
        client.location
          .toLowerCase()
          .trim()
          .includes(search_text.toLowerCase()) ||
        client.poc_designation
          .toLowerCase()
          .trim()
          .includes(search_text.toLowerCase())
    );
    setisLoading(false);
    setSearchResult(result);
  }
  function onChangeSearchText(e) {
    if (e.target === undefined) {
      return;
    }
    onSearch(e.target.value);
  }
  return (
    <Container>
      {initial_loader ? <LoaderForTable /> : <></>}
      <TopFixedMenu>
        <TopMenu
          noDisplay={false}
          internalSearch={true}
          placeholder={SEARCH_CLIENT}
          onChange={(e) => onChangeSearchText(e)}
          onRefresh={() => getData()}
        />
      </TopFixedMenu>
      <MainScreen>
        <ClientDataTable
          searchText={searchText}
          isLoading={isLoading}
          searchResult={searchResult}
          onRefresh={() => getData()}
        />
      </MainScreen>
    </Container>
  );
}
