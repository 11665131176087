import React from "react";
import { MainScreen, TopFixedMenu, Container } from "./StyledView";
import TopMenu from "../Menu/TopMenu";
import { SEARCH_DOMAIN } from "../../Utils/enum";
import DomainTable from "../DataTable/Table/DomainTable";

export default function DomainView() {
  return (
    <Container>
      <TopFixedMenu>
        <TopMenu
          placeholder={SEARCH_DOMAIN}
          onChange={(e) => console.log(e)}
          onRefresh={() => console.log()}
        />
      </TopFixedMenu>
      <MainScreen>
        <DomainTable />
      </MainScreen>
    </Container>
  );
}
