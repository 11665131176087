import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Icon } from "@iconify/react";
import { useSelector, useDispatch } from "react-redux";
import { changeSelectedDueDate } from "../../../feature/store/JobReduxStore";
import { dueDateShortBy } from "../../../Utils/constant";
import { CommonIcon } from "../../../Utils/CommonIcon";
import { FilterIcon } from "../../../style/Icon.style";
import { CommonColor } from "../../../Utils/color";

export default function DateFilter() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const selected_due_date = useSelector(
    (state) => state.JobReduxStore.selected_due_date
  );
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    dispatch(changeSelectedDueDate(e));
    setAnchorEl(null);
  };

  function getIsActive(status) {
    if (status === selected_due_date) return true;
    return false;
  }

  return (
    <div onClose={handleClose}>
      <div
        style={{ display: "flex", cursor: "pointer", alignItems: "center" }}
        onClick={handleClick}
      >
        <span style={{ whiteSpace: "nowrap" }}>Due Date</span>
        <FilterIcon icon={CommonIcon.down_arrow} />
      </div>
      <Menu
        style={{ border: "none" }}
        onClose={(e) => handleClose(selected_due_date)}
        anchorEl={anchorEl}
        open={open}
      >
        {dueDateShortBy.map((element, index) => (
          <MenuItem
            key={index}
            style={{
              color: getIsActive(element)
                ? CommonColor.primary_color
                : CommonColor.grey,
              fontWeight: getIsActive(element) ? "600" : "500",
            }}
            onClick={() => handleClose(element)}
          >
            {element}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
