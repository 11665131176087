import { useDispatch } from "react-redux";
import { getPartnerData } from "../feature/store/ReduxStore";
import PartnerProvider from "../services/provider/PartnerProvider";

export default class PartnerController {
  constructor() {
    this.partnerProvider = new PartnerProvider();
    this.dispatch = new useDispatch();
  }
  getPartners = async () => {
    var res = await this.partnerProvider.getPartnerList();
    return res.data.data;
  };
  deletePartner = async (data) => {
    var res = await this.partnerProvider.deletePartner(data);
    return res;
  };
  addPartner = async (data) => {
    var res = await this.partnerProvider.addNewPartner(data);
    return res;
  };
  updatePartner = async (data) => {
    var res = await this.partnerProvider.updatePartner(data);
    return res;
  };
  getPartnerByClientID = async (data) => {
    var res = await this.partnerProvider.getPartnerByClient(data);
    return res;
  };
  getPartnerData = async () => {
    var res = await this.partnerProvider.getPartnerList();
    this.dispatch(getPartnerData(res.data.data));
    // return res;
  };
}
