import React from "react";
import MenuList from "./MenuList";
import { LeftMenu } from "../../style/menu.style";

export default function Menu(props) {
  return (
    <LeftMenu>
      <MenuList open={props.open} onClick={(e) => props.onClick(e)} />
    </LeftMenu>
  );
}
