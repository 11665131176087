import ApiUtils from "../APICallServices/APIUtils";

export default class Uploader {
  constructor() {
    this.Apiprovider = new ApiUtils();
  }
  uploadResume = (data) => {
    return this.Apiprovider.init().post("upload", data);
  };
}
