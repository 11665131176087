import { createSlice } from "@reduxjs/toolkit";

export const ApplicantStore = createSlice({
  name: "ApplicantStore",
  initialState: {
    searchJobText: "",
    search_result: [],
    skill: "",
    detail: "",
    isAddApplicant: false,
    is_searching: false,
    applicants: [],
    searchResult: [],
    pageNumber: 1,
    searchPageNumber: 1,
    selectedApplicant: null,
    is_open_modal: false,
    button_name: "",
    job_data_for_applicant: [],
    is_resume_open: false,
    selected_file: null,
    raw_resume: "",
    resume_url: null,
    job_list_for_applicant: [],
    is_loading_job: false,
    email: "",
    phone: "",
  },
  reducers: {
    changeEmailList: (state, action) => {
      state.email = action.payload;
    },
    updateApplicantData: (state, action) => {
      var data = state.applicants.map(function (item) {
        return item._id === action.payload._id ? action.payload : item;
      });
      state.applicants = data;
    },
    changePhone: (state, action) => {
      state.phone = action.payload;
    },
    changeLoadingJob: (state, action) => {
      state.is_loading_job = action.payload;
    },
    changeJobListForApplicant: (state, action) => {
      state.job_list_for_applicant = action.payload;
    },
    changeResumeUrl: (state, action) => {
      state.resume_url = action.payload;
    },
    changeRawResume: (state, action) => {
      state.raw_resume = action.payload;
    },
    changeSelectedFileName: (state, action) => {
      state.selected_file = action.payload;
    },
    changeIsResumeOpen: (state, action) => {
      state.is_resume_open = action.payload;
    },
    changeJobData: (state, action) => {
      state.job_data_for_applicant = action.payload;
    },
    changeButtonName: (state, action) => {
      state.button_name = action.payload;
    },
    changeIsOpenModal: (state, action) => {
      state.is_open_modal = action.payload;
    },
    changeIsSearching: (state, action) => {
      state.is_searching = action.payload;
    },
    changeApplicantSearchText: (state, action) => {
      state.searchJobText = action.payload;
    },
    changeSearchApplicant: (state, action) => {
      state.search_result = action.payload;
    },
    changeSkill: (state, action) => {
      state.skill = action.payload;
    },
    changeDetail: (state, action) => {
      state.detail = action.payload;
    },
    changeIsAddApplicant: (state, action) => {
      state.isAddApplicant = action.payload;
    },
    setApplicantData: (state, action) => {
      state.applicants = action.payload.data;
      state.pageNumber = action.payload.page;
    },
    setSearchData: (state, action) => {
      state.searchResult = action.payload.data;
      state.searchPageNumber = action.payload.page;
    },
    setSelectedApplicant: (state, action) => {
      state.selectedApplicant = action.payload;
    },
  },
});

export const {
  updateApplicantData,
  changeEmailList,
  changePhone,
  changeLoadingJob,
  changeJobListForApplicant,
  changeResumeUrl,
  changeRawResume,
  changeSelectedFileName,
  changeIsResumeOpen,
  changeJobData,
  changeButtonName,
  changeIsOpenModal,
  changeIsSearching,
  changeIsAddApplicant,
  changeApplicantSearchText,
  changeSearchApplicant,
  changeSkill,
  changeDetail,
  setApplicantData,
  setSearchData,
  setSelectedApplicant,
} = ApplicantStore.actions;

export default ApplicantStore.reducer;
