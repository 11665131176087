import React, { useEffect, useRef, useState } from "react";
import { Container, MainScreen, TopFixedMenu } from "../View/StyledView";
import TopMenu from "../Menu/TopMenu";
import DailyReportTable from "./DailyReportTable";
import {
  onChangeIsModalOpen,
  onChangeModalStatus,
  onChangeSelectData,
} from "../../feature/store/LevelReduxStore";
import { useDispatch, useSelector } from "react-redux";
import BaseModal from "../Modal/BaseModal";
import {
  ADD_DAILY_REPORT,
  DAILY_REPORT,
  EDIT_CALL_REPORT,
  VIEW_INTERVIEW_DETAIL,
  VIEW_JOBS,
  VIEW_JOB_DETAIL,
  VIEW_SUBMISSION,
  VIEW_SUBMISSION_DETAIL,
} from "../../Utils/enum";
import JobController from "../../controller/JobController";
import AddDailyReport from "./AddDailyReport";
import {
  changeIsOpenForTable,
  getCallReportList,
  onAddDataInCallReport,
  onChangeIsLoading,
  onChangeModalStatusForTable,
  onChangeOpenPositionList,
  onChangeSelectDataForTable,
  onDeleteDataInCallReport,
  onEditDataInCallReport,
} from "../../feature/store/ReportStore";
import ReportController from "../../controller/ReportController";
import SubmissionDetails from "./SubmissionDetails";
import SubmissionViewDetails from "../ViewDetails/SubmissionViewDetails";
import JobDetailForDailyReport from "./JobDetailForDailyReport";
import JobViewDetails from "../ViewDetails/JobViewDetails";
import ModalForDailyReportDetail from "./ModalForDailyReportDetail";
import InterviewDetailsForDailyReport from "./InterviewDetailsForDailyReport";
import RecruiterController from "../../controller/RecruiterController";
import { onCheckLevelAccessibility } from "../../Utils/utilitiFunction";
import { getRecruiterData } from "../../feature/store/ReduxStore";
import CallReportFilter from "./CallReportFilter";
import moment from "moment";
import { TYPE } from "../../Utils/constant";
import { DateObject } from "react-multi-date-picker";
import { confirmAlert } from "react-confirm-alert";
import DeleteConfirmation from "../Modal/DeleteConfirmation";
import { AddButton } from "../../style/button.style";
import {
  FlexContainer,
  FlexContainerForFilter,
} from "../../style/common.style";
import { CommonString } from "../../Utils/CommonString";
import { useAlert } from "react-alert";
function getCurrentWeekDates() {
  const currentDate = new Date();
  const startOfWeek = currentDate.getDate() - currentDate.getDay(); // Monday as the first day
  const weekDates = [];

  for (let i = 0; i < 7; i++) {
    const date = new Date(currentDate);
    date.setDate(startOfWeek + i);
    weekDates.push(date);
  }
  var dates = [weekDates[0], weekDates[6]];
  return dates;
}

export default function DailyReportView(props) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const jobController = new JobController();
  const reportController = new ReportController();
  const recruiterController = new RecruiterController();
  const date = useRef(new DateObject());
  const hasAccess = onCheckLevelAccessibility();
  const user_data = useSelector((state) => state.counter.user_data);
  const modal_status = useSelector(
    (state) => state.LevelStoreRedux.modal_status
  );
  const modal_status_for_Table = useSelector(
    (state) => state.ReportStoreRedux.modal_status
  );
  const select_data = useSelector((state) => state.LevelStoreRedux.select_data);
  const is_modal_open = useSelector(
    (state) => state.LevelStoreRedux.is_modal_open
  );

  const defaultWeekDates = getCurrentWeekDates();
  const [selectedDates, setSelectedDates] = useState([]);
  const [totalCallCount, setTotalCallCount] = useState(0);
  const firstDateOfMonth = (date = new Date()) =>
    new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDateOfMonth = (date = new Date()) =>
    new Date(date.getFullYear(), date.getMonth() + 1, 0);
  useEffect(() => {
    setSelectedDates(date.current);
  }, []);
  useEffect(() => {
    // setSelectedDates(date.current);
    RecruiterValueSet(user_data);
    getList();
    // if (onCheckLevelAccessibility()) {
    //   getData();
    // }
  }, [user_data]);

  const data = useRef({
    from_date: firstDateOfMonth(new Date()),
    to_date: lastDateOfMonth(new Date()),
    recruiter_id: user_data?._id,
  });
  // async function getData() {
  //   var neData = temp_recruiter_list.filter((singleData) => singleData?.is_recruiter === 1);
  //   dispatch(getRecruiterData(neData));
  // }
  const onViewModal = async (modalSt, data) => {
    dispatch(onChangeIsModalOpen(true));
    dispatch(onChangeModalStatus(modalSt));
    dispatch(onChangeSelectData(data));
  };
  async function getOpenJobList() {
    var data = await jobController.getOpenJobs();
    dispatch(onChangeOpenPositionList(data));
  }
  async function getList() {
    var sendData = {
      user_id:
        data.current.recruiter_id === undefined
          ? user_data?._id
          : data.current.recruiter_id,
      from_date: data.current.from_date,
      to_date: data.current.to_date,
    };
    if (sendData.user_id === "") {
      alert.show(CommonString.please_select_recruiter, {
        type: CommonString.error,
      });
      return;
    }
    dispatch(onChangeIsLoading(true));
    var res = await reportController.getCallReportByUserIdAndDate(sendData);
    setTotalCallCount(res?.totalCalls);
    dispatch(getCallReportList(res?.data));
    dispatch(onChangeIsLoading(false));
  }
  async function onAddData(data) {
    dispatch(changeIsOpenForTable(false));
    if (modal_status_for_Table === EDIT_CALL_REPORT) {
      let res = await reportController.editCallReport(data);
      dispatch(onEditDataInCallReport(res));
      return;
    }
    var res = await reportController.addCallReport(data);
    dispatch(onAddDataInCallReport(res));
    // dispatch(getCallReportList(data));
  }
  function getModalView() {
    if (modal_status === VIEW_SUBMISSION) {
      return <SubmissionViewDetails data={select_data} />;
    }
    if (modal_status === VIEW_JOB_DETAIL) {
      return <JobViewDetails data={select_data} />;
    }
    return <AddDailyReport />;
  }
  function getView() {
    if (modal_status_for_Table === VIEW_SUBMISSION_DETAIL) {
      return <SubmissionDetails onViewModal={onViewModal} />;
    }
    if (modal_status_for_Table === VIEW_JOBS) {
      return <JobDetailForDailyReport onViewModal={onViewModal} />;
    }
    if (modal_status_for_Table === VIEW_INTERVIEW_DETAIL) {
      return <InterviewDetailsForDailyReport onViewModal={onViewModal} />;
    }
    if (modal_status_for_Table === EDIT_CALL_REPORT) {
      return (
        <AddDailyReport onAddData={onAddData} getOpenJobList={getOpenJobList} />
      );
    }
    return (
      <AddDailyReport onAddData={onAddData} getOpenJobList={getOpenJobList} />
    );
  }
  function getDateRange(date, typeSent) {
    let from_date, to_date;
    if (typeSent === TYPE[0]) {
      from_date = moment(date).startOf("year").format();
      to_date = moment(date).endOf("year").format();
    } else if (typeSent === TYPE[1]) {
      const firstDateOfMonth = (date = new Date()) =>
        new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDateOfMonth = (date = new Date()) =>
        new Date(date.getFullYear(), date.getMonth() + 1, 0);
      from_date = firstDateOfMonth(new Date(date));
      to_date = lastDateOfMonth(new Date(date));
    } else if (typeSent === TYPE[2]) {
      if (moment(date[0]).format() === moment(date[1]).format()) {
        const today = moment();
        const begginingOfCurrentWeek = today.startOf("week").format();
        const endOfWeek = today.endOf("week").format();
        from_date = begginingOfCurrentWeek;
        to_date = endOfWeek;
      } else {
        from_date = moment(date[0]).format();
        to_date = moment(date[1]).format();
      }
    } else if (typeSent === TYPE[3]) {
      from_date = date[0];
      to_date = date[0];
    } else if (typeSent === TYPE[4]) {
      from_date = moment(date[0]).format();
      to_date = moment(date[1]).format();
    } else if (typeSent === TYPE[5]) {
      from_date = moment(date).subtract("months", 3).startOf("month").format();
      to_date = moment().subtract("months", 1).endOf("month").format();
    } else {
      from_date = moment(date).subtract("months", 6).startOf("month").format();
      to_date = moment().subtract("months", 1).endOf("month").format();
    }
    return { from_date, to_date };
  }
  function setDate(dateObject, type) {
    const date = Array.isArray(dateObject)
      ? dateObject.map((date) => new Date(date).toISOString())
      : [new Date(dateObject).toISOString()];
    let { from_date, to_date } = getDateRange(date, type);
    data.current = {
      recruiter_id: data.current.recruiter_id,
      from_date: moment(from_date).format("YYYY-MM-DD"),
      to_date: moment(to_date).format("YYYY-MM-DD"),
    };
  }
  function TypeSetter(value) {
    if (value === "Week") {
      setSelectedDates(defaultWeekDates);
    } else {
      setSelectedDates(date.current);
    }
    let { from_date, to_date } = getDateRange(date.current, value);
    data.current = {
      recruiter_id: data.current.recruiter_id,
      from_date: moment(from_date).format("YYYY-MM-DD"),
      to_date: moment(to_date).format("YYYY-MM-DD"),
    };
  }
  function RecruiterValueSet(value) {
    if (!hasAccess) return;
    data.current = {
      ...data.current,
      recruiter_id: value === undefined ? "" : value?._id,
    };
  }
  function onDelete(data) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <DeleteConfirmation
            name={""}
            onClose={onClose}
            onDelete={() => {
              onDeleteData(data);
              onClose();
              dispatch(onChangeIsModalOpen(false));
            }}
          />
        );
      },
    });
  }
  async function onDeleteData(data) {
    let res = await reportController.deleteCallReport({ _id: data?._id });
    if (res?.status === 200) {
      dispatch(onDeleteDataInCallReport(data?._id));
    }
  }
  // function onClick(modalSt, data) {
  //   dispatch(onChangeIsModalOpen(true));
  //   dispatch(onChangeModalStatus(modalSt));
  //   dispatch(onChangeSelectData(data));
  // }
  function onClickModal(modalSt, data) {
    dispatch(changeIsOpenForTable(true));
    dispatch(onChangeModalStatusForTable(modalSt));
    dispatch(onChangeSelectDataForTable(data));
  }

  return (
    <Container>
      <TopFixedMenu>
        <TopMenu
          placeholder={DAILY_REPORT}
          noDisplay={false}
          status={DAILY_REPORT}
        />
      </TopFixedMenu>
      <MainScreen>
        <BaseModal
          isBig={true}
          open={is_modal_open}
          formTitle={modal_status}
          handleClose={() => dispatch(onChangeIsModalOpen(!is_modal_open))}
        >
          {getModalView()}
        </BaseModal>
        <ModalForDailyReportDetail view={getView()} />
        <FlexContainerForFilter className="ml-5">
          <CallReportFilter
            getList={getList}
            data={data}
            RecruiterValueSet={RecruiterValueSet}
            date={selectedDates}
            getDateRange={getDateRange}
            setDate={setDate}
            TypeSetter={TypeSetter}
          />

          <FlexContainer style={{ marginTop: 5, display: "flex" }}>
            <p style={{ marginLeft: 10, marginRight: 5 }}>
              {CommonString.total_calls} : {totalCallCount}
            </p>
            <AddButton
              marginRight={true}
              variant="outlined"
              onClick={() => onClickModal(ADD_DAILY_REPORT, null)}
            >
              + {DAILY_REPORT}
            </AddButton>
          </FlexContainer>
        </FlexContainerForFilter>
        <DailyReportTable
          onClick={onClickModal}
          onDelete={onDelete}
          onViewModal={onViewModal}
        />
      </MainScreen>
    </Container>
  );
}
