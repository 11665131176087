import React from "react";
import { TableName } from "./StyledDataTable";
import AppLoader from "../CommonComponet/Loader";
import { AddButton, AddButtonForMobile } from "../../style/button.style";
import { CommonIcon } from "../../Utils/CommonIcon";
import { AddButtonIcon } from "../../style/Icon.style";
import { TableNameContainer } from "../../style/table.styled";
import { useSelector } from "react-redux";

export default function TableToolbar(props) {
  const is_search_loading = useSelector(
    (state) => state.SubmissionReduxStore.is_search_loading
  );
  return (
    <TableNameContainer>
      <TableName>
        {props.title}
        {is_search_loading === true ? (
          <AppLoader pageNumber={is_search_loading ? 1 : props.pageNumber} />
        ) : (
          <></>
        )}
      </TableName>
      {props.filter}
      {(props.isVisible || props.isVisible === null) && !props.isApplicant ? (
        <>
          <AddButton variant="outlined" onClick={props.onClick}>
            + {props.buttonName}
          </AddButton>
          <AddButtonForMobile variant="outlined" onClick={props.onClick}>
            <AddButtonIcon icon={CommonIcon.plus} />
          </AddButtonForMobile>
        </>
      ) : (
        <div />
      )}
    </TableNameContainer>
  );
}
