import React, { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { EDIT, DELETE, VIEW } from "../../Utils/enum";
import { ConfirmProvider } from "material-ui-confirm";
import { VerticalMenuIcon } from "../../style/menu.style";

const ITEM_HEIGHT = 48;
export default function VerticalMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const onClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const onClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const onSelect = (e, status) => {
    e.stopPropagation();
    var data = status.toLowerCase();
    if (status === "Clone") {
      props.onClone();
    }
    if (data === EDIT) {
      props.onEdit();
    }
    if (data === DELETE) {
      props.onDelete();
    }
    if (data === VIEW) {
      props.onView();
    }
    setAnchorEl(null);
  };

  return props.data === undefined || props.data?.length === 0 ? (
    <></>
  ) : (
    <>
      <VerticalMenuIcon onClick={onClick} />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {props.data.map((option) => (
          <ConfirmProvider key={option}>
            <MenuItem onClick={(e) => onSelect(e, option)}>{option}</MenuItem>
          </ConfirmProvider>
        ))}
      </Menu>
    </>
  );
}
