import { createSlice } from "@reduxjs/toolkit";

export const UserStore = createSlice({
  name: "UserStore",
  initialState: {
    selected_user: [],
    get_user_data_while_adding_user: [],
    internal_team_search_result: [],
    internal_team_list_by_domain: [],
    selected_domain_list: [],
    domain_selected_team: { domain: "ALL", team: [] },
  },
  reducers: {
    onChangeSelectesDomainList: (state, action) => {
      state.selected_user = action.payload;
    },
    onChangeSelectUser: (state, action) => {
      state.selected_user = action.payload;
    },
    getUserDataWhileAddingUser: (state, action) => {
      state.get_user_data_while_adding_user = action.payload;
    },
    onGetInternalTeamSearchResult: (state, action) => {
      state.internal_team_search_result = action.payload;
    },
    onGetInternalTeamListByDomain: (state, action) => {
      state.internal_team_list_by_domain = action.payload;
    },
    onGetDomainSelectedTeam: (state, action) => {
      state.domain_selected_team = action.payload;
    },
  },
});

export const {
  onGetDomainSelectedTeam,
  onGetInternalTeamListByDomain,
  onGetInternalTeamSearchResult,
  onChangeSelectUser,
  getUserDataWhileAddingUser,
} = UserStore.actions;

export default UserStore.reducer;
