import { createSlice } from "@reduxjs/toolkit";

export const SubmissionReduxStore = createSlice({
  name: "SubmissionReduxStore",
  initialState: {
    interview_details: [],
    selectedSubmissionData: [],
    single_job: [],
    single_applicant: [],
    status_list: [],
    selected_status: "All",
    modal_status: "",
    is_modal_open: false,
    selected_data: "",
    search_result: [],
    submission_status_list: [],
    selected_submission: null,
    is_update: false,
    project_details: null,
    is_search_loading: false,
    chat_list: [],
    search_chat_list: [],
    submission_data_for_dashboard: [],
    selected_submission_data_for_dashboard: [],
    is_status_change: false,
    selected_submission_data: [],
    table_list: [
      "all",
      "applicant",
      "position_title",
      "job_id",
      "partner",
      "client",
      "recruiter",
      "updated_at",
      "status",
    ],
    filter_data: {
      status: "ALL",
      recruiter_id: "123",
      client_id: "123",
      partner_id: "123",
    },
    submission_status: "",
    temp_partner_data: [],
    temp_client_data: [],
    submission_count: 0,
  },

  reducers: {
    changeSubmissionTotalCount: (state, action) => {
      state.submission_count = action.payload;
    },
    changeTempPartnerData: (state, action) => {
      state.temp_partner_data = action.payload;
    },
    changeTempClientData: (state, action) => {
      state.temp_client_data = action.payload;
    },
    changeSubmissionStatus: (state, action) => {
      state.submission_status = action.payload;
    },
    changeFilterData: (state, action) => {
      state.filter_data = action.payload;
    },
    changeTableList: (state, action) => {
      state.table_list.push(action.payload);
      var newData = state.table_list.filter(
        (item, index) => state.table_list.indexOf(item) === index
      );
      state.table_list = newData;
    },
    changeInitialTableList: (state, action) => {
      state.table_list = action.payload;
    },
    removeFromTableList: (state, action) => {
      var index = state.table_list.indexOf(action.payload);
      if (index !== -1) {
        state.table_list.splice(index, 1);
      }
    },
    updateChatStatus: (state, action) => {
      var data = state.chat_list.map(function (item) {
        return item._id === action.payload._id ? action.payload : item;
      });
      state.chat_list = data;
    },
    onChangeSelectedSubmissionData: (state, action) => {
      state.selected_submission_data = action.payload;
    },
    onChangeIsStatusChange: (state, action) => {
      state.is_status_change = action.payload;
    },
    onChangeSubmissionDataForDashboard: (state, action) => {
      state.submission_data_for_dashboard = action.payload;
    },
    removeChatList: (state, action) => {
      state.chat_list = action.payload;
    },
    changeSearchChatList: (state, action) => {
      state.search_chat_list = action.payload;
    },
    changeChatList: (state, action) => {
      state.chat_list = action.payload;
    },
    addInChatList: (state, action) => {
      state.chat_list.unshift(action.payload);
    },
    changeIsSearchLoading: (state, action) => {
      state.is_search_loading = action.payload;
    },
    changeProjectDetails: (state, action) => {
      state.project_details = action.payload;
    },
    changeIsUpdate: (state, action) => {
      state.is_update = action.payload;
    },
    changeSelectedSubmission: (state, action) => {
      state.selected_submission = action.payload;
    },
    changeSubmissionStatusList: (state, action) => {
      state.submission_status_list = action.payload;
    },
    changeSearchSubmission: (state, action) => {
      state.search_result = action.payload;
    },
    changeIsModalOpen: (state, action) => {
      state.is_modal_open = action.payload;
    },
    changeModalStatus: (state, action) => {
      state.modal_status = action.payload;
    },
    changeSelectedData: (state, action) => {
      state.selected_data = action.payload;
    },
    changeInterviewDetails: (state, action) => {
      state.interview_details = action.payload;
    },
    changeSubmissionData: (state, action) => {
      state.selectedSubmissionData = action.payload;
    },
    changeSingleJob: (state, action) => {
      state.single_job = action.payload;
    },
    changeSingleApplicant: (state, action) => {
      state.single_applicant = action.payload;
    },
    changeStatusList: (state, action) => {
      state.status_list = action.payload;
    },
    changeSelectedStatus: (state, action) => {
      state.selected_status = action.payload;
    },
    updateSubmissionDataForDashboard: (state, action) => {
      var tempData = [];
      state.submission_data_for_dashboard.map(function (item) {
        if (item.submission_id === action.payload.submissions_id) {
          item.call_1_status = action.payload.call_1_status;
          item.call_2_status = action.payload.call_2_status;
          item.call_3_status = action.payload.call_3_status;
        }
        tempData.push(item);
      });
      state.submission_data_for_dashboard = tempData;
    },
  },
  onUpdateSearchStatus: (state, action) => {
    var data = state.search_result.map(function (item) {
      return item._id === action.payload._id ? action.payload : item;
    });
    state.search_result = data;
  },
});

export const {
  changeSubmissionTotalCount,
  changeTempClientData,
  changeTempPartnerData,
  changeSubmissionStatus,
  changeFilterData,
  changeInitialTableList,
  removeFromTableList,
  changeTableList,
  onUpdateSearchStatus,
  onChangeSelectedSubmissionData,
  onChangeIsStatusChange,
  updateSubmissionDataForDashboard,
  onChangeSubmissionDataForDashboard,
  changeSearchChatList,
  updateChatStatus,
  removeChatList,
  addInChatList,
  changeChatList,
  changeIsSearchLoading,
  changeProjectDetails,
  changeIsUpdate,
  changeSelectedSubmission,
  changeSubmissionStatusList,
  changeSearchSubmission,
  changeSelectedData,
  changeIsModalOpen,
  changeModalStatus,
  changeInterviewDetails,
  changeSubmissionData,
  changeSingleJob,
  changeSingleApplicant,
  changeStatusList,
  changeSelectedStatus,
} = SubmissionReduxStore.actions;

export default SubmissionReduxStore.reducer;
