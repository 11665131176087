import React, { useEffect, useState } from "react";
import { SubmissionController } from "../../../controller/SubmissionController";
import EditCallTracker from "../../Modal/EditCallTracker";
import CallTracker from "../../Modal/CallTracker";
import AppLoader from "../../CommonComponet/Loader";
import { VIEW_CALL_TRACKER } from "../../../Utils/enum";

export default function Tracker(props) {
  var submissionController = new SubmissionController();
  const [trackerList, setTrackerList] = useState();
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    var data = {
      _id: props.submission_id,
    };
    var res = await submissionController.getDataById(data);
    setTrackerList(res.data);
    if (res.status === 200) {
      setisLoading(true);
    } else {
      setisLoading(false);
    }
  }

  return (
    <div>
      {isLoading === true ? (
        props.modalStatus === VIEW_CALL_TRACKER ? (
          <EditCallTracker
            isLoading={props.isLoading}
            selectedData={props.selectedData}
            submission_data={props.submission_data}
            trackerList={trackerList}
            handleClose={props.handleClose}
          />
        ) : (
          <CallTracker
            selectedData={props.selectedData}
            trackerList={trackerList}
          />
        )
      ) : (
        <AppLoader />
      )}
    </div>
  );
}
