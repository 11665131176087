import styled from "styled-components";
import { CommonColor } from "../Utils/color";
import { Button } from "@mui/material";

const ButtonForChat = styled(Button)`
  position: absolute !important;
  z-index: 9;
  right: 0;
  margin-top: 70px !important;
  background-color: ${CommonColor.primary_color} !important;
  color: ${CommonColor.white} !important;
  min-width: auto !important;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: ${CommonColor.primary_hover_color} !important;
  }
`;
const ChatContainer = styled.div`
  padding: 20px;
  padding-top: 40px;
  padding-bottom: 0;
  width: 400px;
  height: 100vh;
  overflow: hidden;
`;
const CloseButtonForChat = styled(Button)`
  position: absolute !important;
  z-index: 9;
  right: 0;
  top: 0;
  min-width: auto !important;
  svg {
    width: 20px;
    height: 20px;
    color: ${CommonColor.primary_color};
  }
  //   margin-top: 10px !important;
  //   background-color: ${CommonColor.primary_color} !important;
  //   color: ${CommonColor.white} !important;
`;
const ChatMainContainer = styled.div`
  margin-bottom: 10px;
  border: 1px solid ${CommonColor.light_grey};
  border-radius: 5px;
  padding: 10px;
  &:hover {
    border: 1px solid ${CommonColor.grey};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  }
  p {
    color: ${CommonColor.primary_color};
    font-weight: bold;
    font-size: 11px;
    margin-top: 2px;
  }
  span {
    font-size: 13px;
  }
  hr {
    margin: 0;
  }
`;
const TextWithImgContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  h6 {
    color: ${CommonColor.primary_color};
    font-weight: 600;
    margin-block-start: 0;
    margin-block-end: 0;
  }
  p {
    margin-left: 10px;
    color: ${CommonColor.grey_text};
  }
  hr {
    margin: 10px 0 !important;
  }
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Chip = styled.span`
  padding: 2px 5px;
  border-radius: 14px;
  font-size: 9px !important;
  margin-left: 5px;
  font-weight: bold;
  white-space: nowrap;
`;
const TextWithDot = styled.span`
  // display: flex;
  // align-items: center;
  white-space: nowrap;
  color: ${CommonColor.primary_color};
  svg {
    margin-top: -3px;
    margin-left: 5px;
  }
`;
const SearchContainer = styled.div`
  margin-bottom: 10px;
  button {
    position: absolute;
    right: 20px;
  }
  input{
      border: 1px solid ${CommonColor.light_grey};
  padding: 5.2px;
  }
  form {
    box-shadow: none;
    padding: 0;
    background
  }
`;
const ListContainer = styled.div`
  height: 85vh;
  overflow: auto;
  padding: 0 15px;
  margin: 0 -15px;
`;
export {
  ListContainer,
  SearchContainer,
  TextWithDot,
  Chip,
  FlexContainer,
  ButtonForChat,
  ChatContainer,
  CloseButtonForChat,
  ChatMainContainer,
  TextWithImgContainer,
};
