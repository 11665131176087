import React, { useEffect, useState } from "react";
import TopMenu from "../Menu/TopMenu";
import RecruiterDataTable from "../DataTable/RecruitersDataTable";
import { Container, MainScreen, TopFixedMenu } from "./StyledView";
import { useSelector, useDispatch } from "react-redux";
import RecruiterController from "../../controller/RecruiterController";
import {
  changeInitialLoader,
  getRecruiterData,
  getVisibilityForAdmin,
} from "../../feature/store/ReduxStore";
import { SEARCH_RECRUITER } from "../../Utils/enum";
import { onCheckLevelAccessibilityForAdmin } from "../../Utils/utilitiFunction";
import LoaderForTable from "../CommonComponet/LoaderForTable";

export default function RecruitersView() {
  const recruiterController = new RecruiterController();
  const dispatch = useDispatch();
  const recruiterList = useSelector((state) => state.counter.recruiterData);
  const initial_loader = useSelector((state) => state.counter.initial_loader);
  const [isLoading, setIsLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [searchText, setsearchText] = useState();

  useEffect(() => {
    setSearchResult(recruiterList);
    getData();
    dispatch(getVisibilityForAdmin(onCheckLevelAccessibilityForAdmin()));
  }, []);

  async function getData() {
    dispatch(changeInitialLoader(true));
    if (recruiterList.length === 0) setIsLoading(true);
    var data = await recruiterController.getRecruiters();
    setSearchResult(data);
    dispatch(getRecruiterData(data));
    setIsLoading(false);
    dispatch(changeInitialLoader(false));
  }

  function onSearch(search_text) {
    setsearchText(search_text);
    if (isLoading === true) {
      return;
    }
    setIsLoading(true);
    if (search_text.length < 2) {
      setIsLoading(false);
      if (search_text.length === 0 || recruiterList.length === 0) {
        setSearchResult(recruiterList);
      }
      return;
    }
    const result = recruiterList.filter((recruiter) =>
      recruiter.name.toLowerCase().trim().includes(search_text.toLowerCase())
    );
    setSearchResult(result);
    setIsLoading(false);
  }
  function onChangeSearchText(e) {
    if (e.target === undefined) {
      return;
    }
    onSearch(e.target.value);
  }
  return (
    <Container>
      {initial_loader ? <LoaderForTable /> : <></>}
      {/* <TopFixedMenu>
        <TopMenu
          noDisplay={false}
          internalSearch={true}
          placeholder={SEARCH_RECRUITER}
          onChange={(e) => onChangeSearchText(e)}
          onRefresh={() => getData()}
        />
      </TopFixedMenu> */}
      <MainScreen>
        <RecruiterDataTable
          searchText={searchText}
          isLoading={isLoading}
          searchResult={searchResult}
          onRefresh={() => getData()}
        />
      </MainScreen>
    </Container>
  );
}
