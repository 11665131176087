import React, { useState, useEffect } from "react";
import {
  JobtableForbillrateinput,
  JobtableForbillratetd,
} from "../../style/table.styled";
import SwitchWithText from "./SwitchWithText";

export default function PayRateSectionTile(props) {
  const [isInPercent, setisInPercent] = useState(
    props.partner.is_in_percentage
  );
  const [margin_rate, setmargin_rate] = useState(props.partner.margin ?? 0);
  const [_1099, set_1099] = useState(props.partner._1099 ?? 0);
  const [W2withoutBenifits, set_W2withoutBenifits] = useState(
    props.partner.w2_without_benifit ?? 0
  );
  const [W2withBenifits, set_W2withBenifits] = useState(
    props.partner.w2_with_benifit ?? 0
  );
  const [W2Salarized, setW2Salarized] = useState(
    props.partner.w2_salarized ?? 0
  );

  useEffect(() => {
    setmargin_rate(props.partner.margin);
    set_1099(props.partner._1099);
    setisInPercent(props.partner.is_in_percentage);
    set_W2withoutBenifits(props.partner.w2_without_benifit);
    set_W2withBenifits(props.partner.w2_with_benifit);
    setW2Salarized(props.partner.w2_salarized);
  }, [props.partner]);

  function onChange(value, func) {
    func(value);
  }

  function onSubmitData() {
    var userData = {
      id: props.partner.id,
      name: props.partner.name,
      margin: margin_rate,
      is_in_percentage: isInPercent,
      _1099: _1099.toString(),
      w2_with_benifit: W2withBenifits,
      w2_without_benifit: W2withoutBenifits,
      w2_salarized: W2Salarized,
      working_percentage: "12",
    };
    props.onAddData(userData);
  }

  return (
    <tr key={1}>
      <JobtableForbillratetd>{props.partner.name}</JobtableForbillratetd>
      <JobtableForbillratetd>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <JobtableForbillrateinput
            type="text"
            value={margin_rate}
            onBlur={() => {
              onSubmitData();
            }}
            onChange={(e) => onChange(e.target.value, setmargin_rate)}
            name="Margin"
          />
          <SwitchWithText
            onBlur={() => {
              onSubmitData();
            }}
            check={isInPercent}
            isInPercent={(e) => {
              setisInPercent(e);
            }}
          />
        </div>
      </JobtableForbillratetd>
      <JobtableForbillratetd>
        <JobtableForbillrateinput
          type="text"
          value={_1099}
          onBlur={() => {
            onSubmitData();
          }}
          onChange={(e) => onChange(e.target.value, set_1099)}
          name="1099"
        />
      </JobtableForbillratetd>
      <JobtableForbillratetd>
        <JobtableForbillrateinput
          value={W2withoutBenifits}
          onBlur={() => onSubmitData()}
          onChange={(e) => onChange(e.target.value, set_W2withoutBenifits)}
          name="W2 without Benifits"
        />
      </JobtableForbillratetd>
      <JobtableForbillratetd>
        <JobtableForbillrateinput
          value={W2withBenifits}
          onBlur={() => onSubmitData()}
          onChange={(e) => onChange(e.target.value, set_W2withBenifits)}
          name="W2 with Benifits"
        />
      </JobtableForbillratetd>
      <JobtableForbillratetd>
        <JobtableForbillrateinput
          value={W2Salarized}
          onChange={(e) => onChange(e.target.value, setW2Salarized)}
          onBlur={() => onSubmitData()}
          name="W2 Salarized"
        />
      </JobtableForbillratetd>
    </tr>
  );
}
