import React, { useRef } from "react";
import { CheckBoxContainer, FormLabel } from "../../style/common.style";
import { Grid } from "@mui/material";

export default function CheckBox({ label, value, name, onClick }) {
  const inputRef = useRef(null);
  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click(); // Trigger the click event on the input element
    }
  };
  return (
    <Grid item md={2} xs={4}>
      <CheckBoxContainer>
        <input
          ref={inputRef}
          type="checkbox"
          name={name}
          onClick={onClick}
          onBlur={onClick}
          onChange={onClick}
          checked={value}
          value=""
        />
        <FormLabel onClick={() => handleClick(value)}>{label}</FormLabel>
      </CheckBoxContainer>
    </Grid>
  );
}
