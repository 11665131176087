import React, { useState } from "react";
import styled from "styled-components";
import { AddLocationButton } from "../../../style/dice.style";
import { CommonIcon } from "../../../Utils/CommonIcon";
import { Icon } from "@iconify/react";

// Styled component for the input wrapper and inner elements
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  /* border: 1px solid #ccc; */
  border-radius: 5px;
  margin-top: 10px;
`;
const StyledInput = styled.input`
  border: 1px solid #ccc;
  outline: none;
  font-size: 14px;
  padding: 8px 10px;
  border-radius: 0;
`;
const Title = styled.h1`
  font-size: 14px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 5px 10px;
  width: fit-content;
`;

const CloseButton = styled.button`
  background: lightgray;
  border-radius: 30px;
  border: none;
  font-size: 12px;
  /* padding: 3px 5px; */
  margin-left: 10px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
  svg {
    align-self: center;
    /* margin-top: -2px; */
    /* margin-left: 1px; */
  }
`;
export default function InputButtonWithAddButton(props) {
  const [searchText, setSearchText] = useState("");
  const [selectedData, setSelectedData] = useState([]);

  function onAdd(searchText) {
    if (selectedData.includes(searchText) || searchText === undefined) {
      return;
    }
    setSelectedData([...selectedData, searchText]);
    setSearchText("");
    props.onAdd([...selectedData, searchText]);
  }
  function onRemove(e) {
    var is_exist = selectedData.filter((data) => data !== e);
    setSelectedData(is_exist);
    props.onAdd(is_exist);
  }
  const handleKeyDown = (event, searchText) => {
    if (event.key === "Enter") {
      onAdd(searchText);
    }
  };
  return (
    <div>
      {selectedData.map((data) => (
        <Title onClick={() => onRemove(data)}>
          <p>{data}</p>
          <CloseButton>
            <Icon icon={CommonIcon.close} />
          </CloseButton>
        </Title>
      ))}
      <InputWrapper>
        <StyledInput
          searchText={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          {...props}
          onKeyDown={(e) => handleKeyDown(e, searchText)}
          placeholder={props.placeholder}
        />
        <AddLocationButton size="small" onClick={() => onAdd(searchText)}>
          +
        </AddLocationButton>
      </InputWrapper>
    </div>
  );
}
