import React from "react";
import { Grid } from "@material-ui/core";
import { TitleHR, TitleKey, TitleValue } from "../../../style/common.style";
import { checkNullString } from "../../../Utils/validation";

export default function RowText(props) {
  return props.value === "" ||
    props.value === null ||
    props.value === "--" ||
    props.value === "$" ? (
    <></>
  ) : (
    <div style={{ padding: 3 }}>
      <Grid container {...props}>
        <Grid item xs={6} md={5}>
          <TitleKey color={props.color}>
            {checkNullString(props.title)}{" "}
          </TitleKey>
        </Grid>
        <Grid item xs={6} md={7}>
          <TitleValue color={props.color} isemail={props.isemail}>
            {props.value}
          </TitleValue>
        </Grid>
      </Grid>
      {props.nodivider ? <div /> : <TitleHR />}
    </div>
  );
}
