import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import RowTextHtml from "./view_component/RowTextHtml";
import moment from "moment";
import { ATTACHMENT_BASEURL, DATE_FORMATE } from "../../Utils/constant";
import { useAlert } from "react-alert";
import { useSelector, useDispatch } from "react-redux";
import {
  UPDATE_JOB_STATUS_ERROR,
  UPDATE_JOB_STATUS_IS_SAME,
  UPDATE_JOB_STATUS_SUCCESS,
} from "../../Utils/alertMessage";
import JobController from "../../controller/JobController";
import { addJobData, deletejob } from "../../feature/store/ReduxStore";
import {
  CommonString,
  CommonStringForJob,
  CommonTitle,
} from "../../Utils/CommonString";
import {
  AttachmentDownloadContainer,
  MenuContainer,
  MultiFileContainerCloseIcon,
  MultiFileContainerIcon,
  MultiFileContainerText,
  MultiFileContainerTextContainer,
  OutlinedBtnForAddPartner,
  Title,
} from "../../style/common.style";
import {
  Table,
  TableRow,
  TableTbody,
  TableTd,
  TableTh,
} from "../../style/table.styled";
import { CommonColor } from "../../Utils/color";
import { Icon } from "@iconify/react";
import { CommonIcon } from "../../Utils/CommonIcon";
import {
  addMultipleRecruiterJob,
  changeBillRate,
  changeIsAttachmentOpen,
  changeIsPartnerInAddJob,
} from "../../feature/store/JobReduxStore";
import { getClientName, getDateFormat } from "../../Utils/utilitiFunction";
import { SubmissionController } from "../../controller/SubmissionController";
import { SubmissionTitle } from "../../style/detail.style";
import { submission_table_in_job } from "../../Utils/mockData";
import { changeIsOpenModal } from "../../feature/store/ApplicantStore";
import AddPartnerInUpdateJob from "../Modal/AddPartnerInUpdateJob";
import { checkNullString } from "../../Utils/validation";
import { onChangeIsLoading } from "../../feature/store/LevelReduxStore";
import BaseModal from "../Modal/BaseModal";
import SubmissionViewDetails from "./SubmissionViewDetails";
import { VIEW_SUBMISSION } from "../../Utils/enum";
import TextWithIcon from "../CommonComponet/TextWithIcon";
import ChangeStatus from "../jobs/ChangeStatus";
import JobCustomController from "../../controller/JobCustomController";
import GetViewForDetailsTile from "../jobs/GetViewForDetailsTile";

export default function JobViewDetails(props) {
  const {
    selected_data,
    getSubmissionData,
    getJobAttachmentByID,
    getIcon,
    onViewModal,
    submission_data_for_job,
    attachment_list,
    temp_partner_data,
  } = JobCustomController();

  const alert = useAlert();
  const data = selected_data.child_job?._is_multiple
    ? selected_data.child_job
    : selected_data;
  const job_detail = data.job_detail;

  const dispatch = useDispatch();
  const isVisible = useSelector((state) => state.counter.is_visible);
  const is_attachment_open = useSelector(
    (state) => state.JobReduxStore.is_attachment_open
  );
  const access_data_by_user_id = useSelector(
    (state) => state.counter.access_data_by_user_id
  );

  const [isopenModal, setIsOpenModal] = useState(false);
  // const [attachmentData, setAttachmentData] = useState([]);
  const jobController = new JobController();
  const [selectedData, setSelectedData] = useState(null);
  const [buttonName, setButtonName] = useState("");

  useEffect(() => {
    getJobAttachmentByID();
    getSubmissionData();
    // jobController.onGetPArtner(selected_data);
  }, []);

  async function onAddJobStatus(e) {
    dispatch(onChangeIsLoading(true));
    if (data.status === e) {
      alert.show(UPDATE_JOB_STATUS_IS_SAME + e, {
        type: CommonString.info,
      });
      dispatch(onChangeIsLoading(false));
      return;
    }
    var sendData = {
      _id: data._id,
      status: e,
    };
    var res = await jobController.changeJobStatus(sendData);
    if (res.status === 200) {
      if (data?._is_multiple) {
        dispatch(
          addMultipleRecruiterJob({
            ...selected_data,
            child_job: {
              ...selected_data.child_job,
              status: e,
            },
          })
        );
      } else {
        dispatch(deletejob(data._id));
        dispatch(addJobData(res.data.data));
      }
      dispatch(changeIsOpenModal(false));
      alert.show(UPDATE_JOB_STATUS_SUCCESS, { type: CommonString.success });
      dispatch(onChangeIsLoading(false));
    } else {
      dispatch(changeIsOpenModal(false));
      alert.show(UPDATE_JOB_STATUS_ERROR, { type: CommonString.error });
      dispatch(onChangeIsLoading(false));
    }
    return;
  }

  return (
    <div>
      <BaseModal
        isBig={true}
        open={isopenModal}
        formTitle={
          buttonName === "Add Partner" ? "Add Partner" : VIEW_SUBMISSION
        }
        handleClose={() => setIsOpenModal(!isopenModal)}
      >
        {buttonName === "Add Partner" ? (
          <AddPartnerInUpdateJob preFieldData={false} data={selected_data} />
        ) : (
          <SubmissionViewDetails
            handleClose={() => setIsOpenModal(!isopenModal)}
            onRefresh={() => props.onRefresh()}
            data={selectedData}
          />
        )}
      </BaseModal>
      <div>
        <div className="jobDetailTitle">
          <div className="flex">
            <div>
              <Title>
                {job_detail.position_title} -{" "}
                <span>{job_detail.client_position_id || CommonString.na}</span>
                {` || ${getClientName(
                  data._is_multiple
                    ? data.clients.name
                    : job_detail?.client?.name
                )}`}
              </Title>
              <TextWithIcon
                icon={CommonIcon.bag}
                text={job_detail.department}
              />
              <TextWithIcon
                icon={CommonIcon.map}
                text={`${job_detail.job_location} ${
                  job_detail.zip_code === 0 || job_detail.zip_code === ""
                    ? ""
                    : `, ${job_detail.zip_code}`
                }`}
              />
              <TextWithIcon
                icon={CommonIcon.clock}
                text={job_detail.project_duration}
              />
            </div>
          </div>
          <div className="flex">
            <>
              {attachment_list.length === 0 ? (
                <div />
              ) : (
                <>
                  <AttachmentDownloadContainer
                    isVisible={isVisible}
                    onClick={() => dispatch(changeIsAttachmentOpen(true))}
                  >
                    <Icon icon={CommonIcon.download} />
                    {CommonString.download_attachment}
                  </AttachmentDownloadContainer>
                  <MenuContainer isOpen={is_attachment_open}>
                    <MultiFileContainerCloseIcon
                      onClick={() => dispatch(changeIsAttachmentOpen(false))}
                      icon={CommonIcon.darkClose}
                    />
                    {attachment_list.map((singleAttechment, index) => (
                      <MultiFileContainerTextContainer
                        key={index}
                        href={
                          ATTACHMENT_BASEURL +
                          singleAttechment.attachment_description
                        }
                        download
                      >
                        <MultiFileContainerText>
                          <MultiFileContainerIcon
                            icon={getIcon(
                              singleAttechment.attachment_description
                            )}
                          />
                          {singleAttechment.attachment_name}
                        </MultiFileContainerText>
                        <MultiFileContainerIcon
                          isDownload={true}
                          icon={CommonIcon.download}
                        />
                      </MultiFileContainerTextContainer>
                    ))}
                  </MenuContainer>
                </>
              )}
            </>
            {access_data_by_user_id.includes("add_partner_in_job") &&
            temp_partner_data.length !== 0 ? (
              <OutlinedBtnForAddPartner onClick={() => onViewModal()}>
                Add Partner Job
              </OutlinedBtnForAddPartner>
            ) : (
              <div />
            )}
            <ChangeStatus
              onSubmit={(e) => onAddJobStatus(e)}
              status={data.status}
            />
          </div>
        </div>
        <hr />
        <Grid container rowSpacing={4}>
          <Grid item md={3} style={{ paddingRight: 15 }}>
            <Table className={"t-a-Left"}>
              <GetViewForDetailsTile
                color={CommonColor.secondary_color}
                name={CommonStringForJob.due_date}
                value={getDateFormat(data.due_date ?? job_detail.due_date)}
              />
              <GetViewForDetailsTile
                color={CommonColor.secondary_color}
                name={CommonStringForJob.bill_rate}
                value={`$${job_detail.bill_rate}`}
              />
              <GetViewForDetailsTile
                color={CommonColor.secondary_color}
                name={CommonStringForJob.max_submission}
                value={data.max_submission}
              />
              <GetViewForDetailsTile
                color={CommonColor.secondary_color}
                name={CommonStringForJob.no_of_submission}
                value={data.no_of_submission}
              />
              <GetViewForDetailsTile
                color={CommonColor.secondary_color}
                name={CommonStringForJob.total_opening}
                value={data.total_opening}
              />
            </Table>
          </Grid>
          <Grid
            item
            md={3}
            style={{
              borderLeft: "1px solid lightgrey",
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <Table className={"t-a-Left"}>
              <GetViewForDetailsTile
                name={CommonStringForJob.job_mode}
                value={data.job_detail.job_mode ?? CommonString.na}
              />
              <GetViewForDetailsTile
                name={CommonStringForJob.job_type}
                value={job_detail.job_type}
              />
              <GetViewForDetailsTile
                name={CommonStringForJob.interview_mode}
                value={job_detail.interview_mode ?? CommonString.na}
              />
              <GetViewForDetailsTile
                name={CommonStringForJob.experience}
                value={
                  job_detail.require_year_experience + CommonString.years ??
                  CommonString.na
                }
              />
              <GetViewForDetailsTile
                name={CommonStringForJob.required_education}
                value={job_detail.require_education ?? CommonString.na}
              />
            </Table>
          </Grid>
          <Grid
            item
            md={3}
            style={{
              borderLeft: "1px solid lightgrey",
              paddingLeft: 15,
              paddingRight: 15,
            }}
          >
            <Table className={"t-a-Left"}>
              <GetViewForDetailsTile
                name={CommonStringForJob.assign_to}
                value={
                  data._is_multiple
                    ? selected_data.recruiter.name
                    : data?.recruiter?.name
                }
              />
              <GetViewForDetailsTile
                name={CommonStringForJob.added_by}
                value={job_detail?.position_added_by?.name ?? CommonString.na}
              />
              <GetViewForDetailsTile
                name={CommonStringForJob.updated_by}
                value={job_detail?.position_updated_by?.name ?? CommonString.na}
              />
            </Table>
          </Grid>
          <Grid
            item
            md={3}
            style={{ borderLeft: "1px solid lightgrey", paddingLeft: 15 }}
          >
            <Table className={"t-a-Left"}>
              <GetViewForDetailsTile
                name={CommonStringForJob.open_date}
                value={
                  selected_data.open_date === null
                    ? ""
                    : moment(selected_data.open_date).format(DATE_FORMATE)
                }
              />
              <GetViewForDetailsTile
                name={CommonStringForJob.working_hour}
                value={job_detail.working_hours}
              />
              <GetViewForDetailsTile
                name={CommonStringForJob.contact_manager}
                value={job_detail.poc_name ?? CommonString.na}
              />
              <GetViewForDetailsTile
                name={CommonStringForJob.partner_name}
                value={getClientName(data.partner.name)}
              />
              <GetViewForDetailsTile
                name={CommonStringForJob.internal_domain}
                value={data.internal_domain ?? CommonString.na}
              />
            </Table>
          </Grid>
          <Grid md={12} className={"t-a-Left"}>
            <hr />
            <RowTextHtml
              title={CommonStringForJob.job_description}
              id={CommonStringForJob.job_description}
              value={job_detail.position_description ?? CommonString.na}
            />
            <RowTextHtml
              title={CommonStringForJob.skill_matrix}
              id={CommonStringForJob.skill_matrix}
              value={data.job_detail.skill_matrix ?? CommonString.na}
            />
            <RowTextHtml
              title={CommonStringForJob.comment}
              id={CommonStringForJob.comment}
              value={data.job_detail.comment ?? CommonString.na}
            />
            {submission_data_for_job.length === 0 ? (
              <SubmissionTitle>{CommonTitle.no_candidate}</SubmissionTitle>
            ) : (
              <>
                <SubmissionTitle>
                  {CommonTitle.connected_candidates}
                </SubmissionTitle>
                <table style={{ width: "100%" }}>
                  <TableTbody>
                    <TableRow>
                      {submission_table_in_job.map((data) => (
                        <TableTh textAlignLeft={true}>{data}</TableTh>
                      ))}
                    </TableRow>
                    {submission_data_for_job.map((data) => (
                      <TableRow
                        onDoubleClick={() => {
                          setButtonName(VIEW_SUBMISSION);
                          setIsOpenModal(!isopenModal);
                          setSelectedData(data);
                        }}
                      >
                        <TableTd textAlignLeft={true}>
                          {data.applicant_first_name +
                            " " +
                            data.applicant_last_name}
                        </TableTd>
                        <TableTd>{data.submitions_tax_term}</TableTd>
                        <TableTd>$ {data.submitions_payrate}</TableTd>
                        <TableTd>
                          {checkNullString(data.recruiter_name)}
                        </TableTd>
                        <TableTd>
                          {moment(data._updated_at).format(DATE_FORMATE)}
                        </TableTd>
                        <TableTd>{data.status}</TableTd>
                      </TableRow>
                    ))}
                  </TableTbody>
                </table>
              </>
            )}
          </Grid>
        </Grid>
        {/* <TableForRateCompareForViewJob data={selected_data} /> */}
      </div>
    </div>
  );
}
