import React, { useEffect, useState } from "react";
import CheckBox from "../basic/CheckBox";
import Menu from "@mui/material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { FieldFilterIcon } from "../../style/common.style";
import { CommonIcon } from "../../Utils/CommonIcon";
import {
  changeInitialTableList,
  changeTableList,
  removeFromTableList,
} from "../../feature/store/SubmissionReduxStore";
const ITEM_HEIGHT = 48;
var card_list = {
  all: true,
  applicant: true,
  position_title: true,
  job_id: true,
  partner: true,
  client: true,
  recruiter: true,
  updated_at: true,
  status: true,
};
var card_list_with_value = [
  "all",
  "applicant",
  "position_title",
  "job_id",
  "partner",
  "client",
  "recruiter",
  "updated_at",
  "status",
];

export default function CustomizeTableFiledForStatus() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [formData, setFormData] = useState(card_list);
  const table_list = useSelector(
    (state) => state.SubmissionReduxStore.table_list
  );

  useEffect(() => {
    dispatch(changeInitialTableList(table_list));
  }, []);

  function handleInputChange(event) {
    const { name, checked } = event.target;
    const hasFalse = Object.values({ ...formData, [name]: checked }).some(
      (value) => value === false
    );
    if (hasFalse) {
      setFormData({ ...formData, all: false });
      dispatch(removeFromTableList("all"));
    } else {
      dispatch(changeInitialTableList(card_list_with_value));
    }
    if (name === "all" && !checked) {
      setFormData({
        all: false,
        applicant: false,
        position_title: false,
        job_id: false,
        partner: false,
        client: false,
        recruiter: false,
        updated_at: false,
        status: false,
      });
      dispatch(changeInitialTableList([]));
      return;
    }
    if (name === "all" && checked) {
      setFormData(card_list);
      dispatch(changeInitialTableList(card_list_with_value));
      return;
    }
    setFormData(
      hasFalse
        ? { ...formData, [name]: checked, all: false }
        : { ...formData, [name]: checked }
    );
    if (checked) {
      dispatch(changeTableList(name));
      return;
    }
    dispatch(removeFromTableList(name));
  }

  return (
    <>
      <FieldFilterIcon
        onClick={(event) => setAnchorEl(event.currentTarget)}
        icon={CommonIcon.list}
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(event) => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            padding: "0 10px",
          },
        }}
      >
        <>
          <CheckBox
            onClick={handleInputChange}
            value={formData.all}
            label={"All"}
            name="all"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.applicant}
            label={"Applicant"}
            name="applicant"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.position_title}
            label={"Position Title"}
            name="position_title"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.job_id}
            label={"Job ID"}
            name="job_id"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.partner}
            label={"Partner"}
            name="partner"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.client}
            label={"Client"}
            name="client"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.recruiter}
            label={"Recruiter"}
            name="recruiter"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.updated_at}
            label={"Updated At"}
            name="updated_at"
          />
          <CheckBox
            onClick={handleInputChange}
            value={formData.status}
            label={"Status"}
            name="status"
          />
        </>
      </Menu>
    </>
  );
}
