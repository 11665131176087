import React, { useState, useEffect } from "react";
import TopMenu from "../Menu/TopMenu";
import PartnerDataTable from "../DataTable/PartnerDataTable";
import { Container, MainScreen, TopFixedMenu } from "./StyledView";
import {
  changeInitialLoader,
  getPartnerData,
} from "../../feature/store/ReduxStore";
import { useSelector, useDispatch } from "react-redux";
import PartnerController from "../../controller/PartnerController";
import { SEARCH_PARTNER } from "../../Utils/enum";
import InitialLoader from "../CommonComponet/Loader/InitialLoader";
import LoaderForTable from "../CommonComponet/LoaderForTable";

export default function PartnerView() {
  const partnerController = new PartnerController();
  const dispatch = useDispatch();
  const partnerList = useSelector((state) => state.counter.partnerData);
  const [isLoading, setisLoading] = useState(false);
  const initial_loader = useSelector((state) => state.counter.initial_loader);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    dispatch(changeInitialLoader(true));
    if (partnerList.length === 0) setisLoading(true);
    var data = await partnerController.getPartners();
    dispatch(getPartnerData(data));
    setisLoading(false);
    dispatch(changeInitialLoader(false));
  }

  function onSearch(search_text) {
    if (search_text == "") {
      getData();
    }
    if (isLoading === true) {
      return;
    }
    setisLoading(true);
    if (search_text.length < 2) {
      setisLoading(false);
      if (search_text.length === 0 || partnerList.length === 0) {
        dispatch(getPartnerData(partnerList));
      }
      return;
    }
    const result = partnerList.filter((partner) =>
      partner.name.toLowerCase().trim().includes(search_text.toLowerCase())
    );
    dispatch(getPartnerData(result));
    setisLoading(false);
  }
  function onChangeSearchText(e) {
    if (e.target === undefined) {
      return;
    }
    onSearch(e.target.value);
  }

  return (
    <Container>
      {initial_loader ? <LoaderForTable /> : <></>}
      <TopFixedMenu>
        <TopMenu
          noDisplay={false}
          internalSearch={true}
          placeholder={SEARCH_PARTNER}
          onChange={(e) => onChangeSearchText(e)}
          onRefresh={() => getData()}
        />
      </TopFixedMenu>
      <MainScreen>
        <PartnerDataTable isLoading={isLoading} onRefresh={() => getData()} />
      </MainScreen>
    </Container>
  );
}
