import React from "react";
import {
  Circle,
  FlexContainerVerical,
  Line,
  StatusTitle,
  TimelineBlockContainer,
} from "../../style/status.style";

import { CommonColor } from "../../Utils/color";
import { Icon } from "@iconify/react";
import { onCheckLevelAccessibility } from "../../Utils/utilitiFunction";

export default function StatusTimelineBlock(props) {
  function clickListener(event) {
    props.setTimelineStatus(event, props.title);
  }
  return (
    <FlexContainerVerical style={{ position: "relative" }} width="max-content">
      <TimelineBlockContainer>
        <Line
          visible={props.line === "L" || props.line === "B"}
          isCompleted={
            props.isCompleted ? CommonColor.bg_yellow : CommonColor.grey
          }
        />
        <Circle
          content={props.title}
          isSkip={props.isSkip}
          isActive={props.isActive}
          isCompleted={props.isCompleted}
          onClick={(e) =>
            props.isSkip && !onCheckLevelAccessibility() ? "" : clickListener(e)
          }
          className="timelinecircle"
        >
          <Icon icon="radix-icons:check" color={CommonColor.green} />
        </Circle>
        {/* <Checkbox
          icon={<RadioButtonUncheckedIcon />}
          checkedIcon={<CheckCircleOutlineIcon />}
          color="success"
        /> */}
        <Line
          visible={props.line === "R" || props.line === "B"}
          isCompleted={props.isCompleted || props.isSkip}
          isActive={props.isActive}
        />
      </TimelineBlockContainer>
      <StatusTitle
        isCompleted={props.isCompleted}
        isActive={props.isActive}
        isSkip={props.isSkip}
      >
        {props.title.toLowerCase()}
      </StatusTitle>
    </FlexContainerVerical>
  );
}
