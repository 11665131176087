// import React, { useCallback, useEffect, useRef, useState } from "react";
// import CustomButton from "../Button";
// import resume from "../../../assets/images/resume.png";
// // import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
// import { RESUME_BASEURL } from "../../../Utils/constant";
// import { PdfViewwerContainer } from "../../View/StyledView";
// import { CommonButtonName } from "../../../Utils/CommonString";
// import { useDropzone } from "react-dropzone";
// import { DocContainer, PdfContainer } from "../../../style/applicant.style";
// import { getUrl } from "../../../Utils/validation";
// import mammoth from "mammoth";

// export default function PdfOrDocViewer(props) {
//   const [fileName, setFileName] = useState();
//   // const onDrop = useCallback((e) => {
//   //   console.log(e);
//   //   e.forEach((file) => {
//   //     props.onselect(file);
//   //     const reader = new FileReader();
//   //     reader.readAsArrayBuffer(file);
//   //   });
//   // }, []);
//   // const { getRootProps, getInputProps } = useDropzone({ onDrop });
//   // const inputFile = useRef(null);

//   // const handlePdfFileSubmit = async (e) => {
//   //   if (props.isView) {
//   //     openInNewTab("http://www.google.com");
//   //     return;
//   //   }
//   //   inputFile.current.click();
//   // };

//   // const openInNewTab = (url) => {
//   //   const newWindow = window.open(
//   //     RESUME_BASEURL + props.resumeLink,
//   //     "_blank",
//   //     "noopener,noreferrer"
//   //   );
//   //   if (newWindow) newWindow.opener = null;
//   // };
//   const [selectedDocFile, setSelectedDocFile] = useState(null);

//   const handleFileChange = (file) => {
//     setSelectedDocFile(file);
//   };
//   const FileInput = ({ onFileChange }) => {
//     const handleFileChange = (e) => {
//       const file = e.target.files[0];
//       onFileChange(file);
//     };

//     return <input type="file" onChange={handleFileChange} accept=".docx" />;
//   };
//   const [htmlContent, setHtmlContent] = useState(null);

//   // useEffect(() => {
//   //   // Function to convert DOC to HTML
//   //   const convertDocToHtml = async () => {
//   //     console.log("data", selectedDocFile);
//   //     try {
//   //       console.log("result");
//   //       const result = await mammoth.convertToHtml(selectedDocFile);
//   //       console.log(result);
//   //       setHtmlContent(result.value);
//   //     } catch (error) {
//   //       console.error("Error converting DOC to HTML:", error);
//   //     }
//   //   };

//   //   if (selectedDocFile) {
//   //     convertDocToHtml();
//   //   }
//   // }, [selectedDocFile]);
//   return (
//     <>
//       {selectedDocFile==null?console.log("dssd"):console.log(URL.createObjectURL(selectedDocFile)+`/${selectedDocFile.name}`,)}
//       <FileInput onFileChange={handleFileChange} />
//       {/* <DocViewer docPath={selectedDocFile} /> */}
//       {/* {selectedDocFile ? (
//         <iframe
//           title="Doc Viewer"
//           width="100%"
//           height="500"
//           srcDoc={htmlContent}
//         />
//       ) : (
//         <p>Loading...</p>
//       )} */}
//       {selectedDocFile && (
//         <iframe src="https://docs.google.com/gview?url=http://localhost:3000/f840cc2f-c1a5-465e-abb6-c76fcd271bbf/2.docx&embedded=true"></iframe>

//       )}
//     </>
//   );
// }
import React, { useCallback, useEffect, useRef, useState } from "react";
import mammoth from "mammoth";
import { changeSelectedFileName } from "../../../feature/store/ApplicantStore";
import { useDispatch, useSelector } from "react-redux";
import { ButtonName, CommonString } from "../../../Utils/CommonString";
import { FILE_ERROR } from "../../../Utils/alertMessage";
import { useAlert } from "react-alert";
import { RESUME_BASEURL } from "../../../Utils/constant";
import { useDropzone } from "react-dropzone";
import resume from "../../../assets/images/resume.png";
// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import { PdfViewwerContainer } from "../../View/StyledView";
import { CommonButtonName } from "../../../Utils/CommonString";
import {
  FlexContainerForDragFile,
  PdfContainer,
} from "../../../style/applicant.style";
import CustomButton from "../Button";
import { changeIsChangeResumeInApplicant } from "../../../feature/store/ReduxStore";
import { findEmailFromString } from "../../../Utils/validation";
import { CommonColor } from "../../../Utils/color";

function PdfOrDocViewer(props) {
  const dispatch = useDispatch();
  const alert = useAlert();
  const [fileType, setFileType] = useState("");
  const [pdfUrl, setPdfUrl] = useState(null);
  const button_name = useSelector((state) => state.ApplicantStore.button_name);
  const isChangeResumeInApplicant = useSelector(
    (state) => state.counter.isChangeResumeInApplicant
  );
  const selected_file = useSelector(
    (state) => state.ApplicantStore.selected_file
  );
  // const [isChange, setisChange] = useState(false);

  useEffect(() => {
    if (button_name === ButtonName.edit_applicant && props.url !== undefined) {
      dispatch(changeIsChangeResumeInApplicant(true));
      var values = props.url?.split(".").reverse()[0];
      setFileType(values);
      setPdfUrl(RESUME_BASEURL + props.url);
      dispatch(changeIsChangeResumeInApplicant(true));
    }
  }, [props.url]);

  const convertDocxToHtml = (file) => {
    dispatch(changeIsChangeResumeInApplicant(false));
    // console.log("file", file);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (event) => {
        const arrayBuffer = event.target.result;
        const blob = new Blob([arrayBuffer], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        });
        var options = {
          styleMap: [
            "p[style-name='Section Title'] => h1:fresh",
            "p[style-name='Subsection Title'] => h2:fresh",
          ],
        };
        try {
          const { value } = await mammoth.convertToHtml(
            { arrayBuffer: arrayBuffer },
            options
          );
          resolve(value);
        } catch (error) {
          reject(error);
        }
      };
      reader.readAsArrayBuffer(file);
    });
  };
  const handleFileChange = async (e) => {
    dispatch(changeIsChangeResumeInApplicant(false));
    const selectedFile = e;
    if (e.name.split(".").length > 2) {
      alert.show(FILE_ERROR, { type: CommonString.info });
      setPdfUrl(null);
      return;
    }
    if (e.length == 0) return;
    dispatch(changeSelectedFileName(selectedFile));
    if (selectedFile) {
      var values = e.name.split(".").reverse()[0];
      setFileType(values);
      props.onselect(e);
      // if (values === "docx") {
      //   const file = e;
      //   if (file) {
      //     try {
      //       const result = await convertDocxToHtml(file);
      //       setPdfUrl(result);
      //     } catch (error) {
      //       console.error("Error converting DOCX to HTML:", error);
      //     }
      //   }

      //   return;
      // } else if (values === "doc") {
      //   props.onselect(e);
      // }
      const blobUrl = URL.createObjectURL(selectedFile);
      setPdfUrl(blobUrl);
    } else {
      setPdfUrl(null);
    }
  };
  function getIframeVew(e) {
    if (
      isChangeResumeInApplicant &&
      button_name === ButtonName.edit_applicant &&
      (e === "docx" || e === "doc")
    ) {
      return (
        <iframe
          className="iframeContainer"
          src={CommonString.doc_pre_extention + pdfUrl}
        />
      );
    } else if (e === "doc" || e === "docx") {
      return (
        <iframe
          className="iframeContainer"
          src={CommonString.doc_pre_extention + RESUME_BASEURL + props.url}
        />
      );
    }
    return <iframe className="iframeContainer" src={pdfUrl} />;
  }
  const onDrop = useCallback((e) => {
    dispatch(changeIsChangeResumeInApplicant(false));
    e.forEach((file) => {
      handleFileChange(file);
      dispatch(changeSelectedFileName(file));
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const inputFile = useRef(null);

  const handlePdfFileSubmit = async (e) => {
    dispatch(changeIsChangeResumeInApplicant(false));
    if (props.isView) {
      openInNewTab("http://www.google.com");
      return;
    }
    inputFile.current.click();
  };

  const openInNewTab = (url) => {
    const newWindow = window.open(
      RESUME_BASEURL + props.resumeLink,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };
  return (
    <div>
      {pdfUrl === null ? (
        <PdfContainer {...getRootProps()} onClick={handlePdfFileSubmit}>
          <input
            accept=".pdf,.doc,.docx"
            type="file"
            // id="file"
            {...getInputProps()}
            ref={inputFile}
            // onChange={props.onselect}
            style={{ display: "none" }}
          />
          <FlexContainerForDragFile>
            <img
              src={resume}
              style={{ width: "50px", marginBottom: 10 }}
              alt="resume"
            />
            <CustomButton name={CommonButtonName.upload_resume} />
          </FlexContainerForDragFile>
        </PdfContainer>
      ) : (
        <PdfViewwerContainer>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <p>{selected_file !== null ? selected_file.name : ""}</p>
            <p
              {...getRootProps()}
              onClick={handlePdfFileSubmit}
              style={{ color: CommonColor.primary_color, fontWeight: "bold" }}
            >
              <span
                style={{
                  color: "blue",
                  textDecoration: "underline",
                  fontWeight: "initial",
                }}
              >
                Change File
              </span>
              <input
                accept=".pdf,.doc,.docx"
                type="file"
                // id="file"
                {...getInputProps()}
                ref={inputFile}
                // onChange={props.onselect}
                style={{ display: "none" }}
              />
            </p>
          </div>
          {pdfUrl && <div>{getIframeVew(fileType)}</div>}
        </PdfViewwerContainer>
      )}
      {/* <input type="file" accept=".pdf,.doc,.docx" onChange={handleFileChange} /> */}
    </div>
  );
}

export default PdfOrDocViewer;
