import React from "react";
import { OutlinedBtnForGroup } from "../../style/common.style";
import { CommonString } from "../../Utils/CommonString";

export default function OutlinedButton(props) {
  return (
    <OutlinedBtnForGroup
      {...props}
      margintop={props.marginTop}
      width={props.width}
      onClick={props.onClick}
      variant={CommonString.outlined_variant}
    >
      {props.name}
    </OutlinedBtnForGroup>
  );
}
