import React, { useCallback, useRef, useState } from "react";
import { CommonButtonName, CommonString } from "../../Utils/CommonString";
import { useDropzone } from "react-dropzone";
import { ATTACHMENT_BASEURL, RESUME_BASEURL } from "../../Utils/constant";
import resume from "../../assets/images/resume.png";
import {
  AttachmentButtonContainer,
  AttachmentContainer,
  FileName,
  FileNameCloseIcon,
  FileNameContainer,
  MultiFileContainer,
  MultiFileContainerIconContainer,
  MultiFileView,
} from "../../style/common.style";
import { useSelector } from "react-redux";
import { CommonIcon } from "../../Utils/CommonIcon";
import JobController from "../../controller/JobController";
import { confirmAlert } from "react-confirm-alert";
import DeleteConfirmation from "../Modal/DeleteConfirmation";
import { deleteTempSelectedFileName } from "../../feature/store/JobReduxStore";
import { useDispatch } from "react-redux";
import { Icon } from "@iconify/react";
import { AttachmentIcon } from "../../style/table.styled";

export default function AddAttachment(props) {
  const dispatch = useDispatch();
  const jobController = new JobController();
  const selected_file_name = useSelector(
    (state) => state.JobReduxStore.selected_file_name
  );
  const temp_selected_file = useSelector(
    (state) => state.JobReduxStore.temp_selected_file
  );
  const onDrop = useCallback((e) => {
    e.forEach((file) => {
      props.onselect(file);
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  const inputFile = useRef(null);
  const handlePdfFileSubmit = async (e) => {
    if (props.isView) {
      openInNewTab("http://www.google.com");
      return;
    }
    inputFile.current.click();
  };
  const openInNewTab = (url) => {
    const newWindow = window.open(
      RESUME_BASEURL + props.resumeLink,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) newWindow.opener = null;
  };

  function onDelete(data) {
    if (props.isUpdate) {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <DeleteConfirmation
              name={data.attachment_name}
              onClose={onClose}
              onDelete={() => {
                jobController.deleteAttachment(data._id);
                onClose();
              }}
            />
          );
        },
      });
      return;
    }
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <DeleteConfirmation
            name={data.name}
            onClose={onClose}
            onDelete={() => {
              dispatch(deleteTempSelectedFileName(data));
              onClose();
            }}
          />
        );
      },
    });
  }

  return (
    <AttachmentContainer
      isUpdate={props.isUpdate}
      {...getRootProps()}
      onClick={handlePdfFileSubmit}
    >
      <input
        multiple
        type={CommonString.file}
        {...getInputProps()}
        ref={inputFile}
      />
      <AttachmentButtonContainer>
        <AttachmentIcon icon={CommonIcon.attachment} />

        {/* <img src={resume} alt={CommonString.resume} /> */}
        <MultiFileContainer>
          {temp_selected_file.map((data, index) => (
            <FileNameContainer key={index} onClick={(e) => e.stopPropagation()}>
              <FileName>{data.name}</FileName>
              <FileNameCloseIcon
                onClick={(e) => onDelete(data)}
                icon={CommonIcon.delete_outline}
              />
            </FileNameContainer>
          ))}
          {selected_file_name.map((data, index) => (
            <FileNameContainer key={index} onClick={(e) => e.stopPropagation()}>
              <FileName>{data.attachment_name}</FileName>
              <MultiFileContainerIconContainer>
                <MultiFileView
                  href={ATTACHMENT_BASEURL + data.attachment_description}
                  target={CommonString.blank}
                >
                  <FileNameCloseIcon icon={CommonIcon.eye} />
                </MultiFileView>
                <FileNameCloseIcon
                  onClick={(e) => onDelete(data)}
                  icon={CommonIcon.delete_outline}
                />
              </MultiFileContainerIconContainer>
            </FileNameContainer>
          ))}
        </MultiFileContainer>
        <p>
          {selected_file_name.length === 0
            ? CommonButtonName.upload_attachment
            : CommonButtonName.upload_more_attachment}
        </p>
      </AttachmentButtonContainer>
    </AttachmentContainer>
  );
}
