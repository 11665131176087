import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 52,
  height: 27,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 2,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 256 256"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="m208.5 64.5l-144 144a12.1 12.1 0 0 1-17 0a12 12 0 0 1 0-17l144-144a12 12 0 0 1 17 17ZM47.7 104.3A40.1 40.1 0 1 1 76 116a40 40 0 0 1-28.3-11.7ZM60 76a16 16 0 1 0 4.7-11.3A15.9 15.9 0 0 0 60 76Zm160 104a40 40 0 1 1-11.7-28.3A40 40 0 0 1 220 180Zm-24 0a15.9 15.9 0 0 0-4.7-11.3A15.9 15.9 0 1 0 196 180Z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 22,
    height: 22,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M15.999 8.5h2c0-2.837-2.755-4.131-5-4.429V2h-2v2.071c-2.245.298-5 1.592-5 4.429c0 2.706 2.666 4.113 5 4.43v4.97c-1.448-.251-3-1.024-3-2.4h-2c0 2.589 2.425 4.119 5 4.436V22h2v-2.07c2.245-.298 5-1.593 5-4.43s-2.755-4.131-5-4.429V6.1c1.33.239 3 .941 3 2.4zm-8 0c0-1.459 1.67-2.161 3-2.4v4.799c-1.371-.253-3-1.002-3-2.399zm8 7c0 1.459-1.67 2.161-3 2.4v-4.8c1.33.239 3 .941 3 2.4z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

export default function SwitchWithText(props) {
  const [isInPercent, setisInPercent] = useState(props.check);

  return (
    <FormGroup className="switchLabel" style={{ marginLeft: "4px" }}>
      <FormControlLabel
        control={
          <MaterialUISwitch
            {...props}
            size="small"
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              setisInPercent(e.target.checked);
              props.isInPercent(e.target.checked);
            }}
            defaultChecked={props.check}
          />
        }
        label=""
      />
    </FormGroup>
  );
}
