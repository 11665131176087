import React from "react";
import {
  CallTrackerStatus,
  CallTrackerStatusColor,
} from "../../../Utils/utilitiFunction";
import CustomTooltip from "../Tooltip";
import { CommonIcon } from "../../../Utils/CommonIcon";
import { CommonString } from "../../../Utils/CommonString";
import { SubmissionStatusContainer } from "../../../style/common.style";
import { SubmissionIcon } from "../../../style/Icon.style";

export default function SubmissionStatus(props) {
  return (
    <SubmissionStatusContainer onClick={props.onClick}>
      <CustomTooltip
        placement={CommonString.top_placement}
        title={CommonString.call_1 + " " + props.data.call_1_status}
        name={
          <SubmissionIcon
            color={CallTrackerStatusColor[props.data.call_1_status].color}
            icon={CallTrackerStatus[props.data.call_1_status]}
          />
        }
      />
      <CustomTooltip
        placement={CommonString.top_placement}
        title={CommonString.call_2 + " " + props.data.call_2_status}
        name={
          <SubmissionIcon
            color={CallTrackerStatusColor[props.data.call_2_status].color}
            icon={CallTrackerStatus[props.data.call_2_status]}
          />
        }
      />
      <CustomTooltip
        placement={CommonString.top_placement}
        title={CommonString.call_3 + " " + props.data.call_3_status}
        name={
          <SubmissionIcon
            color={CallTrackerStatusColor[props.data.call_3_status].color}
            icon={CallTrackerStatus[props.data.call_3_status]}
          />
        }
      />
      <CustomTooltip
        placement={CommonString.top_placement}
        title={CommonString.view}
        name={<SubmissionIcon icon={CommonIcon.arrow_right} />}
      />
    </SubmissionStatusContainer>
  );
}
