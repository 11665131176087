import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CheckBox from "../basic/CheckBox";
import { Grid } from "@mui/material";
import { changeInternalDomain } from "../../feature/store/JobReduxStore";
import CustomButton from "../CommonComponet/Button";
import { ButtonContainer } from "../../style/recruiter.style";
import RecruiterController from "../../controller/RecruiterController";

export default function InternalDomainAccess(props) {
  const dispatch = useDispatch();
  var recruiterController = new RecruiterController();
  const selected_user = useSelector((state) => state.UserStore.selected_user);

  const [formData, setFormData] = useState({
    ALL: false,
    Public: false,
    Private: false,
    Healthcare: false,
  });
  function handleInputChange(event) {
    const { name, checked } = event.target;
    if (name === "ALL" && checked) {
      setFormData({ ALL: true, Public: true, Private: true, Healthcare: true });
      return;
    }
    if (name === "ALL" && !checked) {
      setFormData({
        ALL: false,
        Public: false,
        Private: false,
        Healthcare: false,
      });
      return;
    }
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: checked,
        ALL:
          checked &&
          prevFormData.Public &&
          prevFormData.Private &&
          prevFormData.Healthcare,
      };
      dispatch(changeInternalDomain(updatedFormData));
      return updatedFormData;
    });
  }
  useEffect(() => {
    onPrefilledData();
  }, [selected_user]);

  async function onPrefilledData() {
    try {
      if (selected_user?._id === undefined) return;
      var access_res = await recruiterController.getInternalDomain({
        user_id: selected_user?._id,
      });
      if (access_res === null) return;
      setFormData(access_res[0].domain);
    } catch (error) {
      console.log(error);
    }
  }
  async function onAddAccess() {
    try {
      ///api call for Update or add Data
      const dataArray = Object.entries(formData)
        .filter(([key, value]) => value === true) // Filter for entries where value is true
        .map(([key, value]) => ({ name: key, value }));
      let res = await recruiterController.updateInternalDomain({
        user_id: selected_user?._id,
        domain: dataArray,
      });
      props.handleClose();
      props.onRefresh();
      ///ends here
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {/* <Title className="mt-2">Internal Domain</Title> */}
      <Grid container>
        <CheckBox
          onClick={handleInputChange}
          value={formData.ALL}
          label={"All"}
          name={"ALL"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.Public}
          label={"Public"}
          name={"Public"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.Private}
          label={"Private"}
          name={"Private"}
        />
        <CheckBox
          onClick={handleInputChange}
          value={formData.Healthcare}
          label={"Healthcare"}
          name={"Healthcare"}
        />
      </Grid>
      <ButtonContainer>
        <CustomButton onClick={() => onAddAccess("")} name={"Save & Exit"} />
      </ButtonContainer>
    </>
  );
}
