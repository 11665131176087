import React from "react";
import Loader from "react-js-loader";
import styled from "styled-components";
import { DashBoardIcon } from "../../style/common.style";
import { CardTitle, CardTotalCount } from "../../style/dashboard.style";
import { CommonColor } from "../../Utils/color";
import { CommonString } from "../../Utils/CommonString";
import { ROUND_LOADER_TYPE } from "../../Utils/constant";
import { getCapitalizeText } from "../../Utils/utilitiFunction";

const Container = styled.div`
  background: #ffffff;
  padding: 15px;
  border-radius: 10px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 27px 80px rgba(0, 0, 0, 0.07),
    0px 5.4px 13px rgba(0, 0, 0, 0.035);
  white-space: wrap;
  height: 82px;
  width: 100%;
  @media ${CommonString.mobile} {
    margin-right: 0px;
    min-width: auto;
  }
`;
const DashBoardContainer = styled.div`
  padding: 10px 5px;
  width: 168px;
`;
export default function Card(props) {
  return (
    <DashBoardContainer {...props} onClick={() => props.onClick(props.title)}>
      <Container className="loadernew">
        <DashBoardIcon src={props.image} alt={props.title} />
        <CardTitle background={props.background}>
          {getCapitalizeText(props.title)}
        </CardTitle>
        {props.isLoading ? (
          <Loader
            type={ROUND_LOADER_TYPE}
            bgColor={CommonColor.primary_color}
            size={20}
            color={CommonColor.white}
            timeout={3000}
          />
        ) : (
          <CardTotalCount background={props.background}>
            {props.total}
          </CardTotalCount>
        )}
      </Container>
    </DashBoardContainer>
  );
}
