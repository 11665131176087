import ApiUtils from "../APICallServices/APIUtils";

export default class PartnerProvider {
  constructor() {
    this.Apiprovider = new ApiUtils();
  }
  getPartnerList = () => {
    return this.Apiprovider.init().get("partner/get-partner");
  };
  addNewPartner = (data) => {
    return this.Apiprovider.init().post("partner/add-partner", data);
  };
  updatePartner = (data) => {
    return this.Apiprovider.init().post("partner/update-partner", data);
  };
  deletePartner = (data) => {
    return this.Apiprovider.init().post("partner/delete-partner", data);
  };
  getPartnerByClient = (data) => {
    return this.Apiprovider.init().post("partner/get-partner-by-client", data);
  };
}
