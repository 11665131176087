import styled from "styled-components";

const DiceMainContainer = styled.div`
  padding: 10vh 2vw;
  overflow: auto !important;
  height: 100vh;
  label {
    white-space: nowrap;
  }
`;
const SearchContainer = styled.div`
  padding: 15px;
  border: 1px solid lightgray;
  border-radius: 5px;
  input {
    padding: 8px;
    padding-left: 40px;
    border: none;
    font-size: 15px;
    width: 100%;
    ::placeholder {
      color: #6f6f6f;
      font-style: italic;
    }
  }
`;
const SearchTitle = styled.div`
  margin: -15px;
  background-color: black;
  color: white;
  padding: 10px;
  margin-bottom: 0px;
  font-size: 16px;
`;
const InputContainer = styled.div`
  border: 1px solid lightgrey;
  /* padding: 10px; */
  margin-top: 15px;
  position: relative;
  border-radius: 5px;
  svg {
    position: absolute;
    left: 10px;
    top: 11px;
    width: 19px;
    height: 19px;
    color: #6f6f6f;
  }
  textarea {
    width: 100%;
    padding-left: 50px;
    font-size: 14px;
    height: auto !important;
    overflow: auto !important;
    border: none !important;
  }
`;
const SearchInfoMessage = styled.div`
  padding: 5px;
  color: #6f6f6f;
  /* border: 1px solid lightgray; */
`;
const SearchLocationContainer = styled.div`
  padding: 3px;
  border: 1px solid lightgrey;
  margin-top: 15px;
  position: relative;
  border-radius: 5px;
  width: 100%;
  margin-right: 10px;
  svg {
    color: #006599;
    position: absolute;
    width: 40px;
    height: 100%;
    border-right: 1px solid lightgrey;
    padding: 14px 10px;
    margin: -3px;
  }
  input {
    padding-left: 50px;
    ::placeholder {
      color: #6c757d;
    }
  }
  /* border: 1px solid lightgray; */
`;
const SearchWithLabelContainer = styled.div`
  border: 1px solid lightgrey;
  width: min-content;
  padding: 0px 10px;
  margin-top: 15px;
  display: flex;

  div:nth-child(2) {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 1px solid lightgrey;
  }
  select {
    border: none;
    width: 50px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border-radius: 4px; /* Round corners */
    padding: 0;
    /* padding: 10px; */
    font-size: 15px;
    transition: border-color 0.2s ease;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"%3E%3Cpath fill="gray" d="M6 8l4 4 4-4z"%3E%3C/path%3E%3C/svg%3E');
    background-repeat: no-repeat;
    background-position: right 1px;
    /* background-size: 22px; */
    /* padding: 0 10px; */
    display: flex;
    align-items: center;
    margin-bottom: 2px;
  }
  option {
    padding: 0 10px;
  }
  input {
    padding: 0;
    margin-bottom: 2px;
  }
`;
const AddLocationButton = styled.div`
  border: 1px solid #006599;
  margin-top: ${(props) => (props.size === "small" ? "0" : "15px")};
  margin-left: 10px;
  padding: ${(props) => (props.size === "small" ? "4px 10px" : "10px 15px")};
  font-size: 18px;
  color: #006599;
  cursor: pointer;
`;
const InfoButton = styled.div`
  margin-top: 15px;
  margin-left: 10px;
  padding: 7px 0px;
  font-size: 22px;
  color: #006599;
  margin-right: 10px;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  color: #006599;
  font-size: 14px;
  margin-top: 25px;
  p {
    margin-right: 30px;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  button {
    background-color: #006599;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
  }
`;
const LocationChipContainer = styled.div`
  display: flex;
  margin-top: 15px;
  flex-wrap: wrap;
  gap: 15px;
  p {
    font-size: 16px;
  }
`;
const SubTitle = styled.p`
  font-size: 16px;
  margin-top: 25px;
`;
const LocationChip = styled.div`
  background-color: white;
  border: 1px solid grey;
  color: grey;
  border-radius: 20px;
  padding: 5px 10px;
  width: fit-content;
  /* margin-right: 10px; */
  font-size: 14px;
  span {
    white-space: nowrap;
  }
  svg {
    background-color: lightgrey;
    padding: 2px;
    border-radius: 50px;
    color: black;
    width: 17px;
    height: 17px;
    margin-left: 5px;
    cursor: pointer;
  }
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .switch {
    padding: 0px 33px;
    border-radius: 11px;
    width: 100px !important;
    background-color: lightgray;
    margin: 0 5px;
  }
  label {
    margin: 0;
  }
`;
const TextWithIconContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: grey;
  svg {
    margin-right: 5px;
  }
`;
const ExperienceWithSkillContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: grey;
  justify-content: space-between;
  padding: 10px;
  background: #f5f5f5;
`;
const FirstContainerForApplicantProfile = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  h5 {
    margin: 0;
    color: #006599;
    /* font-size: 16px; */
  }
  p {
    font-size: 14px;
    font-weight: bold;
  }
  div:first-child {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: white;
    background: #669aa3;
    margin-right: 10px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin: -5px;
  svg,
  img {
    width: 15px;
    height: 15px;
    margin: 5px;
    cursor: pointer;
  }
`;
const ApplicantProfileCardContainer = styled.div`
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
`;
const FilterListContainer = styled.div`
  list-style: none;
  margin: 20px;
  margin-left: 0;
  label {
    font-size: 14px;
  }
`;
export {
  FilterListContainer,
  ApplicantProfileCardContainer,
  IconContainer,
  FirstContainerForApplicantProfile,
  ExperienceWithSkillContainer,
  TextWithIconContainer,
  FlexContainer,
  SubTitle,
  LocationChipContainer,
  LocationChip,
  ButtonContainer,
  InfoButton,
  AddLocationButton,
  SearchWithLabelContainer,
  SearchLocationContainer,
  SearchInfoMessage,
  DiceMainContainer,
  SearchContainer,
  SearchTitle,
  InputContainer,
};
