import styled from "styled-components";
import { CommonColor } from "../../Utils/color";
import { CommonString } from "../../Utils/CommonString";

export const Container = styled.div`
  background: ${CommonColor.screen_background_color};
  width: 100%;
  height: 100vh;
`;
export const MainScreen = styled.div`
  // padding: 13px;
  /* height: 90vh; */
   /* overflow: auto; */
  @media ${CommonString.mobile} {
    overflow: auto;
  }
`;
export const TopFixedMenu = styled.div`
  box-shadow: 2px 2px 10px #aeaeae29 !important;
`;
export const PdfViewwerContainer = styled.div`
  position: relative;
  height: 100%;
  @media ${CommonString.mobile} {
    height: 80vh;
  }
`;
