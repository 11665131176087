import React, { useState } from "react";
import {
  FilterIconInTable,
  FlexContainerForFilter,
  MenuItemForFilter,
} from "../../style/common.style";
import { CommonIcon } from "../../Utils/CommonIcon";
import { Menu } from "@mui/material";
const ITEM_HEIGHT = 48;

export default function OrderFilter(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  function onClick(singleData) {
    setAnchorEl(null);
    props.getValue(singleData);
  }
  var data = ["ASC", "DESC"];

  return (
    <div>
      <FlexContainerForFilter
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <div>
          {props.name}
        </div>
        <FilterIconInTable
          icon={CommonIcon.filter}
        />
      </FlexContainerForFilter>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={(event) => setAnchorEl(null)}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            marginTop: 5,
          },
        }}
      >
        {data?.map((singleData) => (
          <MenuItemForFilter
            activeStatus={props.selectedOrder === singleData}
            onBlur={() => onClick(singleData)}
            onClick={() => onClick(singleData)}
          >
            {singleData}
          </MenuItemForFilter>
        ))}
      </Menu>
    </div>
  );
}
