import { createSlice } from "@reduxjs/toolkit";

export const JobReduxStore = createSlice({
  name: "JobReduxStore",
  initialState: {
    is_active_multiple: false,
    searchJobText: "",
    job_status_name: "OPEN",
    is_change_job_status_name: false,
    temp_partner_list: [],
    temp_recruiter_list: [],
    selected_due_date: null,
    selected_recruiter_id: "123",
    selected_client_id: "123",
    temp_job_list: [],
    selected_partner_id: "123",
    partner_id: [],
    selected_partner_list: "123",
    search_result: [],
    Selected_partner_id: [],
    recruiter_data: [],
    bill_rate: 0,
    selected_file_name: [],
    temp_selected_file: [],
    is_attachment_open: false,
    is_submission: false,
    client_due_date: [],
    is_offer_details_update: false,
    selectedJobForLockApplicant: null,
    job_data_for_dashboard: [],
    job_selected_data: [],
    client_list: [],
    selected_value_client: [],
    job_description: "",
    skill_matrix: "",
    selected_data: [],
    partner_data_for_filter: [],
    recruiter_data_for_filter: [],
    is_loading: false,
    partner_in_add_job: false,
    table_list: [
      "all",
      "status",
      "due_date",
      "job_title",
      "job_id",
      "client",
      "max_sub",
      "sub_made",
      "partner",
      "recruiter",
    ],
    filter_data: {
      status: "OPEN",
      order: "ASC",
      recruiter_id: "123",
      partner_id: "123",
      client_id: "123",
    },
    client_list_for_filter: [],
    is_open_status_dropDown: false,
    internal_domain: {
      All: false,
      Public: false,
      Private: false,
      Healthcare: false,
    },
    recruiter_list: [],
    multiple_recruiter_job_list: [],
    search_text: "",
    is_loading_multiple_recruiter_job_list: false,
    multiple_recruiter_job_count: 0,
    submission_data_for_job: [],
    tab_name: "Individual",
    attachment_list: [],
  },
  reducers: {
    onChangeAttachmentList: (state, action) => {
      state.attachment_list = action.payload;
    },
    onChangeTabName: (state, action) => {
      state.tab_name = action.payload;
    },
    onChangeSubmissionDataForJob: (state, action) => {
      state.submission_data_for_job = action.payload;
    },
    onChangeIsLoadingMultipleRecruiterJobList: (state, action) => {
      state.is_loading_multiple_recruiter_job_list = action.payload;
    },
    onChangeMultipleRecruiterJobCount: (state, action) => {
      state.multiple_recruiter_job_count = action.payload;
    },
    onChangeSearchText: (state, action) => {
      state.search_text = action.payload;
    },
    onChangeIsActiveMultiple: (state, action) => {
      state.is_active_multiple = action.payload;
    },
    addMultipleRecruiterJob: (state, action) => {
      var data = state.multiple_recruiter_job_list.map(function (item) {
        return item.child_job._id === action.payload.child_job_id
          ? action.payload
          : item;
      });
      state.multiple_recruiter_job_list = data;
    },
    deleteMultipleRecruiterJob: (state, action) => {
      const newList = state.multiple_recruiter_job_list.filter(
        (item) => item.child_job._id !== action.payload
      );
      state.multiple_recruiter_job_list = newList;
    },
    onChangeMultipleRecruiterJobList: (state, action) => {
      state.multiple_recruiter_job_list = action.payload;
    },
    onChangeRecruiterList: (state, action) => {
      state.recruiter_list = action.payload;
    },
    changeInternalDomain: (state, action) => {
      state.internal_domain = action.payload;
    },
    changeIsOpenJobStatusDropdown: (state, action) => {
      state.is_open_status_dropDown = action.payload;
    },
    changeClientListForFilter: (state, action) => {
      state.client_list_for_filter = action.payload;
    },
    changeFilterData: (state, action) => {
      state.filter_data = action.payload;
    },
    changeTableList: (state, action) => {
      state.table_list.push(action.payload);
      var newData = state.table_list.filter(
        (item, index) => state.table_list.indexOf(item) === index
      );
      state.table_list = newData;
    },
    changeInitialTableList: (state, action) => {
      state.table_list = action.payload;
    },
    removeFromTableList: (state, action) => {
      var index = state.table_list.indexOf(action.payload);
      if (index !== -1) {
        state.table_list.splice(index, 1);
      }
    },
    deleteSearchJob: (state, action) => {
      const newList = state.search_result.filter(
        (item) => item._id !== action.payload
      );
      state.search_result = newList;
    },
    deleteMultipleJob: (state, action) => {
      const newList = state.multiple_recruiter_job_list.filter(
        (item) => item.child_job._id !== action.payload
      );
      state.multiple_recruiter_job_list = newList;
    },
    changeIsPartnerInAddJob: (state, action) => {
      state.partner_in_add_job = action.payload;
    },
    changeIsLoading: (state, action) => {
      state.is_loading = action.payload;
    },
    changePartnerDataForFilter: (state, action) => {
      state.partner_data_for_filter = action.payload;
    },
    changeRecruiterDataForFilter: (state, action) => {
      state.recruiter_data_for_filter = action.payload;
    },
    changeSelectedData: (state, action) => {
      state.selected_data = action.payload;
    },
    changeJobDescription: (state, action) => {
      state.job_description = action.payload;
    },
    changeSkillMatrix: (state, action) => {
      state.skill_matrix = action.payload;
    },
    changeSelectedClientValue: (state, action) => {
      state.selected_value_client = action.payload;
    },
    changeClientList: (state, action) => {
      state.client_list = action.payload;
    },
    changeJobSelectedData: (state, action) => {
      state.job_selected_data = action.payload;
    },
    changeJobDataForDashboard: (state, action) => {
      state.job_data_for_dashboard = action.payload;
    },
    changeClientDueDate: (state, action) => {
      let is_Sync = false;
      state.client_due_date.forEach((element, index) => {
        if (element.partner_id === action.payload.partner_id) {
          is_Sync = true;
          state.client_due_date[index] = action.payload;
        }
      });
      if (!is_Sync) {
        state.client_due_date.unshift(action.payload);
      }
    },
    changeIsSubmission: (state, action) => {
      state.is_submission = action.payload;
    },
    setSelectedJobForLockApplicant: (state, action) => {
      state.selectedJobForLockApplicant = action.payload;
    },
    changeIsAttachmentOpen: (state, action) => {
      state.is_attachment_open = action.payload;
    },
    changeBillRate: (state, action) => {
      state.bill_rate = action.payload;
    },
    changeRecruiterData: (state, action) => {
      state.recruiter_data = action.payload;
    },
    changeTempJobList: (state, action) => {
      state.temp_job_list = action.payload;
    },
    changeJobSearchText: (state, action) => {
      state.searchJobText = action.payload;
    },
    changeJobStatusName: (state, action) => {
      state.job_status_name = action.payload;
    },
    changeIsJobStatusName: (state, action) => {
      state.is_change_job_status_name = action.payload;
    },
    changeTempPartnerList: (state, action) => {
      state.temp_partner_list = action.payload;
    },
    changeTempRecruiterList: (state, action) => {
      state.temp_recruiter_list = action.payload.filter(
        (data) => data.is_recruiter === 1
      );
    },
    changeSelectedDueDate: (state, action) => {
      state.selected_due_date = action.payload;
    },
    changeSelectedRecruiterId: (state, action) => {
      state.selected_recruiter_id = action.payload;
    },
    changeSelectedCleintId: (state, action) => {
      state.selected_client_id = action.payload;
    },
    changeSelectedPartnerId: (state, action) => {
      state.selected_partner_id = action.payload;
    },
    changeSelectedPartnerList: (state, action) => {
      state.selected_partner_list = action.payload;
    },
    changeSearchjob: (state, action) => {
      state.search_result = action.payload;
    },
    onLoadMoreSearchJob: (state, action) => {
      state.search_result.unshift(...action.payload);
    },
    displayPartnerId: (state, action) => {
      state.partner_id = action.payload;
    },
    changePartnerId: (state, action) => {
      state.partner_id.unshift(action.payload);
    },
    removePartnerId: (state, action) => {
      const newData = [];
      state.partner_id = newData;
    },
    deletePartnerId: (state, action) => {
      const newList = state.partner_id.filter(
        (item) => item.id !== action.payload
      );
      state.partner_id = newList;
    },
    updatePartnerId: (state, action) => {
      var data = state.partner_id.map(function (item) {
        return item.id === action.payload.id ? action.payload : item;
      });
      state.partner_id = data;
    },
    changeSelectedFileName: (state, action) => {
      state.selected_file_name.unshift(action.payload);
    },
    changeTempSelectedFileName: (state, action) => {
      state.temp_selected_file.unshift(action.payload);
    },
    updateSelectedFileName: (state, action) => {
      state.selected_file_name = action.payload;
    },
    removeSelectedFileName: (state, action) => {
      state.temp_selected_file = action.payload;
    },
    removeTempSelectedFileName: (state, action) => {
      state.selected_file_name = action.payload;
    },
    deleteSelectedFileName: (state, action) => {
      const newList = state.selected_file_name.filter(
        (item) => item._id !== action.payload._id
      );
      state.selected_file_name = newList;
    },
    deleteTempSelectedFileName: (state, action) => {
      const newList = state.temp_selected_file.filter(
        (item) => item.name !== action.payload.name
      );
      state.temp_selected_file = newList;
    },

    onUpdateOfferDetilsBoolean: (state, action) => {
      state.is_offer_details_update = action.payload;
    },
  },
});

export const {
  onChangeAttachmentList,
  deleteMultipleJob,
  onChangeTabName,
  onChangeSubmissionDataForJob,
  onChangeMultipleRecruiterJobCount,
  onChangeIsLoadingMultipleRecruiterJobList,
  onChangeSearchText,
  onChangeIsActiveMultiple,
  deleteMultipleRecruiterJob,
  addMultipleRecruiterJob,
  onChangeMultipleRecruiterJobList,
  onChangeRecruiterList,
  changeInternalDomain,
  onLoadMoreSearchJob,
  changeIsOpenJobStatusDropdown,
  changeClientListForFilter,
  changeFilterData,
  changeInitialTableList,
  removeFromTableList,
  changeTableList,
  deleteSearchJob,
  changeIsPartnerInAddJob,
  changeIsLoading,
  changeRecruiterDataForFilter,
  changePartnerDataForFilter,
  changeIsJobStatusName,
  changeSelectedData,
  changeJobDescription,
  changeSkillMatrix,
  changeSelectedClientValue,
  changeClientList,
  changeJobSelectedData,
  changeJobDataForDashboard,
  changeClientDueDate,
  changeIsSubmission,
  changeIsAttachmentOpen,
  deleteTempSelectedFileName,
  removeTempSelectedFileName,
  changeTempSelectedFileName,
  deleteSelectedFileName,
  removeSelectedFileName,
  updateSelectedFileName,
  changeSelectedFileName,
  changeBillRate,
  removePartnerId,
  changeRecruiterData,
  changeJobSearchText,
  changeJobStatusName,
  changeTempPartnerList,
  changeTempRecruiterList,
  changeSelectedDueDate,
  changeSelectedRecruiterId,
  changeSelectedCleintId,
  changeSelectedPartnerId,
  changePartnerId,
  changeSelectedPartnerList,
  changeSearchjob,
  deletePartnerId,
  updatePartnerId,
  displayPartnerId,
  changeTempJobList,
  onUpdateOfferDetilsBoolean,
  setSelectedJobForLockApplicant,
} = JobReduxStore.actions;

export default JobReduxStore.reducer;
