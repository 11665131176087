import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import PlaceholderLoader from "../../CommonComponet/PlaceholderLoader";
import { Icon } from "@iconify/react";
import CustomTooltip from "../../CommonComponet/Tooltip";
import { TitleHR, TitleKey, TitleValue } from "../../../style/common.style";
import { CommonString } from "../../../Utils/CommonString";
import { CommonIcon } from "../../../Utils/CommonIcon";
import { CopyIcon } from "../../../style/Icon.style";

export default function RowTextHtml(props) {
  const [display, setDisplay] = useState(false);

  function afterCopyDisplayText() {
    setDisplay(true);
    setTimeout(() => {
      setDisplay(false);
    }, 2000);
  }

  const handleChildClick = function (e) {
    e.stopPropagation();
  };

  const copyToClipboard = (elementId) => {
    const temp = document.createElement("div");
    temp.setAttribute("contentEditable", true);
    temp.innerHTML = document.getElementById(elementId).innerHTML;
    temp.setAttribute(
      "onfocus",
      "document.execCommand('selectAll',false,null)"
    );
    document.body.appendChild(temp);
    temp.focus();
    document.execCommand("copy");
    document.body.removeChild(temp);
  };

  return props.value === "" || props.value === "--" ? (
    <></>
  ) : (
    <div style={{ marginTop: 5 }}>
      <div onMouseLeave={() => setDisplay(false)} className="textWithCopyIcon">
        <CustomTooltip
          onClick={handleChildClick}
          placement={CommonString.top_placement}
          title={display ? CommonString.copied : CommonString.copy}
          name={
            <p>
              <b> {props.title}</b>
              <CopyIcon
                style={{ display: props.value === "" ? "none" : "initial" }}
                onClick={() => {
                  copyToClipboard(props.id);
                  afterCopyDisplayText();
                }}
                icon={CommonIcon.copy}
              />
            </p>
          }
        />
      </div>
      {props.RowText ? (
        <PlaceholderLoader />
      ) : (
        <div
          id={props.id}
          className="tablecoming"
          dangerouslySetInnerHTML={{
            __html: props.value?.toString().replace(`className="table"`, ""),
          }}
        />
      )}
    </div>
  );
}
