import React, { useState, useEffect } from "react";
import TableToolbar from "./TableToolbar";
import AddClient from "../Modal/AddClient";
import BaseModal from "../Modal/BaseModal";
import {
  CLIENT,
  ADD_CLIENT,
  UPDATE_CLIENT,
  VIEW_CLIENT,
  CLIENTS,
} from "../../Utils/enum";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DeleteConfirmation from "../Modal/DeleteConfirmation";
import { useAlert } from "react-alert";
import ClientViewDetails from "../ViewDetails/ClientViewDetails";
import ClientController from "../../controller/ClientController";
import {
  ADD_CLIENT_ERROR,
  ADD_CLIENT_SUCCESS,
  DELETE_CLIENT_ERROR,
  DELETE_CLIENT_SUCCESS,
  UPDATE_CLIENT_ERROR,
  UPDATE_CLIENT_SUCCESS,
} from "../../Utils/alertMessage";
import ClientTable from "./Table/ClientTable";
import { clientModalStatus } from "../../Utils/utilitiFunction";
import { useSelector } from "react-redux";
import {
  CommonString,
  CommonStringForLocalStorage,
} from "../../Utils/CommonString";
import { onDisplayPocDetail } from "../../feature/store/ClientReduxStore";
import { useDispatch } from "react-redux";
import { TableMainContainer } from "../../style/table.styled";
import { onChangeIsLoading } from "../../feature/store/LevelReduxStore";

export default function ClientDataTable(props) {
  const alert = useAlert();
  const dispatch = useDispatch();
  const clientController = new ClientController();
  const tclientlList = useSelector((state) => state.counter.clientData);
  const [isopenModal, setisOpenModal] = useState(false);
  const [modalStatus, setModalStatus] = useState(ADD_CLIENT);
  const [selectedData, setselectedData] = useState(null);
  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    getData();
  });

  function onViewModal(modalSt, data) {
    setisOpenModal(true);
    setModalStatus(modalSt);
    setselectedData(data);
  }

  function getData() {
    if (props.searchText != null && props.searchText.length > 0) {
      return props.searchResult;
    }
    return tclientlList;
  }

  function onDelete(data) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <DeleteConfirmation
            name={data.name}
            onClose={onClose}
            onDelete={() => {
              OndeleteData(data);
              onClose();
              setisOpenModal(false);
            }}
          />
        );
      },
    });
  }

  async function OndeleteData(data) {
    dispatch(onChangeIsLoading(true));
    var sendData = {
      _id: data._id,
    };
    var res = await clientController.deleteClient(sendData);
    if (res.data.status === 200) {
      props.onRefresh();
      alert.show(DELETE_CLIENT_SUCCESS, { type: CommonString.success });
      dispatch(onChangeIsLoading(false));
    } else {
      alert.show(DELETE_CLIENT_ERROR, { type: CommonString.error });
      dispatch(onChangeIsLoading(false));
    }
  }

  async function onSubmitData(data) {
    dispatch(onChangeIsLoading(true));
    if (modalStatus === UPDATE_CLIENT) {
      var sendData = {
        _id: selectedData._id,
        ...data,
      };
      var res = await clientController.updateClient(sendData);
      if (res.data.status === 200) {
        dispatch(onDisplayPocDetail([]));
        props.onRefresh();
        setisOpenModal(!isopenModal);
        alert.show(UPDATE_CLIENT_SUCCESS, { type: CommonString.success });
        dispatch(onChangeIsLoading(false));
      } else {
        alert.show(UPDATE_CLIENT_ERROR, { type: CommonString.error });
        dispatch(onChangeIsLoading(false));
      }
      return;
    }
    var add_res = await clientController.addClient(data);
    if (add_res.data.status === 200) {
      dispatch(onDisplayPocDetail([]));
      localStorage.removeItem(CommonStringForLocalStorage.client_local_data);
      props.onRefresh();
      setisOpenModal(!isopenModal);
      alert.show(ADD_CLIENT_SUCCESS, { type: CommonString.success });
      dispatch(onChangeIsLoading(false));
    } else {
      alert.show(ADD_CLIENT_ERROR, { type: CommonString.error });
      dispatch(onChangeIsLoading(false));
    }
  }

  return (
    <>
      <TableToolbar
        isLoading={props.isLoading}
        title={CLIENTS}
        isVisible={true}
        buttonName={CLIENT}
        onClick={() => onViewModal(ADD_CLIENT, null)}
      />
      <BaseModal
        isHide={CommonString.true}
        isUpdate={modalStatus === UPDATE_CLIENT}
        isLoading={isLoading}
        isView={modalStatus === VIEW_CLIENT ? true : false}
        editClose={() => onViewModal(UPDATE_CLIENT, selectedData)}
        deleteClose={() => onDelete(selectedData)}
        open={isopenModal}
        isBig={true}
        formTitle={clientModalStatus(modalStatus, selectedData)}
        handleClose={() => {
          setisOpenModal(!isopenModal);
          dispatch(onDisplayPocDetail([]));
        }}
      >
        {modalStatus === VIEW_CLIENT ? (
          <ClientViewDetails data={selectedData} />
        ) : (
          <AddClient
            isUpdate={modalStatus === UPDATE_CLIENT}
            data={selectedData}
            onSubmitData={onSubmitData}
          />
        )}
      </BaseModal>
      <TableMainContainer>
        <ClientTable
          isLoading={props.isLoading}
          onDelete={onDelete}
          onViewModal={onViewModal}
          clientlList={getData()}
          onRefresh={() => props.onRefresh()}
        />
      </TableMainContainer>
    </>
  );
}
