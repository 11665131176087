import { Icon } from "@iconify/react";
import styled from "styled-components";
import { CommonColor } from "../Utils/color";

const LoaderForLoginContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LoaderForLoginImg = styled.img`
  width: 300px;
`;
const LoginForm = styled.form`
  /* margin-bottom: 10px; */
`;
const LoginImgContainer = styled.div`
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  hr {
    margin: auto;
    align-self: center;
    width: 20% !important;
    border-bottom: 1px solid #ccc;
  }
`;
const LoginImg = styled.img`
  padding-bottom: 20px;
  width: 180px;
  height: 100px;
`;
const ErrorText = styled.p`
  font-size: 11px;
  color: ${CommonColor.error_text_color};
`;
const LoginTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 20px; */
`;
const LoginTitle = styled.h4``;
const SecondaryColorText = styled.span`
  color: ${CommonColor.secondary_color};
`;
const PrimaryColorText = styled.span`
  color: ${CommonColor.primary_color};
`;
const LightText = styled.p`
  color: ${CommonColor.light_text};
  margin-bottom: 5px !important;
`;
const LoginIcon = styled(Icon)`
  width: 25px;
  height: 25px;
`;
const ContainerForLogin = styled.div`
  background-color: white;
  justify-content: center;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  height: 100vh;
`;
const ConfirmationAlertContainer = styled.div`
  background: ${CommonColor.white};
  padding: 25px;
  border-radius: 5px;
  width: fit-content;
`;
export {
  ConfirmationAlertContainer,
  ContainerForLogin,
  LoginIcon,
  LightText,
  LoginTitleContainer,
  PrimaryColorText,
  SecondaryColorText,
  LoginTitle,
  ErrorText,
  LoginImg,
  LoaderForLoginContainer,
  LoaderForLoginImg,
  LoginForm,
  LoginImgContainer,
};
