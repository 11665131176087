import React, { useEffect, useImperativeHandle, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeDetail,
  changeIsSearching,
  changeSkill,
} from "../../feature/store/ApplicantStore";
import {
  ApplicantSearchContainer,
  SearchContainer,
} from "../../style/common.style";
import {
  ApplicantFirstSearch,
  SearchInput,
  SearchInputApplicant,
} from "../../style/menu.style";
import { FormPlaceholder } from "../../Utils/FormPlaceholder";
import { CommonIcon } from "../../Utils/CommonIcon";
import { Icon } from "@iconify/react";
import { BLANK_TEXT_VALIDATION } from "../../Utils/constant";
import {
  changeSearchText,
  removeSearchApplicantData,
} from "../../feature/store/ReduxStore";

function Search(
  {
    onChange,
    textValue1,
    setStatus,
    autoFocus,
    placeholder,
    isApplicant,
    onClickSearch,
    internalSearch,
  },
  ref
) {
  const dispatch = useDispatch();
  const searchText = useSelector((state) => state.counter.searchText);
  const [searchValue, setSearchValue] = useState("");
  const [skillValue, setskillValue] = useState("");
  const [detailValue, setdetailValue] = useState("");

  function onBlur() {
    setStatus(true);
  }
  useEffect(() => {
    applicantSearch();
  }, [skillValue, detailValue]);

  useImperativeHandle(ref, () => ({
    storeExpandedRecords() {
      alert("getAlert from Child");
    },
  }));

  function applicantSearch() {
    dispatch(changeSkill(skillValue));
    dispatch(changeDetail(detailValue));
    var data = {
      search_query:
        skillValue === null || skillValue.length === 0
          ? "abcdefghijklmnopqrstuvwxyz090"
          : skillValue.trim(),
      search_word:
        detailValue === null || detailValue.length === 0
          ? "abcdefghijklmnopqrstuvwxyz090"
          : detailValue.trim(),
    };
    if (
      data.search_query === BLANK_TEXT_VALIDATION &&
      data.search_word === BLANK_TEXT_VALIDATION
    ) {
      dispatch(changeIsSearching(false));
      dispatch(removeSearchApplicantData([]));
    }
    dispatch(changeSearchText(data));
  }

  return isApplicant ? (
    <ApplicantSearchContainer>
      <SearchInputApplicant
        value={skillValue}
        onChange={(e) => setskillValue(e.target.value)}
        placeholder={FormPlaceholder.skillsAndKeyword}
      />
      <ApplicantFirstSearch
        value={detailValue}
        // onPaste={async () => {
        //   let text = await navigator.clipboard.readText();
        //   dispatch(changeDetail(text));
        // }}
        onChange={(e) => setdetailValue(e.target.value)}
        placeholder={FormPlaceholder.job_info}
      />
    </ApplicantSearchContainer>
  ) : (
    <SearchContainer searchValue={searchValue} searchText={searchText}>
      {internalSearch ? (
        <>
          <SearchInput
            type="text"
            isFocused={searchValue.length !== 0}
            value={textValue1}
            searchValue={searchValue}
            onChange={(e) => {
              onChange(e);
              setSearchValue(e.target.value);
            }}
            onFocus={() => {
              setStatus(false);
            }}
            onBlur={onBlur}
            placeholder={placeholder}
            autoFocus={autoFocus}
          />
          <Icon
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              onClickSearch(e);
            }}
            icon={CommonIcon.search_icon}
          />
        </>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            onClickSearch(e);
          }}
          style={{ width: "100%", display: "flex", flexDirection: "row" }}
        >
          <SearchInput
            isFocused={searchValue.length !== 0}
            type="text"
            // value={textValue1}
            searchValue={searchValue}
            onChange={(e) => {
              onChange(e);
              setSearchValue(e.target.value);
            }}
            onFocus={() => {
              setStatus(false);
            }}
            onBlur={onBlur}
            placeholder={placeholder}
            autoFocus={autoFocus}
          />
          <Icon
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              onClickSearch(e);
            }}
            icon={CommonIcon.search_icon}
          />
        </form>
      )}
    </SearchContainer>
  );
}
export default React.forwardRef(Search);
