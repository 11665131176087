import ApiUtils from "../APICallServices/APIUtils";

export default class AuthProvider {
  constructor() {
    this.Apiprovider = new ApiUtils();
  }
  login = (data) => {
    return this.Apiprovider.init().post("auth/login", data);
  };
}
