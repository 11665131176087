import React, { useEffect } from "react";
import VerticalMenu from "../../CommonComponet/VerticalMenu";
import { UPDATE_RECRUITERS, VIEW_RECRUITERS } from "../../../Utils/enum";
import {
  CustomTableContainer,
  TableRow,
  TableTbody,
  TableTd,
  TableTh,
  Table,
  IsRecruiterContainer,
  FlexContainerForRecruiterName,
  RecruiterNameImg,
  RecruiterNameImgContainer,
} from "../../../style/table.styled";
import { PROFILE_BASEURL, RECRUITER_TH } from "../../../Utils/constant";
import Container from "../../CommonComponet/Container";
import { formatPhoneNumber } from "../../../services/Validate";
import { CommonString } from "../../../Utils/CommonString";
import User from "../../../assets/images/User.png";
import { getAccessForRecruiter } from "../../../Utils/utilitiFunction";

export default function RecruiterTable(props) {
  function getData() {
    if (props.recruiterList == null) return;
    var lowestToHighest = props.recruiterList
      .slice()
      .sort(
        (a, b) =>
          parseInt(a.employee_id.replaceAll(CommonString.prexelite, "")) -
          parseInt(b.employee_id.replaceAll(CommonString.prexelite, ""))
      );
    return lowestToHighest;
  }

  return (
    <CustomTableContainer>
      <Table>
        <TableTbody>
          <TableRow>
            {RECRUITER_TH.map((singleTH, index) => (
              <TableTh align={singleTH.align} key={index}>
                {singleTH.name}
              </TableTh>
            ))}
          </TableRow>
          {getData().length === 0 ? (
            <div />
          ) : (
            getData().map((data, index) => {
              return (
                <TableRow
                  key={index}
                  onDoubleClick={() => props.onViewModal(VIEW_RECRUITERS, data)}
                  onClick={() =>
                    window.innerWidth < 500
                      ? props.onViewModal(VIEW_RECRUITERS, data)
                      : null
                  }
                >
                  <TableTd>{index + 1}</TableTd>
                  <TableTd>
                    <FlexContainerForRecruiterName>
                      <RecruiterNameImgContainer>
                        <RecruiterNameImg
                          src={
                            data.profile_image_url !== undefined
                              ? PROFILE_BASEURL + data.profile_image_url
                              : User
                          }
                        />
                      </RecruiterNameImgContainer>
                      {data.name}
                    </FlexContainerForRecruiterName>
                  </TableTd>
                  <TableTd isemail={CommonString.true}>{data.email}</TableTd>
                  <TableTd isUpperCase={true}>{data.employee_id}</TableTd>
                  <TableTd>{formatPhoneNumber(data.phone)}</TableTd>
                  <TableTd isCenter={true}>
                    <IsRecruiterContainer
                      recruiter={data.is_recruiter}
                      icon="carbon:dot-mark"
                    />
                  </TableTd>
                  <TableTd>
                    <VerticalMenu
                      data={getAccessForRecruiter()}
                      onView={() => props.onViewModal(VIEW_RECRUITERS, data)}
                      onEdit={() => props.onViewModal(UPDATE_RECRUITERS, data)}
                      onDelete={() => props.onDelete(data)}
                    />
                  </TableTd>
                </TableRow>
              );
            })
          )}
        </TableTbody>
      </Table>
      <Container
        isLoading={props.isLoading === true}
        isVisible={getData().length === 0}
      />
    </CustomTableContainer>
  );
}
