import moment from "moment";
import React from "react";
import {
  IntrviewShowDetail,
  SubmissionStatusContainer,
} from "../../../style/common.style";
import { CommonString } from "../../../Utils/CommonString";
import { DATE_FORMATE, SUBMISSION_STATUS } from "../../../Utils/constant";
import SubmissionStatus from "./SubmissionStatus";
// import { convertTo12HourFormat } from "../../../Utils/utilitiFunction";

export default function InterviewDetailView(props) {
  // var time =
  //   props?.data?.interviewData?.data?.interview_time !== null
  //     ? `${convertTo12HourFormat(
  //         props?.data?.interviewData?.data?.interview_time
  //       )} ${props?.data?.interviewData?.data?.time_zone}`
  //     : props?.data?.interviewData?.data?.time;
  function getStatus() {
    if (props.data.status === SUBMISSION_STATUS.INTERVIEW) {
      return (
        <SubmissionStatusContainer>
          <IntrviewShowDetail onClick={props?.onClickInterviewDetail}>
            {CommonString.interview_details +
              " (" +
              moment(props?.data?.interviewData?.data?.date).format(
                DATE_FORMATE
              ) +
              ")"}
          </IntrviewShowDetail>
        </SubmissionStatusContainer>
      );
    } else if (props?.data?.status === SUBMISSION_STATUS.SUBMISSION) {
      return (
        <SubmissionStatus
          data={props?.data}
          onClick={props.onClickSubmissionTracker}
        />
      );
    }
    return "";
  }
  return getStatus();
}
