import React from "react";
import { TEXT_TERM_OBJ } from "../../Utils/constant";
import PayRateSectionTile from "./PayRateSectionTile";
import { useSelector, useDispatch } from "react-redux";
import { updatePartnerId } from "../../feature/store/JobReduxStore";
import { JobtableForbillrate, JobtableForbillrateTh } from "../../style/table.styled";

export default function PayRateSection(props) {
  const dispatch = useDispatch();
  const partner_id = useSelector((state) => state.JobReduxStore.partner_id);

  function addData(data) {
    dispatch(updatePartnerId(data));
  }

  return partner_id.length === 0 ? (
    <div />
  ) : (
    <JobtableForbillrate>
      <tbody>
        <tr>
          <JobtableForbillrateTh>Partner and Assigned Recruiter</JobtableForbillrateTh>
          <JobtableForbillrateTh>Margin</JobtableForbillrateTh>
          <JobtableForbillrateTh>{TEXT_TERM_OBJ._1099} %</JobtableForbillrateTh>
          <JobtableForbillrateTh>W2 wo B %</JobtableForbillrateTh>
          <JobtableForbillrateTh>W2 w B %</JobtableForbillrateTh>
          <JobtableForbillrateTh>W2 Sal %</JobtableForbillrateTh>
        </tr>
        {partner_id.map((item, index) => (
          <PayRateSectionTile key={index} partner={item} onAddData={(e) => addData(e)} />
        ))}
      </tbody>
    </JobtableForbillrate>
  );
}
