import React from "react";
import { formatPhoneNumber } from "../../services/Validate";
import { ViewDetailContainer } from "../../style/common.style";
import { CommonString, CommonStringForPartner } from "../../Utils/CommonString";
import RowText from "./view_component/RowText";

export default function PartnerViewDetails(props) {
  return (
    <ViewDetailContainer>
      <RowText
        title={CommonStringForPartner.Partner_id}
        value={props.data.partner_id}
      />
      <RowText title={CommonStringForPartner.name} value={props.data.name} />
      <RowText
        title={CommonStringForPartner.poc_name}
        value={props.data.poc_name}
      />
      <RowText
        isemail={CommonString.true}
        title={CommonStringForPartner.email}
        value={props.data.email}
      />
      <RowText
        nodivider={true}
        title={CommonStringForPartner.phone}
        value={formatPhoneNumber(props.data.phone)}
      />
    </ViewDetailContainer>
  );
}
